import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  getAllProductionVariables,
  productionVariablesUpdate
} from '../../services/productionVariables/productionVariables';
import { messageError, messageSuccess } from '../../utils/messages';
import { Dialog } from 'primereact/dialog';

const DOLLAR_SIGN = '$';
const defaultVariable = {
  description: '',
  value: 0
};

function VariablesList() {
  const [productionVariables, setProductionVariables] = useState([]);
  const [loading, setLoading] = useState(true);
  const [displayDialog, setDisplayDialog] = useState(false);
  const [globalFilter, setGlobalFilter] = React.useState('');
  const [fetchProductionVariables, setFetchProductionVariables] = React.useState(true);
  const [selectedVariable, setSelectedVariable] = React.useState(defaultVariable);
  const { t } = useTranslation();

  useEffect(() => {
    getAllProductionVariables().then(result => {
      setProductionVariables(result.data);
      setLoading(false);
    }).catch(error => {
      (error.response) ? messageError(error.response.data.message) : messageError(t('withoutConnection'));
    });
    setFetchProductionVariables(false);
  }, [t, fetchProductionVariables]);

  const updateVariable = () => {
    const newValue = {
      id: selectedVariable.id,
      value: selectedVariable.value
    };
    productionVariablesUpdate(newValue).then(() => {
      messageSuccess(t('messageSuccessVariableUpdated'));
      setFetchProductionVariables(true);
    }).catch(error => {
      (error.response) ? messageError(error.response.data.message) : messageError(t('messageErrorVariableUpdated'));
    });
    setDisplayDialog(false);
    setSelectedVariable(defaultVariable);
  };

  const actionEdit = (rowData) => {
    return <div style={{ content: 'center', textAlign: 'center' }} id={'div-' + rowData.id}>
      <Button type="button" icon="pi pi-pencil" id={'button-edit-' + rowData.id}
        onClick={() => { setSelectedVariable(rowData); setDisplayDialog(true); }} className="tooltip button-table">
        <span className="tooltiptext tooltiptext-bottom"></span>
      </Button>
    </div>;
  };

  const getProductionVariableName = (rowData) => {
    return t(`${rowData.description}`);
  };

  const showValue = (rowData) => {
    return (rowData.unit === DOLLAR_SIGN)
      ? `${rowData.unit}${rowData.value}`
      : `${rowData.value} ${rowData.unit}`;
  };

  const updateDialog = () => (
    <Dialog visible={displayDialog} footer={dialogFooter}
      header={t(`${selectedVariable.description}`)} modal={true} onHide={() => setDisplayDialog(false)}>
      <div className="p-grid p-fluid">
        <div className="p-col-4" style={{ padding: '.75em' }}><label htmlFor="value">{t('value')}</label></div>
        <div className="p-col-8" style={{ padding: '.5em' }}>
          <InputText keyfilter="pnum" id="value" value={selectedVariable.value}
            onChange={(e) => { setSelectedVariable({ ...selectedVariable, value: e.target.value }); }} />
        </div>
      </div>
    </Dialog>
  );

  const dialogFooter =
    <div className="p-clearfix" style={{ width: '100%' }}>
      <Button id='cancel' style={{ float: 'left' }} label={t('cancel')}
        icon="pi pi-times" onClick={(e) => { e.preventDefault(); setDisplayDialog(false); }} />
      <Button id='save' style={{ float: 'right' }} label={t('save')} icon="pi pi-save" onClick={updateVariable} />
    </div>;

  return (
    <div>
      <div className="ui-g ui-fluid" >
        <div className="ui-g-12">
          <div className="card">
            <h1>{t('variables')}</h1>
            <div className="content-section implementation">
              <div className="p-grid p-fluid">
                <div className="p-col-9 p-md-4">
                  <div className="p-inputgroup">
                    <InputText id="input-search" className="input"
                      onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
                  </div>
                </div>
              </div>
              {updateDialog()}
              <div className="ui-g-12">
                <div className="datatable">
                  {
                    loading && <div id="div-loader" className="spiner-container">
                      <i className="pi pi-spin pi-spinner spiner" ></i>
                    </div>
                  }
                  {!loading &&
                    <DataTable paginator={true} emptyMessage={t('emptyList')} globalFilter={globalFilter}
                      rows={10} responsive={true} value={productionVariables}>
                      <Column className="ui-column" body={getProductionVariableName} header={t('name')} />
                      <Column className="ui-column" body={showValue} header={t('value')} />
                      <Column className="ui-column-edit" header={t('edit')} body={actionEdit} />
                    </DataTable>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default VariablesList;
