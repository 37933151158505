import { axiosInstance } from '../config';
import { getUserSession, getHeaders } from '../authentication';
import { pickBy, identity } from 'lodash';

export const getProductsByKeyAndClientId = (key, id) => {
  const config = getHeaders(getUserSession());
  return axiosInstance.get('products/byKeyAndClientId/' + key + '/' + id, config);
};

export const getLastVersionProductByCode = (code) => {
  const config = getHeaders(getUserSession());
  return axiosInstance.get('products/lastVersionProductByCode/' + code, config);
};

export const getProductById = (id) => {
  const config = getHeaders(getUserSession());
  return axiosInstance.get('products/byId/' + id, config);
};

export const getProductsByParams = (params) => {
  let paramsSend = pickBy(params, identity);
  const config = {
    headers: getHeaders(getUserSession()).headers,
    params: paramsSend
  };
  return axiosInstance.get('products/byParams/list',config);
};

export const productCreate = (product) => {
  const config = getHeaders(getUserSession());
  return axiosInstance.post('product',product,config);
};

export const productUpdate = (product) => {
  const config = getHeaders(getUserSession());
  return axiosInstance.put('products/update',product,config);
};

export const approveProductById = (id) => {
  const config = getHeaders(getUserSession());
  return axiosInstance.put('products/approveProductById/' + id,null, config);
};

export const verifyCode = (code) => {
  const config = getHeaders(getUserSession());
  return axiosInstance.get('products/byCode/' + code, config).then(() => {return false;}).catch( () => {return true;});
};

export const turnSampleIntoNewProduct = (product) => {
  const config = getHeaders(getUserSession());
  return axiosInstance.post('products/turnSampleIntoProduct/product',product,config);
};
