import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FileUpload } from 'primereact/fileupload';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { messageError, messageSuccess, messageWarning } from '../../../utils/messages';
import { importAccess } from '../../../services/importProcesses/importProcesses';

function AccessImport () {
  const { t } = useTranslation();
  const [selectedFile, setSelectedFile] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [notUploadedProducts, setNotUploadedProducts] = useState([]);

  const uploadFile = () => {
    setIsLoading(true);
    importAccess(selectedFile).then(result => {
      setIsLoading(false);
      if (result.data.length === 0) {
        messageSuccess(t('messageSuccessUploaded'));
      } else {
        messageWarning(t('messageWarningUploaded'));
        setNotUploadedProducts(result.data);
      }
    }).catch(error => {
      setIsLoading(false);
      (error.response) ? messageError(error.response.data.message) : messageError(t('withoutConnection'));
    });
  };

  const reason = (row) => {
    if (row.error) {
      const message = row.error.message.split('\'');
      switch (message[1]) {
      case 'weight': return t('wrongWeightField');
      case 'bct': return t('wrongBctField');
      case 'sheet_width': return t('wrongSheetWidthField');
      case 'sheet_long': return t('wrongSheetLongField');
      case 'body': return t('wrongBodyField');
      case 'internal_long': return t('wrongInternalLongField');
      case 'internal_width': return t('wronginternalWidthField');
      case 'internal_height': return t('wronginternalHeightField');
      default: return t('wrongLineProduct');
      }
    }
    return t('wrongLineProduct');
  };

  return (
    <div className="ui-g ui-fluid" >
      <div className="ui-g-12">
        <div className="card">
          <h1>{t('accessImport')}</h1>
          <div className="ui-g-12 ui-md-7 ui-lg-2">
            <div className="p-grid p-fluid">
              <div className="p-col-12 p-md-9">
                <div className="p-inputgroup">
                  <InputText placeholder='Seleccione un archivo' readOnly value={selectedFile.name}/>
                </div>
              </div>
              <div className="p-col-12 p-md-3">
                <div className="p-inputgroup">
                  <FileUpload
                    id="fileUpload"
                    accept=".xlsx"
                    name="access"
                    mode="basic"
                    auto
                    disabled={isLoading}
                    chooseLabel={t('choose')}
                    onSelect={(e) => setSelectedFile(e.files[0])}
                  />
                  <Button
                    disabled={!selectedFile.name || isLoading}
                    icon="pi pi-upload"
                    label={t('upload')}
                    onClick={uploadFile}
                  />
                </div>
              </div>
            </div>
            {isLoading &&
            <div id="div-loader" className="ui-g-12 ui-md-7 ui-lg-2">
              <i id='loader' className="pi pi-spin pi-spinner"></i>
            </div>}
            {!isLoading && notUploadedProducts.length > 0 &&
              <div className="ui-g-12">
                <div className="p-col-12"><h4>{t('notUpdatedProducts')}</h4></div>
                <DataTable
                  paginator={true}
                  responsive={true}
                  rows={10}
                  rowsPerPageOptions={[5,10,20]}
                  value={notUploadedProducts}
                >
                  <Column field="CLIENTE" header={t('client')} />
                  <Column field="CODIGOS" header={t('code')} />
                  <Column field="VERSIÓN" header={t('version')} />
                  <Column body={reason} header={t('reason')} style={{width: '400px'}}/>
                </DataTable>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccessImport;
