import { AutoComplete } from 'primereact/autocomplete';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { RadioButton } from 'primereact/radiobutton';
import React, { useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { getBoxes } from '../../../services/catalogBox/catalogBox';
import { InputTextarea } from 'primereact/inputtextarea';
import { constantsObject } from '../../../utils/constantsObject';
import { productCreate, verifyCode } from '../../../services/product/product';
import { messageError, messageSuccess } from '../../../utils/messages';
import { routes } from '../../../utils/routes';
import { getClientsByKey } from '../../../services/client/client';
import { productDetailCreate } from '../../../services/productDetail/productDetail';
import { getFlautesByWall } from '../../../services/catalogFlaute/catalogFlaute';
import { getPapersByTest } from '../../../services/catalogPaper/catalogPaper';
import { getProductTypes } from '../../../services/catalogProductType/catalogProductType';
import { getMeasurementsByFlauteIdAndBoxId } from '../../../services/measurementsByFlauteAndBox/measurementsByFlauteAndBox';
import { getAllProductionVariables } from '../../../services/productionVariables/productionVariables';
import { getActiveTests } from '../../../services/tests/tests';
import { currencyFormatter } from '../../../utils/currency';
import { baseStyle, acceptStyle, activeStyle, rejectStyle } from '../../../utils/dropzoneStyle';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { validates } from './../../../utils/validations';
import './productRegister.css';
import { kraftOneFace, whiteOneFace, whiteTwoFaces } from '../../../utils/generalConstants';

const SHEET = 'Lamina';
const TRAY = 'Bandeja';
const OBSOLETE = 'Obsoleta';
const DIE_CUT = 'troquel';

function ProductRegister({ history }) {
  const { t } = useTranslation();
  const [productName, setProductName] = useState('');
  const [productCode, setProductCode] = useState('');
  const [internalHeight, setInternalHeight] = useState('');
  const [productFlaps, setProductFlaps] = useState('');
  const [productMinimumCombination, setProductMinimumCombination] = useState('');
  const [internalLong, setInternalLong] = useState('');
  const [internalWidth, setInternalWidth] = useState('');
  const [productLiner1, setProductLiner1] = useState('');
  const [productLiner2, setProductLiner2] = useState('');
  const [productLiner3, setProductLiner3] = useState('');
  const [productMedium1, setProductMedium1] = useState('');
  const [productMedium2, setProductMedium2] = useState('');
  const [productColor1, setProductColor1] = useState('');
  const [productColor2, setProductColor2] = useState('');
  const [productColor3, setProductColor3] = useState('');
  const [productColor4, setProductColor4] = useState('');
  const [widthTolerance, setWidthTolerance] = useState('');
  const [longTolerance, setLongTolerance] = useState('');
  const wastePercentage = 0;
  const [waterproofedFaces, setWaterproofedFaces] = useState(0);
  const [productIsDieCut, setProductIsDieCut] = useState(false);
  const [productIsCliche, setProductIsCliche] = useState(false);
  const [regularWaterproofing, setRegularWaterproofing] = useState(false);
  const [cobbWaterproofing, setCobbWaterproofing] = useState(false);
  const [productIsPaletized, setProductIsPaletized] = useState(false);
  const [isSample, setIsSample] = useState(false);
  const [selectedBox, setSelectedBox] = useState({});
  const [selectedProductType, setSelectedProductType] = useState({});
  const [selectedClient, setSelectedClient] = useState('');
  const [clients, setClients] = useState([]);
  const [selectedFlaute, setSelectedFlaute] = useState('');
  const [selectedPaper, setSelectedPaper] = useState('');
  const [selectedTest, setSelectedTest] = useState('');
  const [productSheetLong, setProductSheetLong] = useState('');
  const [productSheetWidth, setProductSheetWidth] = useState('');
  const [selectedDetailIndex, setSelectedDetailIndex] = useState(0);
  const [unitPrice, setUnitPrice] = useState(0);
  const [papers, setPapers] = useState([]);
  const [flautes, setFlautes] = useState([]);
  const [tests, setTests] = useState([]);
  const [boxes, setBoxes] = useState([]);
  const [productDetails, setProductDetails] = useState([]);
  const [productDetail, setProductDetail] = useState({ name: '', description: '', url: '' });
  const [displayDialog, setDisplayDialog] = useState(false);
  const [displaySampleOrProductDialog, setDisplaySampleOrProductDialog] = useState(true);
  const [productTypes, setProductTypes] = useState([]);
  const [productPriceByTc, setProductPriceByTc] = useState(0);
  const [productThirdProcesses, setProductThirdProcesses] = useState('');
  const [productAmountCliche, setProductAmountCliche] = useState();
  const [productAmountDieCut, setProductAmountDieCut] = useState(0);
  const [productAmountOneRegularFaceWaterproofing, setProductAmountOneRegularFaceWaterproofing] = useState();
  const [productAmountTwoRegularFacesWaterproofing, setProductAmountTwoRegularFacesWaterproofing] = useState();
  const [productAmountOneCobbFaceWaterproofing, setProductAmountOneCobbFaceWaterproofing] = useState();
  const [productAmountTwoCobbFacesWaterproofing, setProductAmountTwoCobbFacesWaterproofing] = useState();
  const [constants, setConstants] = useState();
  const [isRendered, setIsRendered] = useState(true);
  const fileInput = document.querySelector('#fileInput');
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    noDrag: true,
    accept: 'image/*, .pdf, .xlsx',
    getFilesFromEvent: event => myCustomFileGetter(event)
  });

  const myCustomFileGetter = (event) => {
    const files = [];
    const fileList = event.dataTransfer ? event.dataTransfer.files : event.target.files;
    Array.from(fileList).forEach(file => {
      Object.defineProperty(file, 'myProp', {
        value: true
      });
      files.push({
        download: URL.createObjectURL(file),
        url: file,
        name: file.name,
        description: file.name
      });
    });
    setProductDetails([...productDetails, ...files]);
  };

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ]);

  useEffect(() => {
    getAllProductionVariables().then(result => {
      const variables = constantsObject(result.data);
      setConstants(variables);
      setProductAmountOneRegularFaceWaterproofing(variables.oneFaceRegularWaterproofing);
      setProductAmountTwoRegularFacesWaterproofing(variables.twoFacesRegularWaterproofing);
      setProductAmountOneCobbFaceWaterproofing(variables.oneFaceCobbWaterproofing);
      setProductAmountTwoCobbFacesWaterproofing(variables.twoFacesCobbWaterproofing);
      setProductAmountCliche(variables.maxCliche);
    }).catch(error => {
      (error.response) ? messageError(error.response.data.message) : messageError(t('withoutConnection'));
    });
    getActiveTests().then(result => {
      setTests(result.data);
    }).catch(error => {
      (error.response) ? messageError(error.response.data.message) : messageError(t('withoutConnection'));
    });
    getBoxes().then(resultBox => {
      const boxes = resultBox.data.filter(element => element.name !== OBSOLETE && element.name !== TRAY);
      setBoxes(boxes);
    }).catch(error => {
      (error.response) ? messageError(error.response.data.message) : messageError(t('withoutConnection'));
    });
    getProductTypes().then(result => {
      setProductTypes(result.data);
    }).catch(error => {
      (error.response) ? messageError(error.response.data.message) : messageError(t('withoutConnection'));
    });
  }, [t]);

  const handleSubmit = async (event) => {
    setIsRendered(false);
    event.preventDefault();
    const product = {
      code: productCode,
      idCatalogBox: selectedBox.id,
      name: productName,
      weight: Number(calculateWeight()),
      idTest: selectedTest.id,
      idCatalogFlaute: selectedFlaute.id,
      idCatalogPaper: selectedPaper.id,
      liner1: productLiner1,
      liner2: productLiner2,
      liner3: productLiner3,
      medium1: productMedium1,
      medium2: productMedium2,
      flaps: productFlaps,
      idCatalogTypeProduct: selectedProductType.id,
      minimumCombination: productMinimumCombination,
      internalLong: Number(internalLong),
      internalWidth: Number(internalWidth),
      internalHeight: Number(internalHeight),
      version: 1,
      thirdProcesses: Number(productThirdProcesses),
      unitPrice: unitPrice > 0 ? unitPrice : calculateUnitPrice(),
      priceByTc: Number(productPriceByTc),
      wastePercentage: Number(wastePercentage),
      isException: validatesMeasurements(),
      isClose: false
    };
    (productIsPaletized) ? product.isPaletized = 1 : product.isPaletized = 0;
    if (productIsCliche) {
      product.isCliche = 1;
      product.amountCliche = productAmountCliche;
      product.printing = 1;
      product.color1 = productColor1 ? productColor1 : '';
      product.color2 = productColor2 ? productColor2 : '';
      product.color3 = productColor3 ? productColor3 : '';
      product.color4 = productColor4 ? productColor4 : '';
      product.colorQuantity = calculateColorQuantity();
      product.clicheTotalCost = calculateClicheCost();
    } else {
      product.isCliche = 0;
      product.printing = 0;
    }
    if (regularWaterproofing) {
      product.regularWaterproofing = 1;
      product.waterproofedFaces = waterproofedFaces;

      if (waterproofedFaces === 1) {
        product.amountOneRegularFaceWaterproofing = productAmountOneRegularFaceWaterproofing;
      } else if (waterproofedFaces === 2) {
        product.amountTwoRegularFacesWaterproofing = productAmountTwoRegularFacesWaterproofing;
      }
    }
    if (productIsDieCut) {
      product.isDieCut = 1;
      product.amountDieCut = productAmountDieCut;
      product.dieCutTotalCost = calculateDieCutCost();
    } else {
      product.isDieCut = 0;
    }
    if (cobbWaterproofing) {
      product.cobbWaterproofing = 1;
      product.waterproofedFaces = waterproofedFaces;

      if (waterproofedFaces === 1) {
        product.amountOneCobbFaceWaterproofing = productAmountOneCobbFaceWaterproofing;
      } else if (waterproofedFaces === 2) {
        product.amountTwoCobbFacesWaterproofing = productAmountTwoCobbFacesWaterproofing;
      }
    }
    if (selectedClient) {
      product.idClient = selectedClient.id;
      product.isFirstOrder = 1;
    }
    (isSample) ? product.isSample = 1 : product.isSample = 0;
    if (selectedBox.name === SHEET || isSample) {
      product.sheetWidth = Number(productSheetWidth);
      product.sheetLong = Number(productSheetLong);
      product.area = Number(calculateSheetArea());
    } else {
      product.sheetWidth = Number(calculateSheetWidth());
      product.sheetLong = Number(calculateSheetLong());
      product.area = Number(calculateArea());
    }
    if (await verifyCode(productCode)) {
      productCreate(product).then(result => {
        handleProductDetailSubmit(result.data.id);
      }).catch(error => {
        (error.response) ? messageError(error.response.data.message) : messageError(t('messageErrorProductCreated'));
        setIsRendered(true);
      });
    } else {
      messageError(t('messageErrorRepeatedCode'));
      setIsRendered(true);
    }
  };

  const handleProductDetailSubmit = async (id) => {
    let product;
    for (const detail of productDetails) {
      product = {
        id: -1,
        idProduct: id,
        name: detail.name,
        description: detail.description,
        url: detail.url
      };
      await productDetailCreate(product).catch(error => {
        (error.response) ? messageError(error.response.data.message) : messageError(t('messageErrorProductCreated'));
        return;
      });
    }
    messageSuccess(t('messageSuccessProductCreated'));
    history.push(routes.Products);
  };

  useEffect(() => {
    if (fileInput) {
      fileInput.addEventListener('change', event => {
        const files = event.target.files;
        setProductDetails([...productDetails, files[0]]);
      });
    }
  }, [fileInput]);

  useEffect(() => () => {
    return () => {
      fileInput.removeEventListener('change', event => {
        const files = event.target.files;
        setProductDetails([...productDetails, files[0]]);
      });
    };
  }, [fileInput, productDetails]);

  const validatesMeasurements = () => {
    let long, width;
    if (selectedBox.name === SHEET) {
      width = Number(productSheetWidth);
      long = Number(productSheetLong);
    } else {
      long = Number(calculateSheetLong());
      width = Number(calculateSheetWidth());
    }
    return validates(selectedBox, long, width, cobbWaterproofing, productIsCliche,
      productAmountCliche, productIsDieCut, productAmountDieCut, selectedTest, constants);
  };

  const cancel = () => {
    history.push(routes.Products);
  };

  const calculateArea = () => {
    return Number(Number(calculateSheetLong()) * Number(calculateSheetWidth()) / 10000).toFixed(4);
  };

  const calculateSheetArea = () => {
    if (productSheetWidth && productSheetLong) {
      return Number(Number(productSheetLong) * Number(productSheetWidth) / 10000).toFixed(4);
    } return 0;
  };

  const calculateLongInInches = () => {
    if (selectedBox.name !== SHEET) {
      return Number(Number(calculateSheetLong()) / 2.54).toFixed(4) + '"';
    } return Number(Number(productSheetLong) / 2.54).toFixed(4) + '"';
  };

  const calculateWidthInInches = () => {
    if (selectedBox.name !== SHEET) {
      return Number(Number(calculateSheetWidth()) / 2.54).toFixed(4) + '"';
    } return Number(Number(productSheetWidth) / 2.54).toFixed(4) + '"';
  };

  const assignCombination = (test) => {
    const combination = test.combination;
    let arrayCombination = [];
    setProductMinimumCombination(combination);

    if (combination !== undefined) {
      if (combination.includes('-')) {
        arrayCombination = combination.split('-');
      } else if (combination.includes('/')) {
        arrayCombination = combination.split('/');
      }
    }

    setProductLiner1(arrayCombination[0] ? arrayCombination[0] : '');
    setProductMedium1(arrayCombination[1] ? arrayCombination[1] : '');
    setProductLiner2(arrayCombination[2] ? arrayCombination[2] : '');
    setProductMedium2(arrayCombination[3] ? arrayCombination[3] : '');
    setProductLiner3(arrayCombination[4] ? arrayCombination[4] : '');
  };

  const callGetFlautesByWall = (test) => {
    getFlautesByWall(test.wall).then(result => {
      setFlautes(result.data);
    }).catch(error => {
      if (!error.response) {
        messageError(t('withoutConnection'));
      }
    });
    getPapersByTest(test.id).then(result => {
      result.data.findIndex((e) => {
        if (e.name === kraftOneFace) {
          e.price = constants.kraftOneFace;
        }
        if (e.name === whiteOneFace) {
          e.price = constants.whiteOneFace;
        }
        if (e.name === whiteTwoFaces) {
          e.price = constants.whiteTwoFaces;
        }
      });
      setPapers(result.data);
    }).catch(error => {
      if (!error.response) {
        messageError(t('withoutConnection'));
      }
    });
  };

  const getTolerance = (e) => {
    if (e.wall && selectedBox.id) {
      getMeasurementsByFlauteIdAndBoxId(e.id, selectedBox.id).then(result => {
        setLongTolerance(result.data.longLossByIncrease);
        setWidthTolerance(result.data.widthLossByIncrease);
      }).catch(error => {
        if (!error.response) {
          messageError(t('withoutConnection'));
        }
      });
    } else if (selectedFlaute.id) {
      getMeasurementsByFlauteIdAndBoxId(selectedFlaute.id, e.id).then(result => {
        setLongTolerance(result.data.longLossByIncrease);
        setWidthTolerance(result.data.widthLossByIncrease);
      }).catch(error => {
        if (!error.response) {
          messageError(t('withoutConnection'));
        }
      });
    }
  };

  const calculateUnitPrice = () => {
    let area;
    (selectedProductType.name === SHEET) ? area = Number(calculateSheetArea()) : area = Number(calculateArea());
    let total = Number(productPriceByTc * calculateWeight() / 1000).toFixed(4);
    if (regularWaterproofing) {
      if (waterproofedFaces === 1) {
        total = Number(total) + Number(productAmountOneRegularFaceWaterproofing * area);
      } else if (waterproofedFaces === 2) {
        total = Number(total) + Number(productAmountTwoRegularFacesWaterproofing * area);
      }
    } else if (cobbWaterproofing) {
      if (waterproofedFaces === 1) {
        total = Number(total) + Number(productAmountOneCobbFaceWaterproofing * area);
      } else if (waterproofedFaces === 2) {
        total = Number(total) + Number(productAmountTwoCobbFacesWaterproofing * area);
      }
    }
    if (productIsPaletized) {
      return (Number(total) + (Number(total) * Number(wastePercentage) / 100))
        + Number(productThirdProcesses) + Number(constants.paletizedCost);
    }
    return (Number(total) + (Number(total) * Number(wastePercentage) / 100)) + Number(productThirdProcesses);
  };

  const calculateDieCutCost = () => {
    let area;
    (selectedBox.name === SHEET) ? area = Number(calculateSheetArea()) : area = Number(calculateArea());
    return Number(productAmountDieCut * area);
  };

  const calculateColorQuantity = () => {
    let colors = 0;
    if (productColor1 !== '') {
      colors = Number(colors) + 1;
    }
    if (productColor2 !== '') {
      colors = Number(colors) + 1;
    }
    if (productColor3 !== '') {
      colors = Number(colors) + 1;
    }
    if (productColor4 !== '') {
      colors = Number(colors) + 1;
    }
    return colors;
  };

  const calculateClicheCost = () => {
    let area;
    (selectedBox.name === SHEET) ? area = Number(calculateSheetArea()) : area = Number(calculateArea());
    if (productIsCliche) {
      let colors = calculateColorQuantity();
      if (colors === 4) {
        return Number(productAmountCliche * (constants.forthColorPercentage / 100))
          + Number(productAmountCliche * colors * area);
      }
      return Number(productAmountCliche * colors * area);
    } return 0;
  };

  const calculateWeight = () => {
    let area;
    (selectedBox.name === SHEET) ? area = calculateSheetArea() : area = calculateArea();
    if (area !== 0.0000 && selectedTest?.weight !== undefined) {
      return Number(Number(selectedTest.weight) * area).toFixed(4);
    } return 0;
  };

  const calculateSheetLong = () => {
    if (longTolerance !== 0) {
      if (selectedBox.nameWithType === 'RSC ') {
        return (((((Number(internalLong) + Number(internalWidth)) * 2) + Number(longTolerance)) / 10).toFixed(4));
      } else if (selectedBox.nameWithType === 'HSC Fondo') {
        return (((((Number(internalLong) + Number(internalWidth)) * 2) + Number(longTolerance)) / 10).toFixed(4));
      }
      else if (selectedBox.nameWithType === 'HSC Tapa') {
        return (((((Number(internalLong) + Number(internalWidth)) * 2) + Number(longTolerance)) / 10).toFixed(4));
      }
      else if (selectedBox.nameWithType === 'CST Fondo') {
        return (Number((Number(internalLong) + (Number(internalHeight) * 2) + Number(longTolerance)) / 10).toFixed(4));
      }
      else if (selectedBox.nameWithType === 'CST Tapa') {
        return (Number((Number(internalLong) + (Number(internalHeight) * 2) + Number(longTolerance)) / 10).toFixed(4));
      }
    } return 0;
  };

  const calculateSheetWidth = () => {
    if (widthTolerance !== 0) {
      if (selectedBox.nameWithType === 'RSC ') {
        return (((Number(internalWidth) + Number(internalHeight) + Number(widthTolerance)) / 10).toFixed(4));
      } else if (selectedBox.nameWithType === 'HSC Fondo') {
        return (Number((Number(internalHeight) + (Number(internalWidth) / 2) + Number(widthTolerance)) / 10).toFixed(4));
      }
      else if (selectedBox.nameWithType === 'HSC Tapa') {
        return (Number((Number(internalHeight) + (Number(internalWidth) / 2) + Number(widthTolerance)) / 10).toFixed(4));
      }
      else if (selectedBox.nameWithType === 'CST Fondo') {
        return (Number((Number(internalWidth) + (Number(internalHeight) * 2) + Number(widthTolerance)) / 10).toFixed(4));
      }
      else if (selectedBox.nameWithType === 'CST Tapa') {
        return (Number((Number(internalWidth) + (Number(internalHeight) * 2) + Number(widthTolerance)) / 10).toFixed(4));
      }
    } return 0;
  };

  const setPriceByTc = (event) => {
    setUnitPrice(event.target.value);
    const weight = calculateWeight() || 1;
    setProductPriceByTc(Number((event.target.value * 1000) / weight).toFixed(4));
  };

  const verifyName = () => {
    let isRepeated = false;
    productDetails.forEach((detail) => {
      if (detail.name === productDetail.name && productDetails.indexOf(detail) !== selectedDetailIndex) {
        isRepeated = true;
      }
    }); return isRepeated;
  };

  const save = (e) => {
    e.preventDefault();
    if (productDetail.url !== '' && productDetail.name !== '' && productDetail.description !== '') {
      if (!verifyName()) {
        let details = [...productDetails];
        details[selectedDetailIndex] = productDetail;
        setProductDetails(details);
        setProductDetail({ name: '', description: '', url: '' });
        setDisplayDialog(false);
      } else {
        return messageError(t('messageErrorRepeatedName'));
      }
    } else {
      return messageError(t('missingFields'));
    }
  };

  const deleteDetail = (row) => {
    let index = findSelectedDetailIndex(row);
    setProductDetails(productDetails.filter((val, i) => i !== index));
  };

  const findSelectedDetailIndex = (row) => {
    return productDetails.indexOf(row);
  };

  const onproductDetailselect = (row) => {
    setDisplayDialog(true);
    setProductDetail(row);
    setSelectedDetailIndex(productDetails.indexOf(row));
  };
  const actions = (row) => {
    return (
      <>
        <a href={row.download} download>
          <i style={{ marginRight: '3px', marginTop: '3px' }} className="pi pi-download" />
        </a>
        <Button
          icon="pi pi-pencil"
          id="button edit"
          style={{ margin: '3px' }}
          className="margin-button"
          onClick={(e) => { e.preventDefault(); onproductDetailselect(row); }}
        />
        <Button
          icon="pi pi-times"
          id="button-remove"
          style={{ margin: '3px' }}
          className="margin-button"
          onClick={(e) => { e.preventDefault(); deleteDetail(row); }}
        />
      </>
    );
  };

  const handleClientOnChange = (e) => {
    getClientsByKey(e.query).then(result => {
      setClients(result.data);
    });
  };

  const dialogSampleOrProduct = () => {
    return (
      <Dialog
        style={{ width: '300px' }}
        onHide={() => setDisplaySampleOrProductDialog(false)}
        footer={dialogSampleOrProductFooter}
        id='selectSampleOrProductDialog'
        closable={false}
        visible={displaySampleOrProductDialog}
        header={t('selectSampleOrProductDialog')}
        modal={false}
        closeOnEscape={false}
      >
        <div>
          <div className="p-col-12">
            <RadioButton
              inputId="sample"
              id="sample"
              style={{ marginLeft: '40px' }}
              name="sampleOrProduct"
              onChange={(e) => setIsSample(e.checked)}
              checked={Boolean(isSample)}
            />
            <label htmlFor="sample" className="p-radiobutton-label">{t('sample')}</label>
          </div>
          <div className="p-col-12">
            <RadioButton
              inputId="product"
              id="product"
              style={{ marginLeft: '40px' }}
              name="sampleOrProduct"
              onChange={(e) => setIsSample(!e.checked)}
              checked={!isSample}
            />
            <label htmlFor="product" className="p-radiobutton-label">{t('product')}</label>
          </div>
        </div>
      </Dialog>
    );
  };

  const setValuesOnSelectedBox = (box) => {
    setSelectedBox(box);
    getTolerance(box);
    if (box.type === DIE_CUT) {
      setProductIsDieCut(true);
      setProductAmountDieCut(constants.maxDieCut);
    }
  };

  let dialogFooter = <div className="ui-dialog-buttonpane p-clearfix">
    <Button id="button-save-detail" label={t('save')} icon="pi pi-check" onClick={save} />
  </div>;

  let dialogSampleOrProductFooter = <div className="ui-dialog-buttonpane p-clearfix">
    <Button
      style={{ width: '50px' }}
      id="button-sample-or-product"
      icon="pi pi-arrow-right"
      onClick={(e) => { e.preventDefault(); setDisplaySampleOrProductDialog(false); }}
    />
  </div>;

  return (
    <div>
      <div className="ui-g ui-fluid" >
        <div className="ui-g-12">
          <div className="card-user">
            <div className="card">
              {dialogSampleOrProduct()}
              {!isRendered && <div id='div-loader'><i id='loader' className="pi pi-spin pi-spinner"></i></div>}
              {(!displaySampleOrProductDialog && isRendered) &&
                <div className="content-section introduction">
                  {isSample && <h1>{t('sampleRegister')}</h1>}
                  {!isSample && <h1>{t('productRegister')}</h1>}
                  <form autoComplete="off" onSubmit={handleSubmit}>
                    <div className="p-grid">
                      <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('client')}</h5></div>
                      <div className="p-col-8 p-md-8 p-lg-11">
                        <AutoComplete
                          placeholder={t('client')}
                          id='client'
                          inputStyle={{ width: '100%' }}
                          className="input-client"
                          field="name"
                          value={selectedClient}
                          onSelect={(e) => setSelectedClient(e.value)}
                          suggestions={clients}
                          onChange={(e) => setSelectedClient(e.value)}
                          completeMethod={(e) => handleClientOnChange(e)}
                        />
                      </div>
                      <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('name')}</h5></div>
                      <div className="p-col-8 p-md-8 p-lg-7">
                        <InputText
                          id='name'
                          placeholder={t('name')}
                          value={productName}
                          required
                          className="input-text"
                          onChange={(event) => setProductName((event.target.value))}
                        />
                      </div>
                      <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('version')}</h5></div>
                      <div className="p-col-8 p-md-8 p-lg-3"><h5>1</h5></div>
                      <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('code')}</h5></div>
                      <div className="p-col-8 p-md-8 p-lg-3">
                        <InputText
                          id='code'
                          value={productCode}
                          placeholder={t('code')}
                          required
                          className="input-full-row"
                          onChange={(event) => { setProductCode((event.target.value)); }}
                        />
                      </div>
                      <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('boxType')}</h5></div>
                      <div className="p-col-8 p-md-8 p-lg-3">
                        <Dropdown
                          id='box'
                          required
                          className="input-full-row"
                          optionLabel="nameWithType"
                          value={selectedBox}
                          onChange={(e) => setValuesOnSelectedBox(e.target.value)}
                          placeholder={t('selectBox')} options={boxes}
                        />
                      </div>
                      <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('productType')}</h5></div>
                      <div className="p-col-8 p-md-8 p-lg-3">
                        <Dropdown
                          id='productType'
                          inputStyle={{ width: '100%' }}
                          className="input-full-row"
                          optionLabel="name"
                          value={selectedProductType}
                          onChange={(e) => setSelectedProductType(e.target.value)}
                          placeholder={t('selectProductType')}
                          options={productTypes}
                        />
                      </div>
                      <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('test')}</h5></div>
                      <div className="p-col-8 p-md-8 p-lg-3">
                        <Dropdown
                          id='test'
                          className="input-full-row"
                          optionLabel="nameWithCombination"
                          value={selectedTest}
                          required
                          onChange={(e) => {
                            setSelectedTest(e.target.value);
                            callGetFlautesByWall(e.target.value);
                            assignCombination(e.target.value);
                          }}
                          placeholder={t('selectTest')}
                          options={tests}
                        />
                      </div>
                      <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('testWeight')}</h5></div>
                      <div className="p-col-8 p-md-8 p-lg-3" style={{ marginTop: '15px' }}>{selectedTest?.weight ?? 0}</div>
                      <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('flaute')}</h5></div>
                      <div className="p-col-8 p-md-8 p-lg-3">
                        <Dropdown
                          id='flaute'
                          className="input-full-row"
                          optionLabel="name"
                          required
                          value={selectedFlaute}
                          onChange={(e) => {
                            setSelectedFlaute(e.target.value);
                            callGetFlautesByWall(e.target.value);
                            getTolerance(e.target.value);
                          }}
                          placeholder={t('selectFlaute')}
                          options={flautes}
                        />
                      </div>
                      <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('paper')}</h5></div>
                      {!isSample && <div className="p-col-8 p-md-8 p-lg-3">
                        <Dropdown
                          id='paper'
                          className="input-full-row"
                          optionLabel="name"
                          value={selectedPaper}
                          required
                          onChange={(e) => {
                            setSelectedPaper(e.target.value);
                            setProductPriceByTc(e.target.value.price);
                          }}
                          placeholder={t('selectPaper')}
                          options={papers}
                        />
                      </div>}
                      {isSample && <div className="p-col-8 p-md-8 p-lg-11">
                        <Dropdown
                          id='paper'
                          className="input-small-row"
                          optionLabel="name"
                          value={selectedPaper}
                          onChange={(e) => {
                            setSelectedPaper(e.target.value);
                            setProductPriceByTc(e.target.value.price);
                          }}
                          placeholder={t('selectPaper')}
                          options={papers}
                        />
                      </div>}
                      {!isSample && selectedBox.name !== SHEET && <>
                        <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('flaps')}</h5></div>
                        <div className="p-col-8 p-md-8 p-lg-3">
                          <InputText
                            id='flaps'
                            value={productFlaps}
                            placeholder={t('flaps')}
                            className="input-full-row"
                            onChange={(event) => setProductFlaps((event.target.value))}
                          />
                        </div>
                        <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('minimalCombination')}</h5></div>
                        <div className="p-col-8 p-md-8 p-lg-3">
                          <InputText
                            id='minimumCombination'
                            value={productMinimumCombination}
                            placeholder={t('minimalCombination')}
                            className="input-full-row"
                            onChange={(event) => setProductMinimumCombination((event.target.value))}
                          />
                        </div>
                      </>}
                      {!isSample && selectedBox.name === SHEET && <>
                        <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('minimalCombination')}</h5></div>
                        <div className="p-col-8 p-md-8 p-lg-7">
                          <InputText
                            id='minimumCombination'
                            value={productMinimumCombination}
                            placeholder={t('minimalCombination')}
                            className="input-medium-row"
                            onChange={(event) => setProductMinimumCombination((event.target.value))}
                          />
                        </div>
                      </>}
                      {!isSample && <>
                        <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('liner1')}</h5></div>
                        <div className="p-col-8 p-md-8 p-lg-3">
                          <InputText
                            id='liner1'
                            value={productLiner1}
                            placeholder={t('liner1')}
                            className="input-full-row"
                            onChange={(event) => setProductLiner1((event.target.value))}
                          />
                        </div>
                        <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('medium1')}</h5></div>
                        <div className="p-col-8 p-md-8 p-lg-7">
                          <InputText
                            id='medium1'
                            value={productMedium1}
                            placeholder={t('medium1')}
                            className="input-medium-row"
                            onChange={(event) => setProductMedium1((event.target.value))}
                          />
                        </div>
                        <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('liner2')}</h5></div>
                        <div className="p-col-8 p-md-8 p-lg-3">
                          <InputText
                            id='liner2'
                            value={productLiner2}
                            placeholder={t('liner2')}
                            className="input-full-row"
                            onChange={(event) => setProductLiner2((event.target.value))}
                          />
                        </div>
                        <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('medium2')}</h5></div>
                        <div className="p-col-8 p-md-8 p-lg-7">
                          <InputText
                            id='medium2'
                            value={productMedium2}
                            placeholder={t('medium2')}
                            className="input-medium-row"
                            onChange={(event) => setProductMedium2((event.target.value))}
                          />
                        </div>
                        <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('liner3')}</h5></div>
                        <div className="p-col-8 p-md-8 p-lg-11">
                          <InputText
                            id='liner3'
                            value={productLiner3}
                            placeholder={t('liner3')}
                            className="input-small-row"
                            onChange={(event) => setProductLiner3((event.target.value))}
                          />
                        </div>
                      </>}
                      <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('isPaletized')}</h5></div>
                      <div className="p-col-8 p-md-8 p-lg-11">
                        <Checkbox
                          id="isPaletized"
                          style={{ marginTop: '15px' }}
                          className="input"
                          onChange={(e) => {
                            setProductIsPaletized(e.checked);
                            (e.checked)
                              ? setProductPriceByTc(Number(productPriceByTc) + constants.paletizedCost)
                              : setProductPriceByTc(Number(productPriceByTc) - constants.paletizedCost);
                          }}
                          checked={Boolean(productIsPaletized)}
                        />
                      </div>
                      <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('isDieCut')}</h5></div>
                      {!productIsDieCut &&
                        <div className="p-col-8 p-md-8 p-lg-11">
                          <Checkbox
                            id="dieCut"
                            style={{ marginTop: '15px' }}
                            className="input"
                            onChange={(e) => {
                              setProductIsDieCut(e.checked);
                              setProductAmountDieCut(constants.maxDieCut);
                            }}
                            checked={Boolean(productIsDieCut)}
                          />
                        </div>
                      }
                      {productIsDieCut && <>
                        <div className="p-col-4 p-md-1 p-lg-1">
                          <Checkbox
                            id="dieCut"
                            style={{ marginTop: '15px' }}
                            className="input"
                            onChange={(e) => {
                              setProductIsDieCut(e.checked);
                              setProductAmountDieCut(0);
                            }}
                            checked={Boolean(productIsDieCut)}
                          />
                        </div>
                        <div id='chargeRadioButton' className="p-col-4 p-md-4 p-lg-2">
                          <label htmlFor="dieCutCharge" className="p-radiobutton-label">{t('noCharge')}</label>
                          <Checkbox
                            inputId="dieCutCharge"
                            id="dieCutCharge"
                            onChange={() => {
                              (productAmountDieCut === 0)
                                ? setProductAmountDieCut(constants.maxDieCut)
                                : setProductAmountDieCut(0);
                            }
                            }
                            checked={productAmountDieCut === 0}
                          />
                        </div>
                        <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('amountDieCut')}</h5></div>
                        <div className="p-col-8 p-md-8 p-lg-7">
                          <InputText
                            id='amountDieCut'
                            keyfilter="pnum"
                            value={productAmountDieCut}
                            className="input-small-row"
                            onChange={(event) => setProductAmountDieCut(event.target.value)}
                          />
                        </div>
                      </>}
                      <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('cliche')}</h5></div>
                      {!productIsCliche &&
                        <div className="p-col-8 p-md-8 p-lg-11">
                          <Checkbox
                            id="cliche"
                            className="input"
                            onChange={(e) => setProductIsCliche(e.checked)}
                            checked={Boolean(productIsCliche)}
                          />
                        </div>
                      }
                      {productIsCliche && <>
                        <div className="p-col-4 p-md-1 p-lg-1">
                          <Checkbox
                            id="cliche"
                            className="input"
                            onChange={(e) => setProductIsCliche(e.checked)}
                            checked={Boolean(productIsCliche)}
                          />
                        </div>
                        <div id='chargeRadioButton' className="p-col-4 p-md-4 p-lg-2">
                          <label htmlFor="clicheCharge" className="p-radiobutton-label">{t('noCharge')}</label>
                          <Checkbox
                            id="clicheCharge"
                            inputId="clicheCharge"
                            onChange={() => {
                              (productAmountCliche === 0)
                                ? setProductAmountCliche(constants.maxCliche)
                                : setProductAmountCliche(0);
                            }} checked={productAmountCliche === 0}
                          />
                        </div>
                        <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('amountCliche')}</h5></div>
                        <div className="p-col-8 p-md-4 p-lg-7">
                          <InputText
                            id='amountCliche'
                            keyfilter="pnum"
                            value={productAmountCliche}
                            className="input-small-row"
                            onChange={(event) => setProductAmountCliche(event.target.value)}
                          />
                        </div>
                        <div className="p-col-12 p-md-12 p-lg-12">
                          <h4>{t('colors')}</h4>
                        </div>
                        <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('color1')}</h5></div>
                        <div className="p-col-8 p-md-8 p-lg-2">
                          <InputText
                            id='color1'
                            value={productColor1}
                            placeholder={t('color1')}
                            className="input-row"
                            onChange={(event) => setProductColor1((event.target.value))}
                          />
                        </div>
                        <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('color2')}</h5></div>
                        <div className="p-col-8 p-md-8 p-lg-2">
                          <InputText
                            id='color2'
                            value={productColor2}
                            placeholder={t('color2')}
                            className="input-row"
                            onChange={(event) => setProductColor2((event.target.value))}
                          />
                        </div>
                        <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('color3')}</h5></div>
                        <div className="p-col-8 p-md-8 p-lg-2">
                          <InputText
                            id='color3'
                            value={productColor3}
                            placeholder={t('color3')}
                            className="input-row"
                            onChange={(event) => setProductColor3((event.target.value))}
                          />
                        </div>
                        <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('color4')}</h5></div>
                        <div className="p-col-8 p-md-8 p-lg-2">
                          <InputText
                            id='color4'
                            value={productColor4}
                            placeholder={t('color4')}
                            className="input-row"
                            onChange={(event) => setProductColor4((event.target.value))}
                          />
                        </div>
                      </>}
                      <div className="p-col-12 p-md-12 p-lg-12">
                        <h3>{t('waterproofing')}</h3>
                      </div>
                      <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('regular')}</h5></div>
                      <div className="p-col-8 p-md-8 p-lg-11">
                        <Checkbox
                          name='waterproofing'
                          id="regularWaterproofing"
                          style={{ marginTop: '15px' }}
                          className="input"
                          onChange={(e) => setRegularWaterproofing(e.checked)}
                          checked={Boolean(regularWaterproofing)}
                        />
                      </div>
                      {regularWaterproofing && <>
                        <div className="p-col-3">
                          <div className="p-col-12">
                            <RadioButton
                              inputId="rb1"
                              id="rb1"
                              style={{ marginLeft: '40px' }}
                              name="waterproofed"
                              onChange={() => setWaterproofedFaces(1)}
                              checked={waterproofedFaces === 1}
                            />
                            <label htmlFor="rb1" className="p-radiobutton-label">{t('oneFace')}</label>
                          </div>
                          <div className="p-col-12">
                            <RadioButton
                              inputId="rb2"
                              id="rb2"
                              style={{ marginLeft: '40px' }}
                              name="waterproofed"
                              onChange={() => setWaterproofedFaces(2)}
                              checked={waterproofedFaces === 2}
                            />
                            <label htmlFor="rb2" className="p-radiobutton-label">{t('twoFaces')}</label>
                          </div>
                        </div>
                        {waterproofedFaces === 1 && <>
                          <div className="p-col-4 p-md-4 p-lg-2" style={{ marginTop: '0.8em' }}>
                            <label htmlFor="oneWaterCutCharge" className="p-radiobutton-label">{t('noCharge')}</label>
                            <Checkbox
                              inputId="oneWaterCutCharge"
                              onChange={() => (productAmountOneRegularFaceWaterproofing === 0)
                                ? setProductAmountOneRegularFaceWaterproofing(constants.oneFaceRegularWaterproofing)
                                : setProductAmountOneRegularFaceWaterproofing(0)}
                              checked={productAmountOneRegularFaceWaterproofing === 0}
                            />
                          </div>
                          <div className="p-col-4 p-md-4 p-lg-2"><h5>{t('amountOneFaceWaterproofing')}</h5></div>
                          <div className="p-col-8 p-md-8 p-lg-5">
                            <InputText
                              id='amountOneRegularFaceWaterproofing'
                              keyfilter="pnum"
                              value={productAmountOneRegularFaceWaterproofing}
                              required
                              className="input-small-row"
                              onChange={(event) => setProductAmountOneRegularFaceWaterproofing(event.target.value)}
                            />
                          </div>
                        </>}
                        {waterproofedFaces === 2 && <>
                          <div className="p-col-4 p-md-4 p-lg-2" style={{ marginTop: '3.4em' }}>
                            <label htmlFor="twoWaterCutCharge" className="p-radiobutton-label">{t('noCharge')}</label>
                            <Checkbox
                              inputId="twoWaterCutCharge"
                              onChange={() => (productAmountTwoRegularFacesWaterproofing === 0)
                                ? setProductAmountTwoRegularFacesWaterproofing(constants.twoFacesRegularWaterproofing)
                                : setProductAmountTwoRegularFacesWaterproofing(0)}
                              checked={productAmountTwoRegularFacesWaterproofing === 0}
                            />
                          </div>
                          <div className="p-col-4 p-md-4 p-lg-2" style={{ marginTop: '34px' }}>
                            <h5>{t('amountTwoFacesWaterproofing')}</h5>
                          </div>
                          <div className="p-col-8 p-md-8 p-lg-5" style={{ marginTop: '38px' }}>
                            <InputText
                              id='amountTwoRegularFacesWaterproofing'
                              keyfilter="pnum"
                              value={productAmountTwoRegularFacesWaterproofing}
                              required
                              className="input-small-row"
                              onChange={(event) => setProductAmountTwoRegularFacesWaterproofing(event.target.value)}
                            />
                          </div>
                        </>}
                        <div className="p-col-12"></div>
                      </>}
                      {(selectedBox.name === SHEET || selectedBox.name === 'CST') && <>
                        <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('cobb')}</h5></div>
                        <div className="p-col-8 p-md-8 p-lg-11">
                          <Checkbox
                            name='waterproofing'
                            id="cobbWaterproofing"
                            style={{ marginTop: '15px' }}
                            className="input"
                            onChange={(e) => setCobbWaterproofing(e.checked)}
                            checked={Boolean(cobbWaterproofing)}
                          />
                        </div>
                        {cobbWaterproofing && <>
                          <div className="p-col-3">
                            <div className="p-col-12">
                              <RadioButton
                                style={{ marginLeft: '40px' }}
                                id="rb1"
                                inputId="rb1"
                                name="waterproofed"
                                onChange={() => setWaterproofedFaces(1)}
                                checked={waterproofedFaces === 1}
                              />
                              <label htmlFor="rb1" className="p-radiobutton-label">{t('oneFace')}</label>
                            </div>
                            <div className="p-col-12">
                              <RadioButton
                                style={{ marginLeft: '40px' }}
                                id="rb2"
                                inputId="rb2"
                                name="waterproofed"
                                onChange={() => setWaterproofedFaces(2)}
                                checked={waterproofedFaces === 2}
                              />
                              <label htmlFor="rb2" className="p-radiobutton-label">{t('twoFaces')}</label>
                            </div>
                          </div>
                          {waterproofedFaces === 1 && <>
                            <div className="p-col-4 p-md-4 p-lg-2" style={{ marginTop: '0.8em' }}>
                              <label htmlFor="oneCobbWaterCutCharge" className="p-radiobutton-label">{t('noCharge')}</label>
                              <Checkbox
                                inputId="oneCobbWaterCutCharge"
                                onChange={() => (productAmountOneCobbFaceWaterproofing === 0)
                                  ? setProductAmountOneCobbFaceWaterproofing(constants.oneFaceCobbWaterproofing)
                                  : setProductAmountOneCobbFaceWaterproofing(0)}
                                checked={productAmountOneCobbFaceWaterproofing === 0}
                              />
                            </div>
                            <div className="p-col-4 p-md-4 p-lg-2"><h5>{t('amountOneFacesWaterproofing')}</h5></div>
                            <div className="p-col-8 p-md-8 p-lg-5">
                              <InputText
                                id='amountOneCobbFaceWaterproofing'
                                keyfilter="pnum"
                                value={productAmountOneCobbFaceWaterproofing}
                                required
                                className="input-small-row"
                                onChange={(event) => setProductAmountOneCobbFaceWaterproofing(event.target.value)}
                              />
                            </div>
                          </>}
                          {waterproofedFaces === 2 && <>
                            <div className="p-col-4 p-md-4 p-lg-2" style={{ marginTop: '3.4em' }}>
                              <label htmlFor="twoCobbWaterCutCharge" className="p-radiobutton-label">{t('noCharge')}</label>
                              <Checkbox
                                inputId="twoCobbWaterCutCharge"
                                onChange={() => (productAmountTwoCobbFacesWaterproofing === 0)
                                  ? setProductAmountTwoCobbFacesWaterproofing(constants.twoFacesCobbWaterproofing)
                                  : setProductAmountTwoCobbFacesWaterproofing(0)}
                                checked={productAmountTwoCobbFacesWaterproofing === 0}
                              />
                            </div>
                            <div className="p-col-4 p-md-4 p-lg-2" style={{ marginTop: '34px' }}>
                              <h5>{t('amountTwoFacesWaterproofing')}</h5>
                            </div>
                            <div className="p-col-8 p-md-8 p-lg-5" style={{ marginTop: '38px' }}>
                              <InputText
                                id='amountTwoCobbFacesWaterproofing'
                                keyfilter="pnum"
                                value={productAmountTwoCobbFacesWaterproofing}
                                required
                                className="input-small-row"
                                onChange={(event) => setProductAmountTwoCobbFacesWaterproofing(event.target.value)}
                              />
                            </div>
                          </>}
                        </>}
                      </>}
                      {(selectedBox.name === 'RSC' || selectedBox.name === 'HSC') && <>
                        <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('cobbExterior')}</h5></div>
                        {!cobbWaterproofing && <>
                          <div className="p-col-8 p-md-8 p-lg-5">
                            <Checkbox
                              name='waterproofing'
                              id="cobbWaterproofing"
                              style={{ marginTop: '15px' }}
                              className="input"
                              onChange={(e) => { setCobbWaterproofing(e.checked); setWaterproofedFaces(1); }}
                              checked={Boolean(cobbWaterproofing)}
                            />
                          </div>
                        </>}
                        {cobbWaterproofing && <>
                          <div className="p-col-8 p-md-8 p-lg-1">
                            <Checkbox
                              name='waterproofing'
                              id="cobbWaterproofing"
                              style={{ marginTop: '15px' }}
                              className="input"
                              onChange={(e) => { setCobbWaterproofing(e.checked); setWaterproofedFaces(1); }}
                              checked={Boolean(cobbWaterproofing)}
                            />
                          </div>
                          <div className="p-col-4 p-md-4 p-lg-2" style={{ marginTop: '0.8em' }}>
                            <label htmlFor="oneCobbWaterCutCharge" className="p-radiobutton-label">{t('noCharge')}</label>
                            <Checkbox
                              inputId="oneCobbWaterCutCharge"
                              onChange={() => (productAmountOneCobbFaceWaterproofing === 0)
                                ? setProductAmountOneCobbFaceWaterproofing(constants.oneFaceCobbWaterproofing)
                                : setProductAmountOneCobbFaceWaterproofing(0)}
                              checked={productAmountOneCobbFaceWaterproofing === 0}
                            />
                          </div>
                          <div className="p-col-4 p-md-4 p-lg-2"><h5>{t('amountOneFaceWaterproofing')}</h5></div>
                          <div className="p-col-8 p-md-8 p-lg-5">
                            <InputText
                              id='amountOneCobbFaceWaterproofing'
                              keyfilter="pnum"
                              value={productAmountOneCobbFaceWaterproofing}
                              required
                              className="input-small-row"
                              onChange={(event) => setProductAmountOneCobbFaceWaterproofing(event.target.value)}
                            />
                          </div>
                        </>}
                      </>}
                      <div className="p-col-12 p-md-12 p-lg-12"><h3>{t('measurements')}</h3></div>
                      {selectedBox.name !== SHEET && <>
                        <div className="p-col-12 p-md-12 p-lg-12"><h4>{t('internal')}</h4></div>
                        <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('long')} (mm)</h5></div>
                        <div className="p-col-8 p-md-8 p-lg-3">
                          <InputText id='internalLong' value={internalLong} keyfilter="pnum" placeholder={t('internalLong')}
                            className="input-full-row" onChange={(event) => { setInternalLong((event.target.value)); }} />
                        </div>
                        <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('width')} (mm)</h5></div>
                        <div className="p-col-8 p-md-8 p-lg-3">
                          <InputText
                            id='internalWidth'
                            className="input-full-row"
                            value={internalWidth}
                            keyfilter="pnum"
                            placeholder={t('internalWidth')}
                            onChange={(event) => setInternalWidth((event.target.value))}
                          />
                        </div>
                        <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('height')} (mm)</h5></div>
                        <div className="p-col-8 p-md-8 p-lg-3">
                          <InputText
                            id='internalHeight'
                            value={internalHeight}
                            keyfilter="pnum"
                            placeholder={t('internalHeight')}
                            className="input-full-row"
                            onChange={(event) => setInternalHeight((event.target.value))}
                          />
                        </div>
                      </>}
                      <div className="p-col-12 p-md-12 p-lg-12"><h4>{t('sheet')}</h4></div>
                      {(selectedBox.name === SHEET) && <>
                        <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('long')} (cm)</h5></div>
                        <div className="p-col-8 p-md-8 p-lg-2">
                          <div id="tooltip" data-tooltip={calculateLongInInches()}>
                            <InputText
                              id='sheetLong'
                              value={productSheetLong}
                              keyfilter="pnum"
                              tooltipOptions={{ event: 'focus' }}
                              tooltip={t('cmMeasure')}
                              placeholder={t('sheetLong')}
                              className="input-full-row"
                              onChange={(event) => setProductSheetLong((event.target.value))}
                            />
                          </div>
                        </div>
                        <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('width')} (cm)</h5></div>
                        <div className="p-col-8 p-md-8 p-lg-2">
                          <div id="tooltip" data-tooltip={calculateWidthInInches()}>
                            <InputText
                              id='sheetWidth'
                              value={productSheetWidth}
                              keyfilter="pnum"
                              placeholder={t('sheetWidth')}
                              tooltipOptions={{ event: 'focus' }}
                              tooltip={t('cmMeasure')}
                              className="input-full-row"
                              onChange={(event) => setProductSheetWidth((event.target.value))}
                            />
                          </div>
                        </div>
                        <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('sheetArea')}</h5></div>
                        <div className="p-col-8 p-md-8 p-lg-2" style={{ marginTop: '15px' }}>{calculateSheetArea()}</div>
                      </>}
                      {selectedBox.name !== SHEET && <>
                        <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('long')} (cm)</h5></div>
                        <div className="p-col-8 p-md-8 p-lg-2">
                          <div
                            id="tooltip"
                            data-tooltip={calculateLongInInches()}
                            style={{ marginTop: '15px' }}
                            className="input-full-row"
                          >
                            {calculateSheetLong()}
                          </div>
                        </div>
                        <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('width')} (cm)</h5></div>
                        <div className="p-col-8 p-md-8 p-lg-2">
                          <div
                            id="tooltip"
                            data-tooltip={calculateWidthInInches()}
                            style={{ marginTop: '15px' }}
                            className="input-full-row"
                          >
                            {calculateSheetWidth()}
                          </div>
                        </div>
                        <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('sheetArea')}</h5></div>
                        <div className="p-col-8 p-md-8 p-lg-2" style={{ marginTop: '15px' }}>{calculateArea()}</div>
                      </>}
                      <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('weight')}</h5></div>
                      <div className="p-col-8 p-md-8 p-lg-2" style={{ marginTop: '15px' }}>{calculateWeight()}</div>
                      {selectedBox.type === DIE_CUT && <>
                        <div className="p-col-12 p-md-12 p-lg-12"><h4>{t('internal')}</h4></div>
                        <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('long')} (mm)</h5></div>
                        <div className="p-col-8 p-md-8 p-lg-2">
                          <InputText id='internalLong' value={internalLong} keyfilter="pnum" placeholder={t('internalLong')}
                            className="input-full-row" onChange={(event) => { setInternalLong((event.target.value)); }} />
                        </div>
                        <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('width')} (mm)</h5></div>
                        <div className="p-col-8 p-md-8 p-lg-2">
                          <InputText id='internalWidth' value={internalWidth} keyfilter="pnum" className="input-full-row"
                            placeholder={t('internalWidth')} onChange={(event) => setInternalWidth((event.target.value))} />
                        </div>
                        <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('height')} (mm)</h5></div>
                        <div className="p-col-8 p-md-8 p-lg-5">
                          <InputText
                            id='internalHeight'
                            value={internalHeight}
                            keyfilter="pnum"
                            placeholder={t('internalHeight')}
                            className="input-medium-row"
                            onChange={(event) => setInternalHeight((event.target.value))}
                          />
                        </div></>}
                      <div className="p-col-12 p-md-12 p-lg-12">
                        <h3>{t('costs')}</h3>
                      </div>
                      {isSample && <>
                        <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('thirdProcesses')}</h5></div>
                        <div className="p-col-8 p-md-8 p-lg-11">
                          <InputText
                            id='thirdProcesses'
                            keyfilter="pnum"
                            value={productThirdProcesses}
                            placeholder={t('thirdProcesses')}
                            className="input-small-row"
                            onChange={(event) => setProductThirdProcesses((event.target.value))}
                          />
                        </div>
                      </>}
                      {!isSample && <>
                        <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('wastePercentage')}</h5></div>
                        <div className="p-col-8 p-md-8 p-lg-3" style={{ marginTop: '15px' }}>
                          {wastePercentage || t('doesNotApply')}
                        </div>
                        <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('thirdProcesses')}</h5></div>
                        <div className="p-col-8 p-md-8 p-lg-3">
                          <InputText
                            id='thirdProcesses'
                            keyfilter="pnum"
                            value={productThirdProcesses}
                            placeholder={t('thirdProcesses')}
                            className="input-full-row"
                            onChange={(event) => setProductThirdProcesses((event.target.value))}
                          />
                        </div>
                        <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('priceXtc')}</h5></div>
                        <div className="p-col-8 p-md-8 p-lg-3" style={{ marginTop: '15px' }}>
                          {productPriceByTc ? currencyFormatter.format(productPriceByTc) : 0}
                        </div>
                        <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('clicheCost')}</h5></div>
                        <div className="p-col-8 p-md-8 p-lg-3" style={{ marginTop: '15px' }}>
                          {currencyFormatter.format(calculateClicheCost())}
                        </div>
                        <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('dieCutCost')}</h5></div>
                        <div className="p-col-8 p-md-8 p-lg-3" style={{ marginTop: '15px' }}>
                          {currencyFormatter.format(calculateDieCutCost())}
                        </div>
                        <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('unitPrice')}</h5></div>
                        <div className="p-col-8 p-md-8 p-lg-3">
                          <InputText
                            id='priceXtc'
                            keyfilter="pnum"
                            value={unitPrice > 0 ? unitPrice : calculateUnitPrice()}
                            className="input-full-row"
                            onChange={setPriceByTc}
                          />
                        </div>
                      </>}
                    </div>
                    <div className="p-col-12 p-md-12 p-lg-12">
                      <h3>{t('attachedReferences')}</h3>
                    </div>
                    <div className="container">
                      <div {...getRootProps({ style })}>
                        <input {...getInputProps()} />
                        <p>{t('dragndropText')}</p>
                      </div>
                    </div>
                    <div className="content-section implementation">
                      <DataTable value={productDetails} rows={15}>
                        <Column field="name" header={t('name')} />
                        <Column field="description" header={t('description')} />
                        <Column style={{ textAlign: 'center' }} body={actions} header={t('actions')} />
                      </DataTable>
                      <Dialog
                        visible={displayDialog}
                        footer={dialogFooter}
                        header={t('productDetail')}
                        modal={true}
                        onHide={() => setDisplayDialog(false)}
                      >
                        {productDetail &&
                          <div className="p-grid p-fluid">
                            <div className="p-col-4" style={{ padding: '.75em' }}>
                              <label htmlFor="name">{t('name')}</label>
                            </div>
                            <div className="p-col-8" style={{ padding: '.5em' }}>
                              <InputTextarea
                                id="fileName"
                                onChange={(e) => setProductDetail({ ...productDetail, name: e.target.value })}
                                value={productDetail.name}
                              />
                            </div>
                            <div className="p-col-4" style={{ padding: '.75em' }}>
                              <label htmlFor="description">{t('description')}</label>
                            </div>
                            <div className="p-col-8" style={{ padding: '.5em' }}>
                              <InputTextarea
                                id="description"
                                onChange={(e) => setProductDetail({ ...productDetail, description: e.target.value })}
                                value={productDetail.description}
                              />
                            </div>
                          </div>
                        }
                      </Dialog>
                    </div>
                    <div className="ui-button-group">
                      <Button
                        id='button-save'
                        icon="pi pi-save"
                        label={t('save')}
                        style={{ marginTop: '10px', width: 'auto' }}
                      />
                      <Button
                        id='button-return'
                        type="button"
                        icon="pi pi-times"
                        label={t('cancel')}
                        style={{ margin: '10px', width: 'auto' }}
                        onClick={cancel}
                      />
                    </div>
                  </form>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ProductRegister;
