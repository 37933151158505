export const routes  = {
  Login: '/',
  Users: '/users',
  Proformas: '/proformas',
  ProformaRegister: '/registerProforma',
  ProformaEdit: '/proformaEdit',
  UserRegister: '/userRegister',
  UserEdit: '/userEdit',
  ForgotPassword: '/forgot-password',
  ChangePassword: '/change-password',
  Products: '/products',
  ProductRegister: '/productRegister',
  ProductEdit: '/productEdit',
  PurchaseOrders: '/purchaseOrders',
  PurchaseOrderRegister: '/registerPurchaseOrder',
  PurchaseOrderEdit: '/editPurchaseOrder',
  Clients: '/clients',
  ClientRegister: '/clientRegister',
  ClientEdit: '/clientEdit',
  Prospects: '/prospects',
  ProspectRegister: '/prospectRegister',
  ProspectEdit: '/prospectEdit',
  Tests: '/tests',
  TestRegister: '/testRegister',
  TestEdit: '/testEdit',
  Exactus: '/exactus',
  Access: '/access',
  Variables: '/variables'
};
