import { axiosInstance } from '../config';
import { getUserSession, getHeaders } from '../authentication';

export const getPurchaseOrderDetailsByPurchaseOrderHeaderId = (id) => {
  const config = getHeaders(getUserSession());
  return axiosInstance.get('purchaseOrderDetails/byPurchaseOrderHeadersId/' + id, config);
};

export const purchaseOrderDetailCreate = (purchaseOrder) => {
  const config = getHeaders(getUserSession());
  return axiosInstance.post('purchaseOrderDetail',purchaseOrder,config);
};

export const purchaseOrderDetailUpdate = (purchaseOrder) => {
  const config = getHeaders(getUserSession());
  return axiosInstance.put('purchaseOrderDetails/update',purchaseOrder,config);
};

export const purchaseOrderDetailDelete = (id) => {
  const config = getHeaders(getUserSession());
  return axiosInstance.delete('purchaseOrderDetails/' + id,config);
};
