import React, {  useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { useTranslation } from 'react-i18next';
import { clientUpdate } from '../../../services/client/client';
import { messageError, messageSuccess, messageConfirm } from '../../../utils/messages';
import { routes } from '../../../utils/routes';
import './clientEdit.css';

function ClientEdit ({ history }) {
  const { t } = useTranslation();
  const [clientId, setClientId] = useState('');
  const [clientName, setClientName] = useState('');
  const [clientAlias, setClientAlias] = useState('');
  const [clientEmail, setClientEmail] = useState('');
  const [clientPhone, setClientPhone] = useState('');
  const [clientFax, setClientFax] = useState('');
  const [clientIdUser, setClientIdUser] = useState('');
  const [clientContactName, setClientContactName] = useState('');
  const [clientContactLastName, setClientContactLastName] = useState('');
  const [clientPosition, setClientPosition] = useState('');
  const [clientIva, setClientIva] = useState(13);
  const [clientFreeTransport, setClientFreeTransport] = useState('');
  const [clientSeeArts, setClientSeeArts] = useState('');
  const [clientLegalId, setClientLegalId] = useState('');
  const [readOnly, setReadOnly] = useState();

  useEffect(()=>{
    (history.location.state.clientUpdate.active === 1) ? setReadOnly(false) : setReadOnly(true);
    setClientIdUser(history.location.state.clientUpdate.idUser);
    setClientId(history.location.state.clientUpdate.id);
    setClientName(history.location.state.clientUpdate.name);
    setClientLegalId(history.location.state.clientUpdate.legalId);
    setClientAlias(history.location.state.clientUpdate.alias);
    setClientEmail(history.location.state.clientUpdate.email);
    setClientPhone(history.location.state.clientUpdate.phone);
    setClientFax(history.location.state.clientUpdate.fax);
    setClientContactLastName(history.location.state.clientUpdate.contactLastName);
    setClientContactName(history.location.state.clientUpdate.contactFirstName);
    setClientPosition(history.location.state.clientUpdate.position);
    setClientFreeTransport(Boolean(history.location.state.clientUpdate.freeTransport));
    setClientSeeArts(Boolean(history.location.state.clientUpdate.seeArts));
    setClientIva(Number(history.location.state.clientUpdate.iva) * 100);
  },[history.location.state.clientUpdate]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const client = {
      legalId: clientLegalId ?? '',
      id: clientId,
      name: clientName,
      alias: clientAlias,
      email: clientEmail,
      phone: clientPhone ?? '',
      fax: clientFax ?? '',
      contactFirstName: clientContactName ?? '',
      contactLastName: clientContactLastName ?? '',
      position: clientPosition ?? '',
      idUser: clientIdUser ?? -1,
      iva: Number(Number((clientIva === '') ? 13 : clientIva) / 100).toFixed(2),
      freeTransport: Boolean(clientFreeTransport),
      seeArts: Boolean(clientSeeArts),
      isProspect: 0
    };
    clientUpdate(client).then( () => {
      messageSuccess(t('messageSuccessClientUpdated'));
      history.push(routes.Clients);
    }).catch(error => {
      (error.response)
        ? messageError(error.response.data.message)
        : messageError(t('messageErrorClientUpdated'));
    });
  };

  const cancel = () => {
    history.push(routes.Clients);
  };

  const handleDelete = async () => {
    const confirm = await messageConfirm(t('clientDeactivate'));
    /* istanbul ignore else */
    if (confirm){
      const client = {
        id: clientId,
        active: false,
        name: clientName,
        alias: clientAlias ?? ''
      };
      clientUpdate(client).then( () => {
        messageSuccess(t('messagesSuccessClientDeactivated'));
        history.push(routes.Clients);
      }).catch(error => {
        (error.response)
          ? messageError(error.response.data.message)
          : messageError(t('messagesErrorUserUpdated'));
      });
    }
  };

  const handleActivate = async () => {
    const confirm = await messageConfirm(t('clientActivate'));
    /* istanbul ignore else */
    if (confirm){
      const client = {
        id: clientId,
        active: true,
        name: clientName,
        alias: clientAlias ?? ''
      };
      clientUpdate(client).then(() => {
        messageSuccess(t('messageSuccessClientUpdated'));
        history.push(routes.Clients);
      }).catch(error => {
        (error.response)
          ? messageError(error.response.data.message)
          : messageError(t('messagesErrorClientUpdated'));
      });
    }
  };

  return (
    <div>
      <div className="ui-g ui-fluid" >
        <div className="ui-g-12">
          <div className="card-user">
            <div className="card">
              <div className="content-section introduction">
                <h1>{t('clientEdit')}</h1>
                <form autoComplete="off" onSubmit={handleSubmit}>
                  <div className="p-grid">
                    <div className="p-col-4 p-md-4 p-lg-2"><h5>{t('companyName')}</h5></div>
                    <div className="p-col-8 p-md-8 p-lg-10">
                      <InputText readOnly={readOnly} required id='name' value={clientName} placeholder={t('companyName')}
                        className="input" onChange={(event) => {setClientName((event.target.value));}}/>
                    </div>
                    <div className="p-col-4 p-md-4 p-lg-2"><h5>{t('legalId')}</h5></div>
                    <div className="p-col-8 p-md-8 p-lg-10">
                      <InputText readOnly={readOnly} id='legalId' value={clientLegalId} placeholder={t('legalId')}
                        className="input" onChange={(event) => {setClientLegalId((event.target.value));}}/>
                    </div>
                    <div className="p-col-4 p-md-4 p-lg-2"><h5>{t('alias')}</h5></div>
                    <div className="p-col-8 p-md-8 p-lg-10">
                      <InputText readOnly={readOnly} required id='alias' value={clientAlias} placeholder={t('alias')}
                        className="input" onChange={(event) => {setClientAlias((event.target.value));}}/>
                    </div>
                    <div className="p-col-4 p-md-4 p-lg-2"><h5>{t('email')}</h5></div>
                    <div className="p-col-8 p-md-8 p-lg-10">
                      <InputText readOnly={readOnly} required id='email' value={clientEmail} placeholder={t('email')}
                        className="input" onChange={(event) => {setClientEmail(event.target.value);}}/>
                    </div>
                    <div className="p-col-4 p-md-4 p-lg-2"><h5>{t('contactName')}</h5></div>
                    <div className="p-col-8 p-md-8 p-lg-4">
                      <InputText
                        readOnly={readOnly}
                        id='contactName'
                        value={clientContactName}
                        placeholder={t('contactName')}
                        className="input"
                        onChange={(event) => {setClientContactName((event.target.value));}}
                      />
                    </div>
                    <div className="p-col-4 p-md-4 p-lg-2"><h5>{t('contactLastName')}</h5></div>
                    <div className="p-col-8 p-md-8 p-lg-4">
                      <InputText
                        readOnly={readOnly}
                        id='contactLastName'
                        value={clientContactLastName}
                        placeholder={t('contactLastName')}
                        className="input"
                        onChange={(event) => {setClientContactLastName((event.target.value));}}
                      />
                    </div>
                    <div className="p-col-4 p-md-4 p-lg-2"><h5>{t('phone')}</h5></div>
                    <div className="p-col-8 p-md-8 p-lg-4">
                      <InputText readOnly={readOnly} id='phone' value={clientPhone} placeholder={t('phone')}
                        className="input" onChange={(event) => {setClientPhone((event.target.value));}}/>
                    </div>
                    <div className="p-col-4 p-md-4 p-lg-2"><h5>{t('fax')}</h5></div>
                    <div className="p-col-8 p-md-8 p-lg-4">
                      <InputText readOnly={readOnly} id='fax' value={clientFax} placeholder={t('fax')} className="input"
                        onChange={(event) => {setClientFax((event.target.value));}}/>
                    </div>
                    <div className="p-col-4 p-md-4 p-lg-2"><h5>{t('position')}</h5></div>
                    <div className="p-col-8 p-md-8 p-lg-10">
                      <InputText readOnly={readOnly} id='position' value={clientPosition} placeholder={t('position')}
                        className="input-phone" onChange={(event) => {setClientPosition((event.target.value));}}/>
                    </div>
                    <div className="p-col-4 p-md-4 p-lg-2"><h5>{t('iva')} (%)</h5></div>
                    <div className="p-col-8 p-md-8 p-lg-10">
                      <InputText readOnly={readOnly} keyfilter="pnum" id='iva' value={clientIva} placeholder={t('iva')}
                        className="input-phone" onChange={(event) => {setClientIva(event.target.value);}}/>
                    </div>
                    <div className="p-col-4 p-md-4 p-lg-2"><h5>{t('freeTransport')}</h5></div>
                    <div className="p-col-8 p-md-8 p-lg-10">
                      <Checkbox readOnly={readOnly} id="freeTransport" style={{marginTop: '15px'}}
                        onChange={(e) => setClientFreeTransport(e.checked)} checked={Boolean(clientFreeTransport)}/>
                    </div>
                    <div className="p-col-4 p-md-4 p-lg-2"><h5>{t('seeArts')}</h5></div>
                    <div className="p-col-8 p-md-8 p-lg-10">
                      <Checkbox readOnly={readOnly} id="seeArts" style={{marginTop: '15px'}}
                        onChange={(e) => setClientSeeArts(e.checked)} checked={Boolean(clientSeeArts)}/>
                    </div>
                  </div>
                  <div className="ui-button-group">
                    {readOnly
                      ? <Button
                        id='button-activate'
                        type="button"
                        icon="pi pi-user-plus"
                        label={t('activate')}
                        style={{ marginRight: '10px', width: 'auto' }}
                        onClick={handleActivate}
                      />
                      : <>
                        <Button
                          id='button-delete'
                          type="button"
                          icon="pi pi-trash"
                          label={t('deactivate')}
                          style={{ marginRight: '10px', width: 'auto' }}
                          onClick={handleDelete}
                          className="btn-danger"
                        />
                        <Button
                          icon="pi pi-save"
                          label={t('save')}
                          style={{ marginTop: '10px', width: 'auto' }}
                          className="btn-back"
                        />
                      </>
                    }
                    <Button
                      id='button-return'
                      type="button"
                      icon="pi pi-times"
                      label={t('cancel')}
                      style={{ margin: '10px', width: 'auto' }}
                      onClick={cancel}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ClientEdit;
