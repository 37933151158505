import React from 'react';

function SimpleLayout (props) {
  const { children } = props;
  return (
    <div className="all">{children}</div>
  );
}

export default SimpleLayout;
