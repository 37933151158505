import { local } from './Files/Local';
import { qa } from './Files/Qa';
import  { production } from './Files/Production';

export const getConfig = () => {
  const env = process.env.REACT_APP_ENV;
  switch (env) {
  case 'local':
    return local;
  case 'qa':
    return qa;
  case 'prod':
    return production;
  default :
    return local;
  }
};
