import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { routes } from '../../utils/routes';

const ConfigRoute = ({  path, authenticated, component: Component, layout: Layout, ...rest }) => (
  <Route {...rest}
    path={path}
    render={props => (
      authenticated ? (
        <Layout>
          <Component {...props} />
        </Layout>
      ) : (
        <Redirect to={{
          pathname: routes.Login
        }}/>
      )
    )}
  />
);
export default ConfigRoute;
