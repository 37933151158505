import React from 'react';
import AppSubMenu from '../appSubMenu/appSubMenu';

function AppMenu(props) {
  const { model, onMenuItemClick } = props;
  return  (
    <div className="layout-menu-container">
      <AppSubMenu items={model} className="layout-menu" onMenuItemClick={onMenuItemClick} root={true}/>
    </div>
  );
}

export default AppMenu;
