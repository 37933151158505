import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { getClientsByParams } from '../../../services/client/client';
import { messageError } from '../../../utils/messages';
import { routes } from '../../../utils/routes';
import { getProspectStorage, cleanProspect } from '../../../utils/cleanLocalStore';
import './list.css';

function ProspectList({ history }) {
  const [prospects, setProspects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showData, setShowData] = useState(false);
  const [params, setParams] = useState({
    alias: '',
    email: '',
    name: '',
    isProspect: 1
  });
  const { t } = useTranslation();

  const actionEdit = (rowData) => {
    return <div style={{ content: 'center', textAlign: 'center' }} id={'div-' + rowData.id}>
      <Button onClick={() => updateProspect(rowData)}
        type="button" icon="pi pi-pencil" id='button-edit'
        className="p-button-primary">
        <span className="tooltiptext tooltiptext-bottom"></span>
      </Button>
    </div>;
  };

  const updateProspect = (prospect) => {
    history.push({
      pathname: routes.ProspectEdit,
      state: { prospectUpdate: prospect }
    });
  };

  const searchProspects = (searchProspects = '') => {
    const postParamts = searchProspects !== '' ? {...searchProspects} : {...params};
    localStorage.setItem('paramsProspect', JSON.stringify({...postParamts, search: true}));
    setLoading(true);
    delete postParamts.search;
    getClientsByParams(postParamts).then(result => {
      setLoading(false);
      setProspects(result.data);
      setShowData(true);
    }).catch(error => {
      setLoading(false);
      if (error.response) {
        setShowData(true);
        setProspects([]);
        messageError(error.response.data.message);
      }
      else {
        messageError(t('withoutConnection'));
      }
    });
  };

  const clean = () => {
    setParams({
      name: '',
      alias: '',
      email: '',
      isProspect: 1
    });
    cleanProspect();
  };

  const showIsActive = (rowData) => {
    if (rowData.active === 1) {
      return <div style={{ content: 'center', textAlign: 'center' }}>
        <i style={{ content: 'center', textAlign: 'center' }} className='pi pi-check'></i>
      </div>;
    }
  };

  const addProspect = () => {
    history.push(routes.ProspectRegister);
  };

  useEffect(() => {
    const { paramsProspect } = getProspectStorage();
    setParams(paramsProspect);
    if (paramsProspect.search) {
      searchProspects(paramsProspect);
    }
  }, []);

  return (
    <div>
      <div className="ui-g ui-fluid" >
        <div className="ui-g-12">
          <div className="card">
            <h1>{t('prospectList')}</h1>
            <div className="ui-g-12 ui-md-7 ui-lg-2">
              <div className="p-grid p-fluid"><div className="p-col-6 p-md-2">
                <div className="p-inputgroup">
                  <span className="p-label">
                    <label htmlFor="name">{t('name')}</label>
                    <InputText id='name' value={params.name} className="dropdown-all-width"
                      onChange={(event) => { setParams({ ...params, name: event.target.value }); }} />
                  </span>
                </div>
              </div>
              <div className="p-col-6 p-md-2">
                <div className="p-inputgroup">
                  <span className="p-label">
                    <label htmlFor="alias">{t('alias')}</label>
                    <InputText id='alias' value={params.alias} className="dropdown-all-width"
                      onChange={(event) => { setParams({ ...params, alias: event.target.value }); }} />
                  </span>
                </div>
              </div>
              <div className="p-col-6 p-md-2">
                <div className="p-inputgroup">
                  <span className="p-label">
                    <label htmlFor="email">{t('email')}</label>
                    <InputText id='email' value={params.email} className="dropdown-all-width"
                      onChange={(event) => { setParams({ ...params, email: event.target.value }); }} />
                  </span>
                </div>
              </div>
              <div className="p-col-5 p-md-4">
                <div className="p-inputgroup-button">
                  <Button icon="pi pi-search" onClick={() => searchProspects()}
                    label={t('search')} style={{ marginBottom: '10px', width: 'auto' }}
                    className="tooltip button-table" />
                  <Button icon="pi pi-refresh" onClick={clean}
                    label={t('clean')} style={{ marginBottom: '10px', width: 'auto' }}
                    className="tooltip button-table" />
                  <Button icon="pi pi-plus" label={t('newProspect')}
                    onClick={addProspect} style={{ marginBottom: '10px', width: 'auto' }}
                    className="tooltip button-table" />
                </div>
              </div>
              </div>
            </div>
            <div className="ui-g-12">
              {loading && <div id="div-loader" className="spiner-container">
                <i className="pi pi-spin pi-spinner spiner" ></i>
              </div>}
              {!loading && showData &&
                <DataTable paginator={true} emptyMessage={t('emptyList')} rows={10} responsive={true} value={prospects}>
                  <Column className="ui-column" field="name" header={t('name')} />
                  <Column className="ui-column" field="alias" header={t('alias')} />
                  <Column className="ui-column" field="email" header={t('email')} />
                  <Column className="ui-column" field="phone" header={t('phone')} />
                  <Column className="ui-column-exception" body={showIsActive} header={t('isActive')} />
                  <Column className="ui-column-edit" header={t('edit')} body={actionEdit} />
                </DataTable>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProspectList;
