import React from 'react';
import './App.css';
import { Switch } from 'react-router-dom';
import ConfigRoute from './components/configRoute/configRoute';
import SimpleLayout from './components/common/layout/simple/simple';
import AdminLayout from './components/common/layout/admin/admin';
import { routes } from './utils/routes';
import { cleanLocalStore } from './utils/cleanLocalStore';
import Login from './components/login/login';
import UserList from './components/user/list/list';
import UserRegister from './components/user/registerUser/userRegister';
import UserEdit from './components/user/editUser/userEdit';
import { loggedIn } from './services/authentication';
import ForgotPassword from './components/forgotPassword/forgotPassword';
import ChangePassword from './components/changePassword/changePassword';
import ProformaList from './components/proforma/list/list';
import RegisterProforma from './components/proforma/registerProforma/registerProforma';
import EditProforma from './components/proforma/editProforma/editProforma';
import ProductList from './components/product/list/list';
import ProductRegister from './components/product/registerProduct/productRegister';
import ProductEdit from './components/product/editProduct/productEdit';
import PurchaseOrderList from './components/purchaseOrder/list/list';
import PurchaseOrderRegister from './components/purchaseOrder/registerPurchaseOrder/registerPurchaseOrder';
import PurchaseOrderEdit from './components/purchaseOrder/editPurchaseOrder/editPurchaseOrder';
import ClientList from './components/client/list/list';
import ClientRegister from './components/client/registerClient/clientRegister';
import ClientEdit from './components/client/editClient/clientEdit';
import ProspectList from './components/prospect/list/list';
import ProspectRegister from './components/prospect/registerProspect/prospectRegister';
import ProspectEdit from './components/prospect/editProspect/prospectEdit';
import TestList from './components/test/list/list';
import TestRegister from './components/test/registerTest/testRegister';
import TestEdit from './components/test/editTest/testEdit';
import ExactusImport from './components/import/exactus/exactus';
import AccessImport from './components/import/access/access';
import VariablesList from './components/variables/list';


function App() {
  cleanLocalStore();

  return (
    <Switch>
      <ConfigRoute layout={SimpleLayout} exact={true} path={routes.Login} component={Login} authenticated={true} />
      <ConfigRoute layout={AdminLayout} path={routes.Users} component={UserList} authenticated={loggedIn}/>
      <ConfigRoute layout={AdminLayout} path={routes.UserRegister} component={UserRegister} authenticated={loggedIn}/>
      <ConfigRoute layout={AdminLayout} path={routes.UserEdit} component={UserEdit} authenticated={loggedIn}/>
      <ConfigRoute layout={SimpleLayout} exact={true}
        path={routes.ForgotPassword} component={ForgotPassword} authenticated={true} />
      <ConfigRoute layout={AdminLayout} exact={true} path={routes.ChangePassword}
        component={ChangePassword} authenticated={loggedIn} />
      <ConfigRoute layout={AdminLayout} exact={true} path={routes.Proformas}
        component={ProformaList} authenticated={loggedIn} />
      <ConfigRoute layout={AdminLayout} exact={true} path={routes.ProformaRegister}
        component={RegisterProforma} authenticated={loggedIn} />
      <ConfigRoute layout={AdminLayout} exact={true} path={routes.ProformaEdit}
        component={EditProforma} authenticated={loggedIn} />
      <ConfigRoute layout={AdminLayout} exact={true} path={routes.Products}
        component={ProductList} authenticated={loggedIn} />
      <ConfigRoute layout={AdminLayout} path={routes.ProductRegister} component={ProductRegister} authenticated={loggedIn}/>
      <ConfigRoute layout={AdminLayout} path={routes.ProductEdit} component={ProductEdit} authenticated={loggedIn}/>
      <ConfigRoute layout={AdminLayout} path={routes.PurchaseOrders} component={PurchaseOrderList} authenticated={loggedIn}/>
      <ConfigRoute layout={AdminLayout} path={routes.PurchaseOrderRegister}
        component={PurchaseOrderRegister} authenticated={loggedIn}/>
      <ConfigRoute layout={AdminLayout} path={routes.PurchaseOrderEdit}
        component={PurchaseOrderEdit} authenticated={loggedIn}/>
      <ConfigRoute layout={AdminLayout} path={routes.Clients} component={ClientList} authenticated={loggedIn}/>
      <ConfigRoute layout={AdminLayout} path={routes.ClientRegister} component={ClientRegister} authenticated={loggedIn}/>
      <ConfigRoute layout={AdminLayout} path={routes.ClientEdit} component={ClientEdit} authenticated={loggedIn}/>
      <ConfigRoute layout={AdminLayout} path={routes.Prospects} component={ProspectList} authenticated={loggedIn}/>
      <ConfigRoute layout={AdminLayout} path={routes.ProspectRegister}
        component={ProspectRegister} authenticated={loggedIn}/>
      <ConfigRoute layout={AdminLayout} path={routes.ProspectEdit} component={ProspectEdit} authenticated={loggedIn}/>
      <ConfigRoute layout={AdminLayout} path={routes.Tests} component={TestList} authenticated={loggedIn}/>
      <ConfigRoute layout={AdminLayout} path={routes.TestRegister} component={TestRegister} authenticated={loggedIn}/>
      <ConfigRoute layout={AdminLayout} path={routes.TestEdit} component={TestEdit} authenticated={loggedIn}/>
      <ConfigRoute layout={AdminLayout} exact={true} path={routes.Exactus}
        component={ExactusImport} authenticated={loggedIn}/>
      <ConfigRoute layout={AdminLayout} exact={true} path={routes.Access} component={AccessImport} authenticated={loggedIn}/>
      <ConfigRoute layout={AdminLayout} exact={true} path={routes.Variables}
        component={VariablesList} authenticated={loggedIn}/>
    </Switch>
  );
}

export default App;
