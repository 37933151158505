export const setUserSession = (credentials) => {
  return localStorage.setItem('currentUser', JSON.stringify(credentials));
};

export const getUserSession = () => {
  const result = localStorage.getItem('currentUser');
  if (result) {
    return JSON.parse(result);
  }
  return undefined;
};

export const getHeaders = (user) => {
  let token = user.auth.token;
  return { headers: { 'Authorization': token } };
};

export const getHeadersFile = (user) => {
  let token = user.auth.token;
  return {
    headers: { 'Authorization': token, 'Content-Type': 'multipart/form-data' },
    maxContentLength: Infinity,
    maxBodyLength: Infinity,
    timeout: 120000,
    onUploadProgress: (progressEvent) => {
      const complete = (progressEvent.loaded / progressEvent.total * 100 | 0) + '%';
      console.log('upload percent: ' + complete);
    }
  };
};

export const deleteSession = () => {
  localStorage.removeItem('currentUser');
};

export const loggedIn = () => {
  if (getUserSession()) {
    return true;
  }
  return false;
};
