export const validates = (
  selectedBox, long, width, cobbWaterproofing,
  productIsCliche, productAmountCliche, productIsDieCut,
  productAmountDieCut, test, constants
) => {
  if ((selectedBox.name === 'Lamina' || selectedBox.name === 'CST') &&
    (long < 60 || width < 40 || width > 130 || long > 275)) {
    return 1;
  }
  if ((selectedBox.name === 'RSC' || selectedBox.name === 'HSC') &&
    (long < 60 || width < 30 || width > 130 || long > 275)) {
    return 1;
  }
  if (cobbWaterproofing) {
    return 1;
  }
  if (productIsDieCut && (productAmountDieCut === 0 || productAmountDieCut < constants.maxDieCut)) {
    return 1;
  }
  if (productIsCliche && (productAmountCliche === 0 || productAmountCliche < constants.maxCliche)) {
    return 1;
  }
  if (!test.isAuthorized || test.isAuthorized === 0) {
    return 1;
  }
  return 0;
};

