import React from 'react';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';
import { getLastVersionProductByCode } from '../../../services/product/product';
import {
  productCreate
} from '../../../services/product/product';
import { messageError, messageSuccess } from '../../../utils/messages';
import { routes } from '../../../utils/routes';
import './style.css';

const NewVersion = ({
  history,
  productCode,
  showText = true
}) => {
  const { t } = useTranslation();

  const handleProductOnChange = (e) => {
    e.preventDefault();
    getLastVersionProductByCode(productCode).then(result => {
      const product = result.data;
      const updatedProduct = {
        code: product.code,
        idCatalogBox: product.idCatalogBox ? product.idCatalogBox : 1,
        name: product.name,
        weight: product.weight ? product.weight : 1,
        amountCliche: product.amountCliche ? product.amountCliche : 1,
        amountDieCut: product.amountDieCut ? product.amountDieCut : 1,
        liner1: product.liner1 ? product.liner1 : '',
        liner2: product.liner2 ? product.liner2 : '',
        liner3: product.liner3 ? product.liner3 : '',
        medium1: product.medium1 ? product.medium1 : '',
        medium2: product.medium2 ? product.medium2 : '',
        version: (product.version + 1),
        flaps: product.flaps ? product.flaps : '',
        active: (product.active ? true : false),
        minimumCombination: product.minimumCombination ? product.minimumCombination : '',
        internalLong: product.internalLong ? product.internalLong : 1,
        internalWidth: product.internalWidth ? product.internalWidth : 1,
        internalHeight: product.internalHeight ? product.internalHeight : 1,
        thirdProcesses: product.thirdProcesses ? product.thirdProcesses : 0,
        unitPrice: product.unitPrice ? product.unitPrice : 1,
        priceByTc: product.priceByTc ? product.priceByTc : 1,
        wastePercentage: product.wastePercentage ? product.wastePercentage : 0,
        isException: product.isException ? product.isException : 0,
        isClose: false,
        isPaletized: product.isPaletized ? product.isPaletized : 0,
        idClient: product.idClient,
        isCliche: product.isCliche.data[0] ? product.isCliche.data[0] : 0,
        printing: product.printing ? product.printing : 0,
        isDieCut: product.isDieCut.data[0] ? product.isDieCut.data[0] : 0,
        isSample: product.isSample ? product.isSample : 0,
        idTest: product.idTest ? product.idTest : -1,
        idCatalogFlaute: product.idCatalogFlaute ? product.idCatalogFlaute : 1,
        idCatalogPaper: product.idCatalogPaper ? product.idCatalogPaper : 1,
        idCatalogTypeProduct: product.idCatalogTypeProduct ? product.idCatalogTypeProduct : 1,
        sheetWidth: product.sheetWidth ? product.sheetWidth : 0,
        sheetLong: product.sheetLong ? product.sheetLong : 0,
        area: product.area ? product.area : 1,
        isFirstOrder: 1
      };
      productCreate(updatedProduct).then(result => {
        product.id = result.data.id;
        messageSuccess(t('messageSuccessProductNewVersion'));
        history.push({
          pathname: routes.ProductEdit,
          state: { productUpdate: product },
          newVersion: true
        });
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      }).catch(error => {
        (error.response) ? messageError(error.response.data.message) : messageError(t('messageErrorProductCreated'));
      });
    });
  };

  return (
    <Button
      id='addProformaDetailButton'
      label={showText ? t('createNewVersion') : ''}
      title={!showText ? t('createNewVersion') : ''}
      icon="pi pi-plus"
      onClick={handleProductOnChange}
      className={showText ? 'btn-primary btn-inside' : 'btn-primary btn-outside'}
    />
  );
};

export default NewVersion;
