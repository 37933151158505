import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { useTranslation } from 'react-i18next';
import { getAllRoles } from '../../../services/rol/rol';
import { userCreate } from '../../../services/user/user';
import { messageError, messageSuccess } from '../../../utils/messages';
import { routes } from '../../../utils/routes';
import './userRegister.css';

function UserRegister({ history }) {
  const { t } = useTranslation();
  const [roles, setRoles] = React.useState([]);
  const [userFirstName, setFirstName] = useState('');
  const [userLastName, setLastName] = useState('');
  const [userEmail, setEmail] = useState('');
  const [selectedRol, setSelectedRol] = useState('');

  useEffect(() => {
    getAllRoles().then(result => {
      setRoles(result.data);
    }).catch(error => {
      if (error.response) {
        messageError(error.response.data.message);
      }
      else {
        messageError(t('withoutConnection'));
      }
    });
  }, [t]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const user = {
      email: userEmail,
      firstName: userFirstName,
      lastName: userLastName,
      idRole: selectedRol.id
    };
    userCreate(user).then(() => {
      messageSuccess(t('messageSuccessUserCreated'));
      history.push(routes.Users);
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 404) {
          messageError(error.response.data.message);
        } else {
          messageError(t('messageErrorUserCreated'));
        }
      }
    });
  };

  const cancel = () => {
    history.push(routes.Users);
  };

  return (
    <div>
      <div className="ui-g ui-fluid" >
        <div className="ui-g-12">
          <div className="card-user">
            <div className="card">
              <div className="content-section introduction">
                <h1>{t('userRegister')}</h1>
                <form onSubmit={handleSubmit}>
                  <h4 className="label-gray">{t('firstName')}</h4>
                  <InputText value={userFirstName} required className="input"
                    onChange={(event) => { setFirstName((event.target.value)); }} />
                  <h4 className="label-gray">{t('lastName')}</h4>
                  <InputText value={userLastName} required className="input"
                    onChange={(event) => { setLastName((event.target.value)); }} />
                  <h4 className="label-gray">{t('email')}</h4>
                  <InputText value={userEmail} required className="input"
                    onChange={(event) => { setEmail((event.target.value)); }} />
                  <h4 className="label-gray">{t('rol')}</h4>
                  <Dropdown required className="input" optionLabel="name"
                    value={selectedRol} onChange={(e) => { setSelectedRol(e.value); }}
                    placeholder={t('selectRol')} options={roles} />
                  <div className="ui-button-group">
                    <Button id='button-save' icon="pi pi-save" label={t('save')}
                      style={{ marginTop: '10px', width: 'auto' }} />
                    <Button id='button-return' type="button" icon="pi pi-times"
                      label={t('cancel')} style={{ margin: '10px', width: 'auto' }} onClick={cancel} />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default UserRegister;
