import { axiosInstance } from '../config';
import { getUserSession, getHeaders } from '../authentication';

export const getBoxes = () => {
  const config = getHeaders(getUserSession());
  return axiosInstance.get('catalogBoxes/list',config);
};

export const getBoxById = (id) => {
  const config = getHeaders(getUserSession());
  return axiosInstance.get('catalogBoxes/byId/' + id,config);
};

