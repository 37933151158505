import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { ScrollPanel } from 'primereact/scrollpanel';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { AutoComplete } from 'primereact/autocomplete';
import { Checkbox } from 'primereact/checkbox';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import { useTranslation } from 'react-i18next';
import {
  proformaUpdate,
  getProformaById,
  sendProformaToClientById,
  sendsProformaApprovalEmail,
  sendsApprovedProformaEmail
} from '../../../services/proforma/proforma';
import { messageError, messageSuccess, messageSuccessDownload } from '../../../utils/messages';
import { updateSubtotal } from '../../../utils/functions';
import { constantsObject } from '../../../utils/constantsObject';
import { currencyFormatter } from '../../../utils/currency';
import { getClientsByKey } from '../../../services/client/client';
import {
  proformaDetailCreate,
  proformaDetailUpdate,
  proformaDetailDelete
} from '../../../services/proformaDetail/proformaDetail';
import { getFlautesByWall } from '../../../services/catalogFlaute/catalogFlaute';
import { getPapersByTest } from '../../../services/catalogPaper/catalogPaper';
import { getTests } from '../../../services/tests/tests';
import { getCountrySubdivisions1ByCountryId } from '../../../services/countrySubdivision1/countrySubdivision1';
import { getCountrySubdivisions2ByCountrySubdivisions1Id } from '../../../services/countrySubdivision2/countrySubdivision2';
import { getCountrySubdivisions3ByCountrySubdivisions2Id } from '../../../services/countrySubdivision3/countrySubdivision3';
import { getProductsByKeyAndClientId } from '../../../services/product/product';
import { getMeasurementsByFlauteIdAndBoxId } from '../../../services/measurementsByFlauteAndBox/measurementsByFlauteAndBox';
import { getBoxes, getBoxById } from '../../../services/catalogBox/catalogBox';
import { routes } from '../../../utils/routes';
import { Growl } from 'primereact/growl';
import './editProforma.css';
import { getUserSession } from '../../../services/authentication';
import { getAllProductionVariables } from '../../../services/productionVariables/productionVariables';
import { isNil } from 'lodash';
import { generatePDF, detailedPDF } from './../../../utils/pdfDocument';
import {
  getProductDetailsByProductId,
  getProductDetailByBoxNameWithType
} from '../../../services/productDetail/productDetail';
import { purchaseOrderCreate } from '../../../services/purchaseOrderHeader/purchaseOrderHeader';
import { purchaseOrderDetailCreate } from '../../../services/purchaseOrderDetail/purchaseOrderDetail';
import { kraftOneFace, whiteOneFace, whiteTwoFaces } from '../../../utils/generalConstants';
import {
  SALES_MANAGER_CODE,
  SUPER_USER_CODE,
  OPERATIONS_DIRECTOR_CODE,
  QUALITY_MANAGER_CODE
} from '../../../utils/constantsUser';
const COSTA_RICA_ID = 1;
const REOPEN = 0;
const APPROVED_CODE = 1;
const REJECTED_CODE = 3;
let DETAIL_RESET_VALUES = {
  name: '',
  boxWeight: 0,
  sheetWidth: 0,
  sheetLong: 0,
  internalLong: 0,
  internalHeight: 0,
  internalWidth: 0,
  quantity: 0,
  priceByTc: 0,
  wastePercentage: 0,
  amountDieCut: 0,
  amountCliche: 0,
  isFirstOrder: 1,
  thirdProcesses: 0,
  weight: 0,
  product: { unitPrice: 0 },
  test: { isAuthorized: 1 },
  flaute: {},
  colorQuantity: 0,
  paper: {},
  box: {},
  isException: 0
};

function EditProforma({ history }) {
  const { t } = useTranslation();
  const [selectedClient, setSelectedClient] = useState({});
  const [email, setEmail] = useState('');
  const [clients, setClients] = useState([]);
  const [proformaDetails, setProformaDetails] = useState([]);
  const [selectedProformaDetail, setSelectedProfromaDetail] = useState({});
  const [selectedProduct, setSelectedProduct] = useState();
  const [countrySubdivisions1, setCountrySubdivisions1] = useState([]);
  const [countrySubdivisions2, setCountrySubdivisions2] = useState([]);
  const [countrySubdivisions3, setCountrySubdivisions3] = useState([]);
  const [filteredCountrySubdivisions1, setFilteredCountrySubdivisions1] = useState([]);
  const [filteredCountrySubdivisions2, setFilteredCountrySubdivisions2] = useState([]);
  const [filteredCountrySubdivisions3, setFilteredCountrySubdivisions3] = useState([]);
  const [selectedCountrySubdivision1, setSelectedCountrySubdivision1] = useState('');
  const [selectedCountrySubdivision2, setSelectedCountrySubdivision2] = useState('');
  const [selectedCountrySubdivision3, setSelectedCountrySubdivision3] = useState('');
  const [exactDirection, setExactDirection] = useState('');
  const [transportSize, setTransportSize] = useState('');
  const [transportType, setTransportType] = useState(5);
  const [selectedFlaute, setSelectedFlaute] = useState({});
  const [selectedClientInfo, setSelectedClientInfo] = useState({});
  const [selectedTest, setSelectedTest] = useState({});
  const [papers, setPapers] = useState([]);
  const [flautes, setFlautes] = useState([]);
  const [selectedBox, setSelectedBox] = useState({});
  const [boxes, setBoxes] = useState([]);
  const [freeTransport, setFreeTransport] = useState(false);
  const [tests, setTests] = useState([]);
  const [testsAll, setTestsAll] = useState([]);
  const [proformaAttachements, setProformaAttachements] = useState([]);
  const [displayAttachementsDialog, setDisplayAttachementsDialog] = useState(false);
  const [displayDialog, setDisplayDialog] = useState(false);
  const [newProformaDetail, setNewProformaDetail] = useState(false);
  const [products, setProducts] = useState([]);
  const [deletedDetails] = useState([]);
  const [productDetails, setProductDetails] = useState([]);
  const [arrayOfValidations, setArrayOfValidations] = useState([]);
  const [updatedDetails] = useState([]);
  const [widthTolerance, setWidthTolerance] = useState('');
  const [longTolerance, setLongTolerance] = useState('');
  const [newDetails] = useState([]);
  const [constants, setConstants] = useState();
  const [isApproved, setIsApproved] = useState(false);
  const [isRendered, setIsRendered] = useState(false);
  const [isBeingSendedToClient, setIsBeingSendedToClient] = useState(false);
  const [growl, setGrowl] = useState('');
  const [ivaPercentage, setIvaPercentage] = useState(0);
  const [proformaDetail, setProformaDetail] = React.useState();
  const [terms, setTerms] = useState(0);
  const [exceptions, setExceptions] = useState([]);
  const [creditTerm, setCreditTerm] = useState(0);
  const [proforma, setProforma] = useState({
    transport: 0,
    idClient: 0,
    discountDescription: '',
    discountMount: 0,
    discountPorcentage: 0,
    comments: ''
  });
  const [subtotal, setSubtotal] = useState([]);
  const [displayIsNewProductDialog, setDisplayIsNewProductDialog] = useState(false);
  const [displayWarningDialog, setDisplayWarningDialog] = useState(false);
  const [isNewProduct, setIsNewProduct] = useState(true);
  let iva, subtotalAmount, total;
  const [detailAmountOneRegularFaceWaterproofing, setDetailAmountOneRegularFaceWaterproofing] = useState();
  const [detailAmountTwoRegularFacesWaterproofing, setDetailAmountTwoRegularFacesWaterproofing] = useState();
  const [detailAmountOneCobbFaceWaterproofing, setDetailAmountOneCobbFaceWaterproofing] = useState();
  const [detailAmountTwoCobbFacesWaterproofing, setDetailAmountTwoCobbFacesWaterproofing] = useState();

  const SHEET = 'Lamina';
  const TRAY = 'Bandeja';
  const OBSOLETE = 'Obsoleta';
  const DIE_CUT = 'troquel';
  const CREDIT = 1;

  const filters = [
    { label: t('smallTruck'), value: 1 },
    { label: t('mediumTruck'), value: 2 },
    { label: t('bigTruck'), value: 3 }
  ];

  //destructuring
  const { codeRole } = getUserSession().user;

  useEffect(() => {
    getProformaById(history.location.state.proformaUpdate.id).then(result => {
      const tempSubtotal = [
        { name: 'transport', amount: 0 }
      ];
      (result.data[0].terms) ? setTerms(result.data[0].terms) : setTerms(0);
      (result.data[0].creditTerm) ? setCreditTerm(result.data[0].creditTerm) : setCreditTerm(0);
      setFreeTransport(Boolean(result.data[0].freeTransport));
      tempSubtotal[0].amount = (Math.round(result.data[0].transport * 100) / 100).toFixed(2);
      setSubtotal(tempSubtotal);
      result.data[0].discountMount = (Math.round(result.data[0].discountMount * 100) / 100).toFixed(2);
      result.data[0].discountPorcentage = (Math.round(result.data[0].discountPorcentage * 100) / 100).toFixed(2);
      setProforma(result.data[0]);
      setIsApproved((result.data[0].status > 0));
      setExactDirection(result.data[0].exactDirection);
      let size;
      switch (result.data[0].transportSize) {
      case 1:
        size = { label: t('smallTruck'), value: 1 };
        break;
      case 2:
        size = { label: t('mediumTruck'), value: 2 };
        break;
      case 3:
        size = { label: t('bigTruck'), value: 3 };
        break;
      default:
        size = '';
        break;
      }
      setTransportSize(size);
      setTransportType(result.data[0].transportType);
      if (result.data[0].idCountrySubdivisions1) {
        setSelectedCountrySubdivision1(result.data[0].countrySubdivision1);
        getCountrySubdivisions2ByCountrySubdivisions1Id(result.data[0].idCountrySubdivisions1).then(resultCountry1 => {
          setCountrySubdivisions2(resultCountry1.data);
        });
        if (result.data[0].idCountrySubdivisions2) {
          setSelectedCountrySubdivision2(result.data[0].countrySubdivision2);
          getCountrySubdivisions3ByCountrySubdivisions2Id(result.data[0].idCountrySubdivisions2).then(resultCountry2 => {
            setCountrySubdivisions3(resultCountry2.data);
          });
          if (result.data[0].idCountrySubdivisions3) {
            setSelectedCountrySubdivision3(result.data[0].countrySubdivision3);
          }
        }
      }
      let details = [];
      let exceptionDetails = [];
      if (result.data[0].proformaDetails.data !== null) {
        result.data[0].proformaDetails.map((item) => {
          if (!item.idCatalogBox) {
            getBoxById(item.idCatalogBox).then(response => {
              item.box = response.data;
            });
          }
          getProductDetailsByProductId(item.idProduct).then(resultProductDetails => {
            let newDetails = [];
            (getUserSession().user.codeRole !== SALES_MANAGER_CODE)
              ? newDetails = resultProductDetails.data.filter(element => element.url.split('.')[4] !== 'xlsx')
              : details = resultProductDetails.data;
            newDetails.forEach(element => details.push(element));
          });
          let newBoxType = !result.data[0].proformaDetails
            .find(element => element.box.nameWithType === item.box.nameWithType && element.id !== item.id);
          if (newBoxType) {
            getProductDetailByBoxNameWithType(item.box.nameWithType).then(genericImages => {
              genericImages.data.forEach(element => details.push(element));
            });
          }
          (item.isDieCut === 0) ? item.isDieCut = false : item.isDieCut = true;
          (item.isCliche === 0) ? item.isCliche = false : item.isCliche = true;
          (setSubtotal(prevSubtotal => [...prevSubtotal, { name: 'proformaDetail', amount: item.cost }]));
          if (!item.test) {
            item.test = {};
          }
          if (!item.productType) {
            item.productType = {};
          }
          if (item.isException === 1) {
            exceptionDetails.push(item);
          }
          return item;
        });
        setProductDetails(details);
        setProformaDetails(result.data[0].proformaDetails);
        setExceptions(exceptionDetails);
      }
      setEmail(result.data[0].contactEmail);
      if (exceptionDetails.length > 0) {
        setDisplayWarningDialog(true);
      }
      setIsRendered(true);
    }).catch(error => {
      (error.response) ? messageError(error.response.data.message) : messageError(t('withoutConnection'));
    });
  }, [history.location.state.proformaUpdate]);

  useEffect(() => {
    getAllProductionVariables().then(result => {
      const variables = constantsObject(result.data);
      setConstants(variables);
      setDetailAmountOneRegularFaceWaterproofing(variables.oneFaceRegularWaterproofing);
      setDetailAmountTwoRegularFacesWaterproofing(variables.twoFacesRegularWaterproofing);
      setDetailAmountOneCobbFaceWaterproofing(variables.oneFaceCobbWaterproofing);
      setDetailAmountTwoCobbFacesWaterproofing(variables.twoFacesCobbWaterproofing);
      DETAIL_RESET_VALUES.quantity = variables.quantity;
      DETAIL_RESET_VALUES.wastePercentage = variables.wastePercentage;
      DETAIL_RESET_VALUES.amountCliche = variables.amountCliche;
      DETAIL_RESET_VALUES.amountDieCut = variables.amountDieCut;
      setSelectedProduct(DETAIL_RESET_VALUES);
      setProformaDetail(DETAIL_RESET_VALUES);
    }).catch(error => {
      (error.response) ? messageError(error.response.data.message) : messageError(t('withoutConnection'));
    });
    getCountrySubdivisions1ByCountryId(COSTA_RICA_ID).then(result => {
      setCountrySubdivisions1(result.data);
    });
    setSelectedClient({
      value: history.location.state.proformaUpdate.idClient,
      name: history.location.state.proformaUpdate.clients.name
    });
    setSelectedClientInfo(history.location.state.proformaUpdate.clients);
    setIvaPercentage(history.location.state.proformaUpdate.iva);
    getBoxes().then(resultBox => {
      const boxes = resultBox.data.filter(element => element.name !== OBSOLETE && element.name !== TRAY);
      setBoxes(boxes);
    });
    getTests().then(result => {
      setTestsAll(result.data);
      setTests(result.data.filter(({ active }) => active === 1));
    });
  }, [t, history.location.state.proformaUpdate]);

  const handleClientOnChange = (e) => {
    getClientsByKey(e.query).then(result => {
      setClients(result.data);
    }).catch(error => {
      if (!error.response) {
        messageError(t('withoutConnection'));
      }
    });
  };

  const handleSubmit = (event, status) => {
    setIsRendered(false);
    event.preventDefault();
    const proformaUpdated = {
      id: proforma.id,
      discountDescription: proforma.discountDescription,
      discountPorcentage: Number(proforma.discountPorcentage),
      discountMount: Number(proforma.discountMount),
      comments: proforma.comments,
      idClient: selectedClient.value,
      total: Number(total),
      subtotal: Number(subtotalAmount),
      iva: Number(iva),
      status: status >= 0 ? status : proforma.status,
      transport: Number(subtotal[0].amount),
      contactEmail: email,
      transportType: transportType,
      terms: terms
    };
    if (proformaUpdated.status === null) {
      proformaUpdated.status = 0;
    }
    if (terms === 1) {
      proformaUpdated.creditTerm = creditTerm;
    }
    if (transportType !== 1 && transportType !== 2) {
      let direction, size;
      (transportSize.value) ? size = transportSize.value : size = -1;
      if (selectedCountrySubdivision1.id) {
        proformaUpdated.idCountrySubdivisions1 = selectedCountrySubdivision1.id;
      }
      if (selectedCountrySubdivision2.id) {
        proformaUpdated.idCountrySubdivisions2 = selectedCountrySubdivision2.id;
      }
      if (selectedCountrySubdivision3.id) {
        proformaUpdated.idCountrySubdivisions3 = selectedCountrySubdivision3.id;
      }
      (exactDirection) ? direction = exactDirection : direction = '';
      proformaUpdated.exactDirection = direction;
      proformaUpdated.transportSize = size;
    }
    proformaUpdate(proformaUpdated).then(result => {
      handleSubmitWithProformaDetails(result.data.id, status);
    }).catch(error => {
      (error.response) ? messageError(error.response.data.message) : messageError(t('messageErrorProformaUpdated'));
    });
  };

  const calculateUnitPrice = (detail) => {
    let area;
    if (detail.product && detail.product.id) {
      return detail.product.unitPrice;
    }
    (detail.box && detail.box.name === SHEET)
      ? area = Number(calculateSheetArea(detail))
      : area = Number(calculateArea(detail));
    let totalVar = Number(detail.priceByTc * calculateWeight(detail) / 1000).toFixed(4);
    if (detail.regularWaterproofing) {
      if (detail.waterproofedFaces === 1) {
        totalVar = Number(totalVar) + Number(detailAmountOneRegularFaceWaterproofing * area);
      } else if (detail.waterproofedFaces === 2) {
        totalVar = Number(totalVar) + Number(detailAmountTwoRegularFacesWaterproofing * area);
      }
    } else if (detail.cobbWaterproofing) {
      if (detail.waterproofedFaces === 1) {
        totalVar = Number(totalVar) + Number(detailAmountOneCobbFaceWaterproofing * area);
      } else if (detail.waterproofedFaces === 2) {
        totalVar = Number(totalVar) + Number(detailAmountTwoCobbFacesWaterproofing * area);
      }
    }
    if (detail.isPaletized) {
      return Number(Number(totalVar) + (Number(totalVar) * Number(detail.wastePercentage) / 100))
        + Number(detail.thirdProcesses) + Number(constants.paletizedCost);
    }
    return Number(Number(totalVar) + (Number(totalVar)
      * Number(detail.wastePercentage) / 100)) + Number(detail.thirdProcesses);
  };

  const calculateDieCutCost = (detail) => {
    let area;
    area = (detail.box && detail.box.name === SHEET) ? Number(calculateSheetArea(detail)) : Number(calculateArea(detail));
    if ((detail.isDieCut === true || detail.isDieCut === 1)
      && (isNil(detail.product?.id) || detail.product.isFirstOrder === 1)) {
      return Number(detail.amountDieCut * area).toFixed(4);
    } return 0;
  };

  const calculateClicheCost = (detail) => {
    let area;
    area = (detail.box && detail.box.name === SHEET) ? Number(calculateSheetArea(detail)) : Number(calculateArea(detail));
    if ((detail.isCliche === true || detail.isCliche === 1)
      && (isNil(detail.product?.id) || detail.product.isFirstOrder === 1)) {
      if (detail.colorQuantity === 4) {
        return Number(Number(detail.amountCliche * area * detail.colorQuantity)
          + Number(detail.amountCliche * (constants.forthColorPercentage / 100))).toFixed(4);
      } return Number(detail.amountCliche * area * detail.colorQuantity).toFixed(4);
    } return 0;
  };

  const downloadMessage = async () => {
    const confirm = await messageSuccessDownload(t('messagesSuccessProformaUpdated'));
    if (!confirm) {
      createPdf(1);
    }
    history.push(routes.Proformas);
  };

  const handleSubmitWithProformaDetails = async (idProforma, status) => {
    let detail, submited = true;
    let hasDetailsToUpdate = false;
    if (newDetails.length > 0) {
      newDetails.forEach(element => {
        detail = {
          idProforma: Number(idProforma),
          unitPrice: Number(element.unitPrice),
          internalLong: Number(element.internalLong),
          internalWidth: Number(element.internalWidth),
          internalHeight: Number(element.internalHeight),
          sheetLong: Number(element.sheetLong),
          sheetWidth: Number(element.sheetWidth),
          idFlaute: Number(element.flaute.id),
          idPaper: Number(element.paper.id),
          idTest: Number(element.test.id),
          wastePercentage: Number(element.wastePercentage),
          quantity: Number(element.quantity),
          area: Number(calculateArea(element)),
          priceByTc: Number(element.priceByTc),
          cost: Number(element.cost),
          thirdProcesses: Number(element.thirdProcesses),
          isException: Number(element.isException)
        };
        if (element.box) {
          detail.idCatalogBox = Number(element.box.id);
        }
        (element.isPaletized) ? detail.isPaletized = 1 : detail.isPaletized = 0;
        if (element.isCliche) {
          detail.isCliche = 1;
          detail.amountCliche = Number(element.amountCliche);
          detail.clicheTotalCost = Number(element.clicheTotalCost);
          detail.colorQuantity = Number(element.colorQuantity);
        } else {
          detail.isCliche = 0;
          detail.amountCliche = 0;
          detail.clicheTotalCost = 0;
          detail.colorQuantity = 0;
        }
        if (element.isDieCut) {
          detail.isDieCut = 1;
          detail.amountDieCut = Number(element.amountDieCut);
          detail.dieCutTotalCost = Number(element.dieCutTotalCost);
        } else {
          detail.isDieCut = 0;
          detail.amountDieCut = 0;
          detail.dieCutTotalCost = 0;
        }
        if (element.regularWaterproofing) {
          detail.regularWaterproofing = 1;
          detail.waterproofedFaces = element.waterproofedFaces;

          if (element.waterproofedFaces === 1) {
            detail.amountOneRegularFaceWaterproofing = Number(detailAmountOneRegularFaceWaterproofing);
          } else if (element.waterproofedFaces === 2) {
            detail.amountTwoRegularFacesWaterproofing = Number(detailAmountTwoRegularFacesWaterproofing);
          }
        } else {
          detail.amountOneRegularFaceWaterproofing = 0;
          detail.amountTwoRegularFacesWaterproofing = 0;
        }
        if (element.cobbWaterproofing) {
          detail.cobbWaterproofing = 1;
          detail.waterproofedFaces = element.waterproofedFaces;

          if (element.waterproofedFaces === 1) {
            detail.amountOneCobbFaceWaterproofing = Number(detailAmountOneCobbFaceWaterproofing);
          } else if (element.waterproofedFaces === 2) {
            detail.amountTwoCobbFacesWaterproofing = Number(detailAmountTwoCobbFacesWaterproofing);
          }
        } else {
          detail.amountOneCobbFaceWaterproofing = 0;
          detail.amountTwoCobbFacesWaterproofing = 0;
        }
        if (element.product && element.product.id) {
          detail.idProduct = Number(element.product.id);
          detail.name = element.product.name;
        } else {
          detail.name = element.name;
        }
        proformaDetailCreate(detail)
          .catch(error => {
            submited = false;
            (error.response) ? messageError(error.response.data.message) : messageError(t('messageErrorProformaCreated'));
          });
      });
    } if (updatedDetails.length > 0) {
      hasDetailsToUpdate = true;
      updatedDetails.forEach(element => {
        detail = {
          id: element.id,
          idProforma: Number(idProforma),
          unitPrice: Number(element.unitPrice),
          isDieCut: Boolean(element.isDieCut),
          isCliche: Boolean(element.isCliche),
          wastePercentage: Number(element.wastePercentage),
          quantity: Number(element.quantity),
          area: Number(calculateArea(element)),
          priceByTc: Number(element.priceByTc),
          cost: Number(element.cost),
          thirdProcesses: Number(element.thirdProcesses),
          isException: Number(element.isException)
        };
        if (element.paper.id) {
          detail.idPaper = Number(element.paper.id);
        }
        if (element.test.id) {
          detail.idTest = Number(element.test.id);
        }
        if (element.flaute.id) {
          detail.idFlaute = Number(element.flaute.id);
        }
        if (element.box.id) {
          detail.idCatalogBox = Number(element.box.id);
        }
        (element.isPaletized) ? detail.isPaletized = 1 : detail.isPaletized = 0;
        if (element.regularWaterproofing) {
          detail.regularWaterproofing = 1;
          detail.waterproofedFaces = element.waterproofedFaces;
        }
        if (element.cobbWaterproofing) {
          detail.cobbWaterproofing = 1;
          detail.waterproofedFaces = element.waterproofedFaces;
        }
        if (element.product) {
          detail.idProduct = Number(element.product.id);
          detail.name = element.product.name;
        } else {
          detail.name = element.name;
        }
        if (element.isCliche) {
          detail.isCliche = 1;
          detail.amountCliche = element.amountCliche;
          detail.clicheTotalCost = element.clicheTotalCost;
          detail.colorQuantity = element.colorQuantity;
        } else {
          detail.isCliche = 0;
          detail.amountCliche = 0;
          detail.clicheTotalCost = 0;
          detail.colorQuantity = 0;
        }
        if (element.isDieCut) {
          detail.isDieCut = 1;
          detail.amountDieCut = element.amountDieCut;
          detail.dieCutTotalCost = element.dieCutTotalCost;
        } else {
          detail.isDieCut = 0;
          detail.amountDieCut = 0;
          detail.dieCutTotalCost = 0;
        }
        if (element.box.name === SHEET) {
          detail.sheetLong = Number(element.sheetLong);
          detail.sheetWidth = Number(element.sheetWidth);
          detail.area = Number(calculateSheetArea(element));
          detail.internalLong = Number(Number(element.sheetLong) * 10);
          detail.internalWidth = Number(Number(element.sheetWidth) * 10);
          detail.internalHeight = 0;
        } else {
          detail.sheetLong = Number(calculateSheetLong(element));
          detail.sheetWidth = Number(calculateSheetWidth(element));
          detail.area = Number(calculateArea(element));
          detail.internalLong = Number(element.internalLong);
          detail.internalWidth = Number(element.internalWidth);
          detail.internalHeight = Number(element.internalHeight);
        }
        proformaDetailUpdate(detail)
          .catch(error => {
            submited = false;
            (error.response) ? messageError(error.response.data.message) : messageError(t('messageErrorProformaCreated'));
          }).then(async () => {
            if (hasDetailsToUpdate){
              if (isNil(status)) {
                await sendsProformaApprovalEmail(idProforma);
              }
              switch (status) {
              case APPROVED_CODE:
                downloadMessage();
                await sendsApprovedProformaEmail(idProforma);
                break;
              case REOPEN:
                history.location.state.proformaUpdate.status = REOPEN;
                window.location.reload(true);
                break;
              default:
                downloadMessage();
                break;
              }
            }
          });
      });
    } if (deletedDetails.length > 0) {
      deletedDetails.forEach(element => {
        detail = element.id;
        proformaDetailDelete(detail)
          .catch(error => {
            submited = false;
            (error.response) ? messageError(error.response.data.message) : messageError(t('messageErrorProformaCreated'));
          });
      });
    } if (submited && hasDetailsToUpdate === false) {
      if (isNil(status)) {
        await sendsProformaApprovalEmail(idProforma);
      }
      switch (status) {
      case APPROVED_CODE:
        downloadMessage();
        await sendsApprovedProformaEmail(idProforma);
        break;
      case REOPEN:
        history.location.state.proformaUpdate.status = REOPEN;
        window.location.reload(true);
        break;
      default:
        downloadMessage();
        break;
      }
    }
    setIsRendered(true);
  };

  const handleOrderSubmit = async (event) => {
    event.preventDefault();
    if (proformaDetails.length === 0) {
      messageError(t('missingProduct'));
    } else {
      const purchaseOrder = {
        status: 0,
        observations: proforma.comments,
        idClient: selectedClient.value
      };
      purchaseOrderCreate(purchaseOrder).then(result => {
        handlePurchaseOrderDetailSubmit(result.data.id);
      }).catch(error => {
        (error.response) ? messageError(error.response.data.message) : messageError(t('messageErrorPurchaseOrderCreated'));
      });
    }
  };

  const handlePurchaseOrderDetailSubmit = async (id) => {
    let poDetail;
    proformaDetails.forEach((detail) => {
      poDetail = {
        idPurchaseOrderHeaders: id,
        quantity: Number(detail.quantity)
      };
      if (detail.idProduct > 0) {
        poDetail = { ...poDetail, idProduct: detail.idProduct };
      } else {
        poDetail = { ...poDetail, idProformaDetail: detail.id };
      }
      purchaseOrderDetailCreate(poDetail).catch(error => {
        (error.response) ? messageError(error.response.data.message) : messageError(t('messageErrorPurchaseOrderCreated'));
        return;
      });
    });
    messageSuccess(t('messageSuccessPurchaseOrderCreated'));
  };

  const createPdf = (typePDF) => {
    let delivery, paymentTerm, type;
    type = transportType;
    (terms === 0) ? paymentTerm = t('cash') : paymentTerm = t('credit') + ' - ' + creditTerm + ' ' + t('days');
    if (type > 2) {
      type = 3;
    }
    switch (type) {
    case 1:
      delivery = t('GuacimoPlant');
      break;
    case 2:
      delivery = t('CartagoCellar');
      break;
    case 3:
      if (!selectedCountrySubdivision1) {
        delivery = exactDirection;
      } else if (!selectedCountrySubdivision2.name) {
        delivery = selectedCountrySubdivision1.name + '. ' + exactDirection;
      } else if (!selectedCountrySubdivision3.name) {
        delivery = selectedCountrySubdivision1.name + ', ' + selectedCountrySubdivision2.name + '. ' + exactDirection;
      } else {
        delivery = selectedCountrySubdivision1.name + ', ' + selectedCountrySubdivision2.name
            + ', ' + selectedCountrySubdivision3.name + '. ' + exactDirection;
      }
      break;
    default:
      delivery = '-';
      break;
    }
    if (typePDF === 2) {
      detailedPDF(
        selectedClient.name,
        selectedClientInfo.contactFirstName + ' ' + selectedClientInfo.contactLastName,
        selectedClientInfo.phone,
        selectedClientInfo.fax,
        selectedClientInfo.email,
        getUserSession().user.firstName + ' ' + getUserSession().user.lastName,
        proforma.id,
        getDate(proforma),
        proformaDetails,
        iva,
        subtotalAmount,
        total, delivery, ivaPercentage, subtotal[0].amount, paymentTerm, proforma.comments);
    } else {
      generatePDF(
        selectedClient.name,
        selectedClientInfo.contactFirstName + ' ' + selectedClientInfo.contactLastName,
        selectedClientInfo.phone,
        selectedClientInfo.fax,
        selectedClientInfo.email,
        getUserSession().user.firstName + ' ' + getUserSession().user.lastName,
        proforma.id,
        getDate(proforma),
        proformaDetails,
        iva,
        subtotalAmount,
        total, delivery, ivaPercentage, subtotal[0].amount, paymentTerm, proforma.comments);
    }
  };

  const getDate = (proforma) => {
    let d = new Date(proforma.createdOn),
      day = '' + d.getDate(),
      month = '' + (d.getMonth() + 1),
      year = d.getFullYear();
    if (month.length < 2) { month = '0' + month; }
    if (day.length < 2) { day = '0' + day; }
    return [day, month, year].join('/');
  };

  const handleTransportCostChange = (cost, selectedFreeTransport) => {
    const tempSubtotal = [...subtotal];
    if (selectedClient && Boolean(selectedFreeTransport)) {
      tempSubtotal[0].amount = 0;
    } else {
      if (transportType === 3) {
        tempSubtotal[0].amount = cost;
      } else {
        if (selectedCountrySubdivision3.isGam) {
          switch (cost) {
          case 1:
            tempSubtotal[0].amount = constants.transportGAMSmallTruck;
            break;
          case 2:
            tempSubtotal[0].amount = constants.transportGAMMediumTruck;
            break;
          case 3:
            tempSubtotal[0].amount = constants.transportGAMBigTruck;
            break;
          default:
            tempSubtotal[0].amount = 0;
            break;
          }
        } else {
          switch (transportSize.value) {
          case 1:
            tempSubtotal[0].amount = constants.transportOutsideGAMSmallTruck;
            break;
          case 2:
            tempSubtotal[0].amount = constants.transportOutsideGAMMediumTruck;
            break;
          case 3:
            tempSubtotal[0].amount = constants.transportOutsideGAMBigTruck;
            break;
          default:
            tempSubtotal[0].amount = 0;
            break;
          }
        }
      }
    }
    setSubtotal(tempSubtotal);
  };

  const getTotalCosts = () => {
    return subtotal.reduce((total, item) => {
      return total + Number(item.amount);
    }, 0);
  };

  const cancel = () => {
    history.push(routes.Proformas);
  };

  const validatesMeasurements = (detail) => {
    let box, long, width;
    box = detail.box;
    if (arrayOfValidations.find(e => e.summary === detail.name) === undefined) {
      long = Number(calculateSheetLong(detail));
      width = Number(calculateSheetWidth(detail));
      if (box.name === SHEET || box.name === 'CST') {
        if (long < 60 || width < 40) {
          arrayOfValidations.push({ severity: 'warn', summary: detail.name, detail: t('smallerSize'), sticky: true });
        } else if (width > 130 || long > 275) {
          arrayOfValidations.push({ severity: 'warn', summary: detail.name, detail: t('biggerSize'), sticky: true });
        }
      } else if (box.name === 'RSC' || selectedBox.name === 'HSC') {
        if (long < 60 || width < 30) {
          arrayOfValidations.push({ severity: 'warn', summary: detail.name, detail: t('smallerSize'), sticky: true });
        } else if (width > 130 || long > 275) {
          arrayOfValidations.push({ severity: 'warn', summary: detail.name, detail: t('biggerSize'), sticky: true });
        }
      }
      if (detail.cobbWaterproofing === 1 || detail.cobbWaterproofing === true) {
        arrayOfValidations.push({
          severity: 'warn',
          summary: detail.name,
          detail: t('productHasCobbWaterproofing'),
          sticky: true
        });
      }
      if ((detail.isDieCut === true || detail.isDieCut === 1)
        && (isNil(detail.product) || detail.product.isFirstOrder === 1)) {
        if (detail.amountDieCut === 0) {
          arrayOfValidations.push({ severity: 'warn', summary: detail.name, detail: t('noChargeOfDieCut'), sticky: true });
        } else if (detail.amountDieCut < constants.maxDieCut) {
          arrayOfValidations.push({
            severity: 'warn',
            summary: detail.name,
            detail: t('reducedAmountOfDieCut'),
            sticky: true
          });
        }
      }
      if ((detail.isCliche === true || detail.isCliche === 1)
        && (isNil(detail.product) || detail.product.isFirstOrder === 1)) {
        if (proformaDetail.amountCliche === 0) {
          arrayOfValidations.push({ severity: 'warn', summary: detail.name, detail: t('noChargeOfCliche'), sticky: true });
        } else if (proformaDetail.amountCliche < constants.maxCliche) {
          arrayOfValidations.push({
            severity: 'warn',
            summary: detail.name,
            detail: t('reducedAmountOfCliche'),
            sticky: true
          });
        }
      }
      if (!detail.flaute.id) {
        arrayOfValidations.push({ severity: 'error', summary: detail.name, detail: t('noSelectedFlaute'), sticky: true });
      }
      if (!detail.paper.id) {
        arrayOfValidations.push({ severity: 'error', summary: detail.name, detail: t('noSelectedPaper'), sticky: true });
      }
      if (proformaDetail.test.isAuthorized === 0) {
        arrayOfValidations.push({ severity: 'warn', summary: detail.name, detail: t('testIsNotAuthorized'), sticky: true });
      }
      if (arrayOfValidations.length > 0) {
        growl.show(arrayOfValidations);
        return true;
      }
    }
    return false;
  };

  const validatesMeasurementsOnInit = () => {
    setDisplayWarningDialog(false);
    let box, long, width;
    exceptions.forEach(detail => {
      (selectedBox.name === undefined) ? box = detail.box : box = selectedBox;
      if (arrayOfValidations.find(e => e.summary === detail.name) === undefined) {
        long = Number(calculateSheetLong(detail));
        width = Number(calculateSheetWidth(detail));
        if (box.name === SHEET || box.name === 'CST') {
          if (long < 60 || width < 40) {
            arrayOfValidations.push({ severity: 'warn', summary: detail.name, detail: t('smallerSize'), sticky: true });
          } else if (width > 130 || long > 275) {
            arrayOfValidations.push({ severity: 'warn', summary: detail.name, detail: t('biggerSize'), sticky: true });
          }
        } else if (box.name === 'RSC' || selectedBox.name === 'HSC') {
          if (long < 60 || width < 30) {
            arrayOfValidations.push({ severity: 'warn', summary: detail.name, detail: t('smallerSize'), sticky: true });
          } else if (width > 130 || long > 275) {
            arrayOfValidations.push({ severity: 'warn', summary: detail.name, detail: t('biggerSize'), sticky: true });
          }
        }
        if (detail.cobbWaterproofing === 1 || detail.cobbWaterproofing === true) {
          arrayOfValidations.push({
            severity: 'warn',
            summary: detail.name,
            detail: t('productHasCobbWaterproofing'),
            sticky: true
          });
        }
        if ((detail.isDieCut === 1 || detail.isDieCut === true)
          && (isNil(detail.product) || detail.product.isFirstOrder === 1)) {
          if (detail.amountDieCut === 0) {
            arrayOfValidations.push({ severity: 'warn', summary: detail.name, detail: t('noChargeOfDieCut'), sticky: true });
          } else if (detail.amountDieCut < constants.maxDieCut) {
            arrayOfValidations.push({
              severity: 'warn',
              summary: detail.name,
              detail: t('reducedAmountOfDieCut'),
              sticky: true
            });
          }
        }
        if ((detail.isCliche === 1 || detail.isCliche === true)
          && (isNil(detail.product) || detail.product.isFirstOrder === 1)) {
          if (detail.amountCliche === 0) {
            arrayOfValidations.push({ severity: 'warn', summary: detail.name, detail: t('noChargeOfCliche'), sticky: true });
          } else if (detail.amountCliche < constants.maxCliche) {
            arrayOfValidations.push({
              severity: 'warn',
              summary: detail.name,
              detail: t('reducedAmountOfCliche'),
              sticky: true
            });
          }
        }
        if (detail.test.isAuthorized === 0) {
          arrayOfValidations.push({
            severity: 'warn',
            summary: detail.name,
            detail: t('testIsNotAuthorized'),
            sticky: true
          });
        }
        if (arrayOfValidations.length > 0) {
          growl.show(arrayOfValidations);
          return true;
        }
      }
    });
    return false;
  };

  const saveProformaDetail = async (e) => {
    e.preventDefault();
    proformaDetail.clicheTotalCost = calculateClicheCost(proformaDetail);
    proformaDetail.dieCutTotalCost = calculateDieCutCost(proformaDetail);
    proformaDetail.isException = validatesMeasurements(proformaDetail) ? 1 : 0;
    proformaDetail.unitPrice = calculateUnitPrice(proformaDetail);
    proformaDetail.cost = calculateDetailCost(proformaDetail);
    if (((proformaDetail.product?.id && !isNewProduct) || isNewProduct)
      && (proformaDetail.flaute.id && proformaDetail.paper.id)) {
      let details = productDetails;
      let newBoxType = !proformaDetails.find(element => element.box.nameWithType === proformaDetail.box.nameWithType);
      if (newProformaDetail) {
        newDetails.push(proformaDetail);
        proformaDetails.push(proformaDetail);
        setSubtotal(prevSubtotal => [...prevSubtotal, { name: 'proformaDetail', amount: proformaDetail.cost }]);
        if (!isNewProduct) {
          getProductDetailsByProductId(proformaDetail.product.id).then(result => {
            let newDetails = (getUserSession().user.codeRole !== SALES_MANAGER_CODE)
              ? result.data.filter(element => element.url.split('.')[4] !== 'xlsx')
              : result.data;
            newDetails.forEach(element => details.push(element));
          });
        }
      } else {
        let index = findSelectedProformaDetailIndex() + 1; //since the first position is for transport cost
        setSubtotal(updateSubtotal(subtotal, index, proformaDetail.cost));
        updatedDetails.push(proformaDetail);
        const oldProformaDetail = proformaDetails[findSelectedProformaDetailIndex()];
        if (newBoxType) {
          const genericImages = await getProductDetailByBoxNameWithType(oldProformaDetail.box.nameWithType);
          genericImages.data.forEach(file => {
            details = details.filter(element => element.name !== file.name);
          });
        }
        if (!isNewProduct && oldProformaDetail.product.id !== proformaDetail.product.id) {
          getProductDetailsByProductId(proformaDetail.product.id).then(result => {
            let newDetails = (getUserSession().user.codeRole !== SALES_MANAGER_CODE)
              ? result.data.filter(element => element.url.split('.')[4] !== 'xlsx')
              : result.data;
            newDetails.forEach(element => details.push(element));
          });
        }
        proformaDetails[findSelectedProformaDetailIndex()] = proformaDetail;
      }
      if (newBoxType) {
        const genericImages = await getProductDetailByBoxNameWithType(proformaDetail.box.nameWithType);
        genericImages.data.forEach(element => details.push(element));
      }
      setProductDetails(details);
      setSelectedProfromaDetail(DETAIL_RESET_VALUES);
      setSelectedFlaute({});
      setSelectedProduct({});
      setSelectedBox({});
      setSelectedTest({});
      setProformaDetail(DETAIL_RESET_VALUES);
      setDisplayDialog(false);
    } else {
      messageError(t('missingFields'));
    }
  };

  const findSelectedProformaDetailIndex = () => {
    return proformaDetails.indexOf(selectedProformaDetail);
  };

  const callGetFlautesByWall = (test) => {
    getFlautesByWall(test.wall).then(result => {
      setFlautes(result.data);
    }).catch(error => {
      if (!error.response) {
        messageError(t('withoutConnection'));
      }
    });
    getPapersByTest(test.id).then(result => {
      result.data.findIndex((e) => {
        if (e.name === kraftOneFace) {
          e.price = constants.kraftOneFace;
        }
        if (e.name === whiteOneFace) {
          e.price = constants.whiteOneFace;
        }
        if (e.name === whiteTwoFaces) {
          e.price = constants.whiteTwoFaces;
        }
      });
      setPapers(result.data);
    }).catch(error => {
      if (!error.response) {
        messageError(t('withoutConnection'));
      }
    });
  };

  const onProformaDetailSelect = (e) => {
    setNewProformaDetail(false);
    (!e.data.product || e.data.product.id === undefined) ? setIsNewProduct(true) : setIsNewProduct(false);
    setDisplayDialog(true);
    setSelectedProduct(e.data.product);
    setSelectedBox(e.data.box);
    if (e.data.test) {
      const testFilter = testsAll.filter(({ id, active }) => active === 1 || id === e.data.test.id);
      setTests(testFilter);
      setSelectedTest(e.data.test);
      getFlautesByWall(e.data.test.wall).then(result => {
        setFlautes(result.data);
      }).catch(error => {
        if (!error.response) {
          messageError(t('withoutConnection'));
        }
      });
      getPapersByTest(e.data.test.id).then(result => {
        result.data.map(e => delete e.idPaperByTest);
        setPapers(result.data);
      }).catch(error => {
        if (!error.response) {
          messageError(t('withoutConnection'));
        }
      });
    }
    setSelectedFlaute(e.data.flaute);
    setProformaDetail(e.data);
    getMeasurementsByFlauteIdAndBoxId(e.data.flaute.id, e.data.box.id).then(result => {
      setLongTolerance(result.data.longLossByIncrease);
      setWidthTolerance(result.data.widthLossByIncrease);
    }).catch(error => {
      if (!error.response) {
        messageError(t('withoutConnection'));
      }
    });
  };

  const addNew = (e) => {
    e.preventDefault();
    if (selectedClient) {
      setArrayOfValidations([]);
      setNewProformaDetail(true);
      setSelectedFlaute({});
      setSelectedProduct('');
      setSelectedBox({});
      setSelectedTest({});
      setProformaDetail(DETAIL_RESET_VALUES);
      setDisplayIsNewProductDialog(true);
    } else {
      messageError(t('missingClient'));
    }
  };

  const deleteProformaDetail = async (e) => {
    e.preventDefault();
    let index = findSelectedProformaDetailIndex();
    let updatedProformaDetails = proformaDetails.filter((val, i) => i !== index);
    let details = productDetails;
    if (proformaDetail.product?.id) {
      details = details.filter(detail => {
        return (detail.idProduct !== proformaDetail.product.id);
      });
    }
    if (!updatedProformaDetails.find(element => element.box.nameWithType === proformaDetail.box.nameWithType)) {
      const genericImages = await getProductDetailByBoxNameWithType(proformaDetail.box.nameWithType);
      genericImages.data.forEach(file => {
        details = details.filter(element => element.name !== file.name);
      });
    }
    setProductDetails(details);
    deletedDetails.push(proformaDetail);
    setSubtotal(subtotal.filter((val, i) => i !== index + 1));
    setProformaDetails(proformaDetails.filter((val, i) => i !== index));
    setProformaDetail(DETAIL_RESET_VALUES);
    setSelectedFlaute({});
    setSelectedBox({});
    setSelectedProduct(DETAIL_RESET_VALUES);
    setSelectedTest({});
    setDisplayDialog(false);
  };

  const handleProductOnChange = (e) => {
    getProductsByKeyAndClientId(e.query, selectedClient.value).then(result => {
      setProducts(result.data);
    });
  };

  const sendProformaToClient = (e) => {
    setIsRendered(false);
    e.preventDefault();
    sendProformaToClientById(proforma.id, email, proformaAttachements).then(() => {
      messageSuccess(t('messagesSuccessProformaUpdated'));
      history.push(routes.Proformas);
    }).catch(error => {
      (error.response) ? messageError(error.response.data.message) : messageError(t('withoutConnection'));
    });
  };

  const displaySelectAttachements = (e) => {
    e.preventDefault();
    setIsBeingSendedToClient(true);
    setDisplayAttachementsDialog(true);
    dialogAttachements();
  };

  const setChecked = (isDieCut) => {
    if (isDieCut.data) {
      (isDieCut.data[0] === 1)
        ? setProformaDetail({ ...proformaDetail, isDieCut: true })
        : setProformaDetail({ ...proformaDetail, isDieCut: false });
    }
  };

  const applyCurrencyFormatter = (value) => {
    return (value) ? currencyFormatter.format(value) : '-';
  };

  const showCheck = (value) => {
    if (value) {
      return <i className='pi pi-check'></i>;
    } return '-';
  };

  const calculateDetailCost = (proformaDet) => {
    const individualPrice = (proformaDet.product && proformaDet.product.id)
      ? proformaDet.product.unitPrice
      : calculateUnitPrice(proformaDet);
    if (individualPrice > 0) {
      const totalLet = individualPrice * Number(proformaDet.quantity);
      return Number(totalLet) + Number(calculateClicheCost(proformaDet)) + Number(calculateDieCutCost(proformaDet));
    }
    return 0;
  };

  const getLong = (rowData) => {
    if (rowData.box && rowData.box.name === SHEET) {
      return rowData.sheetLong * 10;
    } return rowData.internalLong;
  };

  const getWidth = (rowData) => {
    if (rowData.box && rowData.box.name === SHEET) {
      return rowData.sheetWidth * 10;
    } return rowData.internalWidth;
  };

  const getHeight = (rowData) => {
    if (rowData.box && rowData.box.name === SHEET) {
      return 0;
    } return rowData.internalHeight;
  };

  const setProformaDetailsValues = (e) => {
    e.value.quantity = 0;
    e.value.product = e.value;
    if (e.value.isDieCut.data) {
      (e.value.isDieCut.data[0] === 1) ? e.value.isDieCut = true : e.value.isDieCut = false;
    }
    if (e.value.isCliche.data) {
      (e.value.isCliche.data[0] === 1) ? e.value.isCliche = true : e.value.isCliche = false;
    }
    if (!e.value.test) {
      e.value.test = {};
    }
    setSelectedProduct(e.value);
    setProformaDetail(e.value);
    if (!e.value.idClient) {
      setChecked(e.value.isDieCut);
      if (e.value.test) {
        const testFilter = testsAll.filter(({ id, active }) => active === 1 || id === e.data.test.id);
        setTests(testFilter);
        setSelectedTest(e.value.test);
        callGetFlautesByWall(e.value.test);
      }
      setSelectedFlaute(e.value.flaute);
      setSelectedBox(e.value.box);
      getMeasurementsByFlauteIdAndBoxId(e.value.flaute.id, e.value.box.id).then(result => {
        setLongTolerance(result.data.longLossByIncrease);
        setWidthTolerance(result.data.widthLossByIncrease);
      }).catch(error => {
        if (!error.response) {
          messageError(t('withoutConnection'));
        }
      });
    }
  };

  const calculateArea = (detail) => {
    return Number(Number(calculateSheetLong(detail)) * Number(calculateSheetWidth(detail)) / 10000).toFixed(4);
  };

  const calculateSheetArea = (detail) => {
    if (detail.sheetWidth && detail.sheetLong) {
      return Number(Number(detail.sheetLong) * Number(detail.sheetWidth) / 10000).toFixed(4);
    } return 0;
  };

  const calculateLongInInches = (detail) => {
    if (!detail.box) {
      detail.box = selectedBox;
    }
    if (detail.box.name !== SHEET) {
      return Number(Number(calculateSheetLong(detail)) / 2.54).toFixed(4) + '"';
    } return Number(Number(detail.sheetLong) / 2.54).toFixed(4) + '"';
  };

  const calculateWidthInInches = (detail) => {
    if (!detail.box) {
      detail.box = selectedBox;
    }
    if (detail.box.name !== SHEET) {
      return Number(Number(calculateSheetWidth(detail)) / 2.54).toFixed(4) + '"';
    } return Number(Number(detail.sheetWidth) / 2.54).toFixed(4) + '"';
  };

  const calculateWeight = (detail) => {
    let area;
    if (!detail.box) {
      detail.box = selectedBox;
    }
    (detail.box.name === SHEET) ? area = calculateSheetArea(detail) : area = calculateArea(detail);
    if (area > 0 && detail.test) {
      return Number(Number(detail.test.weight) * area).toFixed(4);
    } return 0;
  };

  const calculateSheetLong = (detail) => {
    if (longTolerance !== 0) {
      if (!detail.box) {
        detail.box = selectedBox;
      }
      switch (detail.box.nameWithType) {
      case 'RSC ':
        return (((((Number(detail.internalLong) + Number(detail.internalWidth)) * 2)
            + Number(longTolerance)) / 10).toFixed(4));
      case 'HSC Fondo':
        return (((((Number(detail.internalLong) + Number(detail.internalWidth)) * 2)
            + Number(longTolerance)) / 10).toFixed(4));
      case 'HSC Tapa':
        return (((((Number(detail.internalLong) + Number(detail.internalWidth)) * 2)
            + Number(longTolerance)) / 10).toFixed(4));
      case 'CST Fondo':
        return (Number((Number(detail.internalLong) + (Number(detail.internalHeight) * 2)
            + Number(longTolerance)) / 10).toFixed(4));
      case 'CST Tapa':
        return (Number((Number(detail.internalLong) + (Number(detail.internalHeight) * 2)
            + Number(longTolerance)) / 10).toFixed(4));
      default:
        return detail.sheetLong;
      }
    }
  };

  const calculateSheetWidth = (detail) => {
    if (widthTolerance !== 0) {
      if (!detail.box) {
        detail.box = selectedBox;
      }
      switch (detail.box.nameWithType) {
      case 'RSC ':
        return (((Number(detail.internalWidth) + Number(detail.internalHeight)
            + Number(widthTolerance)) / 10).toFixed(4));
      case 'HSC Fondo':
        return (Number((Number(detail.internalHeight) + (Number(detail.internalWidth) / 2)
            + Number(widthTolerance)) / 10).toFixed(4));
      case 'HSC Tapa':
        return (Number((Number(detail.internalHeight) + (Number(detail.internalWidth) / 2)
            + Number(widthTolerance)) / 10).toFixed(4));
      case 'CST Fondo':
        return (Number((Number(detail.internalWidth) + (Number(detail.internalHeight) * 2)
            + Number(widthTolerance)) / 10).toFixed(4));
      case 'CST Tapa':
        return (Number((Number(detail.internalWidth) + (Number(detail.internalHeight) * 2)
            + Number(widthTolerance)) / 10).toFixed(4));
      default:
        return detail.sheetWidth;
      }
    }
  };

  const getTolerance = (e) => {
    if (e.wall && selectedBox.id) {
      getMeasurementsByFlauteIdAndBoxId(e.id, selectedBox.id).then(result => {
        setLongTolerance(result.data.longLossByIncrease);
        setWidthTolerance(result.data.widthLossByIncrease);
      }).catch(error => {
        if (!error.response) {
          messageError(t('withoutConnection'));
        }
      });
    } else if (selectedFlaute.id) {
      getMeasurementsByFlauteIdAndBoxId(selectedFlaute.id, e.id).then(result => {
        setLongTolerance(result.data.longLossByIncrease);
        setWidthTolerance(result.data.widthLossByIncrease);
      }).catch(error => {
        if (!error.response) {
          messageError(t('withoutConnection'));
        }
      });
    }
  };

  const getCountrySubdivisions2 = (id) => {
    getCountrySubdivisions2ByCountrySubdivisions1Id(id).then(result => {
      setCountrySubdivisions2(result.data);
    }).catch(error => {
      if (!error.response) {
        messageError(t('withoutConnection'));
      }
    });
  };

  const getCountrySubdivisions3 = (id) => {
    getCountrySubdivisions3ByCountrySubdivisions2Id(id).then(result => {
      setCountrySubdivisions3(result.data);
    }).catch(error => {
      if (!error.response) {
        messageError(t('withoutConnection'));
      }
    });
  };

  const filterCountrySubdivisions1 = (event) => {
    setTimeout(() => {
      let results = countrySubdivisions1.filter((subdivision) => {
        return subdivision.name.toLowerCase().startsWith(event.query.toLowerCase());
      });
      setFilteredCountrySubdivisions1(results);
    }, 250);
  };

  const filterCountrySubdivisions2 = (event) => {
    setTimeout(() => {
      let results = countrySubdivisions2.filter((subdivision) => {
        return subdivision.name.toLowerCase().startsWith(event.query.toLowerCase());
      });
      setFilteredCountrySubdivisions2(results);
    }, 250);
  };

  const filterCountrySubdivisions3 = (event) => {
    setTimeout(() => {
      let results = countrySubdivisions3.filter((subdivision) => {
        return subdivision.name.toLowerCase().startsWith(event.query.toLowerCase());
      });
      setFilteredCountrySubdivisions3(results);
    }, 250);
  };

  const transportInfo = () => {
    return (
      <>
        <div className="p-col-12 p-md-3">
          <h5 className="label-gray">{t('countrySubdivision1')}</h5>
          <AutoComplete
            disabled={isApproved}
            style={{ width: '100%' }}
            id='countrySubdivision1'
            className="input-client"
            field="name"
            value={selectedCountrySubdivision1}
            onChange={(e) => setSelectedCountrySubdivision1(e.value)}
            onSelect={(e) => {
              setSelectedCountrySubdivision1(e.value);
              getCountrySubdivisions2(e.value.id);
            }}
            placeholder={t('countrySubdivision1')}
            suggestions={filteredCountrySubdivisions1}
            completeMethod={filterCountrySubdivisions1}
          />
        </div>
        <div className="p-col-12 p-md-3">
          <h5 className="label-gray">{t('countrySubdivision2')}</h5>
          <AutoComplete
            disabled={isApproved}
            style={{ width: '100%' }}
            id='countrySubdivision2'
            className="input-client"
            field="name"
            value={selectedCountrySubdivision2}
            onChange={(e) => setSelectedCountrySubdivision2(e.value)}
            onSelect={(e) => {
              setSelectedCountrySubdivision2(e.value);
              getCountrySubdivisions3(e.value.id);
            }}
            placeholder={t('countrySubdivision2')}
            suggestions={filteredCountrySubdivisions2}
            completeMethod={filterCountrySubdivisions2}
          />
        </div>
        <div className="p-col-12 p-md-3">
          <h5 className="label-gray">{t('countrySubdivision3')}</h5>
          <AutoComplete
            disabled={isApproved}
            style={{ width: '100%' }}
            id='countrySubdivision3'
            className="input-client"
            field="name"
            value={selectedCountrySubdivision3}
            onChange={(e) => setSelectedCountrySubdivision3(e.value)}
            onSelect={(e) => setSelectedCountrySubdivision3(e.value)}
            placeholder={t('countrySubdivision3')}
            suggestions={filteredCountrySubdivisions3}
            completeMethod={filterCountrySubdivisions3}
          />
        </div>
        {transportType === 3 &&
          <div className="p-col-12 p-md-3">
            <h5 className="label-gray">{t('transportCost')}</h5>
            <InputText
              readOnly={isApproved}
              style={{ width: '100%' }}
              keyfilter="pnum"
              placeholder={t('transportCost')}
              id='transportCost'
              className="input-client"
              value={subtotal[0].amount}
              name="amount"
              data-id={0}
              onChange={(e) => handleTransportCostChange(e.target.value, transportSize.value)}
            />
          </div>
        }
        {transportType === 4 &&
          <div className="p-col-12 p-md-3">
            <h5 className="label-gray">{t('transportSize')}</h5>
            <Dropdown
              disabled={isApproved}
              placeholder={t('transportSize')}
              id='transportSize'
              value={transportSize}
              options={filters}
              optionLabel="label"
              optionValue="value"
              className="input-client"
              onChange={(event) => {
                setTransportSize(event.target.value);
                handleTransportCostChange(event.target.value.value, transportSize.value);
              }}
            />
          </div>
        }
        <div className="p-col-12">
          <h5 className="label-gray">{t('exactDirection')}</h5>
          <InputTextarea
            readOnly={isApproved}
            placeholder={t('exactDirection')}
            style={{ width: '100%' }}
            id='exactDirection'
            className="input-client"
            value={exactDirection}
            onChange={(e) => setExactDirection(e.target.value)}
          />
        </div>
        {transportType === 4 && getUserSession().user.codeRole !== 'CL' && <>
          <div className="p-col-4 p-md-4 p-lg-2"><h5>{t('freeTransport')}</h5></div>
          <div className="p-col-8 p-md-8 p-lg-10">
            <Checkbox
              id="freeTransport"
              style={{ marginTop: '15px' }}
              onChange={(e) => {
                setFreeTransport(e.checked);
                handleTransportCostChange(transportSize.value, e.checked);
              }}
              checked={Boolean(freeTransport)}
            />
          </div></>
        }
      </>
    );
  };

  const dialogFooter =
    <div className="ui-dialog-buttonpane p-clearfix">
      {!newProformaDetail &&
        <Button id='deleteDetail' label={t('delete')} icon="pi pi-times" onClick={deleteProformaDetail} />
      }
      <Button id='saveDetail' label={t('save')} icon="pi pi-check" onClick={saveProformaDetail} />
    </div>;

  const AttachmentsdialogFooter = () =>
    <div className="ui-dialog-buttonpane p-clearfix wrap-btn-sent">
      <Button id='saveAttachements' label={t('sendToClient')} icon="pi pi-check" onClick={sendProformaToClient} />
    </div>;

  const footer =
    <div className="p-clearfix" style={{ width: '100%' }}>
      <Button
        id='showAttachedReferences'
        style={{ float: 'left' }}
        label={t('showAttachedReferences')}
        icon="pi pi-file"
        onClick={(e) => { e.preventDefault(); setDisplayAttachementsDialog(true); }}
      />
      <Button
        disabled={isApproved}
        id='addProformaDetailButton'
        style={{ float: 'right' }}
        label={t('add')}
        icon="pi pi-plus"
        onClick={addNew}
      />
    </div>;

  const dialogIsNewProductFooter =
    <div className="ui-dialog-buttonpane p-clearfix">
      <Button
        id='continue'
        label={t('continue')}
        icon="pi pi-arrow-right"
        onClick={() => { setDisplayIsNewProductDialog(false); setDisplayDialog(true); }}
      />
    </div>;

  const warningsDialogFooter =
    <div className="ui-dialog-buttonpane p-clearfix">
      <Button
        id='showToasts'
        label={t('showWarnings')}
        onClick={e => { e.preventDefault(); validatesMeasurementsOnInit(); }}
      />
    </div>;

  subtotalAmount = (Math.round((getTotalCosts() - proforma.discountMount) * 100) / 100).toFixed(2);
  iva = (Math.round((getTotalCosts() * ivaPercentage) * 100) / 100).toFixed(2);
  total = Number((getTotalCosts() - proforma.discountMount) * ivaPercentage
    + (getTotalCosts() - proforma.discountMount)).toFixed(2);

  const dialogAttachements = () => {
    return (
      <>
        {isBeingSendedToClient &&
          <Dialog
            id='attachementsDialog'
            blockScroll
            visible={displayAttachementsDialog}
            header={t('selectReferences')}
            onHide={() => setDisplayAttachementsDialog(false)}
          >
            <div>
              <AttachmentsdialogFooter />
              <DataTable
                emptyMessage={t('emptyList')}
                value={productDetails.filter(element => element.url.split('.')[4] !== 'xlsx')}
              >
                <Column field="name" header={t('name')} />
                <Column field="description" header={t('description')} />
                <Column style={{ textAlign: 'center', width: '100px' }} body={download} header={t('download')} />
                <Column style={{ textAlign: 'center', width: '100px' }} body={attache} header={t('attache')} />
              </DataTable>
            </div>
          </Dialog>
        }
        {!isBeingSendedToClient &&
          <Dialog
            id='attachementsDialog'
            blockScroll
            visible={displayAttachementsDialog}
            header={t('attachedReferences')}
            onHide={() => setDisplayAttachementsDialog(false)}
          >
            <div>
              <DataTable emptyMessage={t('emptyList')} value={productDetails}>
                <Column field="name" header={t('name')} />
                <Column field="description" header={t('description')} />
                <Column style={{ textAlign: 'center', width: '100px' }} body={download} header={t('download')} />
              </DataTable>
            </div>
          </Dialog>
        }
      </>
    );
  };

  const dialogWarnings = () => {
    return (
      <Dialog
        id='warningsDialog'
        footer={warningsDialogFooter}
        blockScroll
        visible={displayWarningDialog}
        header={t('requiredAuthorization')}
        modal={true}
        onHide={() => validatesMeasurementsOnInit()}
      >
        <div>{t('someDetailsRequireAuthorization')}</div>
      </Dialog>
    );
  };

  const setValuesOnSelectedBox = (value) => {
    setSelectedBox(value);
    getTolerance(value);
    (value.type === DIE_CUT)
      ? setProformaDetail({ ...proformaDetail, isDieCut: true, amountDieCut: constants.maxDieCut, box: value })
      : setProformaDetail({ ...proformaDetail, box: value });
  };

  const download = (row) => {
    return (
      <a download={row.name} href={row.url} target="_blank" rel="noopener noreferrer">
        <i style={{ marginRight: '3px', marginTop: '3px' }} className="pi pi-download" />
      </a>
    );
  };

  const addAttachement = (e, row) => {
    if (e.checked) {
      setProformaAttachements(prevAttachements => [...prevAttachements, row]);
    } else {
      setProformaAttachements(proformaAttachements.filter(detail => {
        return (detail.id !== row.id);
      }));
    }
  };

  const attache = (row) => {
    return (
      <Checkbox onChange={(e) => addAttachement(e, row)} checked={Boolean(proformaAttachements.includes(row))}></Checkbox>
    );
  };

  const dialogNewDetail = () => {
    return (
      <>
        {displayIsNewProductDialog &&
          <Dialog
            id='dialog'
            style={{ width: '660px' }}
            blockScroll
            visible={displayIsNewProductDialog}
            header={t('proformaDetails')}
            modal={true}
            footer={dialogIsNewProductFooter}
            onHide={() => { setDisplayIsNewProductDialog(false); setSelectedProfromaDetail({}); }}
          >
            <div className="p-grid p-fluid">
              <div className="p-col-12">
                <RadioButton
                  inputId="newProduct"
                  id="newProduct"
                  style={{ marginLeft: '40px' }}
                  name="newProduct"
                  onChange={(e) => setIsNewProduct(e.checked)}
                  checked={Boolean(isNewProduct)}
                />
                <label htmlFor="newProduct" className="p-radiobutton-label">{t('newProductLabel')}</label>
              </div>
              <div className="p-col-12">
                <RadioButton
                  inputId="oldProduct"
                  id="oldProduct"
                  style={{ marginLeft: '40px' }}
                  name="newProduct"
                  onChange={(e) => setIsNewProduct(!e.checked)}
                  checked={!isNewProduct}
                />
                <label htmlFor="oldProduct" className="p-radiobutton-label">{t('oldProduct')}</label>
              </div>
            </div>
          </Dialog>
        }
      </>
    );
  };

  const newDetailWithNewProductDialog = () => {
    return (
      <ScrollPanel className="custom">
        <Dialog
          className="layout-main"
          id='dialog'
          maximizable
          style={{ marginTop: 'auto', width: '870px', height: '85%', overflow: 'scroll' }}
          blockScroll
          visible={displayDialog && isNewProduct}
          header={isApproved ? t('proformaDetailsLine') : t('proformaDetails')}
          modal={true}
          footer={!isApproved ? dialogFooter : ''}
          onHide={() => { setDisplayDialog(false); setSelectedProfromaDetail({}); }}
        >
          <div className="p-grid p-fluid">
            <div className="p-col-2"><h5>{t('name')}</h5></div>
            <div className="p-col-10">
              <InputText
                readOnly={isApproved}
                id='productName'
                value={proformaDetail.name}
                style={{ width: '100%' }}
                className="input-full-row"
                onChange={(event) => setProformaDetail({ ...proformaDetail, name: event.target.value })}
              />
            </div>
            <div className="p-col-2" style={{ padding: '.75em' }}><h5><label htmlFor="test">{t('test')}</label></h5></div>
            <div className="p-col-4" style={{ marginTop: '1.4em', padding: '.5em' }}>
              <Dropdown id='test' optionLabel="nameWithCombination" value={selectedTest} onChange={(e) => {
                setProformaDetail({ ...proformaDetail, test: e.target.value });
                setSelectedTest(e.target.value); callGetFlautesByWall(e.target.value); getTolerance(e.target.value);
              }} options={tests} disabled={isApproved} />
            </div>
            <div className="p-col-2" style={{ padding: '.75em' }}><h5>{t('testWeight')}</h5></div>
            <div className="p-col-4" style={{ marginTop: '1.4em', padding: '.5em' }}>{selectedTest.weight}</div>
            <div className="p-col-2" style={{ padding: '.75em' }}>
              <h5>
                <label htmlFor="flaute">{t('flaute')}</label>
              </h5>
            </div>
            <div className="p-col-4" style={{ marginTop: '1.4em', padding: '.5em' }}>
              <Dropdown
                disabled={isApproved}
                id='flaute'
                optionLabel="name"
                value={proformaDetail.flaute}
                onChange={(e) => {
                  setProformaDetail({ ...proformaDetail, flaute: e.target.value });
                  setSelectedFlaute(e.target.value); getTolerance(e.target.value);
                }}
                options={flautes}
              />
            </div>
            <div className="p-col-2" style={{ padding: '.75em' }}><h5><label htmlFor="paper">{t('paper')}</label></h5></div>
            <div className="p-col-4" style={{ marginTop: '1.4em', padding: '.5em' }}>
              <Dropdown
                disabled={isApproved}
                id='paper'
                optionLabel="name"
                value={proformaDetail.paper}
                onChange={(e) =>
                  setProformaDetail({ ...proformaDetail, paper: e.target.value, priceByTc: e.target.value.price })}
                options={papers}
              />
            </div>
            <div className="p-col-2" style={{ padding: '.75em' }}><h5><label htmlFor="box">{t('boxType')}</label></h5></div>
            <div className="p-col-10" style={{ marginTop: '1.4em', padding: '.5em' }}>
              <Dropdown disabled={isApproved} id='box' optionLabel="nameWithType" value={selectedBox}
                onChange={(e) => setValuesOnSelectedBox(e.target.value)} options={boxes} />
            </div>
            <div className="p-col-2" style={{ padding: '.75em' }}>
              <h5>
                <label htmlFor="isDieCut">{t('isDieCut')}</label>
              </h5>
            </div>
            {!proformaDetail.isDieCut && <>
              <div className="p-col-10" style={{ marginTop: '1.4em', padding: '.5em' }}>
                <Checkbox
                  disabled={isApproved}
                  id="isDieCut"
                  onChange={(e) =>
                    setProformaDetail({ ...proformaDetail, isDieCut: e.checked, amountDieCut: constants.maxDieCut })}
                  checked={Boolean(proformaDetail.isDieCut)}
                />
              </div>
            </>}
            {Boolean(proformaDetail.isDieCut) && <>
              <div className="p-col-1" style={{ marginTop: '1.4em', padding: '.5em' }}>
                <Checkbox
                  disabled={isApproved}
                  id="isDieCut"
                  onChange={(e) => setProformaDetail({ ...proformaDetail, isDieCut: e.checked, amountDieCut: 0 })}
                  checked={Boolean(proformaDetail.isDieCut)}
                />
              </div>
              <div id='chargeRadioButton' style={{ marginTop: '1.4em', padding: '.5em' }} className="p-col-4 p-md-4 p-lg-2">
                <label htmlFor="dieCutCharge" className="p-radiobutton-label">{t('noCharge')}</label>
                <Checkbox
                  disabled={isApproved}
                  inputId="dieCutCharge"
                  onChange={() => (proformaDetail.amountDieCut === 0)
                    ? setProformaDetail({ ...proformaDetail, amountDieCut: constants.maxDieCut })
                    : setProformaDetail({ ...proformaDetail, amountDieCut: 0 })
                  }
                  checked={proformaDetail.amountDieCut === 0}
                />
              </div>
              <div className="p-col-2 p-md-2 p-lg-1"><h5>{t('amountDieCut')}</h5></div>
              <div className="p-col-8 p-md-8 p-lg-6">
                <InputText
                  readOnly={isApproved}
                  id='amountDieCut'
                  keyfilter="pnum"
                  value={proformaDetail.amountDieCut}
                  className="input-diecut-row"
                  onChange={(event) => setProformaDetail({ ...proformaDetail, amountDieCut: event.target.value })}
                />
              </div></>}
            <div className="p-col-4 p-md-4 p-lg-2"><h5>{t('cliche')}</h5></div>
            {!proformaDetail.isCliche &&
              <div className="p-col-8 p-md-8 p-lg-10">
                <Checkbox
                  disabled={isApproved}
                  id="cliche"
                  className="input"
                  onChange={(event) =>
                    setProformaDetail({ ...proformaDetail, isCliche: event.checked, amountCliche: constants.maxCliche })}
                  checked={Boolean(proformaDetail.isCliche)}
                />
              </div>
            }
            {proformaDetail.isCliche && <>
              <div className="p-col-8 p-md-8 p-lg-1">
                <Checkbox
                  disabled={isApproved}
                  id="cliche"
                  className="input"
                  onChange={(event) => setProformaDetail({ ...proformaDetail, isCliche: event.checked, amountCliche: 0 })}
                  checked={Boolean(proformaDetail.isCliche)}
                />
              </div>
              <div id='chargeRadioButton' style={{ marginTop: '1.4em', padding: '.5em' }} className="p-col-4 p-md-4 p-lg-2">
                <label htmlFor="clicheCharge" className="p-radiobutton-label">{t('noCharge')}</label>
                <Checkbox
                  disabled={isApproved}
                  inputId="clicheCharge"
                  onChange={() => (proformaDetail.amountCliche === 0)
                    ? setProformaDetail({ ...proformaDetail, amountCliche: constants.maxCliche })
                    : setProformaDetail({ ...proformaDetail, amountCliche: 0 })}
                  checked={proformaDetail.amountCliche === 0}
                />
              </div>
              <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('amountCliche')}</h5></div>
              <div className="p-col-8 p-md-8 p-lg-2">
                <InputText
                  readOnly={isApproved}
                  id='amountCliche'
                  keyfilter="pnum"
                  value={proformaDetail.amountCliche}
                  className="input-full-row"
                  onChange={(event) => setProformaDetail({ ...proformaDetail, amountCliche: event.target.value })}
                />
              </div>
              <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('colorQuantity')}</h5></div>
              <div className="p-col-8 p-md-8 p-lg-2">
                <InputText
                  readOnly={isApproved}
                  id='colorQuantity'
                  keyfilter="pnum"
                  value={proformaDetail.colorQuantity}
                  className="input-full-row"
                  onChange={(event) => setProformaDetail({ ...proformaDetail, colorQuantity: event.target.value })}
                />
              </div></>
            }
            <div className="p-col-4 p-md-4 p-lg-2" style={{ padding: '.75em' }}><h5>{t('isPaletized')}</h5></div>
            <div className="p-col-8 p-md-8 p-lg-10" style={{ marginTop: '1.0em', padding: '.5em' }}>
              <Checkbox
                disabled={isApproved}
                id="isPaletized"
                className="input"
                onChange={(event) => (event.checked)
                  ? setProformaDetail({ ...proformaDetail, isPaletized: event.checked })
                  : setProformaDetail({ ...proformaDetail, isPaletized: event.checked })}
                checked={Boolean(proformaDetail.isPaletized)}
              />
            </div>
            <div className="p-col-12 p-md-12 p-lg-12">
              <h4>{t('waterproofing')}</h4>
            </div>
            <div className="p-col-4 p-md-4 p-lg-2"><h5>{t('regular')}</h5></div>
            <div className="p-col-8 p-md-8 p-lg-10">
              <Checkbox
                disabled={isApproved}
                name='waterproofing'
                id="regularWaterproofing"
                style={{ marginTop: '15px' }}
                className="input"
                onChange={(e) => setProformaDetail({ ...proformaDetail, regularWaterproofing: e.checked })}
                checked={Boolean(proformaDetail.regularWaterproofing)}
              />
            </div>
            {Boolean(proformaDetail.regularWaterproofing) && <>
              <div className="p-col-3">
                <div className="p-col-12">
                  <RadioButton
                    disabled={isApproved}
                    inputId="rb1"
                    style={{ marginLeft: '40px' }}
                    name="city"
                    value="New York"
                    onChange={() => setProformaDetail({ ...proformaDetail, waterproofedFaces: 1 })}
                    checked={proformaDetail.waterproofedFaces === 1}
                  />
                  <label htmlFor="rb1" className="p-radiobutton-label">{t('oneFace')}</label>
                </div>
                <div className="p-col-12">
                  <RadioButton
                    disabled={isApproved}
                    inputId="rb2"
                    style={{ marginLeft: '40px' }}
                    name="city"
                    value="San Francisco"
                    onChange={() => setProformaDetail({ ...proformaDetail, waterproofedFaces: 2 })}
                    checked={proformaDetail.waterproofedFaces === 2}
                  />
                  <label htmlFor="rb2" className="p-radiobutton-label">{t('twoFaces')}</label>
                </div>
              </div>
              {proformaDetail.waterproofedFaces === 1 && (codeRole === 'GV' || codeRole === 'EV') && <>
                {codeRole === 'GV' && <>
                  <div className="p-col-4 p-md-4 p-lg-2" style={{ marginTop: '0.8em' }}>
                    <label htmlFor="oneWaterCutCharge" className="p-radiobutton-label">{t('noCharge')}</label>
                    <Checkbox
                      disabled={isApproved}
                      inputId="oneWaterCutCharge"
                      onChange={() => (detailAmountOneRegularFaceWaterproofing === 0)
                        ? setDetailAmountOneRegularFaceWaterproofing(constants.oneFaceRegularWaterproofing)
                        : setDetailAmountOneRegularFaceWaterproofing(0)}
                      checked={detailAmountOneRegularFaceWaterproofing === 0}
                    />
                  </div>
                </>}
                <div className="p-col-4 p-md-4 p-lg-2"><h5>{t('amountOneFaceWaterproofing')}</h5></div>
                <div className="p-col-8 p-md-8 p-lg-5">
                  <InputText
                    readOnly={isApproved}
                    id='amountOneRegularFaceWaterproofing'
                    keyfilter="pnum"
                    value={detailAmountOneRegularFaceWaterproofing}
                    disabled={codeRole !== 'GV'}
                    required
                    className="input-small-row"
                    onChange={(event) => setDetailAmountOneRegularFaceWaterproofing(event.target.value)}
                  />
                </div>
              </>}
              {proformaDetail.waterproofedFaces === 2 && (codeRole === 'GV' || codeRole === 'EV') && <>
                {codeRole === 'GV' && <>
                  <div className="p-col-4 p-md-4 p-lg-2" style={{ marginTop: '3.4em' }}>
                    <label htmlFor="twoWaterCutCharge" className="p-radiobutton-label">{t('noCharge')}</label>
                    <Checkbox
                      disabled={isApproved}
                      inputId="twoWaterCutCharge"
                      onChange={() => (detailAmountTwoRegularFacesWaterproofing === 0)
                        ? setDetailAmountTwoRegularFacesWaterproofing(constants.twoFacesRegularWaterproofing)
                        : setDetailAmountTwoRegularFacesWaterproofing(0)}
                      checked={detailAmountTwoRegularFacesWaterproofing === 0}
                    />
                  </div>
                </>}
                <div className="p-col-4 p-md-4 p-lg-2" style={{ marginTop: '34px' }}>
                  <h5>{t('amountTwoFacesWaterproofing')}</h5>
                </div>
                <div className="p-col-8 p-md-8 p-lg-5" style={{ marginTop: '38px' }}>
                  <InputText
                    readOnly={isApproved}
                    id='amountTwoRegularFacesWaterproofing'
                    keyfilter="pnum"
                    value={detailAmountTwoRegularFacesWaterproofing}
                    disabled={codeRole !== 'GV'}
                    required
                    className="input-small-row"
                    onChange={(event) => setDetailAmountTwoRegularFacesWaterproofing(event.target.value)}
                  />
                </div>
              </>}
              <div className="p-col-12"></div>
            </>}
            {(selectedBox.name === SHEET || selectedBox.name === 'CST') && <>
              <div className="p-col-4 p-md-4 p-lg-2"><h5>{t('cobb')}</h5></div>
              <div className="p-col-8 p-md-8 p-lg-10">
                <Checkbox
                  disabled={isApproved}
                  name='waterproofing'
                  id="cobbWaterproofing"
                  style={{ marginTop: '15px' }}
                  className="input"
                  onChange={(e) => setProformaDetail({ ...proformaDetail, cobbWaterproofing: e.checked })}
                  checked={Boolean(proformaDetail.cobbWaterproofing)}
                />
              </div>
              {proformaDetail.cobbWaterproofing && <>
                <div className="p-col-3">
                  <div className="p-col-12">
                    <RadioButton
                      disabled={isApproved}
                      style={{ marginLeft: '40px' }}
                      inputId="rb1"
                      name="city"
                      value="New York"
                      onChange={() => setProformaDetail({ ...proformaDetail, waterproofedFaces: 1 })}
                      checked={proformaDetail.waterproofedFaces === 1}
                    />
                    <label htmlFor="rb1" className="p-radiobutton-label">{t('oneFace')}</label>
                  </div>
                  <div className="p-col-12">
                    <RadioButton
                      disabled={isApproved}
                      style={{ marginLeft: '40px' }}
                      inputId="rb2"
                      name="city"
                      value="San Francisco"
                      onChange={() => setProformaDetail({ ...proformaDetail, waterproofedFaces: 2 })}
                      checked={proformaDetail.waterproofedFaces === 2}
                    />
                    <label htmlFor="rb2" className="p-radiobutton-label">{t('twoFaces')}</label>
                  </div>
                </div>
                {proformaDetail.waterproofedFaces === 1 && (codeRole === 'GV' || codeRole === 'EV') && <>
                  {codeRole === 'GV' && <>
                    <div className="p-col-4 p-md-4 p-lg-2" style={{ marginTop: '3.4em' }}>
                      <label htmlFor="oneCobbWaterCutCharge" className="p-radiobutton-label">{t('noCharge')}</label>
                      <Checkbox
                        disabled={isApproved}
                        inputId="oneCobbWaterCutCharge"
                        onChange={() => (detailAmountOneCobbFaceWaterproofing === 0)
                          ? setDetailAmountOneCobbFaceWaterproofing(constants.oneFaceCobbWaterproofing)
                          : setDetailAmountOneCobbFaceWaterproofing(0)}
                        checked={detailAmountOneCobbFaceWaterproofing === 0}
                      />
                    </div>
                  </>}
                  <div className="p-col-4 p-md-4 p-lg-2" style={{ marginTop: '34px' }}>
                    <h5>{t('amountOneFacesWaterproofing')}</h5>
                  </div>
                  <div className="p-col-8 p-md-8 p-lg-5" style={{ marginTop: '38px' }}>
                    <InputText
                      readOnly={isApproved}
                      id='amountOneCobbFaceWaterproofing'
                      keyfilter="pnum"
                      value={detailAmountOneCobbFaceWaterproofing}
                      disabled={codeRole !== 'GV'}
                      required
                      className="input-small-row"
                      onChange={(event) => setDetailAmountOneCobbFaceWaterproofing(event.target.value)}
                    />
                  </div>
                </>}
                {proformaDetail.waterproofedFaces === 2 && (codeRole === 'GV' || codeRole === 'EV') && <>
                  {codeRole === 'GV' && <>
                    <div className="p-col-4 p-md-4 p-lg-2" style={{ marginTop: '0.8em' }}>
                      <label htmlFor="twoCobbWaterCutCharge" className="p-radiobutton-label">{t('noCharge')}</label>
                      <Checkbox
                        disabled={isApproved}
                        inputId="twoCobbWaterCutCharge"
                        onChange={() => (detailAmountTwoCobbFacesWaterproofing === 0)
                          ? setDetailAmountTwoCobbFacesWaterproofing(constants.twoFacesCobbWaterproofing)
                          : setDetailAmountTwoCobbFacesWaterproofing(0)}
                        checked={detailAmountTwoCobbFacesWaterproofing === 0}
                      />
                    </div>
                  </>}
                  <div className="p-col-4 p-md-4 p-lg-2"><h5>{t('amountTwoFacesWaterproofing')}</h5></div>
                  <div className="p-col-8 p-md-8 p-lg-5">
                    <InputText
                      readOnly={isApproved}
                      id='amountTwoCobbFacesWaterproofing'
                      keyfilter="pnum"
                      value={detailAmountTwoCobbFacesWaterproofing}
                      disabled={codeRole !== 'GV'}
                      required
                      className="input-small-row"
                      onChange={(event) => setDetailAmountTwoCobbFacesWaterproofing(event.target.value)}
                    />
                  </div>
                </>}
                <div className="p-col-12"></div>
              </>}
            </>}
            {(selectedBox.name === 'RSC' || selectedBox.name === 'HSC') && <>
              <div className="p-col-4 p-md-4 p-lg-2"><h5>{t('cobbExterior')}</h5></div>
              {!proformaDetail.cobbWaterproofing && <>
                <div className="p-col-8 p-md-8 p-lg-5">
                  <Checkbox
                    disabled={isApproved}
                    name='waterproofing'
                    id="cobbWaterproofing"
                    style={{ marginTop: '15px' }}
                    className="input"
                    onChange={(e) =>
                      setProformaDetail({ ...proformaDetail, cobbWaterproofing: e.checked, waterproofedFaces: 1 })}
                    checked={Boolean(proformaDetail.cobbWaterproofing)}
                  />
                </div>
              </>}
              {proformaDetail.cobbWaterproofing && <>
                <div className="p-col-8 p-md-8 p-lg-1">
                  <Checkbox
                    disabled={isApproved}
                    name='waterproofing'
                    id="cobbWaterproofing"
                    style={{ marginTop: '15px' }}
                    className="input"
                    onChange={(e) =>
                      setProformaDetail({ ...proformaDetail, cobbWaterproofing: e.checked, waterproofedFaces: 1 })}
                    checked={Boolean(proformaDetail.cobbWaterproofing)}
                  />
                </div>
                {(codeRole === 'GV' || codeRole === 'EV') && <>
                  {codeRole === 'GV' && <>
                    <div className="p-col-4 p-md-4 p-lg-2" style={{ marginTop: '0.8em' }}>
                      <label htmlFor="oneCobbWaterCutCharge" className="p-radiobutton-label">{t('noCharge')}</label>
                      <Checkbox
                        disabled={isApproved}
                        inputId="oneCobbWaterCutCharge"
                        onChange={() => (detailAmountOneCobbFaceWaterproofing === 0)
                          ? setDetailAmountOneCobbFaceWaterproofing(constants.oneFaceCobbWaterproofing)
                          : setDetailAmountOneCobbFaceWaterproofing(0)}
                        checked={detailAmountOneCobbFaceWaterproofing === 0}
                      />
                    </div>
                  </>}
                  <div className="p-col-4 p-md-4 p-lg-2"><h5>{t('amountOneFaceWaterproofing')}</h5></div>
                  <div className="p-col-8 p-md-8 p-lg-5">
                    <InputText
                      readOnly={isApproved}
                      id='amountOneCobbFaceWaterproofing'
                      keyfilter="pnum"
                      value={detailAmountOneCobbFaceWaterproofing}
                      disabled={codeRole !== 'GV'}
                      required
                      className="input-small-row"
                      onChange={(event) => setDetailAmountOneCobbFaceWaterproofing(event.target.value)}
                    />
                  </div>
                </>}
                <div className="p-col-12"></div>
              </>}
            </>}
            {selectedBox.name !== SHEET && <>
              <div className="p-col-12" style={{ padding: '.5em' }}><h5>{t('internalMeasurements')}</h5></div>
              <div className="p-col-2" style={{ padding: '.75em' }}>
                <h5>
                  <label htmlFor="long">{t('long')} (mm)</label>
                </h5>
              </div>
              <div className="p-col-2" style={{ marginTop: '1.4em', padding: '.5em' }}>
                <InputText
                  readOnly={isApproved}
                  id="internalLong"
                  keyfilter="pnum"
                  onChange={(e) => setProformaDetail({ ...proformaDetail, internalLong: e.target.value })}
                  value={proformaDetail.internalLong}
                />
              </div>
              <div className="p-col-2" style={{ padding: '.75em' }}>
                <h5>
                  <label htmlFor="width">{t('width')} (mm)</label>
                </h5>
              </div>
              <div className="p-col-2" style={{ marginTop: '1.4em', padding: '.5em' }}>
                <InputText
                  readOnly={isApproved}
                  id="internalWidth"
                  keyfilter="pnum"
                  onChange={(e) => setProformaDetail({ ...proformaDetail, internalWidth: e.target.value })}
                  value={proformaDetail.internalWidth}
                />
              </div>
              <div className="p-col-2" style={{ padding: '.75em' }}>
                <h5>
                  <label htmlFor="height">{t('height')} (mm)</label>
                </h5>
              </div>
              <div className="p-col-2" style={{ marginTop: '1.4em', padding: '.5em' }}>
                <InputText
                  readOnly={isApproved}
                  id="internalHeight"
                  keyfilter="pnum"
                  onChange={(e) => setProformaDetail({ ...proformaDetail, internalHeight: e.target.value })}
                  value={proformaDetail.internalHeight}
                />
              </div>
            </>}
            <div className="p-col-12" ><h5>{t('sheetMeasurements')}</h5></div>
            {selectedBox.name === SHEET && <>
              <div className="p-col-2" style={{ padding: '.75em' }}>
                <h5>
                  <label htmlFor="long">{t('long')} (cm)</label>
                </h5>
              </div>
              <div className="p-col-2" style={{ marginTop: '1.4em', padding: '.5em' }}>
                <div id="tooltip" data-tooltip={calculateLongInInches(proformaDetail)}>
                  <InputText
                    readOnly={isApproved}
                    id="sheetLong"
                    keyfilter="pnum"
                    tooltipOptions={{ event: 'focus' }}
                    tooltip={t('cmMeasure')}
                    onChange={(e) => setProformaDetail({ ...proformaDetail, sheetLong: e.target.value })}
                    value={proformaDetail.sheetLong}
                  />
                </div>
              </div>
              <div className="p-col-2" style={{ padding: '.75em' }}>
                <h5>
                  <label htmlFor="width">{t('width')} (cm)</label>
                </h5>
              </div>
              <div className="p-col-2" style={{ marginTop: '1.4em', padding: '.5em' }}>
                <div id="tooltip" data-tooltip={calculateWidthInInches(proformaDetail)}>
                  <InputText
                    readOnly={isApproved}
                    id="sheetWidth"
                    keyfilter="pnum"
                    tooltipOptions={{ event: 'focus' }}
                    tooltip={t('cmMeasure')}
                    onChange={(e) => setProformaDetail({ ...proformaDetail, sheetWidth: e.target.value })}
                    value={proformaDetail.sheetWidth}
                  />
                </div>
              </div>
              <div className="p-col-2" style={{ padding: '.75em' }}><h5>{t('sheetArea')}</h5></div>
              <div className="p-col-2" style={{ marginTop: '1.4em', padding: '.5em' }}>
                {calculateSheetArea(proformaDetail)}
              </div>
            </>}
            {selectedBox.type === DIE_CUT && <>
              <div className="p-col-12" style={{ padding: '.5em' }}><h4>{t('internalMeasurements')}</h4></div>
              <div className="p-col-2" style={{ padding: '.75em' }}>
                <h5>
                  <label htmlFor="long">{t('internalLong')} (mm)</label>
                </h5>
              </div>
              <div className="p-col-2" style={{ marginTop: '1.4em', padding: '.5em' }}>
                <InputText
                  readOnly={isApproved}
                  id="internalLong"
                  keyfilter="pnum"
                  onChange={(e) => setProformaDetail({ ...proformaDetail, internalLong: e.target.value })}
                  value={proformaDetail.internalLong}
                />
              </div>
              <div className="p-col-2" style={{ padding: '.75em' }}>
                <h5>
                  <label htmlFor="width">{t('internalWidth')} (mm)</label>
                </h5>
              </div>
              <div className="p-col-2" style={{ marginTop: '1.4em', padding: '.5em' }}>
                <InputText
                  readOnly={isApproved}
                  id="internalWidth"
                  keyfilter="pnum"
                  onChange={(e) => setProformaDetail({ ...proformaDetail, internalWidth: e.target.value })}
                  value={proformaDetail.internalWidth}
                />
              </div>
              <div className="p-col-2" style={{ padding: '.75em' }}>
                <h5>
                  <label htmlFor="height">{t('internalHeight')} (mm)</label>
                </h5>
              </div>
              <div className="p-col-2" style={{ marginTop: '1.4em', padding: '.5em' }}>
                <InputText
                  readOnly={isApproved}
                  id="internalHeight"
                  keyfilter="pnum"
                  onChange={(e) => setProformaDetail({ ...proformaDetail, internalHeight: e.target.value })}
                  value={proformaDetail.internalHeight}
                />
              </div>
            </>}
            {(selectedBox.name !== SHEET) && <>
              <div className="p-col-2" style={{ padding: '.75em' }}>
                <h5>
                  <label htmlFor="long">{t('long')} (cm)</label>
                </h5>
              </div>
              <div
                className="p-col-2"
                style={{ marginTop: '1.4em', padding: '.5em' }}
                id="tooltip"
                data-tooltip={calculateLongInInches(proformaDetail)}
              >
                {calculateSheetLong(proformaDetail)}
              </div>
              <div className="p-col-2" style={{ padding: '.75em' }}>
                <h5>
                  <label htmlFor="width">{t('width')} (cm)</label>
                </h5>
              </div>
              <div
                className="p-col-2"
                style={{ marginTop: '1.4em', padding: '.5em' }}
                id="tooltip"
                data-tooltip={calculateWidthInInches(proformaDetail)}
              >
                {calculateSheetWidth(proformaDetail)}
              </div>
              <div className="p-col-2" style={{ padding: '.75em' }}><h5>{t('sheetArea')}</h5></div>
              <div className="p-col-2" style={{ marginTop: '1.4em', padding: '.5em' }}>{calculateArea(proformaDetail)}</div>
            </>}
            <div className="p-col-2" style={{ padding: '.75em' }}><h5>{t('weight')}</h5></div>
            <div className="p-col-2" style={{ marginTop: '1.4em', padding: '.5em' }}>{calculateWeight(proformaDetail)}</div>
            <div className="p-col-2" style={{ padding: '.75em' }}>
              <h5>
                <label htmlFor="wastePercentage">{t('wastePercentage')} </label>
              </h5>
            </div>
            <div className="p-col-2" style={{ marginTop: '1.4em', padding: '.5em' }}>
              <InputText
                readOnly={isApproved}
                id="wastePercentage"
                keyfilter="pnum"
                onChange={(e) => setProformaDetail({ ...proformaDetail, wastePercentage: e.target.value })}
                value={proformaDetail.wastePercentage}
              />
            </div>
            <div className="p-col-2" style={{ padding: '.75em' }}>
              <h5>
                <label htmlFor="quantity">{t('quantity')}</label>
              </h5>
            </div>
            <div className="p-col-2" style={{ marginTop: '1.4em', padding: '.5em' }}>
              <InputText
                readOnly={isApproved}
                id="quantity"
                keyfilter="pnum"
                onChange={(e) => setProformaDetail({ ...proformaDetail, quantity: e.target.value })}
                value={proformaDetail.quantity}
              />
            </div>
            <div className="p-col-2" style={{ padding: '.75em' }}>
              <h5>
                <label htmlFor="priceXtc">{t('priceXtc')}</label>
              </h5>
            </div>
            <div className="p-col-2" style={{ marginTop: '1.4em', padding: '.5em' }}>
              <InputText
                readOnly={isApproved}
                id="priceXtc"
                keyfilter="pnum"
                onChange={(e) => setProformaDetail({ ...proformaDetail, priceByTc: e.target.value })}
                value={proformaDetail.priceByTc}
              />
            </div>
            <div className="p-col-2" style={{ padding: '.75em' }}>
              <h5>
                <label htmlFor="thirdProcesses">{t('thirdProcesses')}</label>
              </h5>
            </div>
            <div className="p-col-2" style={{ marginTop: '1.4em', padding: '.5em' }}>
              <InputText
                readOnly={isApproved}
                id="thirdProcesses"
                keyfilter="pnum"
                onChange={(e) => setProformaDetail({ ...proformaDetail, thirdProcesses: e.target.value })}
                value={proformaDetail.thirdProcesses}
              />
            </div>
            <div className="p-col-2" style={{ padding: '.75em' }}><h5>{t('unitPrice')}</h5></div>
            <div className="p-col-2" style={{ marginTop: '1.4em', padding: '.5em' }}>
              {currencyFormatter.format(calculateUnitPrice(proformaDetail))}
            </div>
            <div className="p-col-2" style={{ padding: '.75em' }}><h5>{t('clicheCost')}</h5></div>
            <div className="p-col-2" style={{ marginTop: '1.4em', padding: '.5em' }}>
              {currencyFormatter.format(calculateClicheCost(proformaDetail))}
            </div>
            <div className="p-col-2" style={{ padding: '.75em' }}><h5>{t('dieCutCost')}</h5></div>
            <div className="p-col-2" style={{ marginTop: '1.4em', padding: '.5em' }}>
              {currencyFormatter.format(calculateDieCutCost(proformaDetail))}
            </div>
            <div className="p-col-2" style={{ padding: '.75em' }}><h3>{t('total')}</h3></div>
            <div className="p-col-2" style={{ marginTop: '1.4em', padding: '.5em' }}>
              {currencyFormatter.format(calculateDetailCost(proformaDetail))}
            </div>
          </div>
        </Dialog>
      </ScrollPanel>
    );
  };

  const newDetailWithProductDialog = () => {
    return (
      <ScrollPanel className="custom">
        <Dialog
          className="layout-main"
          id='dialog'
          maximizable
          style={{ marginTop: 'auto', width: '870px', height: '85%', overflow: 'scroll' }}
          blockScroll
          visible={displayDialog && !isNewProduct}
          header={t('proformaDetails')}
          modal={true}
          footer={!isApproved ? dialogFooter : ''}
          onHide={() => { setDisplayDialog(false); setSelectedProfromaDetail({}); }}
        >
          <div className="p-grid p-fluid">
            <div className="p-col-2" style={{ marginTop: '.75em', padding: '.75em' }}>
              <h5>
                <label htmlFor="product">{t('product')}</label>
              </h5>
            </div>
            <div className="p-col-10" style={{ marginTop: '1.4em', padding: '.5em' }}>
              <AutoComplete
                style={{ width: '100%' }}
                id='product'
                field="name"
                value={selectedProduct}
                onSelect={(e) => setProformaDetailsValues(e)}
                suggestions={products}
                completeMethod={(e) => handleProductOnChange(e)}
                disabled={isApproved}
              />
            </div>
            {proformaDetail.isSample === 1 && <>
              <div className="p-col-2" style={{ padding: '.75em' }}><h5>{t('type')}</h5></div>
              <div className="p-col-10" style={{ marginTop: '1.4em', padding: '.5em' }}>{t('sample')}</div>
            </>}
            <div className="p-col-2" style={{ padding: '.75em' }}><h5>{t('code')}</h5></div>
            <div className="p-col-4" style={{ marginTop: '1.4em', padding: '.5em' }}>{proformaDetail.product?.code}</div>
            <div className="p-col-2" style={{ padding: '.75em' }}><h5>{t('version')}</h5></div>
            <div className="p-col-4" style={{ marginTop: '1.4em', padding: '.5em' }}>
              {proformaDetail.product?.version}
            </div>
            <div className="p-col-2" style={{ padding: '.75em' }}><h5>{t('test')}</h5></div>
            <div className="p-col-4" style={{ marginTop: '1.4em', padding: '.5em' }}>
              {proformaDetail.test.nameWithCombination}
            </div>
            <div className="p-col-2" style={{ padding: '.75em' }}><h5>{t('testWeight')}</h5></div>
            <div className="p-col-4" style={{ marginTop: '1.4em', padding: '.5em' }}>{proformaDetail.test.weight}</div>
            <div className="p-col-2" style={{ padding: '.75em' }}><h5>{t('flaute')}</h5></div>
            <div className="p-col-4" style={{ marginTop: '1.4em', padding: '.5em' }}>{proformaDetail.flaute.name}</div>
            <div className="p-col-2" style={{ padding: '.75em' }}><h5>{t('paper')}</h5></div>
            <div className="p-col-4" style={{ marginTop: '1.4em', padding: '.5em' }}>{proformaDetail.paper.name}</div>
            <div className="p-col-2" style={{ padding: '.75em' }}><h5>{t('boxType')}</h5></div>
            <div className="p-col-10" style={{ marginTop: '1.4em', padding: '.5em' }}>{proformaDetail.box.name}</div>
            <div className="p-col-2" style={{ padding: '.75em' }}>
              <h5>
                <label htmlFor="isDieCut">{t('isDieCut')}</label>
              </h5>
            </div>
            {!proformaDetail.isDieCut &&
              <div className="p-col-10" style={{ marginTop: '1.4em', padding: '.5em' }}>
                {showCheck(proformaDetail.isDieCut)}
              </div>
            }
            {proformaDetail.isDieCut && <>
              <div className="p-col-1" style={{ marginTop: '1.4em', padding: '.5em' }}>
                {showCheck(proformaDetail.isDieCut)}
              </div>
              <div className="p-col-4 p-md-4 p-lg-2"><h5>{t('amountDieCut')}</h5></div>
              <div className="p-col-8 p-md-8 p-lg-7" style={{ marginTop: '1.4em', padding: '.5em' }}>
                {proformaDetail.amountDieCut}
              </div>
            </>}
            <div className="p-col-4 p-md-4 p-lg-2"><h5>{t('cliche')}</h5></div>
            {!proformaDetail.isCliche &&
              <div className="p-col-8 p-md-8 p-lg-10" style={{ marginTop: '1.4em', padding: '.5em' }}>
                {showCheck(proformaDetail.isCliche)}
              </div>
            }
            {proformaDetail.isCliche && <>
              <div className="p-col-8 p-md-8 p-lg-1" style={{ marginTop: '1.4em', padding: '.5em' }}>
                {showCheck(proformaDetail.isCliche)}
              </div>
              <div className="p-col-4 p-md-4 p-lg-2"><h5>{t('amountCliche')}</h5></div>
              <div className="p-col-8 p-md-8 p-lg-2" style={{ marginTop: '1.4em', padding: '.5em' }}>
                {proformaDetail.amountCliche}
              </div>
              <div className="p-col-4 p-md-4 p-lg-2"><h5>{t('colorQuantity')}</h5></div>
              <div className="p-col-8 p-md-8 p-lg-2" style={{ marginTop: '1.4em', padding: '.5em' }}>
                {proformaDetail.colorQuantity}
              </div>
            </>}
            <div className="p-col-4 p-md-4 p-lg-2" style={{ padding: '.75em' }}><h5>{t('isPaletized')}</h5></div>
            <div className="p-col-8 p-md-8 p-lg-10" style={{ marginTop: '1.0em', padding: '.5em' }}>
              {showCheck(proformaDetail.isPaletized)}
            </div>
            <div className="p-col-12 p-md-12 p-lg-12">
              <h4>{t('waterproofing')}</h4>
            </div>
            <div className="p-col-4 p-md-4 p-lg-2"><h5>{t('regular')}</h5></div>
            <div className="p-col-8 p-md-8 p-lg-10" style={{ marginTop: '1.4em', padding: '.5em' }}>
              {showCheck(proformaDetail.regularWaterproofing)}
            </div>
            {Boolean(proformaDetail.regularWaterproofing) && <>
              <div className="p-col-3">
                <div className="p-col-12">
                  <RadioButton
                    disabled
                    inputId="rb1"
                    style={{ marginLeft: '40px' }}
                    name="city"
                    value="New York"
                    checked={proformaDetail.waterproofedFaces === 1}
                  />
                  <label htmlFor="rb1" className="p-radiobutton-label">{t('oneFace')}</label>
                </div>
                <div className="p-col-12">
                  <RadioButton
                    disabled
                    inputId="rb2"
                    style={{ marginLeft: '40px' }}
                    name="city"
                    value="San Francisco"
                    checked={proformaDetail.waterproofedFaces === 2}
                  />
                  <label htmlFor="rb2" className="p-radiobutton-label">{t('twoFaces')}</label>
                </div>
              </div>
              {proformaDetail.waterproofedFaces === 1 && <>
                <div className="p-col-4 p-md-4 p-lg-2"><h5>{t('amountOneFaceWaterproofing')}</h5></div>
                <div className="p-col-8 p-md-8 p-lg-7" style={{ marginTop: '1.4em', padding: '.5em' }}>
                  {proformaDetail.amountOneRegularFaceWaterproofing}
                </div>
              </>}
              {proformaDetail.waterproofedFaces === 2 && <>
                <div className="p-col-4 p-md-4 p-lg-2"><h5>{t('amountTwoFacesWaterproofing')}</h5></div>
                <div className="p-col-8 p-md-8 p-lg-7" style={{ marginTop: '1.4em', padding: '.5em' }}>
                  {proformaDetail.amountTwoRegularFaceWaterproofing}
                </div>
              </>}
              <div className="p-col-12"></div>
            </>}
            {(selectedBox.name === SHEET || selectedBox.name === 'CST') && <>
              <div className="p-col-4 p-md-4 p-lg-2"><h5>{t('cobb')}</h5></div>
              <div className="p-col-8 p-md-8 p-lg-10" style={{ marginTop: '1.4em', padding: '.5em' }}>
                {showCheck(proformaDetail.cobbWaterproofing)}
              </div>
              {proformaDetail.cobbWaterproofing && <>
                <div className="p-col-3">
                  <div className="p-col-12">
                    <RadioButton
                      disabled
                      style={{ marginLeft: '40px' }}
                      inputId="rb1"
                      name="city"
                      value="New York"
                      checked={proformaDetail.waterproofedFaces === 1}
                    />
                    <label htmlFor="rb1" className="p-radiobutton-label">{t('oneFace')}</label>
                  </div>
                  <div className="p-col-12">
                    <RadioButton
                      disabled
                      style={{ marginLeft: '40px' }}
                      inputId="rb2"
                      name="city"
                      value="San Francisco"
                      checked={proformaDetail.waterproofedFaces === 2}
                    />
                    <label htmlFor="rb2" className="p-radiobutton-label">{t('twoFaces')}</label>
                  </div>
                </div>
                {proformaDetail.waterproofedFaces === 1 && <>
                  <div className="p-col-4 p-md-4 p-lg-2"><h5>{t('amountOneFaceWaterproofing')}</h5></div>
                  <div className="p-col-8 p-md-8 p-lg-7" style={{ marginTop: '1.4em', padding: '.5em' }}>
                    {proformaDetail.amountOneCobbFaceWaterproofing}
                  </div>
                </>}
                {proformaDetail.waterproofedFaces === 2 && <>
                  <div className="p-col-4 p-md-4 p-lg-2"><h5>{t('amountTwoFacesWaterproofing')}</h5></div>
                  <div className="p-col-8 p-md-8 p-lg-7" style={{ marginTop: '1.4em', padding: '.5em' }}>
                    {proformaDetail.amountTwoCobbFaceWaterproofing}
                  </div>
                </>}
                <div className="p-col-12"></div>
              </>}
            </>}
            {(selectedBox.name === 'RSC' || selectedBox.name === 'HSC') && <>
              <div className="p-col-4 p-md-4 p-lg-2"><h5>{t('cobbExterior')}</h5></div>
              <div className="p-col-8 p-md-8 p-lg-1" style={{ marginTop: '1.0em', padding: '.5em' }}>
                {() => {
                  showCheck(proformaDetail.cobbWaterproofing);
                  setProformaDetail({ ...proformaDetail, waterproofedFaces: 1 });
                }}
              </div>
              <div className="p-col-4 p-md-4 p-lg-2"><h5>{t('amountOneFaceWaterproofing')}</h5></div>
              <div className="p-col-8 p-md-8 p-lg-7" style={{ marginTop: '1.4em', padding: '.5em' }}>
                {proformaDetail.amountOneCobbFaceWaterproofing}
              </div>
            </>}
            <div className="p-col-12" style={{ padding: '.5em' }}><h5>{t('internalMeasurements')}</h5></div>
            <div className="p-col-2" style={{ padding: '.75em' }}><h5>{t('long')} (mm)</h5></div>
            <div className="p-col-2" style={{ marginTop: '1.4em', padding: '.5em' }}>{selectedProduct?.internalLong}</div>
            <div className="p-col-2" style={{ padding: '.75em' }}><h5>{t('width')} (mm)</h5></div>
            <div className="p-col-2" style={{ marginTop: '1.4em', padding: '.5em' }}>{selectedProduct?.internalWidth}</div>
            <div className="p-col-2" style={{ padding: '.75em' }}><h5>{t('height')} (mm)</h5></div>
            <div className="p-col-2" style={{ marginTop: '1.4em', padding: '.5em' }}>{selectedProduct?.internalHeight}</div>
            <div className="p-col-12" ><h5>{t('sheetMeasurements')}</h5></div>
            <div className="p-col-2" style={{ padding: '.75em' }}><h5>{t('long')} (cm)</h5></div>
            <div
              id="tooltip"
              className="p-col-2"
              data-tooltip={calculateLongInInches(proformaDetail)}
              style={{ marginTop: '1.4em', padding: '.5em' }}
            >
              {selectedProduct?.sheetLong}
            </div>
            <div className="p-col-2" style={{ padding: '.75em' }}><h5>{t('width')} (cm)</h5></div>
            <div
              id="tooltip"
              className="p-col-2"
              data-tooltip={calculateWidthInInches(proformaDetail)}
              style={{ marginTop: '1.4em', padding: '.5em' }}
            >
              {selectedProduct?.sheetWidth}
            </div>
            <div className="p-col-2" style={{ padding: '.75em' }}><h5>{t('sheetArea')}</h5></div>
            <div className="p-col-2" style={{ marginTop: '1.4em', padding: '.5em' }}>{selectedProduct?.area}</div>
            <div className="p-col-2" style={{ padding: '.75em' }}><h5>{t('weight')}</h5></div>
            <div className="p-col-2" style={{ marginTop: '1.4em', padding: '.5em' }}>
              {Number(proformaDetail.weight).toFixed(4)} g
            </div>
            <div className="p-col-2" style={{ padding: '.75em' }}><h5>{t('wastePercentage')}</h5></div>
            <div className="p-col-2" style={{ marginTop: '1.4em', padding: '.5em' }}>{proformaDetail.wastePercentage}</div>
            <div className="p-col-2" style={{ padding: '.75em' }}>
              <h5>
                <label htmlFor="quantity">{t('quantity')}</label>
              </h5>
            </div>
            <div className="p-col-2" style={{ marginTop: '1.4em', padding: '.5em' }}>
              <InputText
                id="quantity"
                keyfilter="pnum"
                onChange={(e) => setProformaDetail({ ...proformaDetail, quantity: e.target.value })}
                value={proformaDetail.quantity}
                readOnly={isApproved}
              />
            </div>
            <div className="p-col-2" style={{ padding: '.75em' }}><h5>{t('priceXtc')}</h5></div>
            <div className="p-col-2" style={{ marginTop: '1.4em', padding: '.5em' }}>
              {Number(proformaDetail.priceByTc).toFixed(4)}
            </div>
            <div className="p-col-2" style={{ padding: '.75em' }}><h5>{t('thirdProcesses')}</h5></div>
            <div className="p-col-2" style={{ marginTop: '1.4em', padding: '.5em' }}>
              {Number(proformaDetail.thirdProcesses).toFixed(4)}
            </div>
            <div className="p-col-2" style={{ padding: '.75em' }}><h5>{t('unitPrice')}</h5></div>
            <div className="p-col-2" style={{ marginTop: '1.4em', padding: '.5em' }}>
              {currencyFormatter.format(proformaDetail.product?.unitPrice)}
            </div>
            <div className="p-col-2" style={{ padding: '.75em' }}><h5>{t('clicheCost')}</h5></div>
            <div className="p-col-2" style={{ marginTop: '1.4em', padding: '.5em' }}>
              {currencyFormatter.format(calculateClicheCost(proformaDetail))}
            </div>
            <div className="p-col-2" style={{ padding: '.75em' }}><h5>{t('dieCutCost')}</h5></div>
            <div className="p-col-2" style={{ marginTop: '1.4em', padding: '.5em' }}>
              {proformaDetail.isDieCut ? currencyFormatter.format(calculateDieCutCost(proformaDetail)) : '-'}
            </div>
            <div className="p-col-2" style={{ padding: '.75em' }}><h3>{t('total')}</h3></div>
            <div className="p-col-2" style={{ marginTop: '1.4em', padding: '.5em' }}>
              {currencyFormatter.format(calculateDetailCost(proformaDetail))}
            </div>
          </div>
        </Dialog>
      </ScrollPanel>

    );
  };

  return (
    <div>
      <div className="ui-g ui-fluid" >
        <div className="ui-g-12">
          <div className="card-user">
            <div className="card">
              {!isRendered && <div id="div-loader"><i id='loader' className="pi pi-spin pi-spinner"></i></div>}
              {isRendered && <>
                <div className="content-section introduction">
                  <h1>{t('proformaEdit')}</h1>
                  <form onSubmit={(e) => handleSubmit(e, null)}>
                    <div className="p-grid p-fluid">
                      <div className="p-col-12 p-md-8">
                        <Growl id='growl' style={{ width: '250px', marginTop: '60px' }} ref={(el) => setGrowl(el)} />
                        <h3 style={{ marginTop: '0px' }} className="label-gray">{t('client')}</h3>
                        <AutoComplete
                          disabled={isApproved}
                          style={{ width: '100%', marginTop: '0px' }}
                          id='client'
                          required
                          className="input-client"
                          field="name"
                          value={selectedClient}
                          onChange={(e) => setSelectedClient(e.value)}
                          onSelect={(e) => {
                            setSelectedClient(e.value);
                            setFreeTransport(e.value.freeTransport);
                            setIvaPercentage(e.value.iva);
                            setEmail(e.value.email);
                          }}
                          placeholder={t('selectClient')}
                          suggestions={clients}
                          completeMethod={(e) => handleClientOnChange(e)}
                        />
                      </div>
                      <div className="p-col-12 p-md-4">
                        <h5 style={{ marginTop: '4px' }} className="label-gray">{t('email')}</h5>
                        <InputText
                          required
                          disabled={proforma.status > 1}
                          style={{ width: '100%', marginTop: '0px' }}
                          id='clientEmail'
                          className="input-client"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                    </div>
                    <h3 className='label-gray'>{t('proformaDetails')}</h3>
                    <div className="content-section introduction">
                    </div>
                    <div className="content-section implementation">
                      {isApproved &&
                        <DataTable
                          id='datatable'
                          className='datatable'
                          style={{ textAlign: 'center' }}
                          resizableColumns={true}
                          responsive={true}
                          value={proformaDetails}
                          selectionMode="single"
                          onRowSelect={onProformaDetailSelect}
                        >
                          <Column field="name" header={t('product')} />
                          <Column field="test.name" header={t('test')} />
                          <Column field="flaute.name" header={t('flaute')} />
                          <Column field="paper.name" header={t('paper')} />
                          <Column field="box.nameWithType" header={t('boxType')} />
                          <Column body={getLong} header={t('long') + ' (mm)'} />
                          <Column body={getWidth} header={t('width') + ' (mm)'} />
                          <Column body={getHeight} header={t('height') + ' (mm)'} />
                          <Column body={e => showCheck(e.isDieCut)} header={t('isDieCut')} />
                          <Column field="quantity" header={t('quantity')} />
                          <Column
                            body={e => e.isCliche ? applyCurrencyFormatter(e.clicheTotalCost) : '-'}
                            header={t('clicheCost')}
                          />
                          <Column
                            body={e => e.isDieCut ? applyCurrencyFormatter(e.dieCutTotalCost) : '-'}
                            header={t('dieCutCost')}
                          />
                          <Column body={e => applyCurrencyFormatter(e.unitPrice)} header={t('unitPrice')} />
                          <Column body={e => applyCurrencyFormatter(e.cost)} header={t('total')} />
                        </DataTable>
                      }
                      {!isApproved &&
                        <DataTable
                          id='datatable'
                          footer={footer}
                          className='datatable'
                          style={{ textAlign: 'center', width: 'auto' }}
                          resizableColumns={true}
                          responsive={true}
                          value={proformaDetails}
                          selectionMode="single"
                          selection={selectedProformaDetail}
                          onSelectionChange={e => {
                            setSelectedProfromaDetail(e.value);
                            setSelectedFlaute(e.value.flaute);
                            setSelectedProduct(e.value.product);
                            setSelectedTest(e.value.test);
                          }}
                          onRowSelect={onProformaDetailSelect}
                        >
                          <Column field="name" header={t('product')} />
                          <Column field="test.name" header={t('test')} />
                          <Column field="flaute.name" header={t('flaute')} />
                          <Column field="paper.name" header={t('paper')} />
                          <Column field="box.nameWithType" header={t('boxType')} />
                          <Column body={getLong} header={t('long') + ' (mm)'} />
                          <Column body={getWidth} header={t('width') + ' (mm)'} />
                          <Column body={getHeight} header={t('height') + ' (mm)'} />
                          <Column body={e => showCheck(e.isDieCut)} header={t('isDieCut')} />
                          <Column field="quantity" header={t('quantity')} />
                          <Column
                            body={e => e.isCliche ? applyCurrencyFormatter(e.clicheTotalCost) : '-'}
                            header={t('clicheCost')}
                          />
                          <Column
                            body={e => e.isDieCut ? applyCurrencyFormatter(e.dieCutTotalCost) : '-'}
                            header={t('dieCutCost')}
                          />
                          <Column body={e => applyCurrencyFormatter(e.unitPrice)} header={t('unitPrice')} />
                          <Column body={e => applyCurrencyFormatter(e.cost)} header={t('total')} />
                        </DataTable>
                      }
                      {newDetailWithProductDialog()}
                      {newDetailWithNewProductDialog()}
                      {dialogNewDetail()}
                      {dialogAttachements()}
                      {getUserSession().user.codeRole !== 'CL' && dialogWarnings()}
                    </div>
                    <h4 className="label-gray">{t('transport')}</h4>
                    <div className="p-grid p-fluid">
                      <div className="p-col-12">
                        <RadioButton
                          disabled={isApproved}
                          inputId="exw"
                          style={{ marginLeft: '40px' }}
                          name="transport"
                          onChange={() => { setTransportType(5); handleTransportCostChange(0, transportSize.value); }}
                          checked={(transportType === 5) || (transportType === 2) || (transportType === 1)}
                        />
                        <label htmlFor="exw" className="p-radiobutton-label">{t('EXW')}</label>
                      </div>
                      {
                        (transportType === 1 || transportType === 2 || transportType === 5) &&
                        <>
                          <div className="p-col-12">
                            <RadioButton
                              disabled={isApproved}
                              inputId="cpt"
                              style={{ marginLeft: '80px' }}
                              name="transport"
                              onChange={() => setTransportType(1)}
                              checked={transportType === 1}
                            />
                            <label htmlFor="cpt" className="p-radiobutton-label">{t('GuacimoPlant')}</label>
                          </div>
                          <div className="p-col-12">
                            <RadioButton
                              disabled={isApproved}
                              inputId="fca"
                              style={{ marginLeft: '80px' }}
                              name="transport"
                              onChange={() => setTransportType(2)}
                              checked={transportType === 2}
                            />
                            <label htmlFor="fca" className="p-radiobutton-label">{t('CartagoCellar')}</label>
                          </div>
                        </>
                      }
                      <div className="p-col-12">
                        <RadioButton
                          disabled={isApproved}
                          inputId="cpt"
                          style={{ marginLeft: '40px' }}
                          name="transport"
                          onChange={() => { setTransportType(3); handleTransportCostChange(0, transportSize.value); }}
                          checked={transportType === 3}
                        />
                        <label htmlFor="cpt" className="p-radiobutton-label">{t('CPT')}</label>
                      </div>
                      {
                        (transportType === 3) && transportInfo()
                      }
                      <div className="p-col-12">
                        <RadioButton
                          disabled={isApproved}
                          inputId="fca"
                          style={{ marginLeft: '40px' }}
                          name="transport"
                          onChange={() => { setTransportType(4); handleTransportCostChange(0, transportSize.value); }}
                          checked={transportType === 4}
                        />
                        <label htmlFor="fca" className="p-radiobutton-label">{t('FCA')}</label>
                      </div>
                      {
                        (transportType === 4) && transportInfo()
                      }
                    </div>
                    <h4 className="label-gray">{t('terms')}</h4>
                    <div className="p-grid p-fluid">
                      <div className="p-col-12">
                        <RadioButton
                          id="cash"
                          disabled={isApproved}
                          inputId="cash"
                          style={{ marginLeft: '40px' }}
                          name="term"
                          onChange={(e) => (e.checked) ? setTerms(0) : setTerms(1)}
                          checked={(terms === 0)}
                        />
                        <label htmlFor="cash" className="p-radiobutton-label">{t('cash')}</label>
                      </div>
                      {terms !== CREDIT && <div className="p-col-12">
                        <RadioButton
                          id="credit"
                          disabled={isApproved}
                          inputId="credit"
                          style={{ marginLeft: '40px' }}
                          name="term"
                          onChange={(e) => (e.checked) ? setTerms(1) : setTerms(0)}
                          checked={(terms === 1)}
                        />
                        <label htmlFor="credit" className="p-radiobutton-label">{t('credit')}</label>
                      </div>}
                      {terms === CREDIT && <><div className="p-col-12 p-md-2 p-lg-2">
                        <RadioButton
                          id="credit"
                          inputId="credit"
                          style={{ marginLeft: '40px', marginTop: '10px' }}
                          name="term"
                          onChange={(e) => (e.checked) ? setTerms(1) : setTerms(0)}
                          checked={(terms === 1)}
                        />
                        <label htmlFor="credit" className="p-radiobutton-label">{t('credit')}</label>
                      </div>
                      <div className="p-field p-col-12 p-md-10 p-lg-10">
                        <span className="p-float-label">
                          <InputText
                            readOnly={isApproved}
                            id='creditTerm'
                            value={creditTerm}
                            className='card-user input-small-row'
                            keyfilter='pint'
                            onChange={e => setCreditTerm(e.target.value)}
                          />
                          <label htmlFor="creditTerm">{t('creditTerm')}</label>
                        </span>
                      </div>
                      </>}
                    </div>
                    {((getUserSession().user.codeRole === 'CL'
                      && proforma.discountMount > 1) || getUserSession().user.codeRole !== 'CL') && <>
                      <div className="p-grid p-justify-between p-fluid">
                        <div className="p-col-32 p-md-16 p-lg-8">
                          <h4 className="label-gray">{t('discount')}</h4>
                          <div className="p-grid p-fluid">
                            <div className="p-col-12 p-md-3">
                              <h5 className="label-gray">{t('discountPercentage')}</h5>
                              <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">%</span>
                                <InputText
                                  readOnly={isApproved}
                                  id='discountPercentage'
                                  value={proforma.discountPorcentage}
                                  keyfilter="pnum"
                                  placeholder={t('discountPercentage')}
                                  onChange={(event) => {
                                    setProforma({
                                      ...proforma,
                                      discountPorcentage: event.target.value,
                                      discountMount: (Math.round((event.target.value / 100)
                                          * (subtotalAmount) * 100) / 100).toFixed(2)
                                    });
                                  }}
                                  className="input"
                                />
                              </div>
                            </div>
                            <div className="p-col-12 p-md-3">
                              <h5 className="label-gray">{t('discountAmount')}</h5>
                              <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">$</span>
                                <InputText
                                  readOnly={isApproved}
                                  id='discountAmount'
                                  value={proforma.discountMount}
                                  keyfilter="pnum"
                                  placeholder={t('discountAmount')}
                                  onChange={(event) => {
                                    setProforma({
                                      ...proforma,
                                      discountMount: event.target.value,
                                      discountPorcentage:
                                          (Math.round((event.target.value * 100) / (subtotalAmount) * 100) / 100).toFixed(2)
                                    });
                                  }}
                                  className="input"
                                />
                              </div>
                            </div>
                            <div className="p-col-12 p-md-6">
                              <h5 className="label-gray">{t('discountDescription')}</h5>
                              <div className="p-inputgroup">
                                <InputTextarea
                                  readOnly={isApproved}
                                  id='discountDescription'
                                  value={proforma.discountDescription}
                                  rows={1}
                                  cols={30}
                                  autoResize={true}
                                  onChange={(event) => setProforma({ ...proforma, discountDescription: event.target.value })}
                                  placeholder={t('discountDescription')}
                                  className="input"
                                />
                              </div>
                            </div>
                            <div className="p-col-12">
                              <h4 className="label-gray">{t('comments')}</h4>
                              <div className="p-inputgroup">
                                <InputTextarea
                                  readOnly={isApproved}
                                  id='comments'
                                  value={proforma.comments}
                                  rows={2}
                                  cols={30}
                                  autoResize={true}
                                  onChange={(event) => setProforma({ ...proforma, comments: event.target.value })}
                                  placeholder={t('comments')}
                                  className="input"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div id='totals' className="p-col-12 p-md-6 p-lg-3">
                          <h5 style={{ paddingTop: '130px', textAlign: 'right' }} className="label-gray">
                            {t('transport')}: {currencyFormatter.format(subtotal[0].amount)}
                          </h5>
                          <h5 style={{ textAlign: 'right' }} className="label-gray">
                            {t('subtotal')}: {currencyFormatter.format(subtotalAmount)}
                          </h5>
                          <h5 style={{ textAlign: 'right' }} className="label-gray">
                            {t('iva')}: {currencyFormatter.format(iva)}
                          </h5>
                          <h2 style={{ textAlign: 'right' }} className="label-gray">
                            {t('total')}: {currencyFormatter.format(total)}
                          </h2>
                        </div>
                      </div>
                    </>}
                    {(getUserSession().user.codeRole === 'CL' && proforma.discountMount < 1) && <>
                      <div className="p-grid p-justify-between">
                        <div className="p-col-32 p-md-16 p-lg-8">
                          <div className="p-col-12">
                            <h5 className="label-gray">{t('comments')}</h5>
                            <div className="p-inputgroup">
                              <InputTextarea
                                readOnly={isApproved}
                                id='comments'
                                value={proforma.comments}
                                rows={2}
                                cols={30}
                                autoResize={true}
                                onChange={(event) => setProforma({ ...proforma, comments: event.target.value })}
                                placeholder={t('comments')}
                                className="input"
                              />
                            </div>
                          </div>
                        </div>
                        <div id='totals' className="p-col-12 p-md-6 p-lg-3">
                          <h5 style={{ textAlign: 'right' }} className="label-gray">
                            {t('transport')}: {currencyFormatter.format(subtotal[0].amount)}
                          </h5>
                          <h5 style={{ textAlign: 'right' }} className="label-gray">
                            {t('subtotal')}: {currencyFormatter.format(subtotalAmount)}
                          </h5>
                          <h5 style={{ textAlign: 'right' }} className="label-gray">
                            {t('iva')}: {currencyFormatter.format(iva)}
                          </h5>
                          <h2 style={{ textAlign: 'right' }} className="label-gray">
                            {t('total')}: {currencyFormatter.format(total)}
                          </h2>
                        </div>
                      </div>
                    </>}
                    <div className="ui-button-group p-grid p-justify-between wrap-button">
                      <div>
                        {proforma.status === 0 &&
                          <Button
                            id='button-save'
                            icon="pi pi-save"
                            label={t('save')}
                            style={{ marginTop: '10px', marginRight: '10px', width: 'auto' }}
                          />
                        }
                        {(getUserSession().user.codeRole === SALES_MANAGER_CODE
                          || getUserSession().user.codeRole === SUPER_USER_CODE) && proforma.status !== 0 && <>
                          <Button
                            id='button-reopen'
                            icon="pi pi-undo"
                            label={t('reopen')}
                            style={{ marginRight: '10px', marginTop: '10px', width: 'auto' }}
                            onClick={(e) => handleSubmit(e, REOPEN)}
                            className="btn-primary"
                          />
                        </>}
                        {proforma.status === 1 &&
                          (getUserSession().user.codeRole === 'EV' ||
                            getUserSession().user.codeRole === SALES_MANAGER_CODE) &&
                          <Button
                            id='button-sendToClient'
                            icon="pi pi-check"
                            label={t('sendToClient')}
                            style={{ marginTop: '10px', marginRight: '10px', width: 'auto' }}
                            onClick={displaySelectAttachements}
                          />
                        }
                        {(getUserSession().user.codeRole === SALES_MANAGER_CODE
                          || getUserSession().user.codeRole === OPERATIONS_DIRECTOR_CODE
                          || getUserSession().user.codeRole === QUALITY_MANAGER_CODE) && proforma.status === 0 && <>
                          <Button
                            id='button-approve'
                            icon="pi pi-check"
                            label={t('approve')}
                            style={{ marginRight: '10px', marginTop: '10px', width: 'auto' }}
                            onClick={(e) => handleSubmit(e, APPROVED_CODE)}
                            className="btn-primary"
                          />
                          <Button
                            id='button-reject'
                            icon="pi pi-times"
                            label={t('reject')}
                            style={{ marginRight: '10px', marginTop: '10px', width: 'auto' }}
                            onClick={(e) => handleSubmit(e, REJECTED_CODE)}
                            className="btn-danger"
                          />
                        </>}
                        <Button
                          id='button-return'
                          type="button"
                          icon="pi pi-dollar"
                          label={t('purchaseOrderRegister')}
                          style={{ marginRight: '10px', marginTop: '10px', width: 'auto' }}
                          onClick={handleOrderSubmit}
                          className="btn-register"
                        />
                      </div>
                      <div>
                        <Button
                          id='button-download'
                          type="button"
                          icon="pi pi-file-pdf"
                          label={t('download')}
                          style={{ marginRight: '10px', marginTop: '10px', width: 'auto' }}
                          onClick={() => createPdf(1)}
                          className="btn-download"
                        />
                        <Button
                          id='button-download'
                          type="button"
                          icon="pi pi-file-pdf"
                          label={t('downloadPDFDetailed')}
                          style={{ marginRight: '10px', marginTop: '10px', width: 'auto' }}
                          onClick={() => createPdf(2)}
                          className="btn-download"
                        />
                        <Button
                          id='button-return'
                          type="button"
                          icon="pi pi-arrow-left"
                          label={t('return')}
                          style={{ marginRight: '10px', marginTop: '10px', width: 'auto' }}
                          onClick={cancel}
                          className="btn-back"
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default EditProforma;
