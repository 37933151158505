import { axiosInstance } from '../config';
import { getUserSession, getHeaders } from '../authentication';

export const login = (user) => {
  return axiosInstance.post('users/login', user);
};

export const getAllUsers = () => {
  const config = getHeaders(getUserSession());
  return axiosInstance.get('users/list',config);
};

export const userCreate = (user) => {
  const config = getHeaders(getUserSession());
  return axiosInstance.post('user',user,config);
};

export const userUpdate = (user) => {
  const config = getHeaders(getUserSession());
  return axiosInstance.put('users/update',user,config);
};

export const recoveryPassword = (user) => {
  return axiosInstance.post('users/recoveryPassword', user);
};
