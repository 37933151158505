import { axiosInstance } from '../config';
import { getUserSession, getHeaders, getHeadersFile } from '../authentication';

export const getProductDetailsByProductId = (id) => {
  const config = getHeaders(getUserSession());
  return axiosInstance.get('productDetails/byProductId/' + id, config);
};

export const getProductDetailByBoxNameWithType = (type) => {
  const config = getHeaders(getUserSession());
  return axiosInstance.get('productDetails/byBoxNameWithType/' + type, config);
};

export const productDetailCreate = (productDetail) => {
  const bodyFormData = new FormData();
  bodyFormData.set('extension', productDetail.url.name ?? productDetail.name);
  bodyFormData.set('name', productDetail.name);
  bodyFormData.set('id', productDetail.id);
  bodyFormData.set('description', productDetail.description);
  bodyFormData.set('idProduct', productDetail.idProduct);
  bodyFormData.set('url', productDetail.url);
  const config = getHeadersFile(getUserSession());
  return axiosInstance.post('productDetail', bodyFormData, config);
};

export const productDetailUpdate = (productDetail) => {
  const bodyFormData = new FormData();
  bodyFormData.set('name', productDetail.name);
  bodyFormData.set('id', productDetail.id);
  bodyFormData.set('description', productDetail.description);
  const config = getHeadersFile(getUserSession());
  return axiosInstance.put('productDetails/update', bodyFormData, config);
};

export const productDetailDelete = (productDetailId) => {
  const config = getHeaders(getUserSession());
  return axiosInstance.delete('productDetails/' + productDetailId, config);
};
