import React from 'react';

function AppTopbar(props) {
  const { onToggleMenu } = props;
  return  (
    <div className="layout-topbar clearfix">
      <button className="p-link layout-menu-button" onClick={() => onToggleMenu()}>
        <span className="pi pi-bars"/>
      </button>
      <div className="layout-topbar-icons">
      </div>
    </div>
  );
}

export default AppTopbar;
