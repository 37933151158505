import { axiosInstance } from '../config';
import { getUserSession, getHeaders } from '../authentication';

export const getPapers = () => {
  const config = getHeaders(getUserSession());
  return axiosInstance.get('catalogPapers/list',config);
};

export const getPapersByTest = (id) => {
  const config = getHeaders(getUserSession());
  return axiosInstance.get('catalogPapers/byTestId/' + id,config);
};

export const paperByTestCreate = (paper) => {
  const config = getHeaders(getUserSession());
  return axiosInstance.post('paperByTest',paper,config);
};

