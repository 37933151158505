import { routes } from './routes';
import { getUserSession } from '../services/authentication';

const sellsAgentMenu = [
  {
    label: 'Proformas',
    icon: 'pi pi-fw pi-file-o',
    items: [{
      label: 'Listado',
      icon: 'pi pi-fw pi-bars',
      command: () => window.location = routes.Proformas
    }]
  },
  {
    label: 'Clientes',
    icon: 'pi pi-fw pi-users',
    items: [{
      label: 'Listado',
      icon: 'pi pi-fw pi-bars',
      command: () => window.location = routes.Clients
    }]
  },
  {
    label: 'Prospectos',
    icon: 'pi pi-fw pi-users',
    items: [{
      label: 'Listado',
      icon: 'pi pi-fw pi-bars',
      command: () => window.location = routes.Prospects
    }]
  },
  {
    label: 'Órdenes de compra',
    icon: 'pi pi-fw pi-dollar',
    items: [{
      label: 'Listado',
      icon: 'pi pi-fw pi-bars',
      command: () => window.location = routes.PurchaseOrders
    }]
  },
  {
    label: 'Productos',
    icon: 'pi pi-fw pi-tag',
    items: [{
      label: 'Listado',
      icon: 'pi pi-fw pi-bars',
      command: () => window.location = routes.Products
    }]
  }
];

const completeMenu = [
  {
    label: 'Usuarios',
    icon: 'pi pi-fw pi-users',
    items: [{
      label: 'Listado',
      icon: 'pi pi-fw pi-bars',
      command: () => window.location = routes.Users
    }]
  },
  {
    label: 'Proformas',
    icon: 'pi pi-fw pi-file-o',
    items: [{
      label: 'Listado',
      icon: 'pi pi-fw pi-bars',
      command: () => window.location = routes.Proformas
    }]
  },
  {
    label: 'Órdenes de compra',
    icon: 'pi pi-fw pi-dollar',
    items: [{
      label: 'Listado',
      icon: 'pi pi-fw pi-bars',
      command: () => window.location = routes.PurchaseOrders
    }]
  },
  {
    label: 'Productos',
    icon: 'pi pi-fw pi-tag',
    items: [{
      label: 'Listado',
      icon: 'pi pi-fw pi-bars',
      command: () => window.location = routes.Products
    }]
  },
  {
    label: 'Clientes',
    icon: 'pi pi-fw pi-users',
    items: [{
      label: 'Listado',
      icon: 'pi pi-fw pi-bars',
      command: () => window.location = routes.Clients
    }]
  },
  {
    label: 'Prospectos',
    icon: 'pi pi-fw pi-users',
    items: [{
      label: 'Listado',
      icon: 'pi pi-fw pi-bars',
      command: () => window.location = routes.Prospects
    }]
  },
  {
    label: 'Tests',
    icon: 'pi pi-fw pi-align-center',
    items: [{
      label: 'Listado',
      icon: 'pi pi-fw pi-bars',
      command: () => window.location = routes.Tests
    }]
  },
  {
    label: 'Actualización de variables',
    icon: 'pi pi-fw pi-pencil',
    command: () => window.location = routes.Variables
  },
  {
    label: 'Procesos de importación',
    icon: 'pi pi-fw pi-upload',
    items: [{
      label: 'Exactus',
      icon: 'pi pi-fw pi-file-excel',
      command: () => window.location = routes.Exactus
    }, {
      label: 'Access',
      icon: 'pi pi-fw pi-file-excel',
      command: () => window.location = routes.Access
    }]
  }
];

const productDesignManagerMenu = [
  {
    label: 'Productos',
    icon: 'pi pi-fw pi-tag',
    items: [{
      label: 'Listado',
      icon: 'pi pi-fw pi-bars',
      command: () => window.location = routes.Products
    }]
  },
  {
    label: 'Tests',
    icon: 'pi pi-fw pi-align-center',
    items: [{
      label: 'Listado',
      icon: 'pi pi-fw pi-bars',
      command: () => window.location = routes.Tests
    }]
  },
  {
    label: 'Procesos de importación',
    icon: 'pi pi-fw pi-upload',
    items: [{
      label: 'Exactus',
      icon: 'pi pi-fw pi-file-excel',
      command: () => window.location = routes.Exactus
    }, {
      label: 'Access',
      icon: 'pi pi-fw pi-file-excel',
      command: () => window.location = routes.Access
    }]
  }
];

const clientMenu = [
  {
    label: 'Proformas',
    icon: 'pi pi-fw pi-file-o',
    items: [{
      label: 'Listado',
      icon: 'pi pi-fw pi-bars',
      command: () => window.location = routes.Proformas
    }]
  },
  {
    label: 'Órdenes de compra',
    icon: 'pi pi-fw pi-dollar',
    items: [{
      label: 'Listado',
      icon: 'pi pi-fw pi-bars',
      command: () => window.location = routes.PurchaseOrders
    }]
  }];

export const menu = () => {
  const userInSession = getUserSession();
  switch (userInSession.user.codeRole) {
  case 'EV':
    return sellsAgentMenu;
  case 'GV':
    return completeMenu;
  case 'DO':
    return completeMenu;
  case 'JCS':
    return completeMenu;
  case 'EDP':
    return productDesignManagerMenu;
  case 'CL':
    return clientMenu;
  default:
    break;
  }
};
