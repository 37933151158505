import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { useTranslation } from 'react-i18next';
import { getPapers, paperByTestCreate } from '../../../services/catalogPaper/catalogPaper';
import { testCreate } from '../../../services/tests/tests';
import { messageError, messageSuccess } from '../../../utils/messages';
import { routes } from '../../../utils/routes';
import './testRegister.css';

function TestRegister({ history }) {
  const { t } = useTranslation();
  const [testName, setTestName] = useState('');
  const [testCombination, setTestCombination] = useState('');
  const [testWeight, setTestWeight] = useState('');
  const [testPrice, setTestPrice] = useState(0);
  const [testIsAuthorized, setTestIsAuthorized] = useState(false);
  const [testWall, setTestWall] = useState('');
  const [papers, setPapers] = useState([]);
  const [selectedPapers, setSelectedPapers] = useState([]);
  const [inactive, setInactive] = useState(false);

  const walls = [
    { label: t('simple'), value: 1 },
    { label: t('double'), value: 2 }
  ];

  useEffect(() => {
    getPapers().then(result => {
      setPapers(result.data);
    }).catch(error => {
      (error.response) ? messageError(error.response.data.message) : messageError(t('withoutConnection'));
    });
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (selectedPapers.length > 0) {
      event.preventDefault();
      const test = {
        name: testName,
        combination: testCombination,
        weight: testWeight,
        wall: testWall.value,
        price: testPrice,
        isAuthorized: testIsAuthorized,
        active: Boolean(!inactive)
      };
      testCreate(test).then(result => {
        submitPapersByTest(result.data.id);
      }).catch(error => {
        (error.response) ? messageError(error.response.data.message) : messageError(t('messageErrorTestCreated'));
      });
    } else {
      return messageError(t('missingPaper'));
    }
  };

  const submitPapersByTest = (id) => {
    let paperByTest;
    selectedPapers.forEach((paper) => {
      paperByTest = {
        idTest: id,
        idPaper: paper.id
      };
      paperByTestCreate(paperByTest).catch(error => {
        (error.response) ? messageError(error.response.data.message) : messageError(t('messageErrorTestCreated'));
        return;
      });
    });
    messageSuccess(t('messageSuccessTestCreated'));
    history.push(routes.Tests);
  };

  const cancel = () => {
    history.push(routes.Tests);
  };

  return (
    <div>
      <div className="ui-g ui-fluid" >
        <div className="ui-g-12">
          <div className="card-user">
            <div className="card">
              <div className="content-section introduction">
                <h1>{t('testRegister')}</h1>
                <form autoComplete="off" onSubmit={handleSubmit}>
                  <div className="p-grid">
                    <div className="p-col-4 p-md-4 p-lg-2"><h5>{t('name')}</h5></div>
                    <div className="p-col-8 p-md-8 p-lg-10">
                      <InputText required id='name' value={testName} className="input-medium-row"
                        placeholder={t('name')} onChange={(event) => setTestName(event.target.value)} />
                    </div>
                    <div className="p-col-4 p-md-4 p-lg-2"><h5>{t('combination')}</h5></div>
                    <div className="p-col-8 p-md-8 p-lg-10">
                      <InputText required id='combination' value={testCombination}
                        placeholder={t('combination')} className="input-medium-row"
                        onChange={(event) => setTestCombination(event.target.value)} />
                    </div>
                    <div className="p-col-4 p-md-4 p-lg-2"><h5>{t('testWeight')}</h5></div>
                    <div className="p-col-8 p-md-8 p-lg-10">
                      <InputText required id='weight' value={testWeight} keyfilter="pnum"
                        placeholder={t('testWeight')} className="input-medium-row"
                        onChange={(event) => setTestWeight(event.target.value)} />
                    </div>
                    <div className="p-col-4 p-md-4 p-lg-2"><h5>{t('price')}</h5></div>
                    <div className="p-col-8 p-md-8 p-lg-10">
                      <InputText required id='price' value={testPrice} keyfilter="pnum"
                        placeholder={t('price')} className="input-medium-row"
                        onChange={(event) => setTestPrice(event.target.value)} />
                    </div>
                    <div className="p-col-4 p-md-4 p-lg-2"><h5>{t('flaute')}</h5></div>
                    <div className="p-col-8 p-md-8 p-lg-10">
                      <Dropdown placeholder={t('flaute')} required id="wall"
                        style={{ marginTop: '15px' }} className="input-medium-row"
                        onChange={(e) => setTestWall(e.value)} optionLabel="label"
                        options={walls} value={testWall} />
                    </div>
                    <div className="p-col-4 p-md-4 p-lg-2"><h5>{t('paper')}</h5></div>
                    <div className="p-col-8 p-md-8 p-lg-10">
                      <MultiSelect placeholder={t('paper')} id="papers"
                        style={{ marginTop: '15px' }} className="input-medium-row"
                        onChange={(e) => setSelectedPapers(e.value)} optionLabel="name"
                        options={papers} value={selectedPapers} />
                    </div>
                    <div className="p-col-4 p-md-4 p-lg-2"><h5>{t('isAuthorized')}</h5></div>
                    <div className="p-col-8 p-md-8 p-lg-10">
                      <Checkbox id="isAuthorized" style={{ marginTop: '15px' }}
                        onChange={(e) => setTestIsAuthorized(e.checked)}
                        checked={Boolean(testIsAuthorized)} />
                    </div>
                    <div className="p-col-4 p-md-4 p-lg-2"><h5>{t('inactive')}</h5></div>
                    <div className="p-col-8 p-md-8 p-lg-10">
                      <Checkbox id="active" style={{ marginTop: '15px' }}
                        onChange={(e) => setInactive(e.checked)}
                        checked={Boolean(inactive)} />
                    </div>
                  </div>
                  <div className="ui-button-group">
                    <Button id='button-save' icon="pi pi-save" label={t('save')}
                      style={{ marginTop: '10px', width: 'auto' }} />
                    <Button id='button-return' type="button" icon="pi pi-times"
                      label={t('cancel')} style={{ margin: '10px', width: 'auto' }}
                      onClick={cancel} />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default TestRegister;
