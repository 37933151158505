import { axiosInstance } from '../config';
import { getUserSession, getHeaders } from '../authentication';

export const getCountrySubdivisions3ByCountrySubdivisions2Id = (id) => {
  const config = getHeaders(getUserSession());
  return axiosInstance.get('countrySubdivisions3/byCountrySubdivisions2Id/' + id,config);
};

export const getCountrySubdivisions3ById = (id) => {
  const config = getHeaders(getUserSession());
  return axiosInstance.get('countrySubdivisions3/byId/' + id,config);
};
