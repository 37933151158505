import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { useTranslation } from 'react-i18next';
import { getAllRoles } from '../../../services/rol/rol';
import { userUpdate } from '../../../services/user/user';
import { messageError, messageSuccess, messageConfirm } from '../../../utils/messages';
import { routes } from '../../../utils/routes';
import './userEdit.css';

function UserEdit({ history }) {
  const { t } = useTranslation();
  const [roles, setRoles] = useState([]);
  const [userFirstName, setFirstName] = useState('');
  const [userLastName, setLastName] = useState('');
  const [userEmail, setEmail] = useState('');
  const [userId, setId] = useState('');
  const [selectedRol, setSelectedRol] = useState({});
  const [readOnly, setReadOnly] = useState();

  useEffect(() => {
    getAllRoles().then(result => {
      setRoles(result.data);
    }).catch(error => {
      (error.response) ? messageError(error.response.data.message) : messageError(t('withoutConnection'));
    });
    setId(history.location.state.userUpdate.id);
    setFirstName(history.location.state.userUpdate.firstName);
    setLastName(history.location.state.userUpdate.lastName);
    setEmail(history.location.state.userUpdate.email);
    const rol = {
      id: history.location.state.userUpdate.idRole,
      name: history.location.state.userUpdate.nameRole,
      code: history.location.state.userUpdate.codeRole
    };
    setSelectedRol(rol);
    const readOn = history.location.state.userUpdate.active === 1 ? false : true;
    setReadOnly(readOn);
  }, [history.location.state.userUpdate, t]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const user = {
      id: userId,
      email: userEmail,
      firstName: userFirstName,
      lastName: userLastName,
      idRole: selectedRol.id
    };
    userUpdate(user).then(() => {
      messageSuccess(t('messagesSuccessUserUpdated'));
      history.push(routes.Users);
    }).catch(error => {
      (error.response) ? messageError(error.response.data.message) : messageError(t('messageErrorUserUpdated'));
    });
  };

  const handleDelete = async () => {
    const confirm = await messageConfirm(t('userDeactivate'));
    if (confirm) {
      const user = {
        id: userId,
        email: userEmail,
        active: false
      };
      userUpdate(user).then(() => {
        messageSuccess(t('messagesSuccessUserDeactivated'));
        history.push(routes.Users);
      }).catch(error => {
        (error.response) ? messageError(error.response.data.message) : messageError(t('messagesErrorUserUpdated'));
      });
    }
  };

  const handleActivate = async () => {
    const confirm = await messageConfirm(t('userActivate'));
    if (confirm) {
      const user = {
        id: userId,
        email: userEmail,
        active: true
      };
      userUpdate(user).then(() => {
        messageSuccess(t('messagesSuccessUserUpdated'));
        history.push(routes.Users);
      }).catch(error => {
        (error.response) ? messageError(error.response.data.message) : messageError(t('messagesErrorUserUpdated'));
      });
    }
  };

  const cancel = () => {
    history.push(routes.Users);
  };

  return (
    <div>
      <div className="ui-g ui-fluid" >
        <div className="ui-g-12">
          <div className="card-user">
            <div className="card">
              <div className="content-section introduction">
                <h1>{t('userEdit')}</h1>
                <form onSubmit={handleSubmit}>
                  <h4 className="label-gray">{t('firstName')}</h4>
                  <InputText required className="input" value={userFirstName}
                    onChange={(event) => { setFirstName((event.target.value)); }} readOnly={readOnly} />
                  <h4 className="label-gray">{t('lastName')}</h4>
                  <InputText required className="input" value={userLastName}
                    onChange={(event) => { setLastName((event.target.value)); }} readOnly={readOnly} />
                  <h4 className="label-gray">{t('email')}</h4>
                  <InputText required className="input" value={userEmail}
                    onChange={(event) => { setEmail((event.target.value)); }} readOnly={readOnly} />
                  <h4 className="label-gray">{t('rol')}</h4>
                  <Dropdown required id="dropdown" className="input" optionLabel="name"
                    optionValue="id" value={selectedRol} onChange={(e) => { setSelectedRol(e.value); }}
                    placeholder={t('selectRol')} options={roles} disabled={readOnly} />
                  <div className="ui-button-group">
                    {!readOnly && <Button icon="pi pi-save" label={t('save')}
                      style={{ marginTop: '10px', width: 'auto' }} />}
                    {!readOnly && <Button id='button-delete' type="button"
                      icon="pi pi-trash" label={t('deactivate')}
                      style={{ marginLeft: '10px', width: 'auto' }}
                      onClick={handleDelete} />}
                    {readOnly && <Button id='button-activate'
                      type="button" icon="pi pi-user-plus" label={t('activate')}
                      style={{ marginLeft: '10px', width: 'auto' }}
                      onClick={handleActivate} />}
                    <Button id='button-cancel' icon="pi pi-times" label={t('cancel')}
                      style={{ margin: '10px', width: 'auto' }} onClick={cancel} />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default UserEdit;
