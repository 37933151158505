import React, {  useState } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { useTranslation } from 'react-i18next';
import { clientCreate } from '../../../services/client/client';
import { messageError, messageSuccess } from '../../../utils/messages';
import { routes } from '../../../utils/routes';
import './clientRegister.css';

function ClientRegister ({ history }) {
  const { t } = useTranslation();
  const [clientName, setClientName] = useState('');
  const [clientAlias, setClientAlias] = useState('');
  const [clientEmail, setClientEmail] = useState('');
  const [clientPhone, setClientPhone] = useState('');
  const [clientFax, setClientFax] = useState('');
  const [clientContactName, setClientContactName] = useState('');
  const [clientContactLastName, setClientContactLastName] = useState('');
  const [clientPosition, setClientPosition] = useState('');
  const [clientIva, setClientIva] = useState('');
  const [clientFreeTransport, setClientFreeTransport] = useState('');
  const [clientSeeArts, setClientSeeArts] = useState('');
  const [clientLegalId, setClientLegalId] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    let iva;
    (clientIva === '') ? iva = 13 : iva = clientIva;
    const client = {
      legalId: clientLegalId,
      name: clientName,
      alias: clientAlias,
      email: clientEmail,
      phone: clientPhone,
      fax: clientFax,
      contactFirstName: clientContactName,
      contactLastName: clientContactLastName,
      position: clientPosition,
      active: true,
      iva: Number(Number(iva) / 100).toFixed(2),
      freeTransport: Boolean(clientFreeTransport),
      seeArts: Boolean(clientSeeArts),
      isProspect: 0
    };
    clientCreate(client).then(() => {
      messageSuccess(t('messageSuccessClientCreated'));
      history.push(routes.Clients);
    }).catch(error => {
      (error.response) ? messageError(error.response.data.message) : messageError(t('messageErrorClientCreated'));
    });
  };

  const cancel = () => {
    history.push(routes.Clients);
  };

  return (
    <div>
      <div className="ui-g ui-fluid" >
        <div className="ui-g-12">
          <div className="card-user">
            <div className="card">
              <div className="content-section introduction">
                <h1>{t('clientRegister')}</h1>
                <form autoComplete="off" onSubmit={handleSubmit}>
                  <div className="p-grid">
                    <div className="p-col-4 p-md-4 p-lg-2"><h5>{t('companyName')}</h5></div>
                    <div className="p-col-8 p-md-8 p-lg-10">
                      <InputText
                        required
                        id='name'
                        value={clientName}
                        placeholder={t('companyName')}
                        className="input"
                        onChange={(event) => setClientName((event.target.value))}
                      />
                    </div>
                    <div className="p-col-4 p-md-4 p-lg-2"><h5>{t('legalId')}</h5></div>
                    <div className="p-col-8 p-md-8 p-lg-10">
                      <InputText
                        id='legalId'
                        value={clientLegalId}
                        placeholder={t('legalId')}
                        className="input"
                        onChange={(event) => setClientLegalId((event.target.value))}
                      />
                    </div>
                    <div className="p-col-4 p-md-4 p-lg-2"><h5>{t('alias')}</h5></div>
                    <div className="p-col-8 p-md-8 p-lg-10">
                      <InputText
                        required
                        id='alias'
                        value={clientAlias}
                        placeholder={t('alias')}
                        className="input"
                        onChange={(event) => setClientAlias((event.target.value))}
                      />
                    </div>
                    <div className="p-col-4 p-md-4 p-lg-2"><h5>{t('email')}</h5></div>
                    <div className="p-col-8 p-md-8 p-lg-10">
                      <InputText
                        required
                        id='email'
                        value={clientEmail}
                        placeholder={t('email')}
                        className="input"
                        onChange={(event) => setClientEmail((event.target.value))}
                      />
                    </div>
                    <div className="p-col-4 p-md-4 p-lg-2"><h5>{t('contactName')}</h5></div>
                    <div className="p-col-8 p-md-8 p-lg-4">
                      <InputText
                        id='contactName'
                        value={clientContactName}
                        placeholder={t('contactName')}
                        className="input"
                        onChange={(event) => setClientContactName((event.target.value))}
                      />
                    </div>
                    <div className="p-col-4 p-md-4 p-lg-2"><h5>{t('contactLastName')}</h5></div>
                    <div className="p-col-8 p-md-8 p-lg-4">
                      <InputText
                        id='contactLastName'
                        value={clientContactLastName}
                        placeholder={t('contactLastName')}
                        className="input"
                        onChange={(event) => setClientContactLastName((event.target.value))}
                      />
                    </div>
                    <div className="p-col-4 p-md-4 p-lg-2"><h5>{t('phone')}</h5></div>
                    <div className="p-col-8 p-md-8 p-lg-4">
                      <InputText
                        id='phone'
                        value={clientPhone}
                        placeholder={t('phone')}
                        className="input"
                        onChange={(event) => setClientPhone((event.target.value))}
                      />
                    </div>
                    <div className="p-col-4 p-md-4 p-lg-2"><h5>{t('fax')}</h5></div>
                    <div className="p-col-8 p-md-8 p-lg-4">
                      <InputText
                        id='fax'
                        value={clientFax}
                        placeholder={t('fax')}
                        className="input"
                        onChange={(event) => setClientFax((event.target.value))}
                      />
                    </div>
                    <div className="p-col-4 p-md-4 p-lg-2"><h5>{t('position')}</h5></div>
                    <div className="p-col-8 p-md-8 p-lg-10">
                      <InputText
                        id='position'
                        value={clientPosition}
                        placeholder={t('position')}
                        className="input-phone"
                        onChange={(event) => setClientPosition((event.target.value))}
                      />
                    </div>
                    <div className="p-col-4 p-md-4 p-lg-2"><h5>{t('iva')} (%)</h5></div>
                    <div className="p-col-8 p-md-8 p-lg-10">
                      <InputText
                        keyfilter="pnum"
                        id='iva'
                        value={clientIva}
                        placeholder={t('iva')}
                        className="input-phone"
                        onChange={(event) => setClientIva((event.target.value))}
                      />
                    </div>
                    <div className="p-col-4 p-md-4 p-lg-2"><h5>{t('freeTransport')}</h5></div>
                    <div className="p-col-8 p-md-8 p-lg-10">
                      <Checkbox
                        id="freeTransport"
                        style={{marginTop: '15px'}}
                        onChange={(e) => setClientFreeTransport(e.checked)}
                        checked={Boolean(clientFreeTransport)}
                      />
                    </div>
                    <div className="p-col-4 p-md-4 p-lg-2"><h5>{t('seeArts')}</h5></div>
                    <div className="p-col-8 p-md-8 p-lg-10">
                      <Checkbox
                        id="seeArts"
                        style={{marginTop: '15px'}}
                        onChange={(e) => setClientSeeArts(e.checked)}
                        checked={Boolean(clientSeeArts)}
                      />
                    </div>
                  </div>
                  <div className="ui-button-group">
                    <Button
                      id='button-save'
                      icon="pi pi-save"
                      label={t('save')}
                      style={{ marginTop: '10px', width: 'auto' }}
                    />
                    <Button
                      id='button-return'
                      type="button"
                      icon="pi pi-times"
                      label={t('cancel')}
                      style={{ margin: '10px', width: 'auto' }}
                      onClick={cancel}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ClientRegister;
