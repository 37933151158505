import React, {  useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { routes } from '../../utils/routes';
import { login } from '../../services/user/user';
import { setUserSession } from '../../services/authentication';
import './login.css';
import { messageError } from '../../utils/messages';
import {
  SALES_MANAGER_CODE,
  SUPER_USER_CODE,
  CLIENT_CODE,
  QUALITY_MANAGER_CODE
} from '../../utils/constantsUser';

function Login ({history}) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { t } = useTranslation();
  const today = new Date();
  const header = <>
    <header className="header-card">
      <img alt="Card" className="logo" src='assets/images/LogoAltaVista_Login.png'/>
      <p className="welcome">{t('welcome')}</p>
      <p className="title-login">{t('titleLogin')}</p>
    </header>
  </>;

  const handleSubmit = (event) => {
    event.preventDefault();
    const userL = {
      email: email,
      password: password
    };
    login(userL).then(response  => {
      response.data.user.superUser = false;
      if (response.data.user.codeRole === SUPER_USER_CODE) {
        response.data.user.codeRole = SALES_MANAGER_CODE;
        response.data.user.superUser = true;
      }
      setUserSession(response.data);
      if (response.data.user.changePassword === '1') {
        history.push(routes.ChangePassword);
      } else if (response.data.user.codeRole === QUALITY_MANAGER_CODE) {
        history.push(routes.Products);
      } else if (response.data.user.codeRole === CLIENT_CODE) {
        history.push(routes.PurchaseOrders);
      } else {
        history.push(routes.Proformas);
      }
    }).catch(error => {
      if (error.response) {
        messageError(error.response.data.message);
      }
      else {
        messageError(t('withoutConnection'));
      }
    });
  };

  const recoveryPassword = () => {
    history.push(routes.ForgotPassword);
  };

  return (
    <div className="login-container">
      <div className="login-right">
        <div className="wrap-card">
          <Card className="card-login" header={header} >
            <form onSubmit={handleSubmit} >
              <div className="row-input">
                <span className="p-float-label">
                  <InputText id="in" type="email" required={true} value={email}  className="all-width input-login"
                    onChange={(event) => setEmail(event.target.value)} />
                  <label htmlFor="in">{t('email')}</label>
                </span>
              </div>
              <div className="row-input input-password">
                <span className="p-float-label">
                  <Password id="pass"required={true} value={password}
                    onChange={(event) => setPassword(event.target.value)}
                    feedback={false}  className="all-width" />
                  <label htmlFor="pass">{t('password')}</label>
                </span>
              </div>
              <Button type="button" className="button-link" label={t('forgotPassword')} onClick={recoveryPassword} />
              <Button label={t('login')} className="all-width btn-submit" />
            </form>
          </Card>
          <div>
            <div className="item-logo">
              <img alt="Card" className="logo-colinas" src='assets/images/LogoAltavista_Black.png' width="200" height="200"/>
            </div>
          </div>
          <div className="footer-login">©Copyright {today.getFullYear()} {t('footer')}</div>
        </div>
      </div>
      <div className="login-left"></div>
    </div>
  );

}

export default Login;
