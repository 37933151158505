import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import AppMenu from '../../appMenu/appMenu';
import AppFooter from '../../appFooter/appFooter';
import AppProfile from '../../appProfile/appProfile';
import AppTopBar from '../../appTopbar/appTopbar';
import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import '../../../../layout/layout.scss';
import { menu } from '../../../../utils/menu';

function AdminLayout (props) {
  const [layoutMode] = useState('static');
  const [layoutColorMode] = useState('dark');
  const [staticMenuInactive, setStaticMenuInactive] = useState(false);
  const [overlayMenuActive, setOverlayMenuActive] = useState(false);
  const [mobileMenuActive, setMobileMenuActive] = useState(false);
  const [menuF] = useState(menu);
  const { children } = props;
  const onToggleMenu = () => {

    if (isDesktop()) {
      if (layoutMode === 'overlay') {
        setOverlayMenuActive(!overlayMenuActive);
      }
      else if (layoutMode === 'static') {
        setStaticMenuInactive(!staticMenuInactive);
      }
    }
    else {
      setMobileMenuActive(!mobileMenuActive);
    }
  };

  const onMenuItemClick = (event) => {
    if (!event.item.items) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }
  };

  const addClass = (element, className)  => {
    if (element.classList)
    {element.classList.add(className);}
    else
    {element.className += ' ' + className;}
  };

  const removeClass = (element, className) => {
    (element.classList)
      ? element.classList.remove(className)
      : element.className = element.className
        .replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
  };

  const isDesktop = () => {
    return window.innerWidth > 1024;
  };

  useEffect(() => {
    if (mobileMenuActive)
    {addClass(document.body, 'body-overflow-hidden');}
    else
    {removeClass(document.body, 'body-overflow-hidden');}
  },[mobileMenuActive]);


  const logo = layoutColorMode === 'dark' ? 'assets/images/LogoAltaVista.png' : 'assets/layout/images/logo.svg';

  const wrapperClass = classNames('layout-wrapper', {
    'layout-overlay': layoutMode === 'overlay',
    'layout-static': layoutMode === 'static',
    'layout-static-sidebar-inactive': staticMenuInactive && layoutMode === 'static',
    'layout-overlay-sidebar-active': overlayMenuActive && layoutMode === 'overlay',
    'layout-mobile-sidebar-active': mobileMenuActive
  });

  const sidebarClassName = classNames('layout-sidebar', {
    'layout-sidebar-dark': layoutColorMode === 'dark',
    'layout-sidebar-light': layoutColorMode === 'light'
  });
  return (
    <div className={wrapperClass} >
      <AppTopBar onToggleMenu={onToggleMenu}/>
      <div  className={sidebarClassName} >
        <div className="layout-logo">
          <img alt="Logo" className="width-all" src={logo} />
        </div>
        <AppProfile {...props} />
        <AppMenu model={menuF} onMenuItemClick={onMenuItemClick} />
      </div>
      <div className="layout-main">
        {children}
      </div>
      <AppFooter />
      <div className="layout-mask"></div>
    </div>
  );

}

export default AdminLayout;
