import React, {  useState } from 'react';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';
import { routes } from '../../utils/routes';
import { messageError, messageSuccess } from '../../utils/messages';
import { userUpdate } from '../../services/user/user';
import { getUserSession } from '../../services/authentication';

function ChangePassword ({history}) {
  const [password, setPassword] = useState('');
  const { t } = useTranslation();

  const handleSubmit = (event) => {
    event.preventDefault();
    const userSession = getUserSession().user;
    const userL = {
      id: userSession.id,
      email: userSession.email,
      password: password
    };
    userUpdate(userL).then( () => {
      setPassword('');
      messageSuccess(t('passwordUpdate'));
      if (getUserSession().user.codeRole === 'JCS'){
        history.push(routes.Products);
      } else if (getUserSession().user.codeRole === 'CL'){
        history.push(routes.PurchaseOrders);
      } else {
        history.push(routes.Proformas);
      }
    }).catch(error => {
      if (error.response) {
        messageError(error.response.data.message);
      }
      else {
        messageError(t('withoutConnection'));
      }
    });
  };

  return (
    <div>
      <div className="p-g p-fluid" >
        <div className="p-g-12">
          <div className="card-user">
            <div className="card">
              <div className="content-section introduction">
                <h1>{t('changePassword')}</h1>
                <form onSubmit={handleSubmit} >
                  <h4 className="label-gray">{t('password')}</h4>
                  <Password
                    className="input"
                    required
                    value={password}
                    feedback={false}
                    onChange={(event) => {setPassword((event.target.value));}}
                  />
                  <div className="ui-button-group">
                    <Button icon="pi pi-save" label={t('save')} style={{ marginTop: '10px', width: 'auto' }} />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

}

export default ChangePassword;
