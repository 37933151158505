import { axiosInstance } from '../config';
import { getUserSession, getHeaders } from '../authentication';

export const importExactus = (excel) => {
  const bodyFormData = new FormData();
  bodyFormData.set('file', excel);
  const config = getHeaders(getUserSession());
  return axiosInstance.post('importProcesses/exactus',bodyFormData,config);
};

export const importAccess = (excel) => {
  const bodyFormData = new FormData();
  bodyFormData.set('file', excel);
  const config = getHeaders(getUserSession());
  return axiosInstance.post('importProcesses/access',bodyFormData,config);
};
