import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Chips } from 'primereact/chips';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { AutoComplete } from 'primereact/autocomplete';
import { getClientsByKey } from '../../../services/client/client';
import { getProductsByParams } from '../../../services/product/product';
import { messageError } from '../../../utils/messages';
import { getProductStorage, cleanProduct } from '../../../utils/cleanLocalStore';
import { routes } from '../../../utils/routes';
import { getUserSession } from '../../../services/authentication';
import './list.css';
import { getProductDetailsByProductId } from '../../../services/productDetail/productDetail';
import CheckClose from '../checkClose/checkClose';
import {
  SALES_MANAGER_CODE,
  OPERATIONS_DIRECTOR_CODE,
  QUALITY_MANAGER_CODE
} from '../../../utils/constantsUser';
import NewVersion from '../newVersion/newVersion';
import { Checkbox } from 'primereact/checkbox';

function ProductList({ history }) {
  const { t } = useTranslation();
  const [clients, setClients] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState([t('activeProducts')]);
  const [loading, setLoading] = useState(false);
  const [showData, setShowData] = useState(false);
  const [displayDialog, setDisplayDialog] = useState(false);
  const [displayAttachementsDialog, setDisplayAttachementsDialog] = useState(false);
  const [selectedProductDetails, setSelectedProductDetails] = useState([]);
  const [first, setFirst] = useState(0);
  const [load, setLoad] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [params, setParams] = useState({
    code: '',
    idClient: '',
    version: '',
    name: '',
    type: '',
    internalHeight: '',
    internalWidth: '',
    internalLong: '',
    search: '',
    active: true
  });

  const filters = [
    { label: t('product'), value: '0' },
    { label: t('sample'), value: '1' }
  ];

  const dialogAttachements = () => {
    return (
      <Dialog id='attachementsDialog' blockScroll visible={displayAttachementsDialog} header={t('attachedReferences')}
        onHide={() => { setDisplayAttachementsDialog(false); setSelectedProductDetails([]); }}>
        <div>
          <DataTable emptyMessage={t('emptyList')} value={selectedProductDetails}>
            <Column field="name" header={t('name')} />
            <Column field="description" header={t('description')} />
            <Column className="downloadColumn" body={download} header={t('download')} />
          </DataTable>
        </div>
      </Dialog>
    );
  };

  const download = (row) => {
    return (
      <a download={row.name} href={row.url} target="_blank" rel="noopener noreferrer">
        <i className="pi pi-download" />
      </a>
    );
  };

  const actionEdit = (rowData) => {
    return <div className='wrap-btn-edit' id={'div-' + rowData.id}>
      <Button
        onClick={() => updateProduct(rowData)}
        type="button"
        icon={`pi ${rowData.isClose ? 'pi-eye' : 'pi-pencil'}`}
        id='button-edit'
        className="p-button-primary"
        title={rowData.isClose ? t('readOnly') : t('edit')}
      >
        <span className="tooltiptext tooltiptext-bottom"></span>
      </Button>
      {rowData.isClose === 1 &&
        <NewVersion
          history={history}
          productCode={rowData.code}
          showText={false}
        />
      }
    </div>;
  };

  const getAttachedReferences = (rowData) => {
    getProductDetailsByProductId(rowData.id).then((data) => {
      setSelectedProductDetails(data.data);
    });
    setDisplayAttachementsDialog(true);
  };

  const actionDownloadFiles = (rowData) => {
    return <div style={{ content: 'center', textAlign: 'center' }} id={'div-' + rowData.id}>
      <Button
        onClick={() => getAttachedReferences(rowData)}
        type="button"
        icon="pi pi-file"
        id='button-download'
        className="p-button-primary"
      >
        <span className="tooltiptext tooltiptext-bottom"></span>
      </Button>
    </div>;
  };

  const productClosedColumn = (rowData) => {
    if (getUserSession().user.superUser) {
      return <CheckClose
        row={rowData}
        searchProducts={() => searchProducts()}
      />;
    } else if (rowData.isClose === 1) {
      return <div style={{ content: 'center', textAlign: 'center' }}>
        <i style={{ content: 'center', textAlign: 'center' }} className='pi pi-check'></i>
      </div>;
    }
  };

  const updateProduct = (product) => {
    history.push({
      pathname: routes.ProductEdit,
      state: { productUpdate: product },
      newVersion: false
    });
  };

  const onPageLoad = async (event) => {
    const { first: _first } = event;
    setFirst(_first);
    setLoad(true);
  };

  useEffect(() => {
    if (load) {
      setLoading(true);
      setLoad(false);
      const parameters = {
        ...params,
        page: first
      };
      delete parameters.search;
      getProductsByParams(parameters).then(result => {
        setLoading(false);
        setProducts(result.data);
        setTotalRecords(result.data[0].total ?? result.data.length);
        setShowData(true);
      }).catch(error => {
        setLoading(false);
        if (error.response) {
          setShowData(true);
          setProducts([]);
          messageError(error.response.data.message);
        }
        else {
          messageError(t('withoutConnection'));
        }
      });
    }
  }, [load, params, first, t]);

  useEffect(() => {
    const {
      paramsProduct,
      selectedClientPT,
      selectedFilterPT
    } = getProductStorage();
    if (selectedClientPT !== '') {
      setSelectedClient(selectedClientPT);
    }
    if (selectedFilterPT.length > 0) {
      setSelectedFilter(selectedFilterPT);
    }
    setParams(paramsProduct);
    if (paramsProduct.search) {
      searchProducts(paramsProduct);
    }
  }, []);

  const searchProducts = (searchProducts = '') => {
    const postParamts = searchProducts !== '' ? { ...searchProducts } : { ...params };
    localStorage.setItem('paramsProduct', JSON.stringify({ ...postParamts, search: true, active: true }));
    setLoading(true);
    setFirst(0);
    const parameters = {
      ...postParamts,
      page: 0
    };
    delete parameters.search;
    getProductsByParams(parameters).then(result => {
      setLoading(false);
      setProducts(result.data);
      setTotalRecords(result.data[0].total);
      setShowData(true);
    }).catch(error => {
      cleanProduct();
      setLoading(false);
      if (error.response) {
        setShowData(true);
        setProducts([]);
        messageError(error.response.data.message);
      }
      else {
        messageError(t('withoutConnection'));
      }
    });
  };

  const handleClientOnChange = (e) => {
    getClientsByKey(e.query).then(result => {
      setClients(result.data);
    });
  };

  const clean = () => {
    setSelectedClient('');
    setParams({
      code: '',
      idClient: '',
      version: '',
      name: '',
      type: '',
      internalHeight: '',
      internalWidth: '',
      internalLong: '',
      active: true
    });
    setFirst(0);
    setSelectedFilter([t('activeProducts')]);
    cleanProduct();
  };

  const getDate = (rowData) => {
    const date = rowData.approvalDate;
    if (date) {
      let d = new Date(date),
        day = '' + d.getDate(),
        month = '' + (d.getMonth() + 1),
        year = d.getFullYear();
      if (month.length < 2) { month = '0' + month; }
      if (day.length < 2) { day = '0' + day; }
      return [day, month, year].join('/');
    }
    return t('noApproved');
  };

  const getClientName = (rowData) => {
    return rowData.client?.name ?? t('noClient');
  };

  const showIsException = (rowData) => {
    if (rowData.isException === 1) {
      return <div style={{ content: 'center', textAlign: 'center' }}>
        <i style={{ content: 'center', textAlign: 'center' }} className='pi pi-check'></i>
      </div>;
    }
  };

  const showState = (rowData) => {
    switch (rowData.active) {
    case 1:
      return t('active');
    case 0:
      return t('inactive');
    default:
      return t('inactive');
    }
  };

  const addProduct = () => {
    history.push(routes.ProductRegister);
  };

  const dialog = () => {
    return (
      <Dialog
        id='filter-dialog'
        visible={displayDialog}
        footer={dialogFooter}
        header={t('searchCriteria')}
        modal={true}
        onHide={() => setDisplayDialog(false)}
      >
        <div className="p-grid p-fluid">
          <div className="p-col-4 p-md-2 p-lg-1">
            <h5>{t('client')}</h5>
          </div>
          <div className="p-col-8 p-md-10 p-lg-7">
            <AutoComplete
              id='client'
              value={selectedClient}
              field="name"
              onSelect={(e) => {
                setSelectedClient(e.value);
                localStorage.setItem('selectedClientPT', JSON.stringify(e.value));
                setParams({ ...params, idClient: e.value.id });
              }}
              suggestions={clients}
              onChange={(e) => setSelectedClient(e.value)}
              completeMethod={(e) => handleClientOnChange(e)}
              className="dropdown-all-width"
            />
          </div>
          <div className="p-col-4 p-md-2 p-lg-1">
            <h5>{t('activeProducts')}</h5>
          </div>
          <div className="p-col-8 p-md-10 p-lg-3">
            <Checkbox
              id="activeProducts"
              onChange={() => { setParams({ ...params, active: !params.active }); }}
              checked={Boolean(params.active)}
            />
          </div>
          <div className="p-col-4 p-md-2 p-lg-1">
            <h5>{t('name')}</h5>
          </div>
          <div className="p-col-8 p-md-10 p-lg-11">
            <InputText id='name' value={params.name} className="dropdown-all-width"
              onChange={(event) => { setParams({ ...params, name: event.target.value }); }} />
          </div>
          <div className="p-col-4 p-md-2 p-lg-1">
            <h5>{t('code')}</h5>
          </div>
          <div className="p-col-8 p-md-10 p-lg-3">
            <InputText id='code' value={params.code} className="dropdown-all-width"
              onChange={(event) => { setParams({ ...params, code: event.target.value }); }} />
          </div>
          <div className="p-col-4 p-md-2 p-lg-1">
            <h5>{t('version')}</h5>
          </div>
          <div className="p-col-8 p-md-10 p-lg-3">
            <InputText id='version' value={params.version} className="dropdown-all-width"
              onChange={(event) => setParams({ ...params, version: event.target.value })} />
          </div>
          <div className="p-col-4 p-md-2 p-lg-1">
            <h5>{t('type')}</h5>
          </div>
          <div className="p-col-8 p-md-10 p-lg-3">
            <Dropdown
              value={filters[params.isSample]}
              id="isSample"
              className="input"
              optionLabel="label"
              optionValue="value"
              onChange={(e) => setParams({ ...params, isSample: e.value.value })}
              options={filters}
            />
          </div>
          <div className="p-col-4 p-md-2 p-lg-1">
            <h5 id='internalLong'>{t('internalLong')} (mm)</h5>
          </div>
          <div className="p-col-8 p-md-10 p-lg-3">
            <InputText
              keyfilter="pnum"
              id='internalLong'
              value={params.internalLong}
              className="dropdown-all-width"
              onChange={(event) => setParams({ ...params, internalLong: event.target.value })}
              tooltipOptions={{ event: 'focus' }}
              tooltip={t('lessPlus10')}
            />
          </div>
          <div className="p-col-4 p-md-2 p-lg-1">
            <h5 id='internalWidth'>{t('internalWidth')} (mm)</h5>
          </div>
          <div className="p-col-8 p-md-10 p-lg-3">
            <InputText
              keyfilter="pnum"
              id='internalWidth'
              value={params.internalWidth}
              className="dropdown-all-width"
              onChange={(event) => setParams({ ...params, internalWidth: event.target.value })}
              tooltipOptions={{ event: 'focus' }}
              tooltip={t('lessPlus10')}
            />
          </div>
          <div className="p-col-4 p-md-2 p-lg-1">
            <h5 id='internalHeight' >{t('internalHeight')} (mm)</h5>
          </div>
          <div className="p-col-8 p-md-10 p-lg-3">
            <InputText
              keyfilter="pnum"
              id='internalHeight'
              value={params.internalHeight}
              className="dropdown-all-width"
              onChange={(event) => setParams({ ...params, internalHeight: event.target.value })}
              tooltipOptions={{ event: 'focus' }}
              tooltip={t('lessPlus10')}
            />
          </div>
        </div>
      </Dialog>
    );
  };

  const saveFilters = () => {
    let filters = [];
    setDisplayDialog(false);
    setFirst(0);
    delete params.search;
    for (const key in params) {
      if (key === 'idClient') {
        if (params[key] !== '' && selectedFilter.indexOf(selectedClient.name) === -1) {
          filters.push(selectedClient.name);
        }
      }
      if (key === 'active') {
        if (params[key] !== false && selectedFilter.indexOf(params[key]) === -1) {
          filters.push(t('activeProducts'));
        }
      }
      else if (key === 'isSample') {
        if (params[key] !== '' && selectedFilter.indexOf(filters[params[key]].label) === -1) {
          filters.push(filters[params[key]].label);
        }
      } else {
        if (params[key] !== '' && selectedFilter.indexOf(params[key]) === -1) {
          filters.push(params[key]);
        }
      }
    }
    setSelectedFilter(filters);
    localStorage.setItem('selectedFilterPT', JSON.stringify(filters));
  };

  let dialogFooter = <div className="ui-dialog-buttonpane p-clearfix">
    <Button id="button-save-detail" label={t('save')} icon="pi pi-check" onClick={saveFilters} />
  </div>;

  return (
    <div>
      <div className="ui-g ui-fluid" >
        <div className="ui-g-12">
          <div className="card">
            <h1>{t('productList')}</h1>
            <div className="ui-g-12 ui-md-7 ui-lg-2">
              <div className="p-grid p-fluid">
                <div className="p-col-5 p-md-5">
                  <div className="p-inputgroup">
                    <Button
                      id="button-display-dialog"
                      onClick={() => setDisplayDialog(true)}
                      icon="pi pi-filter"
                      style={{ marginTop: '10px' }}
                      label={t('filters')}
                      className="p-button-warning"
                    />
                    <Chips className="p-inputgroup" style={{ width: '100%' }} disabled value={selectedFilter} />
                  </div>
                </div>
                <div className="p-col-6 p-md-6">
                  <div className="p-inputgroup-button">
                    <Button
                      icon="pi pi-search"
                      onClick={() => searchProducts()}
                      label={t('search')}
                      style={{ width: 'auto' }}
                      className="tooltip button-table"
                    />
                    <Button
                      icon="pi pi-refresh"
                      onClick={clean}
                      label={t('clean')}
                      style={{ width: 'auto' }}
                      className="tooltip button-table"
                    />
                    <Button
                      icon="pi pi-plus"
                      label={t('newProduct')}
                      onClick={addProduct}
                      style={{ width: 'auto' }}
                      className="tooltip button-table"
                    />
                  </div>
                </div>
              </div>
            </div>
            {dialogAttachements()}
            {dialog()}
            <div className="ui-g-12">
              {loading &&
                <div id="div-loader" className="spiner-container">
                  <i className="pi pi-spin pi-spinner spiner" />
                </div>
              }
              {!loading && showData &&
                <DataTable
                  paginator={true}
                  emptyMessage={t('emptyList')}
                  rows={10}
                  responsive={true}
                  value={products}
                  lazy
                  totalRecords={totalRecords}
                  first={first}
                  onPage={onPageLoad}
                  loading={loading}
                >
                  <Column
                    sortable
                    sortField={getClientName}
                    className="ui-column"
                    body={getClientName}
                    header={t('client')}
                  />
                  <Column sortable className="ui-column" field="name" header={t('name')} />
                  <Column sortable className="ui-column-code" field="code" header={t('code')} />
                  <Column sortable className="ui-column-version" field="version" header={t('version')} />
                  <Column sortable sortField={getDate} className="ui-column-date" body={getDate} header={t('approvalDate')}/>
                  {(getUserSession().user.codeRole === SALES_MANAGER_CODE
                    || getUserSession().user.codeRole === OPERATIONS_DIRECTOR_CODE
                    || getUserSession().user.codeRole === QUALITY_MANAGER_CODE) &&
                    <Column className="ui-column-exception" body={showIsException} header={t('isException')}/>}
                  <Column className="ui-column-state" field="active" header={t('state')} body={showState}/>
                  <Column className="ui-column-files column-center" header={t('productClosed')} body={productClosedColumn} />
                  <Column className="ui-column-files" header={t('attachedReferences')} body={actionDownloadFiles} />
                  <Column className="ui-column-edit" header={t('actions')} body={actionEdit} />
                </DataTable>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductList;
