import React from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

function AppSubMenu(props) {
  const { className, items, root, onMenuItemClick } = props;
  const [activeIndex, setActiveIndex] = React.useState(null);

  const onMenuItemClickR = (event, item, index) => {
    //avoid processing disabled items
    if (item.disabled) {
      event.preventDefault();
      return true;
    }
    //execute command
    if (item.command) {
      item.command({originalEvent: event, item: item});
    }

    if (index === activeIndex)
    {setActiveIndex(null);}
    else
    {setActiveIndex(index);}

    if (onMenuItemClick) {
      onMenuItemClick({
        originalEvent: event,
        item: item
      });
    }
  };

  const renderLinkContent = (item)=> {
    let submenuIcon = item.items && <i className="pi pi-fw pi-angle-down menuitem-toggle-icon"></i>;
    let badge = item.badge && <span className="menuitem-badge">{item.badge}</span>;

    return (
      <React.Fragment>
        <i className={item.icon}></i>
        <span>{item.label}</span>
        {submenuIcon}
        {badge}
      </React.Fragment>
    );
  };

  const renderLink = (item, i) => {
    let content = renderLinkContent(item);

    if (item.to) {
      return (
        <NavLink
          activeClassName="active-route"
          to={item.to}
          onClick={(e) => onMenuItemClickR(e, item, i)}
          exact
          target={item.target}
        >
          {content}
        </NavLink>
      );
    }

    return (
      <a href={item.url} onClick={(e) => onMenuItemClickR(e, item, i)} target={item.target}>
        {content}
      </a>
    );


  };
  const itemsCreate = (itemsR) => {
    let itemsF = itemsR && itemsR.map((item, i) => {
      let active = activeIndex === i;
      let styleClass = classNames(item.badgeStyleClass, {'active-menuitem': active && !item.to});
      return (
        <li className={styleClass} key={i}>
          {item.items && root === true && <div className='arrow'></div>}
          {renderLink(item, i)}
          {
            item.items && <ul className={className}> {itemsCreate(item.items)} </ul>
          }
        </li>
      );
    });
    return itemsF;
  };
  return  (
    items ? <ul className={className}> {itemsCreate(items)} </ul> : null
  );
}

export default AppSubMenu;
