import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputText } from 'primereact/inputtext';
import { AutoComplete } from 'primereact/autocomplete';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Column } from 'primereact/column';
import { currencyFormatter } from '../../../utils/currency';
import { routes } from '../../../utils/routes';
import { getProductsByKeyAndClientId } from '../../../services/product/product';
import { getClientsByKey, getClientById } from '../../../services/client/client';
import { purchaseOrderUpdate } from '../../../services/purchaseOrderHeader/purchaseOrderHeader';
import {
  getPurchaseOrderDetailsByPurchaseOrderHeaderId,
  purchaseOrderDetailCreate,
  purchaseOrderDetailUpdate,
  purchaseOrderDetailDelete
} from '../../../services/purchaseOrderDetail/purchaseOrderDetail';
import { getUserSession } from '../../../services/authentication';
import { messageError, messageSuccess } from '../../../utils/messages';

const EditPurchaseOrder = ({ history }) => {
  const { t } = useTranslation();
  const [selectedClient, setSelectedClient] = useState('');
  const [clients, setClients] = useState([]);
  const [products, setProducts] = useState([]);
  const [id, setId] = useState('');
  const [status, setStatus] = useState('');
  const [observations, setObservations] = useState('');
  const [isRendered, setIsRendered] = useState(false);
  const [newDetails, setNewDetails] = useState([]);
  const [updatedDetails, setUpdatedDetails] = useState([]);
  const [deletedDetails] = useState([]);
  const [purchaseOrderDetails, setPurchaseOrderDetails] = useState([]);
  const [purchaseOrderDetail, setPurchaseOrderDetail] = useState({});
  const [attachedReferences, setAttachedReferences] = useState([]);
  const [selectedPurchaseOrderDetail, setSelectedPurchaseOrderDetail] = useState({});
  const [newPurchaseOrderDetail, setNewPurchaseOrderDetail] = useState(true);
  const [displayDialog, setDisplayDialog] = useState(false);
  const [displayArts, setDisplayArts] = useState(false);

  //destructuring
  const { codeRole } = getUserSession().user;

  useEffect(() => {
    if (getUserSession().user.codeRole === 'CL') {
      getClientById(getUserSession().user.idClient).then(result => {
        setSelectedClient(result.data[0]);
      });
    } else {
      getClientById(history.location.state.purchaseOrderUpdate.idClient).then(result => {
        setSelectedClient(result.data[0]);
      });
    }
    getPurchaseOrderDetailsByPurchaseOrderHeaderId(history.location.state.purchaseOrderUpdate.id).then(result => {
      setPurchaseOrderDetails(result.data);
      setIsRendered(true);
    }).catch(error => {
      setIsRendered(true);
      /* istanbul ignore else */
      if (!error.response) {
        messageError(t('withoutConnection'));
      }
    });
    setId(history.location.state.purchaseOrderUpdate.id);
    setObservations(history.location.state.purchaseOrderUpdate.observations);
    setStatus(history.location.state.purchaseOrderUpdate.status);
  }, [history.location.state]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (purchaseOrderDetails.length === 0) {
      messageError(t('missingProduct'));
    } else {
      validateProduct(purchaseOrderDetails).then(value => {
        if (value) {
          const purchaseOrder = {
            id: id,
            status: 0,
            observations: observations,
            idClient: selectedClient.id
          };
          purchaseOrderUpdate(purchaseOrder).then(() => {
            handlePurchaseOrderDetailSubmit();
          }).catch(error => {
            (error.response)
              ? messageError(error.response.data.message)
              : messageError(t('messageErrorPurchaseOrderUpdated'));
          });
        } else {
          messageError(t('missingProduct'));
        }
      });
    }
  };

  const validateProduct = async (row) => {
    let value = true;
    row.forEach((detail) => {
      if (detail.product.id === undefined) {
        value = false;
      }
    });
    return value;
  };

  const handlePurchaseOrderDetailSubmit = async () => {
    let poDetail;
    newDetails.forEach((detail) => {
      poDetail = {
        idPurchaseOrderHeaders: id,
        quantity: Number(detail.quantity)
      };
      if (detail.product.idProforma !== undefined) {
        poDetail = { ...poDetail, idProformaDetail: detail.product.id };
      } else {
        poDetail = { ...poDetail, idProduct: detail.product.id };
      }
      purchaseOrderDetailCreate(poDetail).catch(error => {
        (error.response) ? messageError(error.response.data.message) : messageError(t('messageErrorPurchaseOrderCreated'));
        return;
      });
    });
    updatedDetails.forEach((detail) => {
      poDetail = {
        id: detail.id,
        idPurchaseOrderHeaders: id,
        quantity: Number(detail.quantity)
      };
      if (detail.product.idProforma !== undefined) {
        poDetail = { ...poDetail, idProformaDetail: detail.product.id };
      } else {
        poDetail = { ...poDetail, idProduct: detail.product.id };
      }
      purchaseOrderDetailUpdate(poDetail).catch(error => {
        (error.response) ? messageError(error.response.data.message) : messageError(t('messageErrorPurchaseOrderCreated'));
        return;
      });
    });
    deletedDetails.forEach((detail) => {
      purchaseOrderDetailDelete(detail.id).catch(error => {
        (error.response) ? messageError(error.response.data.message) : messageError(t('messageErrorPurchaseOrderCreated'));
        return;
      });
    });
    messageSuccess(t('messageSuccessPurchaseOrderUpdated'));
    history.push(routes.PurchaseOrders);
  };

  const cancel = () => {
    history.push(routes.PurchaseOrders);
  };

  const handleClientOnChange = (e) => {
    getClientsByKey(e.query).then(result => {
      setClients(result.data);
    });
  };

  const handleProductOnChange = (e) => {
    getProductsByKeyAndClientId(e.query, selectedClient.id).then(result => {
      setProducts(result.data);
    });
  };

  const approvePurchaseOrder = (e) => {
    e.preventDefault();
    const purchaseOrder = {
      id: id,
      status: 1,
      observations: observations,
      idClient: selectedClient.id
    };
    purchaseOrderUpdate(purchaseOrder).then(() => {
      messageSuccess(t('messageSuccessPurchaseOrderUpdated'));
      history.push(routes.PurchaseOrders);
    }).catch(error => {
      (error.response) ? messageError(error.response.data.message) : messageError(t('messageErrorPurchaseOrderUpdated'));
    });
  };

  const rejectPurchaseOrder = (e) => {
    e.preventDefault();
    const purchaseOrder = {
      id: id,
      status: 3,
      observations: observations,
      idClient: selectedClient.id
    };
    purchaseOrderUpdate(purchaseOrder).then(() => {
      messageSuccess(t('messageSuccessPurchaseOrderUpdated'));
      history.push(routes.PurchaseOrders);
    }).catch(error => {
      (error.response) ? messageError(error.response.data.message) : messageError(t('messageErrorPurchaseOrderUpdated'));
    });
  };

  const save = (e) => {
    e.preventDefault();
    if (purchaseOrderDetail.product && purchaseOrderDetail.quantity !== '') {
      let details = [...purchaseOrderDetails];
      if (newPurchaseOrderDetail) { details.push(purchaseOrderDetail); }
      else { details[findSelectedDetailIndex(selectedPurchaseOrderDetail)] = purchaseOrderDetail; }
      setPurchaseOrderDetails(details);
      (purchaseOrderDetail.id) ? updatedDetails.push(purchaseOrderDetail) : newDetails.push(purchaseOrderDetail);
      setPurchaseOrderDetail({ quantity: '' });
      setDisplayDialog(false);
    } else {
      return messageError(t('missingFields'));
    }
  };

  const deleteDetail = (row) => {
    let index = findSelectedDetailIndex(row);
    setPurchaseOrderDetails(purchaseOrderDetails.filter((val, i) => i !== index));
    if (row.id) {
      index = updatedDetails.indexOf(row);
      setUpdatedDetails(updatedDetails.filter((val, i) => i !== index));
      deletedDetails.push(row);
    } else {
      index = newDetails.indexOf(row);
      setNewDetails(newDetails.filter((val, i) => i !== index));
    }
  };

  const findSelectedDetailIndex = (row) => {
    return purchaseOrderDetails.indexOf(row);
  };

  const onPurchaseOrderDetailselect = (row) => {
    setNewPurchaseOrderDetail(false);
    setDisplayDialog(true);
    setSelectedPurchaseOrderDetail(row);
    setPurchaseOrderDetail(row);
  };

  const onShowProductDetailsSelect = (row) => {
    setDisplayArts(true);
    if (row.product.productDetail !== undefined) {
      selectAttachedReferences(row.product.productDetail);
    }
  };

  const selectAttachedReferences = (row) => {
    if (codeRole === 'CL') {
      row.forEach((detail) => {
        let url = (detail.url.name) ? detail.url.objectURL : detail.url;
        let index = findSelectedDetailIndex(detail);

        if (url.split('.')[4] === 'xlsx') {
          index = row.indexOf(detail);
          setAttachedReferences(row.filter((val, i) => i !== index));
        }
      });
    } else {
      setAttachedReferences(row);
    }
  };

  const addNew = (e) => {
    e.preventDefault();
    if (selectedClient) {
      setNewPurchaseOrderDetail(true);
      setDisplayDialog(true);
      setPurchaseOrderDetail({});
    } else {
      messageError(t('missingClient'));
    }
  };

  const actions = (row) => {
    return (
      <>
        {status === 0 && <>
          <Button disabled={status > 0} icon="pi pi-pencil"
            id="button edit" style={{ margin: '3px' }} className="margin-button"
            onClick={(e) => { e.preventDefault(); onPurchaseOrderDetailselect(row); }}
          />
          <Button disabled={status > 0} icon="pi pi-times"
            id="button-remove" style={{ margin: '3px' }}
            className="margin-button" onClick={(e) => { e.preventDefault(); deleteDetail(row); }}
          />
        </>}

        {((codeRole === 'CL' && selectedClient.seeArts === 1) || codeRole === 'GV') &&
          <Button icon="pi pi-eye" id="button" title={t('showAttachedReferences')}
            style={{ margin: '3px' }} className="margin-button"
            onClick={(e) => { e.preventDefault(); onShowProductDetailsSelect(row); }}
          />
        }
      </>
    );
  };

  const actionsProductDetail = (row) => {
    let url;
    (row.url.name) ? url = row.url.objectURL : url = row.url;
    return (
      <>
        <a href={url} download>
          <i style={{ marginRight: '3px', marginTop: '3px' }} className="pi pi-download" />
        </a>
      </>
    );
  };

  const roundTotal = (rowData) => {
    return currencyFormatter.format(Number(Math.round(rowData.product.unitPrice * 100) / 100).toFixed(2));
  };

  let footer = <div className="p-clearfix" style={{ width: '100%' }}>
    {status === 0 && <Button disabled={status > 0}
      style={{ float: 'right' }} label={t('add')}
      icon="pi pi-plus" onClick={addNew} />}
  </div>;

  let dialogFooter = <div className="ui-dialog-buttonpane p-clearfix">
    <Button label={t('save')} icon="pi pi-check" onClick={save} />
  </div>;

  return (
    <div>
      <div className="ui-g ui-fluid" >
        <div className="ui-g-12">
          <div className="card-user">
            <div className="card">
              {!isRendered && <i id='loader' className="pi pi-spin pi-spinner"></i>}
              {isRendered && <>
                <div className="content-section introduction">
                  <h1>{t('pucharseOrderEdit')}</h1>
                  <form autoComplete="off" onSubmit={handleSubmit}>
                    <div className="p-grid p-fluid">
                      <div className="p-col-12">
                        <h3 className="label-gray">{t('client')}</h3>
                        {(getUserSession().user.codeRole === 'CL' || Number(status) > 0)
                          && <InputText value={selectedClient.name} disabled />}
                        {getUserSession().user.codeRole !== 'CL'
                          && Number(status) === 0
                          && <AutoComplete
                            style={{ width: '100%' }}
                            id='client'
                            required
                            field="name"
                            value={selectedClient}
                            onChange={(e) => setSelectedClient(e.value)}
                            onSelect={(e) => { setSelectedClient(e.value); }}
                            placeholder={t('selectClient')}
                            suggestions={clients}
                            completeMethod={(e) => handleClientOnChange(e)}
                          />}
                      </div>
                      <div className="p-col-12 p-md-12 p-lg-12">
                        <h3>{t('purchaseOrderDetails')}</h3>
                      </div>
                    </div>
                    <div className="content-section implementation">
                      <DataTable value={purchaseOrderDetails} rows={15} footer={footer}>
                        <Column field="product.code" header={t('code')} />
                        <Column field="product.name" header={t('name')} />
                        <Column body={roundTotal} header={t('unitPrice')} />
                        <Column field="quantity" header={t('quantity')} />
                        <Column style={{ textAlign: 'center' }} body={actions} header={t('actions')} />
                      </DataTable>
                      <Dialog visible={displayDialog}
                        footer={dialogFooter} header={t('purchaseOrderDetail')}
                        modal={true} onHide={() => setDisplayDialog(false)}>
                        {
                          purchaseOrderDetail &&
                          <div className="p-grid p-fluid">
                            <div className="p-col-4" style={{ padding: '.75em' }}>
                              <label htmlFor="name">{t('name')}</label>
                            </div>
                            <div className="p-col-8" style={{ padding: '.5em' }}>
                              <AutoComplete style={{ width: '100%' }} id='product'
                                field="name" value={purchaseOrderDetail.product}
                                onChange={(e) => setPurchaseOrderDetail({ ...purchaseOrderDetail, product: e.value })}
                                onSelect={(e) => setPurchaseOrderDetail({ ...purchaseOrderDetail, product: e.value })}
                                suggestions={products} completeMethod={(e) => handleProductOnChange(e)} />
                            </div>
                            <div className="p-col-4" style={{ padding: '.75em' }}>
                              <label htmlFor="quantity">{t('quantity')}</label>
                            </div>
                            <div className="p-col-8" style={{ padding: '.5em' }}>
                              <InputText id="quantity" keyfilter="pnum"
                                onChange={(e) => {
                                  setPurchaseOrderDetail({ ...purchaseOrderDetail, quantity: e.target.value });
                                }}
                                value={purchaseOrderDetail.quantity} />
                            </div>
                          </div>
                        }
                      </Dialog>
                      <Dialog visible={displayArts} header={t('attachedReferences')}
                        modal={true} onHide={() => setDisplayArts(false)}>
                        {attachedReferences.length > 0 &&
                          <div className="p-grid p-fluid" style={{ maxWidth: '700px' }}>
                            <DataTable value={attachedReferences} rows={15} >
                              <Column field="name" header={t('name')} />
                              <Column field="description" header={t('description')} />
                              <Column style={{ textAlign: 'center' }} body={actionsProductDetail} header={t('actions')} />
                            </DataTable>
                          </div>
                        }
                        {attachedReferences.length === 0 &&
                          <div className="p-grid p-fluid" style={{ minWidth: '700px' }}>
                            <p>{t('emptyAttachedReferences')}</p>
                          </div>
                        }
                      </Dialog>
                    </div>
                    <div className="p-grid p-fluid">
                      <div className="p-col-12">
                        <h3 className="label-gray">{t('observations')}</h3>
                        <InputTextarea disabled={status > 0} style={{ width: '100%' }}
                          id='observations' value={observations}
                          onChange={(e) => setObservations(e.target.value)} placeholder={t('observations')}
                        />
                      </div>
                    </div>
                    <div className="ui-button-group">
                      {Number(status) === 0
                        && <Button id='button-save'
                          icon="pi pi-save"
                          label={t('save')}
                          style={{ marginRight: '10px', marginTop: '10px', width: 'auto' }} />
                      }
                      {(
                        getUserSession().user.codeRole === 'GV'
                        || getUserSession().user.codeRole === 'EV'
                      )
                        && Number(status) === 0
                        && <>
                          <Button id='button-approve'
                            icon="pi pi-check"
                            label={t('approve')}
                            style={{ marginRight: '10px', marginTop: '10px', width: 'auto' }}
                            onClick={approvePurchaseOrder} />
                          <Button id='button-reject'
                            icon="pi pi-times"
                            label={t('reject')}
                            style={{ marginRight: '10px', marginTop: '10px', width: 'auto' }}
                            onClick={rejectPurchaseOrder} />
                        </>}
                      <Button id='button-return'
                        type="button" icon="pi pi-times"
                        label={t('cancel')}
                        style={{ marginRight: '10px', marginTop: '10px', width: 'auto' }}
                        onClick={cancel} />
                    </div>
                  </form>
                </div>
              </>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditPurchaseOrder;
