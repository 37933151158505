import React, {  useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { routes } from '../../utils/routes';
import { recoveryPassword } from '../../services/user/user';
import { messageError, messageSuccess } from '../../utils/messages';

function ForgotPassword ({history}) {
  const [email, setEmail] = useState('');
  const { t } = useTranslation();
  const today = new Date();

  const header = <img alt="Card" className="logo" src='assets/images/LogoAltaVista.png'/>;
  const handleSubmit = (event) => {
    event.preventDefault();
    const userL = {
      email: email
    };
    recoveryPassword(userL).then(()  => {
      messageSuccess(t('recoveryPasswordSuccess'));
      history.push(routes.Login);
    }).catch(error => {
      if (error.response) {
        messageError(error.response.data.message);
      }
      else {
        messageError(t('withoutConnection'));
      }
    });
  };

  const goBack = () => {
    history.push(routes.Login);
  };


  return (
    <div className="image-login">
      <Card className="card-login" header={header} >
        <form onSubmit={handleSubmit} >
          <div className="p-g">
            <div className="p-col-12">
              <div className="p-inputgroup-login">
                <span className="p-float-label">
                  <InputText id="in" type="email" required={true} value={email} className="all-width"
                    onChange={(event) => setEmail(event.target.value)} />
                  <label htmlFor="in">{t('email')}</label>
                </span>
              </div>
            </div>
            <div className="p-col-20"><Button label={t('recoveryPassword')} className="all-width" /></div>
            <div className="p-col-20">
              <Button type='button' className="all-width" label={t('goBack')} onClick={goBack} />
            </div>
          </div>
        </form>
      </Card>
      <div className="footer-login">©Copyright {today.getFullYear()} {t('footer')}</div>
    </div>
  );

}

export default ForgotPassword;
