import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { getUserSession, deleteSession } from '../../../services/authentication';
import { routes } from '../../../utils/routes';

function AppProfile(props) {
  const [expanded, setExpanded] = React.useState(false);
  const { t } = useTranslation();
  const user = getUserSession().user;
  const { history } = props.children.props;

  const onClick = () => {
    setExpanded(!expanded);
  };

  const close = () => {
    deleteSession();
    history.push(routes.Login);
  };
  return  (
    <div className="layout-profile">
      <div>
        <img src="assets/images/user.png" alt="" />
      </div>
      <button className="p-link layout-profile-link" onClick={() => onClick()}>
        <span className="username">{user.firstName}  {user.lastName}</span>
        <i className="pi pi-fw pi-cog"/>
      </button>
      <ul className={classNames({'layout-profile-expanded': expanded})}>
        <li>
          <button className="p-link" onClick={() => close()}>
            <i className="pi pi-fw pi-sign-out"/>
            <span>{t('logout')}</span>
          </button>
        </li>
      </ul>
    </div>
  );
}

export default AppProfile;
