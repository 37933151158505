const es = {
  translation: {
    add: 'Agregar',
    newUser: 'Agregar usuario',
    newProforma: 'Nueva',
    allStates: 'Todos los estados',
    yes: 'Sí',
    no: 'No',
    sure: 'Sí, seguro!',
    footer: 'Corrugados Alta Visa S.A',
    account: 'Perfil',
    logout: 'Cerrar Sesión',
    login: 'Iniciar Sesión',
    email: 'Correo Electrónico',
    password: 'Contraseña',
    withoutConnection: 'No se pudo establecer la conexión',
    transport: 'Transporte',
    id: 'Id',
    firstName: 'Nombre',
    lastName: 'Apellidos',
    active: 'Activo',
    activate: 'Activar',
    inactive: 'Inactivo',
    userActivate: 'activar este usuario',
    clientActivate: 'activar este cliente',
    productActivate: 'activar este producto',
    rol: 'Rol',
    edit: 'Editar',
    search: 'Buscar',
    userList: 'Listado de usuarios',
    userRegister: 'Registrar usuario',
    userEdit: 'Editar usuario',
    userDeactivate: 'desactivar este usuario',
    userDeactivated: 'Usuario desactivado!',
    clientDeactivate: 'desactivar este cliente',
    clientDeactivated: 'Cliente desactivado!',
    productDeactivate: 'desactivar este producto',
    productDeactivated: 'Producto desactivado!',
    proformaList: 'Listado de proformas',
    proformaRegister: 'Registrar proforma',
    proformaEdit: 'Editar proforma',
    proformaDelete: 'eliminar esta proforma',
    proformaDeleted: 'Proforma eliminada!',
    emptyList: 'No existen registros para mostrar',
    empty: 'No se encuentran datos',
    selectRol: 'Seleccione un rol',
    selectClient: 'Seleccione un cliente',
    cancel: 'Cancelar',
    delete: 'Eliminar',
    closeProduct: 'Cerrar producto',
    openProduct: 'Abrir producto',
    productClosed: 'Producto cerrado',
    fieldsRequired: 'Verifique que todos los campos estén llenos',
    messageErrorUserCreated: 'El usuario no pudo ser creado',
    messageErrorUserUpdated: 'El usuario no pudo ser actualizado',
    messageSuccessUserCreated: 'El usuario fue creado exitosamente',
    messagesSuccessUserUpdated: 'El usuario fue actualizado exitosamente',
    messagesSuccessUserDeactivated: 'El usuario fue desactivado exitosamente',
    messagesSuccessClientUpdated: 'El cliente fue actualizado exitosamente',
    messagesSuccessClientDeactivated: 'El cliente fue desactivado exitosamente',
    messagesSuccessProductUpdated: 'El producto fue actualizado exitosamente',
    messagesSuccessProductDeactivated: 'El producto fue desactivado exitosamente',
    messageErrorProformaCreated: 'La proforma no pudo ser creada',
    messageErrorProformaUpdated: 'La proforma no pudo ser actualizada',
    messageSuccessProformaCreated: 'La proforma fue creada exitosamente',
    messagesSuccessProformaUpdated: 'La proforma fue actualizada exitosamente',
    messagesSuccessProformaDeleted: 'La proforma fue eliminada exitosamente',
    messageErrorProductCreated: 'El producto no pudo ser creado',
    messageErrorProductUpdated: 'El producto no pudo ser actualizado',
    messageSuccessProductCreated: 'El producto fue creado exitosamente',
    messageSuccessProductUpdated: 'El producto fue actualizado exitosamente',
    messageSuccessProductNewVersion: 'Una nueva versión del producto fue creada exitosamente',
    messageSuccessProductDeleted: 'El producto fue eliminado exitosamente',
    messageErrorClientCreated: 'El cliente no pudo ser creado',
    messageErrorClientUpdated: 'El cliente no pudo ser actualizado',
    messageSuccessClientCreated: 'El cliente fue creado exitosamente',
    messageSuccessClientUpdated: 'El cliente fue actualizado exitosamente',
    messageErrorTestCreated: 'El test no pudo ser creado',
    messageErrorTestUpdated: 'El test no pudo ser actualizado',
    messageSuccessTestCreated: 'El test fue creado exitosamente',
    messageSuccessTestUpdated: 'El test fue actualizado exitosamente',
    messageErrorPurchaseOrderCreated: 'La orden de compra no pudo ser creada',
    messageErrorPurchaseOrderUpdated: 'La orden de compra no pudo ser actualizada',
    messageSuccessPurchaseOrderCreated: 'La orden de compra fue creada exitosamente',
    messageSuccessPurchaseOrderUpdated: 'La orden de compra fue actualizada exitosamente',
    messageErrorProspectCreated: 'El prospecto no pudo ser creado',
    messageErrorProspectUpdated: 'El prospecto no pudo ser actualizado',
    messageSuccessProspectCreated: 'El prospecto fue creado exitosamente',
    messageSuccessProspectUpdated: 'El prospecto fue actualizado exitosamente',
    messageSuccessVariableUpdated: 'El valor fue actualizado exitosamente',
    messageErrorVariableUpdated: 'El valor no pudo ser actualizado',
    state: 'Estado',
    status: 'Estado',
    close: 'Cerrar',
    client: 'Cliente',
    createdBy: 'Vendedor',
    clean: 'Limpiar',
    date: 'Fecha',
    total: 'Total',
    forgotPassword: '¿Ha olvidado su contraseña?',
    recoveryPassword: 'Recuperar contraseña',
    goBack: 'Regresar',
    recoveryPasswordSuccess: 'La nueva contraseña ha sido enviada al correo electrónico',
    changePassword: 'Cambiar Contraseña',
    save: 'Guardar',
    passwordUpdate: 'Su contraseña ha sido actualizada correctamente',
    pendingApproval: 'Pendiente de aprobación',
    approved: 'Aprobada',
    rejected: 'Rechazada',
    subtotal: 'Subtotal',
    iva: 'I.V.A.',
    discount: 'Descuento',
    discountPercentage: 'Porcentaje',
    discountAmount: 'Monto descontado',
    discountDescription: 'Descripción del descuento',
    comments: 'Comentario',
    thirdProcesses: 'Costo de terceros procesos por unidad',
    amountCliche: 'Costo del cliché por m2',
    amountDieCut: 'Costo del troquel por m2',
    amountOneFaceWaterproofing: 'Costo del impermeabilizante por m2',
    amountTwoFacesWaterproofing: 'Costo del impermeabilizante por m2',
    proformaDetails: 'Detalles de la proforma',
    proformaDetailsLine: 'Detalles de la línea',
    product: 'Producto',
    long: 'Largo',
    width: 'Ancho',
    longInInches: 'Largo en pulgadas',
    widthInInches: 'Ancho en pulgadas',
    height: 'Alto',
    isDieCut: 'Troquel',
    flaute: 'Flauta',
    paper: 'Papel',
    quantity: 'Cantidad',
    externalArea: 'Área externa',
    internalArea: 'Área interna',
    missingFields: 'Verifique que todos los campos se encuentren llenos',
    missingClient: 'Verifique que se haya seleccionado el cliente',
    missingProduct: 'Verifique que se haya seleccionado un producto',
    test: 'Test',
    sendForApproval: 'Guardar y enviar solicitud de aprobación',
    saveAndApprove: 'Guardar y aprobar',
    reopen: 'Reabrir',
    approve: 'Aprobar',
    reject: 'Rechazar',
    sendToClient: 'Enviar al cliente',
    sendedToClient: 'Enviada al cliente',
    return: 'Regresar',
    cost: 'Precio x tc',
    code: 'Código',
    name: 'Nombre',
    version: 'Versión',
    newProduct: 'Nuevo',
    approvalDate: 'Fecha de aprobación',
    noApproved: 'Sin aprobación',
    noClient: 'Sin cliente',
    productList: 'Listado de productos',
    productRegister: 'Registrar producto',
    sampleRegister: 'Registrar muestra',
    productEdit: 'Editar producto',
    sampleEdit: 'Editar muestra',
    productType: 'Tipo de producto',
    boxType: 'Tipo de caja',
    cliche: 'Cliché',
    externalBody: 'Cuerpo externo',
    internalBody: 'Cuerpo interno',
    body: 'Cuerpo',
    flaps: 'Flaps',
    minimalCombination: 'Combinación mínima',
    measurements: 'Medidas',
    weight: 'Peso de la caja (kg)',
    external: 'Externas',
    internal: 'Internas',
    liner1: 'Liner 1',
    medium1: 'Medium 1',
    liner2: 'Liner 2',
    medium2: 'Medium 2',
    liner3: 'Liner 3',
    colors: 'Colores',
    color1: 'Color 1',
    color2: 'Color 2',
    color3: 'Color 3',
    color4: 'Color 4',
    selectProductType: 'Seleccione un tipo',
    selectBox: 'Seleccione un tipo de caja',
    selectTest: 'Seleccione un test',
    selectPaper: 'Seleccione un papel',
    selectFlaute: 'Seleccione un tipo de flauta',
    externalHeight: 'Alto externo',
    externalWidth: 'Ancho externo',
    externalLong: 'Largo externo',
    internalHeight: 'Alto interno',
    internalWidth: 'Ancho interno',
    internalLong: 'Largo interno',
    testWeight: 'Peso de test (g)',
    unitPrice: 'Precio unitario',
    priceXtc: 'Precio por tonelada',
    costs: 'Costos',
    messageErrorRepeatedCode: 'El código ingresado ya se encuentra asignado a otro producto',
    messageErrorRepeatedName: 'El nombre ingresado ya se encuentra asignado a otro detalle',
    sheet: 'Lámina',
    sheetArea: 'Área de la lámina (m2)',
    sheetLong: 'Ancho de la lámina ',
    sheetWidth: 'Largo de la lámina ',
    wastePercentage: 'Porcentaje de desperdicio (%)',
    internalMeasurements: 'Medidas internas',
    sheetMeasurements: 'Medidas de lámina',
    printing: 'Impresión',
    requiredAuthorization: 'Requiere autorización',
    smallerSize: 'Producto contiene un tamaño menor a lo permitido',
    biggerSize: 'Producto contiene un tamaño mayor a lo permitido',
    isException: 'Excepción',
    dieCutNeeded: 'El tamaño de la caja requiere de troquelado',
    thirdProcessesNeeded: 'El tamaño de la caja requiere de terceros procesos',
    waterproofing: 'Impermeabilizante',
    regular: 'Regular',
    cobb: 'Bajo cobb',
    cobbExterior: 'Bajo cobb (Cara externa)',
    oneFace: 'Una cara',
    twoFaces: 'Dos caras',
    productHasCobbWaterproofing: 'El producto lleva impermeabilizante bajo cobb',
    isPaletized: 'Paletizado',
    noCharge: 'No cobrar',
    noChargeOfCliche: 'Se ha solicitado no cobrar el cliché',
    noChargeOfDieCut: 'Se ha solicitado no cobrar el troquelado',
    noSelectedFlaute: 'Producto no tiene Flauta seleccionada',
    noSelectedPaper: 'Producto no tiene Papel seleccionado',
    description: 'Descripción',
    file: 'Archivo',
    choose: 'Seleccionar',
    productDetail: 'Detalle de producto',
    missingFile: 'Verifique que se haya seleccionado un archivo',
    invalidFileSizeMessageSummary: '{0}: Tamaño de archivo inválido, ',
    invalidFileSizeMessageDetail: 'el tamaño máximo es {0}',
    reducedAmountOfDieCut: 'Se ha solicitado un troquel con un costo menor al establecido',
    reducedAmountOfCliche: 'Se ha solicitado un cliché con un costo menor al establecido',
    purchaseOrderList: 'Listado de órdenes de compra',
    alias: 'Alias',
    phone: 'Teléfono',
    clientList: 'Listado de clientes',
    newClient: 'Nuevo',
    isActive: 'Activo',
    clientRegister: 'Registrar cliente',
    clientEdit: 'Editar cliente',
    fax: 'Fax',
    contactName: 'Nombre del contacto',
    contactLastName: 'Apellidos del contacto',
    position: 'Posición',
    testList: 'Listado de tests',
    combination: 'Combinación',
    newTest: 'Nuevo',
    isAuthorized: 'Autorizado',
    price: 'Precio',
    testRegister: 'Registrar test',
    wall: 'Pared',
    simple: 'Sencilla',
    double: 'Doble',
    missingPaper: 'Verifique que se haya seleccionado al menos un papel',
    testEdit: 'Editar test',
    showAttachedReferences: 'Mostrar referencias adjuntas',
    emptyAttachedReferences: 'No existen referencias adjuntas',
    attachedReferences: 'Referencias adjuntas',
    actions: 'Acciones',
    download: 'Descargar',
    downloadPDFDetailed: 'PDF Detallado',
    selectReferences: 'Seleccione las referencias por adjuntar',
    attache: 'Adjuntar',
    freeTransport: 'Exento de transporte',
    seeArts: 'Acceso a ver los artes',
    GuacimoPlant: 'Planta Guácimo',
    CartagoCellar: 'Bodega Cartago',
    countrySubdivision1: 'Provincia',
    countrySubdivision2: 'Cantón',
    countrySubdivision3: 'Distrito',
    exactDirection: 'Dirección exacta',
    transportSize: 'Tipo de transporte',
    smallTruck: 'Camión pequeño',
    mediumTruck: 'Camión mediano',
    bigTruck: 'Furgón',
    transportCost: 'Costo del transporte',
    exactusImport: 'Importación de Exactus',
    accessImport: 'Importación de Access',
    upload: 'Subir',
    messageSuccessUploaded: 'La carga se realizó exitosamente',
    messageWarningUploaded: 'Algunos productos no pudieron ser actualizados exitosamente',
    notUpdatedProducts: 'Productos no actualizados',
    nameOfClient: 'Nombre del cliente',
    gtin: 'GTIN',
    item: 'Artículo',
    invBarcode: 'Código de barras inv',
    grossWeight: 'Peso bruto',
    averageCost: 'Costo promedio',
    averagePrice: 'Precio promedio',
    codeNotFound: 'Código no coincide con ningún producto registrado',
    reason: 'Razón',
    wrongUnitPriceField: 'El precio unitario tiene un formato inválido',
    wrongWeightField: 'El peso tiene un formato inválido',
    wrongPriceByTcField: 'El precio por tonelada presentó un error al calcularse',
    wrongLineProduct: 'La línea presentó inconvenientes al cargarse',
    purchaseOrderRegister: 'Registrar orden de compra',
    pucharseOrderEdit: 'Editar orden de compra',
    observations: 'Observaciones',
    purchaseOrderDetails: 'Detalles de la orden de compra',
    purchaseOrderDetail: 'Detalle de la orden de compra',
    missingTransport: 'Verifique que se haya seleccionado el medio de transporte',
    wrongBctField: 'El bct tiene un formato inválido',
    wrongSheetWidthField: 'El ancho unitario tiene un formato inválido',
    wrongSheetLongField: 'El largo tiene un formato inválido',
    wrongBodyField: 'El cuerpo tiene un formato inválido',
    wrongInternalLongField: 'El largo interno tiene un formato inválido',
    wronginternalWidthField: 'El ancho interno tiene un formato inválido',
    wronginternalHeightField: 'La altura interna tiene un formato inválido',
    filters: 'Filtros',
    searchCriteria: 'Criterios de búsqueda',
    type: 'Tipo',
    prospectList: 'Listado de prospectos',
    newProspect: 'Nuevo',
    prospectRegister: 'Registrar prospecto',
    prospectEdit: 'Editar prospecto',
    companyName: 'Nombre de la empresa',
    legalId: 'Cédula jurídica',
    turnProspectIntoClient: 'Convertir a cliente',
    selectSampleOrProductDialog: 'Seleccione el tipo por registrar',
    sample: 'Muestra',
    next: 'Siguiente',
    turnIntoProduct: 'Convertir a producto',
    insertNewCode: 'Inserte el código del nuevo producto',
    dieCutCost: 'Costo del troquel',
    clicheCost: 'Costo del cliché',
    colorQuantity: 'Cantidad de colores',
    newProductLabel: 'Crear un nuevo producto',
    oldProduct: 'Seleccionar un producto previamente creado',
    continue: 'Continuar',
    terms: 'Términos',
    cash: 'Contado',
    credit: 'Crédito',
    creditTerm: 'Plazo de crédito',
    days: 'días',
    cmMeasure: 'Medida en CM',
    testIsNotAuthorized: 'El test seleccionado no tiene autorización',
    showWarnings: 'Mostrar advertencias',
    someDetailsRequireAuthorization: 'La proforma incluye productos que requieren autorización',
    welcome: 'Bienvenidos',
    titleLogin: 'Sistemas de Autogestión de Corrugados',
    lessPlus10: '± 10mm',
    readOnly: 'Solo lectura',
    deactivate: 'Desactivar',
    variables: 'Variables',
    value: 'Valor',
    doesNotApply: 'No aplica',
    dragndropText: 'Haga click para cargar los archivos',
    'generateNewVersion?': '¿Desea generar una nueva versión?',
    'max Cliche': 'Costo promedio por m2 de cliché por color',
    'max Die cut': 'Costo promedio por m2 de troquel',
    'forth color percentage': 'Cuarto color',
    'One face regular waterproofing': 'Costo por m2 por un lado impermeabilizado regular',
    'Two faces regular waterproofing': 'Costo por m2 por dos lados impermeabilizado regular',
    'One face cobb waterproofing': 'Costo por m2 por un lado impermeabilizado bajo cobb',
    'Two faces cobb waterproofing': 'Costo por m2 por dos lados impermeabilizado bajo cobb',
    'Paletized cost by unit': 'Costo de paletizado por unidad',
    'Transport outside gam small truck': 'Costo flete fuera de GAM en camión pequeño ',
    'Transport outside gam medium truck': 'Costo flete fuera de GAM en camión mediano',
    'Transport outside gam big truck': 'Costo flete fuera de GAM en furgón',
    'Transport gam small truck': 'Costo flete GAM en camión pequeño ',
    'Transport gam medium truck': 'Costo flete GAM en camión mediano ',
    'Transport gam big truck': 'Costo flete GAM en furgón',
    'Minimum number of boxes': 'Cantidad mínima de cajas',
    'Production variable +/- percentage': 'Variable de producción +/-',
    'Waste percentage': 'Desperdicio',
    'Minimun cost by ton kraft one face': 'Costo mínimo por tonelada kraft',
    'Minimum cost by ton white one face': 'Costo mínimo por tonelada blanco una cara',
    'Minimum cost by ton white two faces': 'Costo mínimo por tonelada blanco dos caras',
    selectState: 'Seleccione un estado',
    createNewVersion: 'Crear nueva versión',
    activeProducts: 'Productos activos'
  }
};

export default es;
