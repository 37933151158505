import React from 'react';
import { useTranslation } from 'react-i18next';

function AppFooter() {
  const { t } = useTranslation();
  const today = new Date();
  return  (
    <div className="layout-footer">
      <span className="footer-text" style={{'marginRight': '5px'}}>©Copyright {today.getFullYear()}</span>
      <span className="footer-text" style={{'marginLeft': '5px'}}>{t('footer')}</span>
    </div>
  );
}

export default AppFooter;
