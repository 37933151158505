import { axiosInstance } from '../config';
import { getUserSession, getHeaders } from '../authentication';

export const getCountrySubdivisions2ByCountrySubdivisions1Id = (id) => {
  const config = getHeaders(getUserSession());
  return axiosInstance.get('countrySubdivisions2/byCountrySubdivisions1Id/' + id,config);
};

export const getCountrySubdivisions2ById = (id) => {
  const config = getHeaders(getUserSession());
  return axiosInstance.get('countrySubdivisions2/byId/' + id,config);
};

