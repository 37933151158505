import React, { useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { getAllUsers } from '../../../services/user/user';
import { useTranslation } from 'react-i18next';
import { messageError } from '../../../utils/messages';
import { routes } from '../../../utils/routes';
import './list.css';

function UserList({ history }) {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedState, setselectedState] = React.useState('');
  const [globalFilter, setGlobalFilter] = React.useState('');
  const { t } = useTranslation();
  const filters = [
    { label: t('allStates'), value: '' },
    { label: t('active'), value: '1' },
    { label: t('inactive'), value: '0' }
  ];

  useEffect(() => {
    getAllUsers().then(result => {
      setUsers(result.data);
      setLoading(false);
    }).catch(error => {
      (error.response) ? messageError(error.response.data.message) : messageError(t('withoutConnection'));
    });
  }, [t]);

  const updateUser = (user) => {
    history.push({
      pathname: routes.UserEdit,
      state: { userUpdate: user }
    });
  };

  const actionEdit = (rowData) => {
    return <div style={{ content: 'center', textAlign: 'center' }} id={'div-' + rowData.id}>
      <Button type="button" icon="pi pi-pencil" id={'button-edit-' + rowData.id}
        onClick={() => updateUser(rowData)} className="tooltip button-table">
        <span className="tooltiptext tooltiptext-bottom"></span>
      </Button>
    </div>;
  };

  const showState = (rowData) => {
    switch (rowData.active) {
    case 1:
      return t('active');
    case 0:
      return t('inactive');
    default:
      return t('inactive');
    }
  };

  const addUser = () => {
    history.push(routes.UserRegister);
  };

  return (
    <div>
      <div className="ui-g ui-fluid" >
        <div className="ui-g-12">
          <div className="card">
            <h1>{t('userList')}</h1>
            <div className="content-section implementation">
              <div className="ui-g-12 ui-md-6 ui-lg-3">
                <div className="p-grid p-fluid">
                  <div className="p-col-4 p-md-3">
                    <div className="p-inputgroup">
                      <Dropdown value={selectedState} id="dropdown" className="input"
                        optionLabel="label" optionValue="value" placeholder={t('Búsqueda por estado')}
                        onChange={(e) => { setGlobalFilter(e.value.value); setselectedState(e.value); }} options={filters} />
                    </div>
                  </div>
                  <div className="p-col-9 p-md-4">
                    <div className="p-inputgroup">
                      <InputText id="input-search" className="input"
                        onInput={(e) => { setGlobalFilter(e.target.value); setselectedState(e.value); }}
                        placeholder="Buscar..." />
                    </div>
                  </div>
                  <div className="p-col-2 p-md-4">
                    <div className="p-inputgroup">
                      <Button icon="pi pi-plus" label={t('newUser')}
                        iconPos="left" className="p-button-primary" onClick={addUser} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="ui-g-12">
                <div className="datatable">
                  {loading && <div id="div-loader" className="spiner-container">
                    <i className="pi pi-spin pi-spinner spiner" >
                    </i>
                  </div>}
                  {!loading &&
                    <DataTable paginator={true} emptyMessage={t('emptyList')}
                      globalFilter={globalFilter} rows={10} responsive={true} value={users}>
                      <Column id='td' className="ui-column" field="firstName" header={t('firstName')} />
                      <Column className="ui-column" field="lastName" header={t('lastName')} />
                      <Column className="ui-column-email" field="email" header={t('email')} />
                      <Column className="ui-column" field="nameRole" header={t('rol')} />
                      <Column className="ui-column-state" field="active" header={t('state')} body={showState} />
                      <Column className="ui-column-edit" header={t('edit')} body={actionEdit} />
                    </DataTable>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default UserList;
