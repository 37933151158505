import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputText } from 'primereact/inputtext';
import { AutoComplete } from 'primereact/autocomplete';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Column } from 'primereact/column';
import { routes } from '../../../utils/routes';
import { currencyFormatter } from '../../../utils/currency';
import { getProductsByKeyAndClientId } from '../../../services/product/product';
import { getClientsByKey, getClientById } from '../../../services/client/client';
import { purchaseOrderCreate } from '../../../services/purchaseOrderHeader/purchaseOrderHeader';
import { purchaseOrderDetailCreate } from '../../../services/purchaseOrderDetail/purchaseOrderDetail';
import { getUserSession } from '../../../services/authentication';
import { messageError, messageSuccess } from '../../../utils/messages';

function RegisterPurchaseOrder({ history }) {
  const { t } = useTranslation();
  const [selectedClient, setSelectedClient] = useState('');
  const [clients, setClients] = useState([]);
  const [products, setProducts] = useState([]);
  const [purchaseOrderDetails, setPurchaseOrderDetails] = useState([]);
  const [purchaseOrderDetail, setPurchaseOrderDetail] = useState({});
  const [observations, setObservations] = useState('');
  const [newPurchaseOrderDetail, setNewPurchaseOrderDetail] = useState(true);
  const [displayDialog, setDisplayDialog] = useState(false);
  const [attachedReferences, setAttachedReferences] = useState([]);
  const [displayArts, setDisplayArts] = useState(false);
  const { codeRole } = getUserSession().user;

  useEffect(() => {
    if (getUserSession().user.codeRole === 'CL') {
      getClientById(getUserSession().user.idClient).then(result => {
        setSelectedClient(result.data[0]);
      });
    }
  }, [t]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (purchaseOrderDetails.length === 0) {
      messageError(t('missingProduct'));
    } else {
      validateProduct(purchaseOrderDetails).then(value => {
        if (value) {
          const purchaseOrder = {
            status: 0,
            observations: observations,
            idClient: selectedClient.id
          };
          purchaseOrderCreate(purchaseOrder).then(result => {
            handlePurchaseOrderDetailSubmit(result.data.id);
          }).catch(error => (error.response)
            ? messageError(error.response.data.message)
            : messageError(t('messageErrorPurchaseOrderCreated'))
          );
        } else {
          messageError(t('missingProduct'));
        }
      });
    }
  };

  const validateProduct = async (row) => {
    let value = true;
    row.forEach((detail) => {
      if (detail.product.id === undefined) {
        value = false;
      }
    });
    return value;
  };

  const handlePurchaseOrderDetailSubmit = async (id) => {
    let poDetail;
    purchaseOrderDetails.forEach((detail) => {
      poDetail = {
        idPurchaseOrderHeaders: id,
        quantity: Number(detail.quantity),
        idProduct: detail.product.id
      };
      purchaseOrderDetailCreate(poDetail).catch(error => {
        (error.response) ? messageError(error.response.data.message) : messageError(t('messageErrorPurchaseOrderCreated'));
        return;
      });
    });
    messageSuccess(t('messageSuccessPurchaseOrderCreated'));
    history.push(routes.PurchaseOrders);
  };

  const cancel = () => {
    history.push(routes.PurchaseOrders);
  };

  const handleClientOnChange = (e) => {
    getClientsByKey(e.query).then(result => {
      setClients(result.data);
    });
  };

  const handleProductOnChange = (e) => {
    getProductsByKeyAndClientId(e.query, selectedClient.id).then(result => {
      setProducts(result.data);
    });
  };

  const save = (e) => {
    e.preventDefault();
    if (purchaseOrderDetail.product && purchaseOrderDetail.quantity !== '') {
      let details = [...purchaseOrderDetails];

      if (newPurchaseOrderDetail) { details.push(purchaseOrderDetail); }
      else { details[findSelectedDetailIndex()] = purchaseOrderDetail; }

      setPurchaseOrderDetails(details);
      setPurchaseOrderDetail({ quantity: '' });
      setDisplayDialog(false);
    } else {
      return messageError(t('missingFields'));
    }
  };

  const deleteDetail = (row) => {
    let index = findSelectedDetailIndex(row);
    setPurchaseOrderDetails(purchaseOrderDetails.filter((val, i) => i !== index));
  };

  const findSelectedDetailIndex = (row) => {
    return purchaseOrderDetails.indexOf(row);
  };

  const onPurchaseOrderDetailselect = (row) => {
    setNewPurchaseOrderDetail(false);
    setDisplayDialog(true);
    setPurchaseOrderDetail(row);
  };

  const addNew = (e) => {
    e.preventDefault();
    if (selectedClient) {
      setNewPurchaseOrderDetail(true);
      setDisplayDialog(true);
      setPurchaseOrderDetail({});
    } else {
      messageError(t('missingClient'));
    }
  };

  const actions = (row) => {
    return (
      <>
        <Button icon="pi pi-pencil" id="button-edit" style={{ margin: '3px' }}
          className="margin-button" onClick={(e) => { e.preventDefault(); onPurchaseOrderDetailselect(row); }} />
        <Button icon="pi pi-times" id="button-remove" style={{ margin: '3px' }}
          className="margin-button" onClick={(e) => { e.preventDefault(); deleteDetail(row); }} />
        {((codeRole === 'CL' && selectedClient.seeArts === 1) || codeRole === 'GV') &&
          <Button icon="pi pi-eye" id="button" title={t('showAttachedReferences')}
            style={{ margin: '3px' }} className="margin-button"
            onClick={(e) => { e.preventDefault(); onShowProductDetailsSelect(row); }} />
        }
      </>
    );
  };

  const roundTotal = (rowData) => {
    return currencyFormatter.format(Number(Math.round(rowData.product.unitPrice * 100) / 100).toFixed(2));
  };

  const onShowProductDetailsSelect = (row) => {
    setDisplayArts(true);
    if (row.product.productDetail !== undefined) {
      selectAttachedReferences(row.product.productDetail);
    }
  };

  const selectAttachedReferences = (row) => {
    if (codeRole === 'CL') {
      row.forEach((detail) => {
        let url = (detail.url.name) ? detail.url.objectURL : detail.url;
        let index = findSelectedDetailIndex(detail);

        if (url.split('.')[4] === 'xlsx') {
          index = row.indexOf(detail);
          setAttachedReferences(row.filter((val, i) => i !== index));
        }
      });
    } else {
      setAttachedReferences(row);
    }
  };

  const actionsProductDetail = (row) => {
    let url;
    (row.url.name) ? url = row.url.objectURL : url = row.url;
    return (
      <>
        <a href={url} download>
          <i style={{ marginRight: '3px', marginTop: '3px' }} className="pi pi-download" />
        </a>
      </>
    );
  };

  let footer = <div className="p-clearfix" style={{ width: '100%' }}>
    <Button id="button-show-detail-dialog" style={{ float: 'right' }} label={t('add')} icon="pi pi-plus" onClick={addNew} />
  </div>;

  let dialogFooter = <div className="ui-dialog-buttonpane p-clearfix">
    <Button id="button-save-detail" label={t('save')} icon="pi pi-check" onClick={save} />
  </div>;

  return (
    <div>
      <div className="ui-g ui-fluid" >
        <div className="ui-g-12">
          <div className="card-user">
            <div className="card">
              <div className="content-section introduction">
                <h1>{t('purchaseOrderRegister')}</h1>
                <form autoComplete="off" onSubmit={handleSubmit}>
                  <div className="p-grid p-fluid">
                    <div className="p-col-12">
                      <h3 className="label-gray">{t('client')}</h3>
                      {getUserSession().user.codeRole === 'CL' && <InputText value={selectedClient.name} disabled />}
                      {getUserSession().user.codeRole !== 'CL' &&
                        <AutoComplete style={{ width: '100%' }} id='client' required
                          field="name" value={selectedClient} onChange={(e) => setSelectedClient(e.value)}
                          onSelect={(e) => { setSelectedClient(e.value); }}
                          placeholder={t('selectClient')} suggestions={clients}
                          completeMethod={(e) => handleClientOnChange(e)} />}
                    </div>
                    <div className="p-col-12 p-md-12 p-lg-12">
                      <h3>{t('purchaseOrderDetails')}</h3>
                    </div>
                  </div>
                  <div className="content-section implementation">
                    <DataTable value={purchaseOrderDetails} rows={15} footer={footer}>
                      <Column field="product.code" header={t('code')} />
                      <Column field="product.name" header={t('name')} />
                      <Column body={roundTotal} header={t('unitPrice')} />
                      <Column field="quantity" header={t('quantity')} />
                      <Column style={{ textAlign: 'center' }} body={actions} header={t('actions')} />
                    </DataTable>
                    <Dialog visible={displayDialog} footer={dialogFooter}
                      header={t('purchaseOrderDetail')} modal={true}
                      onHide={() => setDisplayDialog(false)}>
                      {purchaseOrderDetail &&
                        <div className="p-grid p-fluid">
                          <div className="p-col-4" style={{ padding: '.75em' }}>
                            <label htmlFor="name">{t('name')}</label></div>
                          <div className="p-col-8" style={{ padding: '.5em' }}>
                            <AutoComplete style={{ width: '100%' }} id='product' field="name"
                              value={purchaseOrderDetail.product}
                              onChange={(e) => setPurchaseOrderDetail({ ...purchaseOrderDetail, product: e.value })}
                              onSelect={(e) => setPurchaseOrderDetail({ ...purchaseOrderDetail, product: e.value })}
                              suggestions={products} completeMethod={(e) => handleProductOnChange(e)} />
                          </div>
                          <div className="p-col-4" style={{ padding: '.75em' }}>
                            <label htmlFor="quantity">{t('quantity')}</label>
                          </div>
                          <div className="p-col-8" style={{ padding: '.5em' }}>
                            <InputText id="quantity" keyfilter="pnum"
                              onChange={
                                (e) => {
                                  setPurchaseOrderDetail({ ...purchaseOrderDetail, quantity: e.target.value });
                                }}
                              value={purchaseOrderDetail.quantity} />
                          </div>
                        </div>
                      }
                    </Dialog>
                    <Dialog visible={displayArts} header={t('attachedReferences')}
                      modal={true} onHide={() => setDisplayArts(false)}>
                      {attachedReferences.length > 0 &&
                        <div className="p-grid p-fluid" style={{ maxWidth: '700px' }}>
                          <DataTable value={attachedReferences} rows={15} >
                            <Column field="name" header={t('name')} />
                            <Column field="description" header={t('description')} />
                            <Column style={{ textAlign: 'center' }} body={actionsProductDetail} header={t('actions')} />
                          </DataTable>
                        </div>
                      }
                      {attachedReferences.length === 0 &&
                        <div className="p-grid p-fluid" style={{ minWidth: '700px' }}>
                          <p>{t('emptyAttachedReferences')}</p>
                        </div>
                      }
                    </Dialog>
                  </div>
                  <div className="p-grid p-fluid">
                    <div className="p-col-12">
                      <h3 className="label-gray">{t('observations')}</h3>
                      <InputTextarea style={{ width: '100%' }} id='observations'
                        value={observations} onChange={(e) => setObservations(e.target.value)}
                        placeholder={t('observations')} />
                    </div>
                  </div>
                  <div className="ui-button-group">
                    <Button id='button-save' icon="pi pi-save" label={t('save')}
                      style={{ marginTop: '10px', width: 'auto' }} />
                    <Button id='button-return' type="button" icon="pi pi-times"
                      label={t('cancel')} style={{ margin: '10px', width: 'auto' }} onClick={cancel} />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegisterPurchaseOrder;
