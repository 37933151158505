import { axiosInstance } from '../config';
import { getUserSession, getHeaders } from '../authentication';

export const proformaDetailCreate = (proformaDetail) => {
  const config = getHeaders(getUserSession());
  return axiosInstance.post('proformaDetail',proformaDetail,config);
};

export const proformaDetailUpdate = (proformaDetail) => {
  const config = getHeaders(getUserSession());
  return axiosInstance.put('proformaDetails/update',proformaDetail,config);
};

export const proformaDetailDelete = (proformaDetailId) => {
  const config = getHeaders(getUserSession());
  return axiosInstance.delete('proformaDetails/' + proformaDetailId,config);
};
