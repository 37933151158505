import { axiosInstance } from '../config';
import { getUserSession, getHeaders } from '../authentication';
import { pickBy, identity } from 'lodash';

export const getTests = () => {
  const config = getHeaders(getUserSession());
  return axiosInstance.get('tests/list',config);
};

export const getActiveTests = () => {
  const config = getHeaders(getUserSession());
  return axiosInstance.get('tests/activeList',config);
};

export const getTestsByParams = (params) => {
  let paramsSend = pickBy(params, identity);
  const config = {
    headers: getHeaders(getUserSession()).headers,
    params: paramsSend
  };
  return axiosInstance.get('tests/byParams/list',config);
};

export const testCreate = (test) => {
  const config = getHeaders(getUserSession());
  return axiosInstance.post('test',test,config);
};

export const testUpdate = (test) => {
  const config = getHeaders(getUserSession());
  return axiosInstance.put('tests/update',test,config);
};
