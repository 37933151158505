import React, { useState, useEffect, useMemo } from 'react';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Checkbox } from 'primereact/checkbox';
import { useTranslation } from 'react-i18next';
import { messageError, messageSuccess, messageConfirm, messageConfirmText } from '../../../utils/messages';
import { routes } from '../../../utils/routes';
import {
  getProductDetailsByProductId,
  productDetailCreate,
  productDetailDelete,
  productDetailUpdate
} from '../../../services/productDetail/productDetail';
import { getFlautesByWall } from '../../../services/catalogFlaute/catalogFlaute';
import { getPapersByTest } from '../../../services/catalogPaper/catalogPaper';
import { getMeasurementsByFlauteIdAndBoxId } from '../../../services/measurementsByFlauteAndBox/measurementsByFlauteAndBox';
import { getTests } from '../../../services/tests/tests';
import { getAllProductionVariables } from '../../../services/productionVariables/productionVariables';
import { currencyFormatter } from '../../../utils/currency';
import { constantsObject } from '../../../utils/constantsObject';
import { getBoxes } from '../../../services/catalogBox/catalogBox';
import { getProductTypes } from '../../../services/catalogProductType/catalogProductType';
import {
  getProductById,
  turnSampleIntoNewProduct,
  verifyCode,
  productCreate,
  getProductsByParams,
  productUpdate
} from '../../../services/product/product';
import { getUserSession } from '../../../services/authentication';
import { RadioButton } from 'primereact/radiobutton';
import { validates } from './../../../utils/validations';
import { baseStyle, acceptStyle, activeStyle, rejectStyle } from '../../../utils/dropzoneStyle';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { useDropzone } from 'react-dropzone';
import { kraftOneFace, whiteOneFace, whiteTwoFaces } from '../../../utils/generalConstants';
import NewVersion from '../newVersion/newVersion';
import './productEdit.css';
const SHEET = 'Lamina';
const TRAY = 'Bandeja';
const OBSOLETE = 'Obsoleta';
const DIE_CUT = 'troquel';
const SALES_MANAGER_CODE = 'GV';
const PRODUCT_DESIGN_CODE = 'EDP';

function ProductEdit({ history }) {
  const { t } = useTranslation();
  const [handleIsClose, setHandleIsClose] = useState(false);
  const [isClose, setIsClose] = useState(false);
  const [product, setProduct] = useState({
    unitPrice: 0
  });
  const [selectedBox, setSelectedBox] = useState({});
  const [selectedProductType, setSelectedProductType] = useState({});
  const [selectedFlaute, setSelectedFlaute] = useState({});
  const [selectedPaper, setSelectedPaper] = useState({});
  const [selectedTest, setSelectedTest] = useState({});
  const [selectedDetailIndex, setSelectedDetailIndex] = useState(0);
  const [papers, setPapers] = useState([]);
  const [flautes, setFlautes] = useState([]);
  const [tests, setTests] = useState([]);
  const [boxes, setBoxes] = useState([]);
  const [productTypes, setProductTypes] = useState([]);
  const [isRendered, setIsRendered] = useState(false);
  const [widthTolerance, setWidthTolerance] = useState('');
  const [longTolerance, setLongTolerance] = useState('');
  const [productDetails, setProductDetails] = useState([]);
  const [productDetail, setProductDetail] = useState({ name: '', description: '', url: '' });
  const [newProductDetail, setNewProductDetail] = useState({});
  const [displayDialog, setDisplayDialog] = useState(false);
  const [isSample, setIsSample] = useState(false);
  const [displayNewProductCodeDialog, setDisplayNewProductCodeDialog] = useState(false);
  const [productAmountOneRegularFaceWaterproofing, setProductAmountOneRegularFaceWaterproofing] = useState();
  const [productAmountTwoRegularFacesWaterproofing, setProductAmountTwoRegularFacesWaterproofing] = useState();
  const [productAmountOneCobbFaceWaterproofing, setProductAmountOneCobbFaceWaterproofing] = useState();
  const [productAmountTwoCobbFacesWaterproofing, setProductAmountTwoCobbFacesWaterproofing] = useState();
  const [readOnly, setReadOnly] = useState();
  const [constants, setConstants] = useState();
  const [newProductDetails, setNewProductDetails] = useState([]);
  const [deletedDetails] = useState([]);
  const [updatedDetails] = useState([]);
  const fileInput = document.querySelector('#fileInput');
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    noDrag: true,
    accept: 'image/*, .pdf, .xlsx',
    getFilesFromEvent: event => myCustomFileGetter(event)
  });

  const myCustomFileGetter = (event) => {
    const files = [];
    const fileList = event.dataTransfer ? event.dataTransfer.files : event.target.files;
    Array.from(fileList).forEach(file => {
      Object.defineProperty(file, 'myProp', {
        value: true
      });
      files.push({
        download: URL.createObjectURL(file),
        url: file,
        name: file.name,
        description: file.name
      });
    });
    newProductDetails.push(...files);
    setProductDetails([...productDetails, ...files]);
  };

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ]);

  useEffect(() => {
    (history.location.state.productUpdate.active === 1) ? setReadOnly(false) : setReadOnly(true);
    getProductById(history.location.state.productUpdate.id).then(result => {
      getAllProductionVariables().then(result => {
        const variables = constantsObject(result.data);
        setConstants(variables);
        setProductAmountOneRegularFaceWaterproofing(variables.oneFaceRegularWaterproofing);
        setProductAmountTwoRegularFacesWaterproofing(variables.twoFacesRegularWaterproofing);
        setProductAmountOneCobbFaceWaterproofing(variables.oneFaceCobbWaterproofing);
        setProductAmountTwoCobbFacesWaterproofing(variables.twoFacesCobbWaterproofing);
      }).catch(error => {
        (error.response) ? messageError(error.response.data.message) : messageError(t('withoutConnection'));
      });
      (result.data[0].isSample === 1) ? setIsSample(true) : setIsSample(false);
      setSelectedBox(result.data[0].box);
      setSelectedTest(result.data[0].test);
      if (result.data[0].test && result.data[0].test.id) {
        getFlautesByWall(result.data[0].test.wall).then(resultFlautes => {
          setFlautes(resultFlautes.data);
        }).catch(error => {
          if (!error.response) {
            messageError(t('withoutConnection'));
          }
        });
        getPapersByTest(result.data[0].test.id).then(papers => {
          setPapers(papers.data);
          if (result.data[0].paper) {
            setSelectedPaper(papers.data.find(element => element.name === result.data[0].paper.name));
          }
        });
      }
      setSelectedFlaute(result.data[0].flaute);
      if (result.data[0].flaute && result.data[0].box) {
        getMeasurementsByFlauteIdAndBoxId(result.data[0].flaute.id, result.data[0].box.id).then(resultMeasurements => {
          setLongTolerance(resultMeasurements.data.longLossByIncrease);
          setWidthTolerance(resultMeasurements.data.widthLossByIncrease);
        }).catch(error => {
          if (!error.response) {
            messageError(t('withoutConnection'));
          }
        });
      }
      setSelectedProductType(result.data[0].productType);
      result.data[0].printing = Boolean(result.data[0].printing);
      result.data[0].isPaletized = Boolean(result.data[0].isPaletized);
      result.data[0].regularWaterproofing = Boolean(result.data[0].regularWaterproofing);
      result.data[0].cobbWaterproofing = Boolean(result.data[0].cobbWaterproofing);
      result.data[0].isDieCut = Boolean(result.data[0].isDieCut);
      result.data[0].isCliche = Boolean(result.data[0].isCliche);
      result.data[0].isClose && setReadOnly(true);
      setIsClose(result.data[0].isClose);
      setProduct(result.data[0]);
      getTests().then(resultTests => {
        const selectedTest = result.data[0].test;
        const allTest = resultTests.data.filter(({ id, active }) => active === 1 || id === selectedTest?.id);
        setTests(allTest);
      }).catch(error => {
        (error.response) ? messageError(error.response.data.message) : messageError(t('withoutConnection'));
      });
      getBoxes().then(resultBox => {
        if (result.data[0].box.name === TRAY) {
          resultBox.data.filter(element => element.name !== OBSOLETE);
        } else if (result.data[0].box.name === OBSOLETE) {
          resultBox.data.filter(element => element.name !== TRAY);
        }
        setBoxes(resultBox.data);
      }).catch(error => {
        (error.response) ? messageError(error.response.data.message) : messageError(t('withoutConnection'));
      });
      getProductTypes().then(resultProducts => {
        setProductTypes(resultProducts.data);
      }).catch(error => {
        (error.response) ? messageError(error.response.data.message) : messageError(t('withoutConnection'));
      });
      getProductDetailsByProductId(result.data[0].id).then(result => {
        let details = (currentUser.codeRole !== SALES_MANAGER_CODE)
          ? result.data.filter(element => element.url.split('.')[4] !== 'xlsx')
          : result.data;
        setProductDetails(details);
      });
      setIsRendered(true);
    }).catch(error => {
      (error.response) ? messageError(error.response.data.message) : messageError(t('withoutConnection'));
    });
  }, [history.location.state.productUpdate]);

  useEffect(() => () => {
    return () => {
      fileInput.removeEventListener('change', event => {
        const files = event.target.files;
        setProductDetails([...productDetails, files[0]]);
      });
    };
  }, [fileInput, productDetails]);

  useEffect(() => {
    if (fileInput) {
      fileInput.addEventListener('change', event => {
        const files = event.target.files;
        setProductDetails([...productDetails, files[0]]);
      });
    }
  }, [fileInput]);

  useEffect(() => {
    if (handleIsClose) {
      setIsRendered(false);
      let updatedProduct = prepareProductToUpload();
      updatedProduct.code = history.location.state.productUpdate.code;
      updatedProduct.id = product.id;
      updatedProduct.approvalDate = new Date().toISOString().slice(0, 19).replace('T', ' ', 'gi');
      productUpdate(updatedProduct).then(result => {
        handleUpdateSubmitWithProductDetails(result.data.id);
      }).catch(error => {
        (error.response) ? messageError(error.response.data.message) : messageError(t('messageErrorProductUpdated'));
      });
    }
  }, [handleIsClose]);

  const currentUser = React.useMemo(() => {
    return getUserSession().user;
  }, []);

  const isProductClosedAndRoleProductDesign = React.useMemo(() => {
    return readOnly && currentUser.codeRole === PRODUCT_DESIGN_CODE;
  }, [readOnly, currentUser]);

  const handleSubmit = async (event) => {
    setIsRendered(false);
    event.preventDefault();
    let updatedProduct = prepareProductToUpload();
    updatedProduct.code = history.location.state.productUpdate.code;
    if (isProductClosedAndRoleProductDesign) {
      updatedProduct.id = product.id;
      handleUpdateSubmitWithProductDetails(updatedProduct.id);
    } else {
      const confirm = history.location.newVersion ? false : await messageConfirmText(t('generateNewVersion?'));
      if (confirm) {
        getProductsByParams({ code: updatedProduct.code }).then(list => {
          updatedProduct.version = Math.max(...list.data.map(prod => prod.version), 0) + 1;
          productCreate(updatedProduct).then(result => {
            handleSubmitWithProductDetails(result.data.id);
          }).catch(error => {
            (error.response) ? messageError(error.response.data.message) : messageError(t('messageErrorProductCreated'));
          });
        });
      } else {
        updatedProduct.id = product.id;
        productUpdate(updatedProduct).then(() => {
          handleUpdateSubmitWithProductDetails(updatedProduct.id);
        }).catch(error => {
          (error.response) ? messageError(error.response.data.message) : messageError(t('messageErrorProductUpdated'));
        });
      }
    }
  };

  const handleSubmitWithProductDetails = async (id) => {
    let detail, elementId, submited = true;
    if (productDetails.length > 0) {
      for (const element of productDetails) {
        (element.id) ? elementId = element.id : elementId = -1;
        detail = {
          id: elementId,
          idProduct: id,
          name: element.name,
          description: element.description,
          url: element.url
        };
        await productDetailCreate(detail)
          // eslint-disable-next-line no-loop-func
          .catch((error) => {
            submited = false;
            (error.response) ? messageError(error.response.data.message) : messageError(t('messageErrorProductCreated'));
          });
      }
    } if (submited) {
      messageSuccess(t('messageSuccessProductUpdated'));
      history.push(routes.Products);
    }
  };

  const handleUpdateSubmitWithProductDetails = async (id) => {
    let detail, submited = true;
    if (newProductDetails.length > 0) {
      for (const element of newProductDetails) {
        detail = {
          id: -1,
          idProduct: id,
          name: element.name,
          description: element.description,
          url: element.url
        };
        await productDetailCreate(detail)
          // eslint-disable-next-line no-loop-func
          .catch(error => {
            submited = false;
            (error.response) ? messageError(error.response.data.message) : messageError(t('messageErrorProductUpdated'));
          });
      }
    } if (updatedDetails.length > 0) {
      for (const element of updatedDetails) {
        detail = {
          id: element.id,
          name: element.name,
          description: element.description
        };
        await productDetailUpdate(detail)
          // eslint-disable-next-line no-loop-func
          .catch(error => {
            submited = false;
            (error.response) ? messageError(error.response.data.message) : messageError(t('messageErrorProductUpdated'));
          });
      }
    } if (deletedDetails.length > 0) {
      deletedDetails.forEach(element => {
        productDetailDelete(element)
          .catch(error => {
            submited = false;
            (error.response) ? messageError(error.response.data.message) : messageError(t('messageErrorProductUpdated'));
          });
      });
    }
    if (submited) {
      messageSuccess(t('messageSuccessProductUpdated'));
      history.push(routes.Products);
    }
  };

  const prepareProductToUpload = () => {
    const updatedProduct = {
      code: product.code,
      idCatalogBox: selectedBox.id ? selectedBox.id : 1,
      name: product.name,
      weight: Number(calculateWeight()),
      amountCliche: Number(product.amountCliche),
      amountDieCut: Number(product.amountDieCut),
      liner1: product.liner1,
      liner2: product.liner2,
      liner3: product.liner3,
      medium1: product.medium1,
      medium2: product.medium2,
      version: product.version,
      flaps: product.flaps,
      active: true,
      minimumCombination: product.minimumCombination,
      internalLong: Number(product.internalLong),
      internalWidth: Number(product.internalWidth),
      internalHeight: Number(product.internalHeight),
      thirdProcesses: Number(product.thirdProcesses),
      unitPrice: product.unitPrice ? product.unitPrice : Number(calculateUnitPrice()),
      priceByTc: Number(product.priceByTc),
      wastePercentage: Number(product.wastePercentage),
      isException: validatesMeasurementsOnEdit(),
      isClose: Boolean(product.isClose)
    };
    (product.isPaletized) ? updatedProduct.isPaletized = 1 : updatedProduct.isPaletized = 0;
    if (product.idClient) {
      updatedProduct.idClient = product.idClient;
    }
    if (product.regularWaterproofing) {
      updatedProduct.regularWaterproofing = 1;
      updatedProduct.waterproofedFaces = product.waterproofedFaces;

      if (product.waterproofedFaces === 1) {
        updatedProduct.amountOneRegularFaceWaterproofing = productAmountOneRegularFaceWaterproofing;
      } else if (product.waterproofedFaces === 2) {
        updatedProduct.amountTwoRegularFacesWaterproofing = productAmountTwoRegularFacesWaterproofing;
      }
    }
    if (product.cobbWaterproofing) {
      updatedProduct.cobbWaterproofing = 1;
      updatedProduct.waterproofedFaces = product.waterproofedFaces;

      if (product.waterproofedFaces === 1) {
        updatedProduct.amountOneCobbFaceWaterproofing = productAmountOneCobbFaceWaterproofing;
      } else if (product.waterproofedFaces === 2) {
        updatedProduct.amountTwoCobbFacesWaterproofing = productAmountTwoCobbFacesWaterproofing;
      }
    }
    if (product.isCliche) {
      updatedProduct.amountCliche = product.amountCliche;
      updatedProduct.isCliche = 1;
      updatedProduct.printing = 1;
      updatedProduct.color1 = product.color1 ? product.color1 : '';
      updatedProduct.color2 = product.color2 ? product.color2 : '';
      updatedProduct.color3 = product.color3 ? product.color3 : '';
      updatedProduct.color4 = product.color4 ? product.color4 : '';
      updatedProduct.colorQuantity = calculateColorQuantity();
      updatedProduct.clicheTotalCost = calculateClicheCost();
    } else {
      updatedProduct.isCliche = 0;
      updatedProduct.printing = 0;
    }
    if (product.isDieCut) {
      updatedProduct.amountDieCut = product.amountDieCut;
      updatedProduct.isDieCut = 1;
      updatedProduct.dieCutTotalCost = calculateDieCutCost();
    } else {
      updatedProduct.isDieCut = 0;
    }
    (isSample) ? updatedProduct.isSample = 1 : updatedProduct.isSample = 0;
    if (selectedTest) {
      updatedProduct.idTest = selectedTest.id;
    }
    if (selectedFlaute) {
      updatedProduct.idCatalogFlaute = selectedFlaute.id;
    }
    if (selectedPaper) {
      updatedProduct.idCatalogPaper = selectedPaper.id;
    }
    if (selectedProductType) {
      updatedProduct.idCatalogTypeProduct = selectedProductType.id;
    }
    if (selectedBox.name === SHEET || selectedBox.name === TRAY || selectedBox.name === OBSOLETE) {
      updatedProduct.sheetWidth = Number(product.sheetWidth);
      updatedProduct.sheetLong = Number(product.sheetLong);
      updatedProduct.area = Number(calculateSheetArea());
    } else {
      updatedProduct.sheetWidth = Number(calculateSheetWidth());
      updatedProduct.sheetLong = Number(calculateSheetLong());
      updatedProduct.area = Number(calculateArea());
    }
    return updatedProduct;
  };

  const cancel = () => {
    history.push(routes.Products);
  };

  const turnSampleIntoProduct = async (e) => {
    setIsRendered(false);
    e.preventDefault();
    const updatedProduct = prepareProductToUpload();
    if (await verifyCode(updatedProduct.code)) {
      turnSampleIntoNewProduct(updatedProduct).then(() => {
        messageSuccess(t('messageSuccessProductUpdated'));
        history.push(routes.Products);
      }).catch(error => {
        (error.response) ? messageError(error.response.data.message) : messageError(t('withoutConnection'));
      });
    } else {
      messageError(t('messageErrorRepeatedCode'));
    }
    setIsRendered(true);
  };

  const validatesMeasurementsOnEdit = () => {
    let long, width;
    if (selectedBox.name === SHEET) {
      long = Number(calculateSheetLong());
      width = Number(calculateSheetWidth());
    } else {
      long = product.sheetLong;
      width = product.sheetWidth;
    }
    return validates(selectedBox, long, width, product.cobbWaterproofing, product.isCliche,
      product.amountCliche, product.isDieCut, product.amountDieCut, selectedTest, constants);
  };

  const calculateArea = () => {
    return Number(Number(calculateSheetLong()) * Number(calculateSheetWidth()) / 10000).toFixed(4);
  };

  const calculateSheetArea = () => {
    if (product.sheetWidth && product.sheetLong) {
      return Number(Number(product.sheetLong) * Number(product.sheetWidth) / 10000).toFixed(4);
    } return 0;
  };

  const calculateLongInInches = () => {
    if (selectedBox.name !== SHEET) {
      return Number(Number(calculateSheetLong()) / 2.54).toFixed(4) + '"';
    } return Number(Number(product.sheetLong) / 2.54).toFixed(4) + '"';
  };

  const calculateWidthInInches = () => {
    if (selectedBox.name !== SHEET) {
      return Number(Number(calculateSheetWidth()) / 2.54).toFixed(4) + '"';
    } return Number(Number(product.sheetWidth) / 2.54).toFixed(4) + '"';
  };

  const assignCombination = (test) => {
    const combination = test.combination;
    let arrayCombination = [];

    if (combination !== undefined) {
      if (combination.includes('-')) {
        arrayCombination = combination.split('-');
      } else if (combination.includes('/')) {
        arrayCombination = combination.split('/');
      }
    }

    setProduct({
      ...product,
      minimumCombination: combination,
      liner1: arrayCombination[0] ? arrayCombination[0] : '',
      medium1: arrayCombination[1] ? arrayCombination[1] : '',
      liner2: arrayCombination[2] ? arrayCombination[2] : '',
      medium2: arrayCombination[3] ? arrayCombination[3] : '',
      liner3: arrayCombination[4] ? arrayCombination[4] : ''
    });
  };

  const callGetFlautesByWall = (test) => {
    getFlautesByWall(test.wall).then(result => {
      setFlautes(result.data);
    }).catch(error => {
      if (!error.response) {
        messageError(t('withoutConnection'));
      }
    });
    getPapersByTest(test.id).then(result => {
      result.data.findIndex((e) => {
        if (e.name === kraftOneFace) {
          e.price = constants.kraftOneFace;
        }
        if (e.name === whiteOneFace) {
          e.price = constants.whiteOneFace;
        }
        if (e.name === whiteTwoFaces) {
          e.price = constants.whiteTwoFaces;
        }
      });
      setPapers(result.data);
    }).catch(error => {
      if (!error.response) {
        messageError(t('withoutConnection'));
      }
    });
  };

  const getTolerance = (e) => {
    if (e.wall && selectedBox.id) {
      getMeasurementsByFlauteIdAndBoxId(e.id, selectedBox.id).then(result => {
        setLongTolerance(result.data.longLossByIncrease);
        setWidthTolerance(result.data.widthLossByIncrease);
      }).catch(error => {
        if (!error.response) {
          messageError(t('withoutConnection'));
        }
      });
    } else if (selectedFlaute.id) {
      getMeasurementsByFlauteIdAndBoxId(selectedFlaute.id, e.id).then(result => {
        setLongTolerance(result.data.longLossByIncrease);
        setWidthTolerance(result.data.widthLossByIncrease);
      }).catch(error => {
        if (!error.response) {
          messageError(t('withoutConnection'));
        }
      });
    }
  };

  const calculateDieCutCost = () => {
    let area;
    (selectedBox.name === SHEET) ? area = Number(calculateSheetArea()) : area = Number(calculateArea());
    return Number(product.amountDieCut * area);
  };

  const calculateColorQuantity = () => {
    let colors = 0;
    if (product.color1 !== '') {
      colors = Number(colors) + 1;
    }
    if (product.color2 !== '') {
      colors = Number(colors) + 1;
    }
    if (product.color3 !== '') {
      colors = Number(colors) + 1;
    }
    if (product.color4 !== '') {
      colors = Number(colors) + 1;
    }
    return colors;
  };

  const calculateClicheCost = () => {
    let area;
    (selectedBox.name === SHEET) ? area = Number(calculateSheetArea()) : area = Number(calculateArea());
    if (product.isCliche && constants !== undefined) {
      let colors = calculateColorQuantity();
      if (colors === 4) {
        return Number(product.amountCliche * (constants.forthColorPercentage / 100))
          + Number(product.amountCliche * colors * area);
      }
      return Number(product.amountCliche * colors * area);
    }
    return 0;
  };

  const calculateUnitPrice = () => {
    if (selectedBox.name === TRAY || selectedBox.name === OBSOLETE || !selectedTest || selectedTest.weight === 0) {
      return product.unitPrice;
    }
    let area;
    area = (selectedBox.name === SHEET) ? Number(calculateSheetArea()) : Number(calculateArea());
    let total = Number(product.priceByTc * calculateWeight() / 1000).toFixed(4);
    if (product.regularWaterproofing) {
      if (product.waterproofedFaces === 1) {
        total = Number(total) + Number(productAmountOneRegularFaceWaterproofing * area);
      } else if (product.waterproofedFaces === 2) {
        total = Number(total) + Number(productAmountTwoRegularFacesWaterproofing * area);
      }
    } else if (product.cobbWaterproofing) {
      if (product.waterproofedFaces === 1) {
        total = Number(total) + Number(productAmountOneCobbFaceWaterproofing * area);
      } else if (product.waterproofedFaces === 2) {
        total = Number(total) + Number(productAmountTwoCobbFacesWaterproofing * area);
      }
    }
    if (product.isPaletized) {
      return (Number(total) + (Number(total) * Number(product.wastePercentage) / 100))
        + Number(product.thirdProcesses) + Number(constants.paletizedCost);
    }
    return (Number(total) + (Number(total) * Number(product.wastePercentage) / 100)) + Number(product.thirdProcesses);
  };

  const calculateWeight = () => {
    let area = (selectedBox.name === SHEET) ? calculateSheetArea() : calculateArea();
    if (area !== 0.0000 && selectedTest?.weight !== undefined && selectedTest?.weight !== 0) {
      return Number(Number(selectedTest.weight) * area).toFixed(4);
    }
    return product.weight;
  };

  const calculateSheetLong = () => {
    if (longTolerance !== 0) {
      if (product.internalHeight > 0 && product.internalLong > 0 && product.internalWidth > 0) {
        if (selectedBox.nameWithType === 'RSC ') {
          return (((((Number(product.internalLong) + Number(product.internalWidth)) * 2)
            + Number(longTolerance)) / 10).toFixed(4));
        } else if (selectedBox.nameWithType === 'HSC Fondo') {
          return (((((Number(product.internalLong) + Number(product.internalWidth)) * 2)
            + Number(longTolerance)) / 10).toFixed(4));
        }
        else if (selectedBox.nameWithType === 'HSC Tapa') {
          return (((((Number(product.internalLong) + Number(product.internalWidth)) * 2)
            + Number(longTolerance)) / 10).toFixed(4));
        }
        else if (selectedBox.nameWithType === 'CST Fondo') {
          return (Number((Number(product.internalLong) + (Number(product.internalHeight) * 2)
            + Number(longTolerance)) / 10).toFixed(4));
        }
        else if (selectedBox.nameWithType === 'CST Tapa') {
          return (Number((Number(product.internalLong) + (Number(product.internalHeight) * 2)
            + Number(longTolerance)) / 10).toFixed(4));
        }
      }
    }
    return product.sheetLong;
  };

  const calculateSheetWidth = () => {
    if (widthTolerance !== 0) {
      if (product.internalHeight > 0 && product.internalLong > 0 && product.internalWidth > 0) {
        if (selectedBox.nameWithType === 'RSC ') {
          return (((Number(product.internalWidth) + Number(product.internalHeight)
            + Number(widthTolerance)) / 10).toFixed(4));
        } else if (selectedBox.nameWithType === 'HSC Fondo') {
          return (Number((Number(product.internalHeight) + (Number(product.internalWidth) / 2)
            + Number(widthTolerance)) / 10).toFixed(4));
        }
        else if (selectedBox.nameWithType === 'HSC Tapa') {
          return (Number((Number(product.internalHeight) + (Number(product.internalWidth) / 2)
            + Number(widthTolerance)) / 10).toFixed(4));
        }
        else if (selectedBox.nameWithType === 'CST Fondo') {
          return (Number((Number(product.internalWidth) + (Number(product.internalHeight) * 2)
            + Number(widthTolerance)) / 10).toFixed(4));
        }
        else if (selectedBox.nameWithType === 'CST Tapa') {
          return (Number((Number(product.internalWidth) + (Number(product.internalHeight) * 2)
            + Number(widthTolerance)) / 10).toFixed(4));
        }
      }
    }
    return product.sheetWidth;
  };

  const setPriceByTc = (event) => {
    const weight = calculateWeight() || 1;
    setProduct({
      ...product,
      unitPrice: event.target.value,
      priceByTc: Number((event.target.value * 1000) / weight).toFixed(4)
    });
  };

  const verifyName = () => {
    let isRepeated = false;
    productDetails.forEach((detail) => {
      if (detail.name === productDetail.name && productDetails.indexOf(detail) !== selectedDetailIndex) {
        isRepeated = true;
      }
    }); return isRepeated;
  };

  const save = (e) => {
    e.preventDefault();
    if (productDetail.url !== '' && productDetails.name !== '' && productDetails.description !== '') {
      if (!verifyName()) {
        let details = [...productDetails];
        if (newProductDetail) {
          newProductDetails.push(productDetail);
          details.push(productDetail);
        } else {
          updatedDetails.push(productDetail);
          details[selectedDetailIndex] = productDetail;
        }
        setProductDetails(details);
        setProductDetail({ name: '', description: '', url: '' });
        setDisplayDialog(false);
      } else {
        return messageError(t('messageErrorRepeatedName'));
      }
    } else {
      return messageError(t('missingFields'));
    }
  };

  const deleteDetail = (row) => {
    let index = findSelectedDetailIndex(row);
    deletedDetails.push(row.id);
    setProductDetails(productDetails.filter((val, i) => i !== index));
    setNewProductDetails(newProductDetails.filter((val, i) => i !== newProductDetails.indexOf(row)));
  };

  const findSelectedDetailIndex = (row) => {
    return productDetails.indexOf(row);
  };

  const onproductDetailselect = (row) => {
    setNewProductDetail(false);
    setDisplayDialog(true);
    setProductDetail(row);
    setSelectedDetailIndex(productDetails.indexOf(row));
  };

  const dialogNewProductCode = () => {
    return (
      <Dialog
        style={{ width: '350px' }}
        onHide={() => setDisplayNewProductCodeDialog(false)}
        footer={dialogNewProductCodeFooter}
        id='newProductCode'
        visible={displayNewProductCodeDialog}
        header={t('insertNewCode')}
        closeOnEscape={false}
      >
        <div className="p-grid">
          <div className="p-col-4 p-md-4 p-lg-4"><h5>{t('code')}</h5></div>
          <div className="p-col-8 p-md-8 p-lg-8">
            <InputText
              id='code'
              value={product.code}
              placeholder={t('code')}
              className="input-full-row"
              onChange={(event) => setProduct({ ...product, code: event.target.value })}
            />
          </div>
        </div>
      </Dialog>
    );
  };

  let dialogNewProductCodeFooter = <div className="ui-dialog-buttonpane p-clearfix">
    <Button
      style={{ width: '50px' }}
      id="button-sample-or-product"
      icon="pi pi-arrow-right"
      onClick={(e) => turnSampleIntoProduct(e)}
    />
  </div>;

  const actions = (row) => (
    <>
      <a href={row.download ?? row.url} download>
        <i style={{ marginRight: '3px', marginTop: '3px' }} className="pi pi-download" />
      </a>
      {(!readOnly || isProductClosedAndRoleProductDesign) && <>
        <Button
          icon="pi pi-pencil"
          id="button edit"
          style={{ margin: '3px' }}
          className="margin-button"
          onClick={(e) => { e.preventDefault(); onproductDetailselect(row); }}
        />
        <Button
          icon="pi pi-times"
          id="button-remove"
          style={{ margin: '3px' }}
          className="margin-button"
          onClick={(e) => { e.preventDefault(); deleteDetail(row); }}
        />
      </>}
    </>
  );

  const setValuesOnSelectedBox = (box) => {
    setSelectedBox(box);
    getTolerance(box);
    if (box.type === DIE_CUT) {
      setProduct({ ...product, isDieCut: true, amountDieCut: constants.maxDieCut });
    }
  };

  const handleDelete = async () => {
    const confirm = await messageConfirm(t('productDeactivate'));
    if (confirm) {
      const productObject = {
        id: history.location.state.productUpdate.id,
        active: false,
        code: product.code,
        version: product.version,
        idCatalogBox: selectedBox.id,
        name: product.name
      };
      productUpdate(productObject).then(() => {
        messageSuccess(t('messagesSuccessProductDeactivated'));
        history.push(routes.Products);
      }).catch(error => {
        (error.response) ? messageError(error.response.data.message) : messageError(t('messagesErrorProductUpdated'));
      });
    }
  };

  const handleActivate = async () => {
    const confirm = await messageConfirm(t('productActivate'));
    if (confirm) {
      const productObject = {
        id: history.location.state.productUpdate.id,
        active: true,
        code: product.code,
        version: product.version,
        idCatalogBox: selectedBox.id,
        name: product.name
      };
      productUpdate(productObject).then(() => {
        messageSuccess(t('messageSuccessProductUpdated'));
        history.push(routes.Products);
      }).catch(error => {
        (error.response) ? messageError(error.response.data.message) : messageError(t('messagesErrorProductUpdated'));
      });
    }
  };

  let dialogFooter = <div className="ui-dialog-buttonpane p-clearfix">
    <Button label={t('save')} icon="pi pi-check" onClick={save} />
  </div>;

  return (
    <div>
      <div className="ui-g ui-fluid" >
        <div className="ui-g-12">
          <div className="card-user">
            <div className="card">
              {!isRendered && <div id='div-loader'><i id='loader' className="pi pi-spin pi-spinner"></i></div>}
              {isRendered && <>
                <div className="content-section introduction">
                  {!isSample && <h1>{t('productEdit')} {isClose ? `(${t('productClosed')})` : ''}</h1>}
                  {isSample && <h1>{t('sampleEdit')}</h1>}
                  <form autoComplete="off" onSubmit={handleSubmit}>
                    <div className="p-grid">
                      <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('client')}</h5></div>
                      {product.client &&
                        <div style={{ marginTop: '15px' }} className="p-col-8 p-md-8 p-lg-11">
                          {product.client.name}
                        </div>
                      }
                      {!product.client &&
                        <div style={{ marginTop: '15px' }} className="p-col-8 p-md-8 p-lg-11">
                          {t('noClient')}
                        </div>
                      }
                      <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('name')}</h5></div>
                      <div className="p-col-8 p-md-8 p-lg-7">
                        <InputText
                          readOnly={readOnly}
                          id='name'
                          placeholder={t('name')}
                          value={product.name}
                          required
                          className="input-text"
                          onChange={(event) => setProduct({ ...product, name: event.target.value })}
                        />
                      </div>
                      <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('version')}</h5></div>
                      <div className="p-col-8 p-md-8 p-lg-3"><h5>{String(Number(product.version))}</h5></div>
                      <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('code')}</h5></div>
                      <div className="p-col-8 p-md-8 p-lg-3">
                        <InputText readOnly id='code' value={product.code} className="input-full-row" />
                      </div>
                      <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('boxType')}</h5></div>
                      <div className="p-col-8 p-md-8 p-lg-3">
                        <Dropdown
                          disabled={readOnly}
                          id='box'
                          required
                          className="input-full-row"
                          optionLabel="nameWithType"
                          value={selectedBox}
                          onChange={(e) => setValuesOnSelectedBox(e.target.value)}
                          placeholder={t('selectBox')}
                          options={boxes}
                        />
                      </div>
                      <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('productType')}</h5></div>
                      <div className="p-col-8 p-md-8 p-lg-3">
                        <Dropdown
                          disabled={readOnly}
                          id='productType'
                          inputStyle={{ width: '100%' }}
                          className="input-full-row"
                          optionLabel="name"
                          value={selectedProductType}
                          onChange={(e) => setSelectedProductType(e.target.value)}
                          placeholder={t('selectProductType')}
                          options={productTypes}
                        />
                      </div>
                      <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('test')}</h5></div>
                      <div className="p-col-8 p-md-8 p-lg-3">
                        <Dropdown
                          id='test'
                          className="input-full-row"
                          optionLabel="nameWithCombination"
                          value={selectedTest}
                          required
                          onChange={(e) => {
                            setSelectedTest(e.target.value);
                            callGetFlautesByWall(e.target.value);
                            assignCombination(e.target.value);
                          }}
                          placeholder={t('selectTest')}
                          options={tests}
                          disabled={readOnly}
                        />
                      </div>
                      <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('testWeight')}</h5></div>
                      <div className="p-col-8 p-md-8 p-lg-3" style={{ marginTop: '15px' }}>
                        {selectedTest && selectedTest.weight}
                      </div>
                      <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('flaute')}</h5></div>
                      <div className="p-col-8 p-md-8 p-lg-3">
                        <Dropdown
                          id='flaute'
                          className="input-full-row"
                          required
                          optionLabel="name"
                          value={selectedFlaute}
                          onChange={(e) => { setSelectedFlaute(e.target.value); getTolerance(e.target.value); }}
                          placeholder={t('selectFlaute')}
                          options={flautes}
                          disabled={readOnly}
                        />
                      </div>
                      {isSample && <><div className="p-col-4 p-md-4 p-lg-1"><h5>{t('paper')}</h5></div>
                        <div className="p-col-8 p-md-8 p-lg-11">
                          <Dropdown
                            id='paper'
                            className="input-small-row"
                            optionLabel="name"
                            required
                            value={selectedPaper}
                            onChange={(e) => setSelectedPaper(e.target.value)}
                            placeholder={t('selectPaper')}
                            options={papers}
                            disabled={readOnly}
                          />
                        </div></>}
                      {!isSample && <>
                        <div className="p-col-4 p-md-4 p-lg-1">
                          <h5>{t('paper')}</h5>
                        </div>
                        <div className="p-col-8 p-md-8 p-lg-3">
                          <Dropdown
                            id='paper'
                            className="input-full-row"
                            optionLabel="name"
                            value={selectedPaper}
                            onChange={(e) => setSelectedPaper(e.target.value)}
                            placeholder={t('selectPaper')}
                            options={papers}
                            disabled={readOnly}
                          />
                        </div>
                      </>}
                      {!isSample && selectedBox.name !== SHEET && <>
                        <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('flaps')}</h5></div>
                        <div className="p-col-8 p-md-8 p-lg-3">
                          <InputText
                            id='flaps'
                            value={product.flaps}
                            placeholder={t('flaps')}
                            readOnly={readOnly}
                            className="input-full-row"
                            onChange={(event) => setProduct({ ...product, flaps: event.target.value })}
                          />
                        </div>
                        <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('minimalCombination')}</h5></div>
                        <div className="p-col-8 p-md-8 p-lg-3">
                          <InputText
                            id='minimumCombination'
                            value={product.minimumCombination}
                            placeholder={t('minimalCombination')}
                            readOnly={readOnly}
                            className="input-full-row"
                            onChange={(event) => setProduct({ ...product, minimumCombination: event.target.value })}
                          />
                        </div>
                      </>}
                      {!isSample && selectedBox.name === SHEET && <>
                        <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('minimalCombination')}</h5></div>
                        <div className="p-col-8 p-md-8 p-lg-7">
                          <InputText
                            id='minimumCombination'
                            value={product.minimumCombination}
                            placeholder={t('minimalCombination')}
                            readOnly={readOnly}
                            className="input-medium-row"
                            onChange={(event) => setProduct({ ...product, minimumCombination: event.target.value })}
                          />
                        </div>
                      </>}
                      {!isSample && <>
                        <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('liner1')}</h5></div>
                        <div className="p-col-8 p-md-8 p-lg-3">
                          <InputText
                            id='liner1'
                            value={product.liner1}
                            placeholder={t('liner1')}
                            className="input-full-row"
                            onChange={(event) => setProduct({ ...product, liner1: event.target.value })}
                            readOnly={readOnly}
                          />
                        </div>
                        <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('medium1')}</h5></div>
                        <div className="p-col-8 p-md-8 p-lg-7">
                          <InputText
                            id='medium1'
                            value={product.medium1}
                            placeholder={t('medium1')}
                            className="input-medium-row"
                            onChange={(event) => setProduct({ ...product, medium1: event.target.value })}
                            readOnly={readOnly}
                          />
                        </div>
                        <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('liner2')}</h5></div>
                        <div className="p-col-8 p-md-8 p-lg-3">
                          <InputText
                            id='liner2'
                            value={product.liner2}
                            placeholder={t('liner2')}
                            className="input-full-row"
                            onChange={(event) => setProduct({ ...product, liner2: event.target.value })}
                            readOnly={readOnly}
                          />
                        </div>
                        <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('medium2')}</h5></div>
                        <div className="p-col-8 p-md-8 p-lg-7">
                          <InputText
                            id='medium2'
                            value={product.medium2}
                            placeholder={t('medium2')}
                            className="input-medium-row"
                            onChange={(event) => setProduct({ ...product, medium2: event.target.value })}
                            readOnly={readOnly}
                          />
                        </div>
                        <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('liner3')}</h5></div>
                        <div className="p-col-8 p-md-8 p-lg-11">
                          <InputText
                            id='liner3'
                            value={product.liner3}
                            placeholder={t('liner3')}
                            className="input-small-row"
                            onChange={(event) => setProduct({ ...product, liner3: event.target.value })}
                            readOnly={readOnly}
                          />
                        </div></>}
                      <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('isPaletized')}</h5></div>
                      <div className="p-col-8 p-md-8 p-lg-11">
                        <Checkbox
                          readOnly={readOnly}
                          id="isPaletized"
                          className="input"
                          onChange={
                            (event) => {
                              (event.checked) ? setProduct({ ...product, isPaletized: event.checked })
                                : setProduct({ ...product, isPaletized: event.checked });
                            }
                          }
                          checked={Boolean(product.isPaletized)} />
                      </div>
                      <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('isDieCut')}</h5></div>
                      {!product.isDieCut &&
                        <div className="p-col-8 p-md-8 p-lg-11">
                          <Checkbox
                            onChange={
                              e => setProduct({ ...product, isDieCut: e.checked, amountDieCut: constants.maxDieCut })
                            }
                            checked={Boolean(product.isDieCut)}
                            readOnly={readOnly}
                            id="isDieCut"
                            className="input"
                          />
                        </div>
                      }
                      {product.isDieCut && <>
                        <div className="p-col-4 p-md-1 p-lg-1">
                          <Checkbox
                            id="isDieCut"
                            className="input"
                            onChange={(event) => setProduct({ ...product, isDieCut: event.checked, amountDieCut: 0 })}
                            checked={Boolean(product.isDieCut)}
                            readOnly={readOnly}
                          />
                        </div>
                        <div id='chargeRadioButton' className="p-col-4 p-md-4 p-lg-2">
                          <label htmlFor="dieCutCharge" className="p-radiobutton-label">{t('noCharge')}</label>
                          <Checkbox
                            readOnly={readOnly}
                            inputId="dieCutCharge"
                            onChange={() => (product.amountDieCut === 0)
                              ? setProduct({ ...product, amountDieCut: constants.maxDieCut })
                              : setProduct({ ...product, amountDieCut: 0 })}
                            checked={product.amountDieCut === 0}
                          />
                        </div>
                        <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('amountDieCut')}</h5></div>
                        <div className="p-col-8 p-md-8 p-lg-7">
                          <InputText
                            readOnly={readOnly}
                            id='amountDieCut'
                            keyfilter="pnum"
                            value={product.amountDieCut}
                            className="input-small-row"
                            onChange={(event) => setProduct({ ...product, amountDieCut: event.target.value })}
                          />
                        </div>
                      </>}
                      <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('cliche')}</h5></div>
                      {!product.isCliche &&
                        <div className="p-col-8 p-md-8 p-lg-11">
                          <Checkbox
                            readOnly={readOnly}
                            id="cliche"
                            className="input"
                            onChange={
                              e => setProduct({ ...product, isCliche: e.checked, amountCliche: constants.maxCliche })
                            }
                            checked={Boolean(product.isCliche)}
                          />
                        </div>
                      }
                      {product.isCliche && <>
                        <div className="p-col-4 p-md-1 p-lg-1">
                          <Checkbox
                            readOnly={readOnly}
                            id="cliche"
                            className="input"
                            onChange={(event) => setProduct({ ...product, isCliche: event.checked, amountCliche: 0 })}
                            checked={Boolean(product.isCliche)}
                          />
                        </div>
                        <div id='chargeRadioButton' className="p-col-4 p-md-4 p-lg-2">
                          <label htmlFor="clicheCharge" className="p-radiobutton-label">{t('noCharge')}</label>
                          <Checkbox
                            readOnly={readOnly}
                            inputId="clicheCharge"
                            onChange={() => (product.amountCliche === 0)
                              ? setProduct({ ...product, amountCliche: constants.maxCliche })
                              : setProduct({ ...product, amountCliche: 0 })}
                            checked={product.amountCliche === 0}
                          />
                        </div>
                        <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('amountCliche')}</h5></div>
                        <div className="p-col-8 p-md-8 p-lg-7">
                          <InputText
                            readOnly={readOnly}
                            id='amountCliche'
                            keyfilter="pnum"
                            value={product.amountCliche}
                            className="input-small-row"
                            onChange={(event) => setProduct({ ...product, amountCliche: event.target.value })}
                          />
                        </div>
                        <div className="p-col-12 p-md-12 p-lg-12">
                          <h4>{t('colors')}</h4>
                        </div>
                        <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('color1')}</h5></div>
                        <div className="p-col-8 p-md-8 p-lg-2">
                          <InputText
                            readOnly={readOnly}
                            id='color1'
                            value={product.color1}
                            placeholder={t('color1')}
                            className="input-color-row"
                            onChange={(event) => setProduct({ ...product, color1: event.target.value })}
                          />
                        </div>
                        <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('color2')}</h5></div>
                        <div className="p-col-8 p-md-8 p-lg-2">
                          <InputText
                            readOnly={readOnly}
                            id='color2'
                            value={product.color2}
                            placeholder={t('color2')}
                            className="input-color-row"
                            onChange={(event) => setProduct({ ...product, color2: event.target.value })}
                          />
                        </div>
                        <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('color3')}</h5></div>
                        <div className="p-col-8 p-md-8 p-lg-2">
                          <InputText
                            readOnly={readOnly}
                            id='color3'
                            value={product.color3}
                            placeholder={t('color3')}
                            className="input-color-row"
                            onChange={(event) => setProduct({ ...product, color3: event.target.value })}
                          />
                        </div>
                        <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('color4')}</h5></div>
                        <div className="p-col-8 p-md-8 p-lg-2">
                          <InputText
                            readOnly={readOnly}
                            id='color4'
                            value={product.color4}
                            placeholder={t('color4')}
                            className="input-color-row"
                            onChange={(event) => setProduct({ ...product, color4: event.target.value })}
                          />
                        </div></>}
                      <div className="p-col-12 p-md-12 p-lg-12">
                        <h3>{t('waterproofing')}</h3>
                      </div>
                      <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('regular')}</h5></div>
                      <div className="p-col-8 p-md-8 p-lg-11">
                        <Checkbox
                          readOnly={readOnly}
                          name='waterproofing'
                          id="regularWaterproofing"
                          style={{ marginTop: '15px' }}
                          className="input"
                          onChange={(e) => setProduct({ ...product, regularWaterproofing: e.checked })}
                          checked={Boolean(product.regularWaterproofing)}
                        />
                      </div>
                      {product.regularWaterproofing && <>
                        <div className="p-col-3">
                          <div className="p-col-12">
                            <RadioButton
                              readOnly={readOnly}
                              inputId="rb1"
                              style={{ marginLeft: '40px' }}
                              name="city"
                              value="New York"
                              onChange={() => setProduct({ ...product, waterproofedFaces: 1 })}
                              checked={product.waterproofedFaces === 1}
                            />
                            <label htmlFor="rb1" className="p-radiobutton-label">{t('oneFace')}</label>
                          </div>
                          <div className="p-col-12">
                            <RadioButton
                              readOnly={readOnly}
                              inputId="rb2"
                              style={{ marginLeft: '40px' }}
                              name="city"
                              value="San Francisco"
                              onChange={() => setProduct({ ...product, waterproofedFaces: 2 })}
                              checked={product.waterproofedFaces === 2}
                            />
                            <label htmlFor="rb2" className="p-radiobutton-label">{t('twoFaces')}</label>
                          </div>
                        </div>
                        {product.waterproofedFaces === 1 && <>
                          <div className="p-col-4 p-md-4 p-lg-2" style={{ marginTop: '0.8em' }}>
                            <label htmlFor="oneWaterCutCharge" className="p-radiobutton-label">{t('noCharge')}</label>
                            <Checkbox
                              readOnly={readOnly}
                              inputId="oneWaterCutCharge"
                              onChange={() => (productAmountOneRegularFaceWaterproofing === 0)
                                ? setProductAmountOneRegularFaceWaterproofing(constants.oneFaceRegularWaterproofing)
                                : setProductAmountOneRegularFaceWaterproofing(0)}
                              checked={productAmountOneRegularFaceWaterproofing === 0}
                            />
                          </div>
                          <div className="p-col-4 p-md-4 p-lg-2"><h5>{t('amountOneFaceWaterproofing')}</h5></div>
                          <div className="p-col-8 p-md-8 p-lg-5">
                            <InputText
                              readOnly={readOnly}
                              id='amountOneRegularFaceWaterproofing'
                              keyfilter="pnum"
                              value={productAmountOneRegularFaceWaterproofing}
                              required
                              className="input-small-row"
                              onChange={(event) => setProductAmountOneRegularFaceWaterproofing(event.target.value)}
                            />
                          </div>
                        </>}
                        {product.waterproofedFaces === 2 && <>
                          <div className="p-col-4 p-md-4 p-lg-2" style={{ marginTop: '3.4em' }}>
                            <label htmlFor="twoWaterCutCharge" className="p-radiobutton-label">{t('noCharge')}</label>
                            <Checkbox
                              readOnly={readOnly}
                              inputId="twoWaterCutCharge"
                              onChange={() => (productAmountTwoRegularFacesWaterproofing === 0)
                                ? setProductAmountTwoRegularFacesWaterproofing(constants.twoFacesRegularWaterproofing)
                                : setProductAmountTwoRegularFacesWaterproofing(0)}
                              checked={productAmountTwoRegularFacesWaterproofing === 0}
                            />
                          </div>
                          <div className="p-col-4 p-md-4 p-lg-2" style={{ marginTop: '34px' }}>
                            <h5>{t('amountTwoFacesWaterproofing')}</h5>
                          </div>
                          <div className="p-col-8 p-md-8 p-lg-5" style={{ marginTop: '38px' }}>
                            <InputText
                              readOnly={readOnly}
                              id='amountTwoRegularFacesWaterproofing'
                              keyfilter="pnum"
                              value={productAmountTwoRegularFacesWaterproofing}
                              required
                              className="input-small-row"
                              onChange={(event) => setProductAmountTwoRegularFacesWaterproofing(event.target.value)}
                            />
                          </div>
                        </>}
                        <div className="p-col-12"></div>
                      </>}
                      {(selectedBox.name === SHEET || selectedBox.name === 'CST') && <>
                        <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('cobb')}</h5></div>
                        <div className="p-col-8 p-md-8 p-lg-11">
                          <Checkbox
                            readOnly={readOnly}
                            name='waterproofing'
                            id="cobbWaterproofing"
                            style={{ marginTop: '15px' }}
                            className="input"
                            onChange={(e) => setProduct({ ...product, cobbWaterproofing: e.checked })}
                            checked={Boolean(product.cobbWaterproofing)}
                          />
                        </div>
                        {product.cobbWaterproofing && <>
                          <div className="p-col-3">
                            <div className="p-col-12">
                              <RadioButton
                                readOnly={readOnly}
                                style={{ marginLeft: '40px' }}
                                inputId="rb1"
                                name="city"
                                value="New York"
                                onChange={() => setProduct({ ...product, waterproofedFaces: 1 })}
                                checked={product.waterproofedFaces === 1}
                              />
                              <label htmlFor="rb1" className="p-radiobutton-label">{t('oneFace')}</label>
                            </div>
                            <div className="p-col-12">
                              <RadioButton
                                readOnly={readOnly}
                                style={{ marginLeft: '40px' }}
                                inputId="rb2"
                                name="city"
                                value="San Francisco"
                                onChange={() => setProduct({ ...product, waterproofedFaces: 2 })}
                                checked={product.waterproofedFaces === 2}
                              />
                              <label htmlFor="rb2" className="p-radiobutton-label">{t('twoFaces')}</label>
                            </div>
                          </div>
                          {product.waterproofedFaces === 1 && <>
                            <div className="p-col-4 p-md-4 p-lg-2" style={{ marginTop: '0.8em' }}>
                              <label htmlFor="oneCobbWaterCutCharge" className="p-radiobutton-label">{t('noCharge')}</label>
                              <Checkbox
                                readOnly={readOnly}
                                inputId="oneCobbWaterCutCharge"
                                onChange={() => (productAmountOneCobbFaceWaterproofing === 0)
                                  ? setProductAmountOneCobbFaceWaterproofing(constants.oneFaceCobbWaterproofing)
                                  : setProductAmountOneCobbFaceWaterproofing(0)}
                                checked={productAmountOneCobbFaceWaterproofing === 0}
                              />
                            </div>
                            <div className="p-col-4 p-md-4 p-lg-2"><h5>{t('amountOneFacesWaterproofing')}</h5></div>
                            <div className="p-col-8 p-md-8 p-lg-5">
                              <InputText
                                readOnly={readOnly}
                                id='amountOneCobbFaceWaterproofing'
                                keyfilter="pnum"
                                value={productAmountOneCobbFaceWaterproofing}
                                required
                                className="input-small-row"
                                onChange={(event) => setProductAmountOneCobbFaceWaterproofing(event.target.value)}
                              />
                            </div>
                          </>}
                          {product.waterproofedFaces === 2 && <>
                            <div className="p-col-4 p-md-4 p-lg-2" style={{ marginTop: '3.4em' }}>
                              <label htmlFor="twoCobbWaterCutCharge" className="p-radiobutton-label">{t('noCharge')}</label>
                              <Checkbox
                                readOnly={readOnly}
                                inputId="twoCobbWaterCutCharge"
                                onChange={() => (productAmountTwoCobbFacesWaterproofing === 0)
                                  ? setProductAmountTwoCobbFacesWaterproofing(constants.twoFacesCobbWaterproofing)
                                  : setProductAmountTwoCobbFacesWaterproofing(0)}
                                checked={productAmountTwoCobbFacesWaterproofing === 0}
                              />
                            </div>
                            <div className="p-col-4 p-md-4 p-lg-2" style={{ marginTop: '34px' }}>
                              <h5>{t('amountTwoFacesWaterproofing')}</h5>
                            </div>
                            <div className="p-col-8 p-md-8 p-lg-5" style={{ marginTop: '38px' }}>
                              <InputText
                                readOnly={readOnly}
                                id='amountTwoCobbFacesWaterproofing'
                                keyfilter="pnum"
                                value={productAmountTwoCobbFacesWaterproofing}
                                required
                                className="input-small-row"
                                onChange={(event) => setProductAmountTwoCobbFacesWaterproofing(event.target.value)}
                              />
                            </div>
                          </>}
                        </>}
                      </>}
                      {(selectedBox.name === 'RSC' || selectedBox.name === 'HSC') && <>
                        <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('cobbExterior')}</h5></div>
                        {!product.cobbWaterproofing && <>
                          <div className="p-col-8 p-md-8 p-lg-5">
                            <Checkbox
                              readOnly={readOnly}
                              name='waterproofing'
                              id="cobbWaterproofing"
                              style={{ marginTop: '15px' }}
                              className="input"
                              onChange={(e) =>
                                setProduct({ ...product, cobbWaterproofing: e.checked, waterproofedFaces: 1 })}
                              checked={Boolean(product.cobbWaterproofing)}
                            />
                          </div>
                        </>}
                        {product.cobbWaterproofing && <>
                          <div className="p-col-8 p-md-8 p-lg-1">
                            <Checkbox
                              readOnly={readOnly}
                              name='waterproofing'
                              id="cobbWaterproofing"
                              style={{ marginTop: '15px' }}
                              className="input"
                              onChange={(e) =>
                                setProduct({ ...product, cobbWaterproofing: e.checked, waterproofedFaces: 1 })}
                              checked={Boolean(product.cobbWaterproofing)}
                            />
                          </div>
                          <div className="p-col-4 p-md-4 p-lg-2" style={{ marginTop: '0.8em' }}>
                            <label htmlFor="oneCobbWaterCutCharge" className="p-radiobutton-label">{t('noCharge')}</label>
                            <Checkbox
                              readOnly={readOnly}
                              inputId="oneCobbWaterCutCharge"
                              onChange={() => (productAmountOneCobbFaceWaterproofing === 0)
                                ? setProductAmountOneCobbFaceWaterproofing(constants.oneFaceCobbWaterproofing)
                                : setProductAmountOneCobbFaceWaterproofing(0)}
                              checked={productAmountOneCobbFaceWaterproofing === 0}
                            />
                          </div>
                          <div className="p-col-4 p-md-4 p-lg-2"><h5>{t('amountOneFaceWaterproofing')}</h5></div>
                          <div className="p-col-8 p-md-8 p-lg-5">
                            <InputText
                              readOnly={readOnly}
                              id='amountOneCobbFaceWaterproofing'
                              keyfilter="pnum"
                              value={productAmountOneCobbFaceWaterproofing}
                              required
                              className="input-small-row"
                              onChange={(event) => setProductAmountOneCobbFaceWaterproofing(event.target.value)}
                            />
                          </div>
                        </>}
                      </>}
                      <div className="p-col-12 p-md-12 p-lg-12"><h3>{t('measurements')}</h3></div>
                      {selectedBox.name !== SHEET && !isSample && <>
                        <div className="p-col-12 p-md-12 p-lg-12"><h4>{t('internal')}</h4></div>
                        <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('long')} (mm)</h5></div>
                        <div className="p-col-8 p-md-8 p-lg-3">
                          <InputText
                            readOnly={readOnly}
                            id='internalLong'
                            value={product.internalLong}
                            keyfilter="pnum"
                            placeholder={t('internalLong')}
                            className="input-full-row"
                            onChange={(event) => setProduct({ ...product, internalLong: event.target.value })}
                          />
                        </div>
                        <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('width')} (mm)</h5></div>
                        <div className="p-col-8 p-md-8 p-lg-3">
                          <InputText
                            readOnly={readOnly}
                            id='internalWidth'
                            value={product.internalWidth}
                            keyfilter="pnum"
                            placeholder={t('internalWidth')}
                            className="input-full-row"
                            onChange={(event) => setProduct({ ...product, internalWidth: event.target.value })}
                          />
                        </div>
                        <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('height')} (mm)</h5></div>
                        <div className="p-col-8 p-md-8 p-lg-3">
                          <InputText
                            readOnly={readOnly}
                            id='internalHeight'
                            value={product.internalHeight}
                            keyfilter="pnum"
                            placeholder={t('internalHeight')}
                            className="input-full-row"
                            onChange={(event) => setProduct({ ...product, internalHeight: event.target.value })}
                          />
                        </div>
                      </>}
                      <div className="p-col-12 p-md-12 p-lg-12"><h4>{t('sheet')}</h4></div>
                      {(selectedBox.name === SHEET) && <>
                        <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('long')} (cm)</h5></div>
                        <div className="p-col-8 p-md-8 p-lg-2">
                          <div id="tooltip" data-tooltip={calculateLongInInches()}>
                            <InputText
                              readOnly={readOnly}
                              id='sheetLong'
                              tooltipOptions={{ event: 'focus' }}
                              tooltip={t('cmMeasure')}
                              value={product.sheetLong}
                              keyfilter="pnum"
                              placeholder={t('sheetLong')}
                              className="input-full-row"
                              onChange={(event) => setProduct({ ...product, sheetLong: event.target.value })}
                            />
                          </div>
                        </div>
                        <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('width')} (cm)</h5></div>
                        <div className="p-col-8 p-md-8 p-lg-2">
                          <div id="tooltip" data-tooltip={calculateWidthInInches()}>
                            <InputText
                              readOnly={readOnly}
                              id='sheetWidth'
                              tooltipOptions={{ event: 'focus' }}
                              tooltip={t('cmMeasure')}
                              value={product.sheetWidth}
                              keyfilter="pnum"
                              placeholder={t('sheetWidth')}
                              className="input-full-row"
                              onChange={(event) => setProduct({ ...product, sheetWidth: event.target.value })}
                            />
                          </div>
                        </div>
                        <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('sheetArea')}</h5></div>
                        <div className="p-col-8 p-md-8 p-lg-2" style={{ marginTop: '15px' }}>{calculateSheetArea()}</div></>
                      }
                      {selectedBox.name !== SHEET && <>
                        <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('long')} (cm)</h5></div>
                        <div
                          className="p-col-8 p-md-8 p-lg-2"
                          style={{ marginTop: '15px' }}
                          id="tooltip"
                          data-tooltip={calculateLongInInches()}
                        >
                          {calculateSheetLong()}
                        </div>
                        <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('width')} (cm)</h5></div>
                        <div
                          className="p-col-8 p-md-8 p-lg-2"
                          style={{ marginTop: '15px' }}
                          id="tooltip"
                          data-tooltip={calculateWidthInInches()}
                        >
                          {calculateSheetWidth()}
                        </div>
                        <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('sheetArea')}</h5></div>
                        <div className="p-col-8 p-md-8 p-lg-2" style={{ marginTop: '15px' }}>{calculateArea()}</div></>
                      }
                      <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('weight')}</h5></div>
                      <div className="p-col-8 p-md-8 p-lg-2" style={{ marginTop: '15px' }}>{calculateWeight()}</div>
                      {selectedBox.type === DIE_CUT && <>
                        <div className="p-col-12 p-md-12 p-lg-12"><h4>{t('internal')}</h4></div>
                        <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('long')} (mm)</h5></div>
                        <div className="p-col-8 p-md-8 p-lg-2">
                          <InputText
                            readOnly={readOnly}
                            id='internalLong'
                            value={product.internalLong}
                            keyfilter="pnum"
                            placeholder={t('internalLong')}
                            className="input-full-row"
                            onChange={(event) => setProduct({ ...product, internalLong: event.target.value })}
                          />
                        </div>
                        <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('width')} (mm)</h5></div>
                        <div className="p-col-8 p-md-8 p-lg-2">
                          <InputText
                            readOnly={readOnly}
                            id='internalWidth'
                            value={product.internalWidth}
                            keyfilter="pnum"
                            placeholder={t('internalWidth')}
                            className="input-full-row"
                            onChange={(event) => setProduct({ ...product, internalWidth: event.target.value })}
                          />
                        </div>
                        <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('height')} (mm)</h5></div>
                        <div className="p-col-8 p-md-8 p-lg-5">
                          <InputText
                            readOnly={readOnly}
                            id='internalHeight'
                            value={product.internalHeight}
                            keyfilter="pnum"
                            placeholder={t('internalHeight')}
                            className="input-medium-row"
                            onChange={(event) => setProduct({ ...product, internalHeight: event.target.value })}
                          />
                        </div></>}
                      <div className="p-col-12 p-md-12 p-lg-12">
                        <h3>{t('costs')}</h3>
                      </div>
                      {isSample && <>
                        <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('thirdProcesses')}</h5></div>
                        <div className="p-col-8 p-md-8 p-lg-11">
                          <InputText
                            readOnly={readOnly}
                            id='thirdProcesses'
                            keyfilter="pnum"
                            value={product.thirdProcesses}
                            className="input-small-row"
                            onChange={(event) => setProduct({ ...product, thirdProcesses: event.target.value })}
                          />
                        </div>
                      </>}
                      {!isSample && <>
                        <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('wastePercentage')}</h5></div>
                        <div className="p-col-8 p-md-8 p-lg-3" style={{ marginTop: '15px' }}>
                          {product.wastePercentage || t('doesNotApply')}
                        </div>
                        <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('thirdProcesses')}</h5></div>
                        <div className="p-col-8 p-md-8 p-lg-3">
                          <InputText
                            readOnly={readOnly}
                            id='thirdProcesses'
                            keyfilter="pnum"
                            value={product.thirdProcesses}
                            className="input-full-row"
                            onChange={(event) => setProduct({ ...product, thirdProcesses: event.target.value })}
                          />
                        </div>
                        <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('priceXtc')}</h5></div>
                        <div className="p-col-8 p-md-8 p-lg-3" style={{ marginTop: '15px' }}>
                          {product.priceByTc ?? 0}
                        </div>
                        <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('clicheCost')}</h5></div>
                        <div className="p-col-8 p-md-8 p-lg-3" style={{ marginTop: '15px' }}>
                          {currencyFormatter.format(calculateClicheCost())}
                        </div>
                        <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('dieCutCost')}</h5></div>
                        <div className="p-col-8 p-md-8 p-lg-3" style={{ marginTop: '15px' }}>
                          {currencyFormatter.format(calculateDieCutCost())}
                        </div>
                        <div className="p-col-4 p-md-4 p-lg-1"><h5>{t('unitPrice')}</h5></div>
                        <div className="p-col-8 p-md-8 p-lg-3">
                          {/* {currencyFormatter.format(calculateUnitPrice())} */}
                          <InputText
                            readOnly={readOnly}
                            id='priceXtc'
                            keyfilter="num"
                            value={product.unitPrice ?? calculateUnitPrice()}
                            className="input-full-row"
                            onChange={setPriceByTc}
                          />
                        </div>
                      </>}
                    </div>
                    <div className="p-col-12 p-md-12 p-lg-12">
                      <h3>{t('attachedReferences')}</h3>
                    </div>
                    {(!readOnly || isProductClosedAndRoleProductDesign) &&
                      <div className="container">
                        <div {...getRootProps({ style })}>
                          <input {...getInputProps()} />
                          <p>{t('dragndropText')}</p>
                        </div>
                      </div>
                    }
                    <div className="content-section implementation">
                      <DataTable value={productDetails} rows={15}>
                        <Column field="name" header={t('name')} />
                        <Column field="description" header={t('description')} />
                        <Column style={{ textAlign: 'center' }} body={actions} header={t('actions')} />
                      </DataTable>
                      <Dialog
                        visible={displayDialog}
                        footer={dialogFooter}
                        header={t('productDetail')}
                        modal={true}
                        onHide={() => setDisplayDialog(false)}
                      >
                        {productDetail &&
                          <div className="p-grid p-fluid">
                            <div className="p-col-4" style={{ padding: '.75em' }}>
                              <label htmlFor="name">{t('name')}</label>
                            </div>
                            <div className="p-col-8" style={{ padding: '.5em' }}>
                              <InputTextarea
                                id="fileName"
                                onChange={(e) => setProductDetail({ ...productDetail, name: e.target.value })}
                                value={productDetail.name}
                              />
                            </div>
                            <div className="p-col-4" style={{ padding: '.75em' }}>
                              <label htmlFor="description">{t('description')}</label>
                            </div>
                            <div className="p-col-8" style={{ padding: '.5em' }}>
                              <InputTextarea
                                id="description"
                                onChange={(e) => setProductDetail({ ...productDetail, description: e.target.value })}
                                value={productDetail.description}
                              />
                            </div>
                          </div>
                        }
                      </Dialog>
                    </div>
                    {dialogNewProductCode()}
                    <div className="ui-button-group">
                      {isProductClosedAndRoleProductDesign &&
                        <Button
                          id='button-save'
                          icon="pi pi-save"
                          label={t('save')}
                          style={{ marginRight: '10px', marginTop: '10px', width: 'auto' }}
                        />
                      }
                      {!readOnly && <>
                        <Button
                          id='button-save'
                          icon="pi pi-save"
                          label={t('save')}
                          style={{ marginRight: '10px', marginTop: '10px', width: 'auto' }}
                        />
                        {product.approvalDate && (currentUser.codeRole === SALES_MANAGER_CODE
                          || currentUser.codeRole === 'DO' || currentUser.codeRole === 'JCS')
                          && isSample &&
                          <Button
                            id='button-turnSample'
                            icon="pi pi-clone"
                            label={t('turnIntoProduct')}
                            style={{ marginRight: '10px', marginTop: '10px', width: 'auto' }}
                            onClick={(e) => { e.preventDefault(); setDisplayNewProductCodeDialog(true); }}
                            className="btn-primary"
                          />
                        }
                        <Button
                          id='button-delete'
                          type="button"
                          icon="pi pi-trash"
                          label={t('deactivate')}
                          style={{ marginRight: '10px', width: 'auto' }}
                          onClick={handleDelete}
                          className="btn-danger"
                        />
                      </>}
                      {(currentUser.superUser || !isClose) &&
                        <>
                          {isClose === 1 &&
                            <NewVersion
                              history={history}
                              productCode={product.code}
                            />
                          }
                          <Button
                            id='button-return'
                            type="button"
                            icon={isClose ? 'pi pi-unlock' : 'pi pi-lock'}
                            label={isClose ? t('openProduct') : t('closeProduct')}
                            style={{ marginRight: '10px', marginTop: '10px', width: 'auto' }}
                            onClick={() => {
                              setProduct({ ...product, isClose: !isClose });
                              setHandleIsClose(true);
                            }}
                            className="btn-register"
                          />
                        </>
                      }
                      {(readOnly && !isClose) &&
                        <Button
                          id='button-activate'
                          type="button"
                          icon="pi pi-user-plus"
                          label={t('activate')}
                          style={{ marginRight: '10px', width: 'auto' }}
                          onClick={handleActivate}
                          className="btn-primary"
                        />
                      }
                      <Button
                        id='button-return'
                        type="button"
                        icon="pi pi-times"
                        label={t('cancel')}
                        style={{ marginRight: '10px', marginTop: '10px', width: 'auto' }}
                        onClick={cancel}
                      />
                    </div>
                  </form>
                </div>
              </>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ProductEdit;
