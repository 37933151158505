export const cleanProduct = () => {
  localStorage.setItem('selectedClientPT', '');
  localStorage.setItem('selectedFilterPT', JSON.stringify([]));
  localStorage.setItem('paramsProduct', JSON.stringify({
    code: '',
    idClient: '',
    version: '',
    name: '',
    type: '',
    internalHeight: '',
    internalWidth: '',
    internalLong: '',
    search: false,
    active: true
  }));
};

export const cleanTest = () => {
  localStorage.setItem('paramsTests', JSON.stringify({
    active: '',
    combination: '',
    name: '',
    search: false
  }));
};

export const cleanProspect = () => {
  localStorage.setItem('paramsProspect', JSON.stringify({
    name: '',
    alias: '',
    email: '',
    isProspect: 1,
    search: false
  }));
};

export const cleanClient = () => {
  localStorage.setItem('selectedClientCl', JSON.stringify({name: ''}));
  localStorage.setItem('paramsClient', JSON.stringify({
    name: '',
    alias: '',
    email: '',
    isProspect: 0,
    active: '',
    search: false
  }));
};

export const cleanLocalStore = () => {
  const pathName = window.location.pathname;
  const pathProforma = 'proforma';
  const pathProducts = 'product';
  const pathPurchaseOrder = 'purchaseorder';
  const pathTests = 'test';
  const pathClient = 'client';
  const pathProspect = 'prospect';

  if (!pathName.toLowerCase().includes(pathProforma)) {
    localStorage.setItem('selectedClientPF', '');
    localStorage.setItem('selectedStatusPF', JSON.stringify({}));
    localStorage.setItem('selectedUserPF', JSON.stringify({}));
    localStorage.setItem('paramsProforma', JSON.stringify({
      status: '',
      idClient: '',
      createdBy: '',
      date: '',
      search: false
    }));
  }

  if (!pathName.toLowerCase().includes(pathProducts)) {
    cleanProduct();
  }

  if (!pathName.toLowerCase().includes(pathPurchaseOrder)) {
    localStorage.setItem('selectedClientOC', '');
    localStorage.setItem('selectedStatusOC', '');
    localStorage.setItem('paramsOC', JSON.stringify({
      status: '',
      idClient: '',
      date: '',
      search: false
    }));
  }

  if (!pathName.toLowerCase().includes(pathTests)) {
    cleanTest();
  }

  if (!pathName.toLowerCase().includes(pathClient)) {
    cleanClient();
  }

  if (!pathName.toLowerCase().includes(pathProspect)) {
    cleanProspect();
  }
};

export const getProductStorage = () => {
  let JSONData = {
    paramsProduct: {active: true},
    selectedClientPT: '',
    selectedFilterPT: []
  };

  if (localStorage.getItem('paramsProduct') !== null) {
    JSONData.paramsProduct = JSON.parse(localStorage.getItem('paramsProduct'));
    localStorage.setItem('paramsProduct', JSON.stringify(JSONData.paramsProduct));
    if (localStorage.getItem('selectedClientPT') !== null
    && JSONData.paramsProduct.idClient !== '') {
      JSONData.selectedClientPT = JSON.parse(localStorage.getItem('selectedClientPT'));
    } else {
      localStorage.setItem('selectedClientPT', '');
    }
    if (localStorage.getItem('selectedFilterPT') !== null) {
      JSONData.selectedFilterPT = JSON.parse(localStorage.getItem('selectedFilterPT'));
    } else {
      localStorage.setItem('selectedFilterPT', JSON.stringify([]));
    }
  } else {
    cleanProduct();
  }

  return JSONData;
};

export const getTestsStorage = () => {
  let JSONData = {
    paramsTests: {}
  };

  if (localStorage.getItem('paramsTests') !== null) {
    JSONData.paramsTests = JSON.parse(localStorage.getItem('paramsTests'));
    localStorage.setItem('paramsTests', JSON.stringify(JSONData.paramsTests));
  } else {
    cleanProduct();
  }

  return JSONData;
};

export const getProspectStorage = () => {
  let JSONData = {
    paramsProspect: {}
  };

  if (localStorage.getItem('paramsProspect') !== null) {
    JSONData.paramsProspect = JSON.parse(localStorage.getItem('paramsProspect'));
    localStorage.setItem('paramsProspect', JSON.stringify(JSONData.paramsProspect));
  } else {
    cleanProspect();
  }

  return JSONData;
};

export const getClientStorage = () => {
  let JSONData = {
    paramsClient: {},
    selectedClientCl: {name: ''}
  };

  if (localStorage.getItem('paramsClient') !== null) {
    JSONData.paramsClient = JSON.parse(localStorage.getItem('paramsClient'));
    localStorage.setItem('paramsClient', JSON.stringify(JSONData.paramsClient));
    if (localStorage.getItem('selectedClientCl') !== null
    && JSONData.paramsClient.idClient !== '') {
      JSONData.selectedClientCl = JSON.parse(localStorage.getItem('selectedClientCl'));
    } else {
      localStorage.setItem('selectedClientCl', JSON.stringify({name: ''}));
    }
  } else {
    cleanProduct();
  }

  return JSONData;
};
