import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { getClientsByParams } from '../../../services/client/client';
import { messageError } from '../../../utils/messages';
import { Dropdown } from 'primereact/dropdown';
import { routes } from '../../../utils/routes';
import { AutoComplete } from 'primereact/autocomplete';
import { getClientsByKey } from '../../../services/client/client';
import { getClientStorage, cleanClient } from '../../../utils/cleanLocalStore';
import './list.css';

function ClientList ({history}) {
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showData, setShowData] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [params, setParams] = useState({
    alias: '',
    email: '',
    name: '',
    isProspect: 0,
    active: ''
  });
  const { t } = useTranslation();

  const actionEdit = (rowData) => {
    return <div style={{content: 'center', textAlign: 'center'}} id={'div-' + rowData.id}>
      <Button
        onClick={() => updateClient(rowData)}
        type="button"
        icon="pi pi-pencil"
        id='button-edit'
        className="p-button-primary"
      >
        <span className="tooltiptext tooltiptext-bottom"></span>
      </Button>
    </div>;
  };

  const updateClient = (client) => {
    history.push({
      pathname: routes.ClientEdit,
      state: { clientUpdate: client }
    });
  };

  const searchClients = (searchClients = '') => {
    const postParamts = searchClients !== '' ? {...searchClients} : {...params};
    localStorage.setItem('paramsClient', JSON.stringify({...postParamts, search: true}));
    setLoading(true);
    delete postParamts.search;
    getClientsByParams(postParamts).then(result => {
      setLoading(false);
      setClients(result.data);
      setShowData(true);
    }).catch(error => {
      setLoading(false);
      if (error.response) {
        setShowData(true);
        setClients([]);
        messageError(error.response.data.message);
      }
      else {
        messageError(t('withoutConnection'));
      }
    });
  };

  const clean = () => {
    setSelectedClient({name: ''});
    setParams({
      name: '',
      alias: '',
      email: '',
      isProspect: 0,
      active: ''
    });
    cleanClient();
  };

  const showState = (rowData) => {
    switch (rowData.active) {
    case 1:
      return t('active');
    case 0:
      return t('inactive');
    default:
      return t('inactive');
    }
  };

  const handleClientOnChange = (e) => {
    setLoading(false);
    getClientsByKey(e.query).then(result => {
      setClients(result.data);
      setShowData(true);
    });
  };

  const filters = [
    {label: t('allStates'), value: ''},
    {label: t('active'), value: 1},
    {label: t('inactive'), value: 0}
  ];

  const addClient = () => {
    history.push(routes.ClientRegister);
  };

  useEffect(() => {
    const {
      paramsClient,
      selectedClientCl
    } = getClientStorage();
    if (selectedClientCl !== '') {
      setSelectedClient(selectedClientCl);
    }
    setParams(paramsClient);
    if (paramsClient.search) {
      searchClients(paramsClient);
    }
  }, []);

  return (
    <div>
      <div className="ui-g ui-fluid" >
        <div className="ui-g-12">
          <div className="card">
            <h1>{t('clientList')}</h1>
            <div className="ui-g-12 ui-md-7 ui-lg-2">
              <div className="p-grid p-fluid"><div className="p-col-6 p-md-2">
                <div className="p-inputgroup">
                  <span className="p-label">
                    <label htmlFor="client">{t('name')}</label>
                    <AutoComplete
                      id='client'
                      value={selectedClient}
                      field="name"
                      onSelect={(e) => {
                        setSelectedClient(e.value);
                        localStorage.setItem('selectedClientCl', JSON.stringify(e.value));
                        setParams({...params, name: e.value.name});
                      }}
                      suggestions={clients}
                      onChange={(e) => setSelectedClient(e.value)}
                      completeMethod={(e) => handleClientOnChange(e)}
                      className="dropdown-all-width"
                    />
                  </span>
                </div>
              </div>
              <div className="p-col-6 p-md-2">
                <div className="p-inputgroup">
                  <span className="p-label">
                    <label htmlFor="alias">{t('alias')}</label>
                    <InputText id='alias' value={params.alias} className="dropdown-all-width"
                      onChange={(event) => {setParams({...params, alias: event.target.value});}}/>
                  </span>
                </div>
              </div>
              <div className="p-col-6 p-md-2">
                <div className="p-inputgroup">
                  <span className="p-label">
                    <label htmlFor="email">{t('email')}</label>
                    <InputText id='email' value={params.email} className="dropdown-all-width"
                      onChange={(event) => {setParams({...params, email: event.target.value});}}/>
                  </span>
                </div>
              </div>
              <div className="p-col-6 p-md-2">
                <div className="p-inputgroup">
                  <span className="p-label">
                    <label htmlFor="state">{t('state')}</label>
                    <Dropdown
                      id='state'
                      value={filters.find(element => element.value === params.active)}
                      className="dropdown-all-width"
                      optionLabel="label"
                      optionValue="value"
                      onChange={(event) => setParams({...params, active: event.value.value})}
                      options={filters}
                    />
                  </span>
                </div>
              </div>
              <div className="p-col-5 p-md-4">
                <div className="p-inputgroup-button">
                  <Button
                    icon="pi pi-search"
                    onClick={() => searchClients()}
                    label={t('search')}
                    style={{marginBottom: '10px', width: 'auto'}}
                    className="tooltip button-table"
                  />
                  <Button
                    icon="pi pi-refresh"
                    onClick={clean}
                    label={t('clean')}
                    style={{marginBottom: '10px', width: 'auto'}}
                    className="tooltip button-table"
                  />
                  <Button
                    icon="pi pi-plus"
                    label={t('newClient')}
                    onClick={addClient}
                    style={{marginBottom: '10px', width: 'auto'}}
                    className="tooltip button-table"
                  />
                </div>
              </div>
              </div>
            </div>
            <div className="ui-g-12">
              {loading &&
                <div id="div-loader" className="spiner-container">
                  <i className="pi pi-spin pi-spinner spiner" />
                </div>
              }
              {!loading && showData &&
                  <DataTable paginator={true} emptyMessage={t('emptyList')} rows={10} responsive={true} value={clients}>
                    <Column className="ui-column" field="name" header={t('name')}/>
                    <Column className="ui-column" field="alias" header={t('alias')}/>
                    <Column className="ui-column" field="email" header={t('email')}/>
                    <Column className="ui-column" field="phone" header={t('phone')}/>
                    <Column className="ui-column-state" field="active" header={t('state')} body={showState}/>
                    <Column className="ui-column-edit" header={t('edit')} body={actionEdit}/>
                  </DataTable>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientList;
