import { axiosInstance } from '../config';
import { getUserSession, getHeaders } from '../authentication';
import { pickBy, identity } from 'lodash';

export const getAllClients = () => {
  const config = getHeaders(getUserSession());
  return axiosInstance.get('clients/list',config);
};

export const getClientsByKey = (key) => {
  const config = getHeaders(getUserSession());
  return axiosInstance.get('clients/byKey/' + key,config);
};

export const getClientById = (key) => {
  const config = getHeaders(getUserSession());
  return axiosInstance.get('clients/byId/' + key,config);
};

export const getClientsByParams = (params) => {
  let paramsSend = pickBy(params, identity);
  paramsSend.isProspect = params.isProspect;
  paramsSend.active = (params.active !== '') ? params.active : null;
  const config = {
    headers: getHeaders(getUserSession()).headers,
    params: paramsSend
  };
  return axiosInstance.get('clients/byParams/list',config);
};

export const clientCreate = (client) => {
  const config = getHeaders(getUserSession());
  return axiosInstance.post('client',client,config);
};

export const clientUpdate = (client) => {
  const config = getHeaders(getUserSession());
  return axiosInstance.put('clients/update',client,config);
};

export const clientWithoutUserCreate = (client) => {
  const config = getHeaders(getUserSession());
  return axiosInstance.post('clients/insertWithoutUser/clients',client,config);
};
