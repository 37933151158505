export const constantsObject = (data) => {
  let object = {};
  data.forEach(element => {
    switch (element.code) {
    case 'CLICHE':
      object.maxCliche = element.value;
      break;
    case 'DIE-CUT':
      object.maxDieCut = element.value;
      break;
    case '4-COLOR':
      object.forthColorPercentage = element.value;
      break;
    case '1F-RW':
      object.oneFaceRegularWaterproofing = element.value;
      break;
    case '2F-RW':
      object.twoFacesRegularWaterproofing = element.value;
      break;
    case '1F-CW':
      object.oneFaceCobbWaterproofing = element.value;
      break;
    case '2F-CW':
      object.twoFacesCobbWaterproofing = element.value;
      break;
    case 'PAL':
      object.paletizedCost = element.value;
      break;
    case 'TR-OUT-S':
      object.transportOutsideGAMSmallTruck = element.value;
      break;
    case 'TR-OUT-M':
      object.transportOutsideGAMMediumTruck = element.value;
      break;
    case 'TR-OUT-B':
      object.transportOutsideGAMBigTruck = element.value;
      break;
    case 'TR-GAM-S':
      object.transportGAMSmallTruck = element.value;
      break;
    case 'TR-GAM-M':
      object.transportGAMMediumTruck = element.value;
      break;
    case 'TR-GAM-B':
      object.transportGAMBigTruck = element.value;
      break;
    case 'BOX-QUANT':
      object.quantity = element.value;
      break;
    case 'PROD-VAR':
      object.productionVariable = element.value;
      break;
    case 'WASTE':
      object.wastePercentage = element.value;
      break;
    case 'K-OF-TON':
      object.kraftOneFace = element.value;
      break;
    case 'W-OF-TON':
      object.whiteOneFace = element.value;
      break;
    case 'W-TF-TON':
      object.whiteTwoFaces = element.value;
      break;
    default:
      break;
    }
  });
  return object;
};
