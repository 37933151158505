import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { useTranslation } from 'react-i18next';
import { clientUpdate } from '../../../services/client/client';
import { messageError, messageSuccess } from '../../../utils/messages';
import { routes } from '../../../utils/routes';
import { getUserSession } from '../../../services/authentication';
import './prospectEdit.css';

function ProspectEdit({ history }) {
  const { t } = useTranslation();
  const [prospectId, setProspectId] = useState('');
  const [prospectName, setProspectName] = useState('');
  const [prospectAlias, setProspectAlias] = useState('');
  const [prospectEmail, setProspectEmail] = useState('');
  const [prospectPhone, setProspectPhone] = useState('');
  const [prospectFax, setProspectFax] = useState('');
  const [prospectIdUser, setProspectIdUser] = useState('');
  const [prospectContactName, setProspectContactName] = useState('');
  const [prospectContactLastName, setProspectContactLastName] = useState('');
  const [prospectPosition, setProspectPosition] = useState('');
  const [prospectIva, setProspectIva] = useState(13);
  const [prospectFreeTransport, setProspectFreeTransport] = useState('');
  const [prospectLegalId, setProspectLegalId] = useState('');

  useEffect(() => {
    setProspectLegalId(history.location.state.prospectUpdate.legalId);
    setProspectIdUser(history.location.state.prospectUpdate.idUser);
    setProspectId(history.location.state.prospectUpdate.id);
    setProspectName(history.location.state.prospectUpdate.name);
    setProspectAlias(history.location.state.prospectUpdate.alias);
    setProspectEmail(history.location.state.prospectUpdate.email);
    setProspectPhone(history.location.state.prospectUpdate.phone);
    setProspectFax(history.location.state.prospectUpdate.fax);
    setProspectContactLastName(history.location.state.prospectUpdate.contactLastName);
    setProspectContactName(history.location.state.prospectUpdate.contactFirstName);
    setProspectPosition(history.location.state.prospectUpdate.position);
    setProspectFreeTransport(Boolean(history.location.state.prospectUpdate.freeTransport));
    setProspectIva(Number(history.location.state.prospectUpdate.iva) * 100);
  }, [history.location.state.prospectUpdate, t]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const prospect = {
      legalId: prospectLegalId ?? '',
      id: prospectId,
      name: prospectName,
      alias: prospectAlias ?? '',
      email: prospectEmail ?? '',
      phone: prospectPhone ?? '',
      fax: prospectFax ?? '',
      contactFirstName: prospectContactName ?? '',
      contactLastName: prospectContactLastName ?? '',
      position: prospectPosition ?? '',
      idUser: prospectIdUser ?? -1,
      iva: Number(Number((prospectIva === '') ? 13 : prospectIva) / 100).toFixed(2),
      freeTransport: Boolean(prospectFreeTransport),
      isProspect: 1
    };
    clientUpdate(prospect).then(() => {
      messageSuccess(t('messageSuccessProspectUpdated'));
      history.push(routes.Prospects);
    }).catch(error => {
      (error.response)
        ? messageError(error.response.data.message)
        : messageError(t('messageErrorProspectUpdated'));
    });
  };

  const turnProspectIntoClient = async () => {
    const prospect = {
      isProspect: 0,
      legalId: prospectLegalId ?? '',
      id: prospectId,
      name: prospectName,
      alias: prospectAlias ?? '',
      email: prospectEmail ?? '',
      phone: prospectPhone ?? '',
      fax: prospectFax ?? '',
      contactFirstName: prospectContactName ?? '',
      contactLastName: prospectContactLastName ?? '',
      position: prospectPosition ?? '',
      idUser: prospectIdUser ?? -1,
      iva: Number(Number((prospectIva === '') ? 13 : prospectIva) / 100).toFixed(2),
      freeTransport: Boolean(prospectFreeTransport)
    };
    clientUpdate(prospect).then(() => {
      messageSuccess(t('messageSuccessProspectUpdated'));
      history.push(routes.Prospects);
    }).catch(error => {
      (error.response)
        ? messageError(error.response.data.message)
        : messageError(t('messageErrorProspectUpdated'));
    });
  };

  const cancel = () => {
    history.push(routes.Prospects);
  };

  return (
    <div>
      <div className="ui-g ui-fluid" >
        <div className="ui-g-12">
          <div className="card-user">
            <div className="card">
              <div className="content-section introduction">
                <h1>{t('prospectEdit')}</h1>
                <form autoComplete="off" onSubmit={handleSubmit}>
                  <div className="p-grid">
                    <div className="p-col-4 p-md-4 p-lg-2"><h5>{t('companyName')}</h5></div>
                    <div className="p-col-8 p-md-8 p-lg-10">
                      <InputText required id='name'
                        value={prospectName} placeholder={t('companyName')}
                        className="input" onChange={(event) => { setProspectName((event.target.value)); }}
                      />
                    </div>
                    <div className="p-col-4 p-md-4 p-lg-2"><h5>{t('legalId')}</h5></div>
                    <div className="p-col-8 p-md-8 p-lg-10">
                      <InputText id='legalId' value={prospectLegalId}
                        placeholder={t('legalId')} className="input"
                        onChange={(event) => { setProspectLegalId((event.target.value)); }} />
                    </div>
                    <div className="p-col-4 p-md-4 p-lg-2"><h5>{t('alias')}</h5></div>
                    <div className="p-col-8 p-md-8 p-lg-10">
                      <InputText id='alias' value={prospectAlias}
                        placeholder={t('alias')} className="input"
                        onChange={(event) => { setProspectAlias((event.target.value)); }} />
                    </div>
                    <div className="p-col-4 p-md-4 p-lg-2"><h5>{t('email')}</h5></div>
                    <div className="p-col-8 p-md-8 p-lg-10">
                      <InputText id='email' value={prospectEmail}
                        placeholder={t('email')} className="input"
                        onChange={(event) => { setProspectEmail((event.target.value)); }} />
                    </div>
                    <div className="p-col-4 p-md-4 p-lg-2"><h5>{t('contactName')}</h5></div>
                    <div className="p-col-8 p-md-8 p-lg-4">
                      <InputText id='contactName'
                        value={prospectContactName}
                        placeholder={t('contactName')}
                        className="input"
                        onChange={(event) => { setProspectContactName((event.target.value)); }} />
                    </div>
                    <div className="p-col-4 p-md-4 p-lg-2"><h5>{t('contactLastName')}</h5></div>
                    <div className="p-col-8 p-md-8 p-lg-4">
                      <InputText id='contactLastName'
                        value={prospectContactLastName}
                        placeholder={t('contactLastName')}
                        className="input"
                        onChange={(event) => { setProspectContactLastName((event.target.value)); }} />
                    </div>
                    <div className="p-col-4 p-md-4 p-lg-2"><h5>{t('phone')}</h5></div>
                    <div className="p-col-8 p-md-8 p-lg-4">
                      <InputText id='phone'
                        value={prospectPhone} placeholder={t('phone')}
                        className="input" onChange={(event) => { setProspectPhone((event.target.value)); }} />
                    </div>
                    <div className="p-col-4 p-md-4 p-lg-2"><h5>{t('fax')}</h5></div>
                    <div className="p-col-8 p-md-8 p-lg-4">
                      <InputText id='fax' value={prospectFax}
                        placeholder={t('fax')} className="input"
                        onChange={(event) => { setProspectFax((event.target.value)); }} />
                    </div>
                    <div className="p-col-4 p-md-4 p-lg-2"><h5>{t('position')}</h5></div>
                    <div className="p-col-8 p-md-8 p-lg-10">
                      <InputText id='position' value={prospectPosition}
                        placeholder={t('position')} className="input-phone"
                        onChange={(event) => { setProspectPosition((event.target.value)); }} />
                    </div>
                    <div className="p-col-4 p-md-4 p-lg-2"><h5>{t('iva')} (%)</h5></div>
                    <div className="p-col-8 p-md-8 p-lg-10">
                      <InputText keyfilter="pnum" id='iva' value={prospectIva}
                        placeholder={t('iva')} className="input-phone"
                        onChange={(event) => { setProspectIva((event.target.value)); }} />
                    </div>
                    <div className="p-col-4 p-md-4 p-lg-2"><h5>{t('freeTransport')}</h5></div>
                    <div className="p-col-8 p-md-8 p-lg-10">
                      <Checkbox id="freeTransport" style={{ marginTop: '15px' }}
                        onChange={(e) => setProspectFreeTransport(e.checked)}
                        checked={Boolean(prospectFreeTransport)} />
                    </div>
                  </div>
                  <div className="ui-button-group">
                    <Button id='button-save' icon="pi pi-save" label={t('save')}
                      style={{ marginTop: '10px', width: 'auto' }} />
                    {getUserSession().user.codeRole === 'GV'
                      && <Button id='button-turn-prospect'
                        icon="pi pi-user"
                        label={t('turnProspectIntoClient')}
                        style={{ marginLeft: '10px', width: 'auto' }}
                        onClick={turnProspectIntoClient} />}
                    <Button id='button-return' type="button" icon="pi pi-times"
                      label={t('cancel')} style={{ margin: '10px', width: 'auto' }} onClick={cancel} />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ProspectEdit;
