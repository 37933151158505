import { axiosInstance } from '../config';
import { getUserSession, getHeaders } from '../authentication';

export const getAllProductionVariables = () => {
  const config = getHeaders(getUserSession());
  return axiosInstance.get('productionVariables/list',config);
};

export const productionVariablesUpdate = (variable) => {
  const config = getHeaders(getUserSession());
  return axiosInstance.put('productionVariables/update',variable,config);
};
