import { axiosInstance } from '../config';
import { getUserSession, getHeaders } from '../authentication';
import { pickBy, identity } from 'lodash';

export const getPurchaseOrderHeadersByParams = (params) => {
  if (params.date){
    params.fromDate = params.date[0];
    params.toDate = params.date[1];
  }
  if (getUserSession().user.codeRole === 'CL'){
    params.idClient = getUserSession().user.idClient;
  }
  delete params.date;
  let paramsSend = pickBy(params, identity);
  const config = {
    headers: getHeaders(getUserSession()).headers,
    params: paramsSend
  };
  return axiosInstance.get('purchaseOrderHeaders/byParams/list',config);
};

export const purchaseOrderCreate = (purchaseOrder) => {
  const config = getHeaders(getUserSession());
  return axiosInstance.post('purchaseOrderHeader',purchaseOrder,config);
};

export const purchaseOrderUpdate = (purchaseOrder) => {
  const config = getHeaders(getUserSession());
  return axiosInstance.put('purchaseOrderHeaders/update',purchaseOrder,config);
};
