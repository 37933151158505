import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { AutoComplete } from 'primereact/autocomplete';
import { getClientsByKey } from '../../../services/client/client';
import { getPurchaseOrderHeadersByParams } from '../../../services/purchaseOrderHeader/purchaseOrderHeader';
import { messageError } from '../../../utils/messages';
import { getUserSession } from '../../../services/authentication';
import { routes } from '../../../utils/routes';
import esCalendar from '../../../translations/esCalendar';
import './list.css';

function PurchaseOrderList({ history }) {
  const [clients, setClients] = useState([]);
  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showData, setShowData] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [params, setParams] = useState({
    status: '',
    idClient: '',
    date: ''
  });
  const { t } = useTranslation();
  const filters = [
    { label: t('pendingApproval'), value: '0' },
    { label: t('approved'), value: '1' },
    { label: t('sendToClient'), value: '2' },
    { label: t('rejected'), value: '3' }
  ];

  const actionEdit = (rowData) => {
    return <div style={{ content: 'center', textAlign: 'center' }} id={'div-' + rowData.id}>
      <Button onClick={() => updatePurchaseOrder(rowData)}
        type="button" icon="pi pi-pencil" id='button-edit' className="p-button-primary">
        <span className="tooltiptext tooltiptext-bottom"></span>
      </Button>
    </div>;
  };

  const updatePurchaseOrder = (purchaseOrder) => {
    history.push({
      pathname: routes.PurchaseOrderEdit,
      state: { purchaseOrderUpdate: purchaseOrder }
    });
  };

  const addPurchaseOrder = () => {
    history.push(routes.PurchaseOrderRegister);
  };

  useEffect(() => {
    if (localStorage.getItem('paramsOC') !== null) {
      const params = JSON.parse(localStorage.getItem('paramsOC'));
      localStorage.setItem('paramsOC', JSON.stringify({...params}));
      if (localStorage.getItem('selectedClientOC') !== null
      && params.idClient !== '') {
        setSelectedClient(JSON.parse(localStorage.getItem('selectedClientOC')));
      } else {
        localStorage.setItem('selectedClientOC', '');
      }
      if (localStorage.getItem('selectedStatusOC') !== null
      && params.status !== '') {
        setSelectedStatus(JSON.parse(localStorage.getItem('selectedStatusOC')));
      } else {
        localStorage.setItem('selectedStatusOC', '');
      }
      setParams(params);
      if (params.search) {
        searchPurchaseOrders(params);
      }
    } else {
      clean();
    }
  }, []);

  const searchPurchaseOrders = (searchOC = '') => {
    const postParams = searchOC !== '' ? {...searchOC} : {...params};
    setLoading(true);
    localStorage.setItem('paramsOC', JSON.stringify({...postParams, search: true}));
    delete postParams.search;
    getPurchaseOrderHeadersByParams(postParams).then(result => {
      setLoading(false);
      setPurchaseOrders(result.data);
      setShowData(true);
    }).catch(error => {
      cleanLocalStore();
      setLoading(false);
      if (error.response) {
        setLoading(false);
        setShowData(true);
        setPurchaseOrders([]);
        messageError(error.response.data.message);
      }
      else {
        setLoading(false);
        messageError(t('withoutConnection'));
      }
    });
  };

  const handleClientOnChange = (e) => {
    getClientsByKey(e.query).then(result => {
      setClients(result.data);
    });
  };

  const cleanLocalStore = () => {
    localStorage.setItem('selectedClientOC', '');
    localStorage.setItem('selectedStatusOC', '');
    localStorage.setItem('paramsOC', JSON.stringify({
      status: '',
      idClient: '',
      date: '',
      search: false
    }));
  };

  const clean = () => {
    setSelectedClient('');
    setSelectedStatus('');
    setParams({
      status: '',
      idClient: '',
      date: ''
    });
    cleanLocalStore();
  };

  const showState = (rowData) => {
    switch (rowData.status) {
    case 0:
      return t('pendingApproval');
    case 1:
      return t('approved');
    case 2:
      return t('rejected');
    default:
      return t('rejected');
    }
  };

  const getDate = (rowData) => {
    let d = new Date(rowData.createdOn),
      day = '' + d.getDate(),
      month = '' + (d.getMonth() + 1),
      year = d.getFullYear();
    if (month.length < 2) { month = '0' + month; }
    if (day.length < 2) { day = '0' + day; }
    return [day, month, year].join('/');
  };

  return (
    <div>
      <div className="ui-g ui-fluid" >
        <div className="ui-g-12">
          <div className="card">
            <h1>{t('purchaseOrderList')}</h1>
            <div className="ui-g-12 ui-md-7 ui-lg-2">
              <div className="p-grid p-fluid">
                <div className="p-col-6 p-md-2">
                  <div className="p-inputgroup">
                    <span className="p-label">
                      <label htmlFor="calendar">{t('date')}</label>
                      <Calendar locale={esCalendar} dateFormat="dd/mm/yy"
                        id='calendar' selectionMode="range" value={params.date}
                        className="dropdown-all-width" onChange={(e) => setParams({ ...params, date: e.value })}>

                      </Calendar>
                    </span>
                  </div>
                </div>
                {getUserSession().user.codeRole !== 'CL' &&
                  <>
                    <div className="p-col-6 p-md-2">
                      <div className="p-inputgroup">
                        <span className="p-label">
                          <label htmlFor="client">{t('client')}</label>
                          <AutoComplete id='client' value={selectedClient} field="name"
                            onSelect={(e) => {
                              setSelectedClient(e.value);
                              localStorage.setItem('selectedClientOC', JSON.stringify(e.value));
                              setParams({ ...params, idClient: e.value.id });
                            }}
                            suggestions={clients} onChange={(e) => setSelectedClient(e.value)}
                            completeMethod={(e) => handleClientOnChange(e)}
                            className="dropdown-all-width" />
                        </span>
                      </div>
                    </div>
                    <div className="p-col-6 p-md-2">
                      <div className="p-inputgroup">
                        <span className="p-label">
                          <label htmlFor="status">{t('status')}</label>
                          <Dropdown id='status' value={selectedStatus}
                            options={filters} optionLabel="label" optionValue="value"
                            className="dropdown-all-width"
                            onChange={(event) => {
                              setSelectedStatus(event.target.value);
                              localStorage.setItem('selectedStatusOC', JSON.stringify(event.target.value));
                              setParams({ ...params, status: event.target.value.value });
                            }} />
                        </span>
                      </div>
                    </div>
                  </>
                }
                <div className="p-col-5 p-md-4">
                  <div className="p-inputgroup-button">
                    <Button icon="pi pi-search" onClick={() => searchPurchaseOrders()}
                      label={t('search')} style={{ marginBottom: '10px', width: 'auto' }}
                      className="tooltip button-table" />
                    <Button icon="pi pi-refresh" onClick={clean} label={t('clean')}
                      style={{ marginBottom: '10px', width: 'auto' }} className="tooltip button-table" />
                    <Button icon="pi pi-plus" label={t('newProforma')}
                      style={{ marginBottom: '10px', width: 'auto' }} onClick={addPurchaseOrder}
                      className="tooltip button-table" />
                  </div>
                </div>
              </div>
            </div>
            <div className="ui-g-12">
              {loading && <div id="div-loader" className="spiner-container">
                <i className="pi pi-spin pi-spinner spiner" ></i>
              </div>}
              {!loading && showData &&
                <DataTable paginator={true} emptyMessage={t('emptyList')} rows={10} responsive={true} value={purchaseOrders}>
                  <Column className="ui-column-date" field="id" header={t('code')} />
                  <Column className="ui-column-date" body={getDate} header={t('date')} />
                  <Column className="ui-column" field="clients.name" header={t('client')} />
                  <Column className="ui-column" field="status" header={t('status')} body={showState} />
                  <Column className="ui-column-edit" header={t('edit')} body={actionEdit} />
                </DataTable>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PurchaseOrderList;
