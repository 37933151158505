import { axiosInstance } from '../config';
import { getUserSession, getHeaders } from '../authentication';
import { pickBy, identity } from 'lodash';

export const getProformasByParams = (params) => {
  if (params.date) {
    params.fromDate = params.date[0];
    params.toDate = params.date[1];
  }
  if (getUserSession().user.codeRole === 'EV') {
    params.createdBy = getUserSession().user.id;
  }
  if (getUserSession().user.codeRole === 'CL') {
    params.idClient = getUserSession().user.idClient;
    params.status = 2;
  }
  delete params.date;
  let paramsSend = pickBy(params, identity);
  const config = {
    headers: getHeaders(getUserSession()).headers,
    params: paramsSend
  };
  return axiosInstance.get('proformas/byParams/list', config);
};

export const proformaCreate = (proforma) => {
  proforma.status = 0;
  const config = getHeaders(getUserSession());
  return axiosInstance.post('proformaHeader', proforma, config);
};

export const proformaUpdate = (proforma) => {
  const config = getHeaders(getUserSession());
  return axiosInstance.put('proformas/update', proforma, config);
};

export const getProformaById = (id) => {
  const config = getHeaders(getUserSession());
  return axiosInstance.get('proformas/byId/' + id, config);
};

export const sendsProformaApprovalEmail = (id) => {
  const config = getHeaders(getUserSession());
  return axiosInstance.put('proformas/sendsProformaApprovalEmail/' + id, null, config);
};

export const sendsApprovedProformaEmail = (id) => {
  /* istanbul ignore else */
  const config = getHeaders(getUserSession());
  return axiosInstance.put('proformas/sendsApprovedProformaEmail/' + id, null, config);
};

export const sendProformaToClientById = (id, email, attachments) => {
  const attachmentsIds = [];
  if (attachments.length === 0) {
    attachmentsIds.push(-1);
  } else {
    attachments.forEach(element => {
      attachmentsIds.push(element.id);
    });
  }
  const config = getHeaders(getUserSession());
  return axiosInstance.put('proformas/sendProformaToClient/' + id + '/' + email + '/' + attachmentsIds, null, config);
};
