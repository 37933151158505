import { axiosInstance } from '../config';
import { getUserSession, getHeaders } from '../authentication';

export const getCountrySubdivisions1ByCountryId = (id) => {
  const config = getHeaders(getUserSession());
  return axiosInstance.get('countrySubdivisions1/byCountryId/' + id,config);
};

export const getCountrySubdivisions1ById = (id) => {
  const config = getHeaders(getUserSession());
  return axiosInstance.get('countrySubdivisions1/byId/' + id,config);
};
