import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { getTestsByParams } from '../../../services/tests/tests';
import { messageError } from '../../../utils/messages';
import { routes } from '../../../utils/routes';
import { getTestsStorage, cleanTest } from '../../../utils/cleanLocalStore';
import './list.css';

function TestList({ history }) {
  const [tests, setTests] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showData, setShowData] = useState(false);
  const [params, setParams] = useState({
    active: '1',
    combination: '',
    name: '',
    search: ''
  });
  const { t } = useTranslation();
  const selectState = [
    {label: t('active'), value: '1'},
    {label: t('inactive'), value: '0'}
  ];

  const actionEdit = (rowData) => {
    return <div style={{ content: 'center', textAlign: 'center' }} id={'div-' + rowData.id}>
      <Button onClick={() => updateTest(rowData)} type="button"
        icon="pi pi-pencil" id='button-edit' className="p-button-primary">
        <span className="tooltiptext tooltiptext-bottom"></span>
      </Button>
    </div>;
  };

  const updateTest = (test) => {
    history.push({
      pathname: routes.TestEdit,
      state: { testUpdate: test }
    });
  };

  const searchTests = (searchTests = '') => {
    const postParamts = searchTests !== '' ? {...searchTests} : {...params};
    localStorage.setItem('paramsTests', JSON.stringify({...postParamts, search: true}));
    setLoading(true);
    delete postParamts.search;
    getTestsByParams(postParamts).then(result => {
      setLoading(false);
      setTests(result.data);
      setShowData(true);
    }).catch(error => {
      setLoading(false);
      if (error.response) {
        setShowData(true);
        setTests([]);
        messageError(error.response.data.message);
      }
      else {
        messageError(t('withoutConnection'));
      }
    });
  };

  const showIsAuthorized = (rowData) => {
    if (rowData.isAuthorized === 1) {
      return <div style={{ content: 'center', textAlign: 'center' }}>
        <i style={{ content: 'center', textAlign: 'center' }} className='pi pi-check'></i>
      </div>;
    }
  };

  const showIsACtive = (rowData) => {
    if (rowData.active === 1) {
      return <div style={{ content: 'center', textAlign: 'center' }}>
        <i style={{ content: 'center', textAlign: 'center' }} className='pi pi-check'></i>
      </div>;
    }
  };

  const clean = () => {
    setParams({
      active: '',
      combination: '',
      name: ''
    });
    cleanTest();
  };

  const addTest = () => {
    history.push(routes.TestRegister);
  };

  useEffect(() => {
    const { paramsTests } = getTestsStorage();
    setParams(paramsTests);
    if (paramsTests.search) {
      searchTests(paramsTests);
    }
  }, []);

  return (
    <div>
      <div className="ui-g ui-fluid" >
        <div className="ui-g-12">
          <div className="card">
            <h1>{t('testList')}</h1>
            <div className="ui-g-12 ui-md-7 ui-lg-2">
              <div className="p-grid p-fluid"><div className="p-col-6 p-md-2">
                <div className="p-inputgroup">
                  <span className="p-label">
                    <label htmlFor="name">{t('name')}</label>
                    <InputText id='name' value={params.name} className="dropdown-all-width"
                      onChange={(event) => { setParams({ ...params, name: event.target.value }); }} />
                  </span>
                </div>
              </div>
              <div className="p-col-6 p-md-2">
                <div className="p-inputgroup">
                  <span className="p-label">
                    <label htmlFor="combination">{t('combination')}</label>
                    <InputText id='combination' value={params.combination} className="dropdown-all-width"
                      onChange={(event) => { setParams({ ...params, combination: event.target.value }); }} />
                  </span>
                </div>
              </div>
              <div className="p-col-6 p-md-2">
                <div className="p-inputgroup">
                  <span className="p-label">
                    <label htmlFor="combination">{t('status')}</label>
                    <Dropdown
                      value={params.active}
                      options={selectState}
                      onChange={(event) => { setParams({ ...params, active: event.target.value }); }}
                      placeholder={t('selectState')}
                      className="dropdown-all-width"
                    />
                  </span>
                </div>
              </div>
              <div className="p-col-5 p-md-4">
                <div className="p-inputgroup-button">
                  <Button icon="pi pi-search" onClick={() => searchTests()}
                    label={t('search')} style={{ marginBottom: '10px', width: 'auto' }}
                    className="tooltip button-table" />
                  <Button icon="pi pi-refresh" onClick={clean}
                    label={t('clean')} style={{ marginBottom: '10px', width: 'auto' }}
                    className="tooltip button-table" />
                  <Button icon="pi pi-plus" label={t('newTest')}
                    onClick={addTest} style={{ marginBottom: '10px', width: 'auto' }}
                    className="tooltip button-table" />
                </div>
              </div>
              </div>
            </div>
            <div className="ui-g-12">
              {loading && <div id="div-loader" className="spiner-container">
                <i className="pi pi-spin pi-spinner spiner" ></i>
              </div>}
              {!loading && showData &&
                <DataTable paginator={true} emptyMessage={t('emptyList')} rows={10} responsive={true} value={tests}>
                  <Column className="ui-column" field="name" header={t('name')} />
                  <Column className="ui-column" field="combination" header={t('combination')} />
                  <Column className="ui-column" field="weight" header={t('testWeight')} />
                  <Column className="ui-column" field="price" header={t('price')} />
                  <Column className="ui-column" body={showIsAuthorized} header={t('isAuthorized')} />
                  <Column className="ui-column" body={showIsACtive} header={t('active')} />
                  <Column className="ui-column-edit" header={t('edit')} body={actionEdit} />
                </DataTable>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TestList;
