import React, { useState, useEffect } from 'react';
import { Checkbox } from 'primereact/checkbox';
import { useTranslation } from 'react-i18next';
import { productUpdate } from '../../../services/product/product';
import { messageError, messageSuccess } from '../../../utils/messages';

const CheckClose = ({
  row,
  searchProducts
}) => {
  const { t } = useTranslation();
  const [isClose, setIsClose] = useState();
  const date = new Date().toISOString().slice(0, 19).replace('T', ' ', 'gi');

  const handleSummit = () => {
    let updateData = {
      id: row.id,
      approvalDate: date,
      isClose: !isClose,
      code: row.code,
      version: row.version,
      name: row.name
    };
    setIsClose(!isClose);
    productUpdate(updateData).then(() => {
      messageSuccess(t('messageSuccessProductUpdated'));
      searchProducts();
    }).catch(error => {
      (error.response) ? messageError(error.response.data.message) : messageError(t('messageErrorProductUpdated'));
    });
  };

  useEffect(() => {
    setIsClose(row.isClose);
  }, [row]);

  return (
    <Checkbox
      inputId="closeProduct"
      id="closeProduct"
      onChange={handleSummit}
      checked={Boolean(isClose)}
    />
  );
};

export default CheckClose;
