import { axiosInstance } from '../config';
import { getUserSession, getHeaders } from '../authentication';

export const getFlautes = () => {
  const config = getHeaders(getUserSession());
  return axiosInstance.get('catalogFlautes/list',config);
};

export const getFlautesByWall = (wall) => {
  const config = getHeaders(getUserSession());
  return axiosInstance.get('catalogFlautes/byWall/' + wall,config);
};

